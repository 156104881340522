import { useEffect, useMemo } from "react";
import { map } from "lodash";

import { EntityType, toTitleOrName } from "@api";
import { useSearch } from "@state/generic";

import { useGoTo } from "@utils/navigation";
import { plural } from "@utils/string";
import { switchEnum } from "@utils/logic";

import { CommandItem, CommandLoading } from "@ui/command-menu";
import { RelationLocationLabel } from "@ui/relation-label";
import { SpaceBetween } from "@ui/flex";
import { Text } from "@ui/text";

import { AppCommandsProps } from "../types";
import { useCommandSearch } from "../utils";

export const EntitySearchCommands = (rest: AppCommandsProps) => {
  const query = useCommandSearch();
  const searchable = useMemo(
    () => [
      "team",
      "meeting",
      "person",
      "roadmap",
      "calendar",
      "backlog",
      "project",
      "sprint",
      "campaign",
      "task",
      "outcome",
      "content",
      "action",
    ],
    []
  );

  if (!query) {
    return <></>;
  }

  return (
    <>
      {map(searchable, (type: EntityType) => (
        <SearchStoreCommands key={type} query={query} type={type} {...rest} />
      ))}
    </>
  );
};

const SearchStoreCommands = ({
  type,
  query,
  showEmpty,
}: AppCommandsProps & {
  type: EntityType;
  query: string;
  showEmpty?: boolean;
}) => {
  const goTo = useGoTo();
  // Increase debounce time for heavy searchables
  const weight = useMemo(
    () =>
      switchEnum(type, {
        team: 0.1,
        person: 0.3,
        backlog: 0.5,
        calendar: 0.5,
        roadmap: 0.5,
        meeting: 0.5,
        task: 2,
        action: 2,
        outcome: 2,
        content: 2,
        else: 1,
      }),
    [type]
  );

  const { results, setQuery, loading } = useSearch(type, "", {
    limit: 5,
    debounce: weight * 400,
    max: weight * 1000,
    empty: false,
    archived: false,
  });

  useEffect(() => setQuery(query), [query]);

  return (
    <>
      {loading && <CommandLoading />}

      {/* Hack to prevent selection state being stuck to bottom (search slack) items while these items load */}
      {!(query && !loading) && !results.length && (
        <CommandItem
          onSelectAction="none"
          value="skip: search more"
          style={{ display: "none" }}
        >
          <Text subtle>Search...</Text>
        </CommandItem>
      )}

      {!results?.length && !!showEmpty && (
        <CommandItem onSelectAction="none" value={`skip: no results ${type}`}>
          <Text subtle>
            No {plural(type)} found matching "{query}"
          </Text>
        </CommandItem>
      )}
      {map(results, (relation) => {
        return (
          <CommandItem
            key={relation.id}
            value={`skip: move to ${toTitleOrName(relation)} ${relation.id}`}
            onSelectAction="close"
            onClick={() => goTo(relation)}
          >
            <SpaceBetween>
              <RelationLocationLabel relation={relation} fit="content" />
            </SpaceBetween>
          </CommandItem>
        );
      })}
    </>
  );
};
