import { subWeeks } from "date-fns";
import { findIndex, map, now, orderBy } from "lodash";
import { useMemo } from "react";

import { Action, Entity, Meeting, Update } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import {
  useLazyEntity,
  useLazyQuery,
  useMarkAsSeen,
  useQueueUpdates,
} from "@state/generic";
import { useCurrentUser } from "@state/workspace";
import { isHost } from "@state/meetings";
import { toTemplateViewId, useLazyItemsForView } from "@state/views";

import { Maybe } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { useISODate } from "@utils/date-fp";
import { useSyncPathnameID } from "@utils/url";

import { useCurrentPage } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { CheckIcon, ClockHistory, RelationIcon, Search } from "@ui/icon";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { TemplateSchedulesPane } from "@ui/engine/schedule";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { render, useEngine } from "@ui/engine";
import {
  MeetingPane,
  MeetingHostPane,
  MeetingLinksPane,
} from "@ui/engine/meeting";
import { MeetingActionsPane } from "@ui/engine/action/pane";
import { Container } from "@ui/container";
import { Field } from "@ui/input";
import { ReadonlyTextBox } from "@ui/rich-text";
import { VStack } from "@ui/flex";

import AppPage from "./app-page";
import { MeetingParticipantPane } from "@ui/engine/meeting/host-pane";
import { isEmpty } from "@utils/rich-text";
import { asUpdate } from "@utils/property-mutations";
import { WithViewingWithin } from "@ui/viewing-within";

interface Props {
  meetingId: string;
}

const MeetingPage = ({ meetingId }: Props) => {
  const pushTo = usePushTo();
  const pageId = useCurrentPage();
  const me = useCurrentUser();
  const meeting = useLazyEntity<"meeting">(meetingId);
  const hosting = useMemo(
    () => !!meeting && isHost(meeting, me),
    [meeting?.owner?.id, me?.id]
  );

  const [page] = useRegisterPage(meetingId, meeting);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(meetingId, meeting?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(meetingId, pageId);

  if (!meeting) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!meeting} title={meeting?.name}>
      <StackContainer>
        <SmartBreadcrumbSheet />

        <MeetingPane id={meetingId} item={meeting} />

        <PaneManager size="secondary">
          {!meeting.template && (
            <>
              {hosting && (
                <PaneItem id="host" title="Host Tools" icon={Search}>
                  <MeetingHostPane meeting={meeting} />
                </PaneItem>
              )}

              {!hosting && <MeetingParticipantPane meeting={meeting} />}

              <PaneItem id="linked" title="Linked work" icon={RelationIcon}>
                <MeetingLinksPane meeting={meeting} />
              </PaneItem>

              <PaneItem
                id="actions"
                title="Actions"
                icon={<CheckIcon checked={true} color="red" />}
              >
                <MeetingActionsPane meeting={meeting} />
              </PaneItem>

              {!!meeting.refs.repeat?.length && (
                <PaneItem id="related" title="Last Meeting" icon={ClockHistory}>
                  <MeetingSchedule meeting={meeting} />
                </PaneItem>
              )}

              <PaneItem id="related" title="Past Meetings" icon={ClockHistory}>
                <RelatedMeetings meeting={meeting} />
              </PaneItem>
            </>
          )}

          {!!meeting.template && (
            <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
              <TemplateSchedulesPane template={meeting} />
            </PaneItem>
          )}
        </PaneManager>
      </StackContainer>
    </AppPage>
  );
};

function RelatedMeetings({ meeting }: { meeting: Maybe<Meeting> }) {
  const goTo = useGoTo();
  const engine = useEngine("meeting");
  const related = useLazyQuery(
    "meeting",
    useMemo(
      () => ({
        and: [
          {
            field: "name",
            type: "text",
            op: "equals",
            value: { text: meeting?.name },
          },
          {
            field: "id",
            type: "text",
            op: "does_not_equal",
            value: { text: meeting?.id },
          },
          {
            field: "start",
            type: "date",
            op: "after",
            value: {
              date: useISODate(meeting?.start, (s) => subWeeks(s || now(), 6)),
            },
          },
          {
            field: "start",
            type: "date",
            op: "before",
            value: { date: meeting?.start },
          },
        ],
      }),
      [meeting?.start]
    )
  );
  const sorted = useMemo(
    () => orderBy(related, (r) => r.start, "desc"),
    [related]
  );

  if (!sorted.length) {
    return <></>;
  }

  return (
    <Sheet size="secondary" height="content">
      <PaneHeader title="Past Meetings" />

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(sorted, (meeting) =>
              render(engine.asMenuItem, {
                key: meeting.id,
                item: meeting,
                onOpen: goTo,
              })
            )}
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
}

function MeetingSchedule({ meeting }: { meeting: Maybe<Meeting> }) {
  const goTo = useGoTo();
  const engine = useEngine("meeting");
  const schedule = useLazyEntity<"schedule">(
    meeting?.refs.repeat?.[0]?.id || ""
  );
  const mutate = useQueueUpdates();
  const lastInstanceId = useMemo(() => {
    const index = findIndex(schedule?.instances, (i) => i.id === meeting?.id);
    return schedule?.instances?.[index - 1]?.id;
  }, [schedule?.instances]);
  const lastMeeting = useLazyEntity<"meeting">(lastInstanceId);
  const actions = useLazyItemsForView(
    toTemplateViewId("meeting-actions", { parent: lastInstanceId })
  );
  const actionEngine = useEngine("action");

  if (!lastMeeting) {
    return <></>;
  }

  return (
    <Sheet size="secondary" height="content">
      <PaneHeader title="Last Meeting" />

      <PaneContainer>
        <WithViewingWithin scope={lastMeeting.id}>
          <Container padding="none" inset="horizontal" stack="vertical" gap={6}>
            {render(engine.asMenuItem, {
              key: lastMeeting.id,
              item: lastMeeting,
              onOpen: goTo,
            })}

            {!isEmpty(lastMeeting.summary) && (
              <Field label="Summary">
                <ReadonlyTextBox text={lastMeeting.summary} />
              </Field>
            )}

            {!!actions.items.sorted?.length && (
              <Field label="Actions">
                <VStack gap={0}>
                  {map(actions.items.sorted, (action: Action) =>
                    render(actionEngine.asMenuItem, {
                      key: action.id,
                      item: action,
                      onChange: (cs) =>
                        mutate(asUpdate(action, cs) as Update<Entity>),
                      onOpen: goTo,
                    })
                  )}
                </VStack>
              </Field>
            )}
          </Container>
        </WithViewingWithin>
      </PaneContainer>
    </Sheet>
  );
}

export default MeetingPage;
