import { first, isString } from "lodash";

import { ID, Person, WorkspaceConfig } from "@api/types";
import { Block, KnownBlock, WebClient } from "@slack/web-api";

import { onClient } from "@utils/ssr";
import { Maybe } from "@utils/maybe";
import { formatMarkdown } from "@utils/slack";

const toSlackConfig = () => ({
  slackApiUrl: onClient(() => `${window.location.origin}/api/proxy/slack`),
});

let client = new WebClient(undefined, toSlackConfig());
let userClient = new WebClient(undefined, toSlackConfig());
let workspace: Maybe<WorkspaceConfig> = undefined;

export const setSlackAuth = (ws: WorkspaceConfig) => {
  client = new WebClient(
    ws.auths?.slack?.bot?.token ||
      ws.auths?.slack?.workspace?.token ||
      undefined,
    toSlackConfig()
  );
  // @ts-ignore
  delete client["axios"].defaults.headers["User-Agent"];

  if (ws.auths?.slack?.user?.token) {
    userClient = new WebClient(ws.auths?.slack?.user?.token, toSlackConfig());
    // @ts-ignore
    delete userClient["axios"].defaults.headers["User-Agent"];
  }
  workspace = ws;
};

export const getUser = async (id: string, token: string) =>
  (await client.users.info({ user: id, token: token })).user;

export const getChannels = async () =>
  (
    await client.conversations.list({
      exclude_archived: true,
      limit: 1000,
    })
  ).channels;

export const getThreads = async (channel: ID) =>
  (
    await client.conversations.history({
      channel: channel,
      exclude_archived: true,
      include_all_metadata: true,
    })
  ).messages;

export const getMessage = async (channel: ID, thread: ID) =>
  first(
    (
      await client.conversations.history({
        channel: channel,
        latest: thread,
        exclude_archived: true,
        limit: 1,
        include_all_metadata: true,
        inclusive: true,
      })
    ).messages
  );

export const getMessages = async (channel: ID, thread: ID) =>
  (
    await client.conversations.replies({
      channel: channel,
      ts: thread,
      include_all_metadata: true,
    })
  ).messages || [];

export const getMessageLink = async (channel: ID, message: ID) =>
  (await client.chat.getPermalink({ channel: channel, message_ts: message }))
    .permalink;

export const searchMessages = async (query: string) =>
  (
    await userClient.search.messages({
      query,
      highlight: false,
      sort: "timestamp",
      sort_dir: "desc",
      count: 8,
    })
  )?.messages?.matches || [];

// Mutations

export const createThread = async (
  channel: ID,
  message: string | (KnownBlock | Block)[],
  as?: Person
) =>
  (
    await client.chat.postMessage({
      metadata: {
        event_type: "traction_message",
        event_payload: { user: as?.id || "" },
      },
      channel: channel,
      text: isString(message) ? formatMarkdown(message) : undefined,
      blocks: !isString(message) ? message : undefined,
      mrkdwn: true,
      username: as?.name,
      icon_url: as?.avatar,
    })
  ).message;

export const replyThread = async (
  channel: ID,
  thread: ID,
  message: string | (KnownBlock | Block)[],
  as?: Person
) =>
  (
    await client.chat.postMessage({
      metadata: {
        event_type: "traction_message",
        event_payload: { user: as?.id || "" },
      },
      channel: channel,
      thread_ts: thread,
      text: isString(message) ? formatMarkdown(message) : undefined,
      blocks: !isString(message) ? message : undefined,
      mrkdwn: true,
      username: as?.name,
      icon_url: as?.avatar,
    })
  ).message;
