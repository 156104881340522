import { useMemo } from "react";
import { flatMap, map } from "lodash";

import { toLabel, useLazyEntities } from "@state/generic";
import { useLazyMyTeams } from "@state/teams";
import { useMe } from "@state/persons";

import { uniqRefs } from "@utils/relation-ref";
import { useGoTo } from "@utils/navigation";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { RelationIcon } from "@ui/relation-label";

import { AppCommandsProps } from "./types";

export const PinnedCommands = ({}: AppCommandsProps) => {
  const me = useMe();
  const goTo = useGoTo();
  const myTeams = useLazyMyTeams();

  const allRefs = useMemo(
    () =>
      uniqRefs([
        ...(me.refs.pins || []),
        ...flatMap(myTeams, (t) => t.refs.pins || []),
      ]),
    [me, myTeams]
  );

  const pins = useLazyEntities(allRefs);

  if (!pins?.length) {
    return <></>;
  }

  return (
    <CommandGroup label="Pinned">
      {map(pins, (f) => (
        <CommandItem
          key={f.id}
          value={`go to ${toLabel(f)} ${f.id}`}
          icon={<RelationIcon relation={f} />}
          onClick={() => goTo(f)}
        >
          {toLabel(f)}
        </CommandItem>
      ))}
    </CommandGroup>
  );
};
