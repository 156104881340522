import { when } from "@utils/maybe";

import { CounterIcon, EllipsisH } from "@ui/icon";
import { Label } from "@ui/label";

import { MenuItem, MenuItemProps } from "./default";

type Props = MenuItemProps & {
  count?: number;
};

export function MoreMenuItem({
  text,
  children,
  className,
  count,
  onClick,
  icon,
  ...props
}: Props) {
  return (
    <MenuItem
      {...props}
      icon={
        icon ??
        when(count, (c) => <CounterIcon color="subtle" count={c} />) ??
        EllipsisH
      }
      onClick={onClick}
      className={className}
    >
      {children || <Label subtle>{text || "Show more"}</Label>}
    </MenuItem>
  );
}
