import { CSSProperties, ReactNode } from "react";

import { Color } from "@api";

import { cx } from "@utils/class-names";
import { isDefined } from "@utils/maybe";

import { HStack } from "@ui/flex";

import styles from "./text.module.css";

interface Props {
  children: ReactNode;
  className?: string;
  subtle?: boolean;
  color?: Color | "ai" | "default";
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
}

type PropsWBold = Props & {
  bold?: boolean;
};

export { SectionLabel } from "./label";

export const Heading = ({
  bold,
  color,
  children,
  subtle,
  className,
  ...rest
}: PropsWBold) => (
  <h1
    {...rest}
    className={cx(
      styles.heading,
      bold && styles.bold,
      subtle && styles.subtle,
      color && styles[color],
      className
    )}
  >
    {children}
  </h1>
);

export const TextXLarge = ({
  bold,
  children,
  subtle,
  color,
  className,
  ...rest
}: PropsWBold) => (
  <p
    {...rest}
    className={cx(
      styles.textXLarge,
      bold && styles.bold,
      subtle && styles.subtle,
      color && styles[color],
      className
    )}
  >
    {children}
  </p>
);

export const TextLarge = ({
  bold,
  children,
  subtle,
  color,
  className,
  ...rest
}: PropsWBold) => (
  <p
    {...rest}
    className={cx(
      styles.textLarge,
      bold && styles.bold,
      subtle && styles.subtle,
      color && styles[color],
      className
    )}
  >
    {children}
  </p>
);

export const TextMedium = ({
  bold,
  children,
  subtle,
  color,
  className,
  ...rest
}: PropsWBold) => (
  <p
    {...rest}
    className={cx(
      styles.textMedium,
      bold && styles.bold,
      subtle && styles.subtle,
      color && styles[color],
      className
    )}
  >
    {children}
  </p>
);

export const Text = ({
  bold,
  color,
  children,
  subtle,
  className,
  ...rest
}: PropsWBold) => (
  <p
    {...rest}
    className={cx(
      styles.text,
      bold && styles.bold,
      subtle && styles.subtle,
      color && styles[color],
      className
    )}
  >
    {children}
  </p>
);

export const TextSmall = ({
  bold,
  children,
  subtle,
  color,
  className,
  ...rest
}: PropsWBold) => (
  <p
    {...rest}
    className={cx(
      styles.textSmall,
      bold && styles.bold,
      subtle && styles.subtle,
      color && styles[color],
      className
    )}
  >
    {children}
  </p>
);

export const SubtleText = ({
  bold,
  color,
  children,
  className,
  ...rest
}: PropsWBold) => (
  <p
    {...rest}
    className={cx(
      styles.text,
      styles.subtle,
      bold && styles.bold,
      color,
      className
    )}
  >
    {children}
  </p>
);

export const SectionTitle = ({
  title,
  count,
}: {
  title: string | ReactNode;
  count?: number;
}) => (
  <HStack gap={6} align="baseline">
    <TextXLarge bold>{title}</TextXLarge>
    {isDefined(count) && (
      <TextLarge bold subtle>
        {count}
      </TextLarge>
    )}
  </HStack>
);
