import { first, flatMap, flatten, map, reduce } from "lodash";
import { useRecoilValue } from "recoil";
import { useState, useEffect, useCallback, useMemo } from "react";

import {
  DatabaseID,
  Entity,
  EntityType,
  getByIds,
  Error,
  PropertyValueRef,
  Update,
  isError,
} from "@api";
import { EntityForType, TypeForEntity } from "@api/mappings";

import { StoreState, getItem } from "@state/store";
import { useAllStores, useQueueUpdates } from "@state/generic";
import { propertiesForScope } from "@state/databases";
import { ActiveWorkspaceSessionAtom, useCurrentUser } from "@state/workspace";

import { ensureMany, OneOrMany, pushDirty, reduceAsync } from "@utils/array";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { inflateStatuses } from "@utils/property-refs";
import { mapAll } from "@utils/promise";
import { typeFromId } from "@utils/id";

import {
  WorkflowData,
  WorkflowAction,
  WorkflowDefinition,
  WorkflowSuggestion,
  WorkflowContext,
  RefOrID,
  toId,
} from "./types";
import {
  whenActionWorkflow,
  whenWillUpdate,
  whenSuggestionWorkflow,
  whenDidUpdate,
} from "./utils";
import { getTriggers, getActions, getSuggestions } from "./definitions";

const omitError = (e: Entity | Error): Maybe<Entity> =>
  isError(e) ? undefined : e;

const carryMode = <T extends Entity>(
  from: Update<T>,
  to: OneOrMany<Update<T>>
) => map(ensureMany(to || []), (a) => ({ ...a, mode: from.mode }));

const useToWorkflowContext = () => {
  const me = useCurrentUser();
  const stores = useAllStores();
  const session = useRecoilValue(ActiveWorkspaceSessionAtom);

  return useCallback(
    <T extends EntityType>(
      source: DatabaseID<T>
    ): WorkflowContext<EntityForType<T>> => {
      const props = propertiesForScope(stores.props, source, me);

      const getItemFromStore = (refID: Maybe<RefOrID>) =>
        when(refID && toId(refID), (id) =>
          getItem(stores[typeFromId<EntityType>(id)] as StoreState<Entity>, id)
        );

      return {
        stores,
        props,
        session,

        getItem: getItemFromStore,
        getItems: (ids) => map(ids, getItemFromStore),

        fetchItem: async (id: Maybe<RefOrID>) => {
          if (!id) return;

          return (
            getItemFromStore(id) ||
            when(first(await getByIds([toId(id)])), omitError)
          );
        },

        fetchItems: async (ids: Maybe<RefOrID[]>) => {
          if (!ids?.length) return [];

          const loaded = maybeMap(ids, getItemFromStore);

          // If all items are loaded already
          if (loaded.length === ids.length) {
            return loaded;
          }

          return map(
            await getByIds(map(ids, toId)),
            omitError
          ) as Maybe<Entity>[];
        },
      };
    },
    [stores, session]
  );
};

export const useSuggestionWorkflows = <T extends Entity = Entity>() => {
  const stores = useAllStores();
  const toContext = useToWorkflowContext();

  return useCallback(
    (update: Update<T>): WorkflowSuggestion<T>[] => {
      const entity = getItem<T>(
        // TODO: Need to figure out...
        stores[update.source.type] as any as StoreState<T>,
        update.id
      );

      if (!entity) {
        return [];
      }

      // Pull out all props for this entity
      const context = toContext(entity.source);
      const inflated = inflateStatuses(entity, context.props);

      return reduce(
        getSuggestions(entity.source.type) as WorkflowDefinition<Entity>[],
        (r, w) =>
          whenSuggestionWorkflow(
            w,
            entity.source.type as TypeForEntity<T>,
            (w) => {
              const data = { entity: inflated, update };

              if (w.allowed(data, context)) {
                return [...r, w];
              }

              return r;
            }
          ) || r,
        [] as WorkflowSuggestion<T>[]
      );
    },
    [stores, toContext]
  );
};

export const useWillUpdateWorkflows = <T extends Entity = Entity>() => {
  const me = useCurrentUser();
  const stores = useAllStores();
  const toContext = useToWorkflowContext();

  const runOnChange = useCallback(
    (entity: T, update: Update<T>, depth: number = 0): Update<T>[] => {
      // Pull out all props for this entity
      const context = toContext(entity?.source || update.source);
      const inflated = inflateStatuses(entity, context.props);
      const triggers = getTriggers(
        entity.source.type
      ) as WorkflowDefinition<Entity>[];

      return reduce(
        triggers,
        (r, w) =>
          whenWillUpdate(w, entity.source.type as TypeForEntity<T>, (w) => {
            const data = { entity: inflated, update };

            if (!w.allowed(data, context)) {
              return r;
            }

            const updates = carryMode(update, w.execute(data, context) || []);

            if (!updates?.length) {
              return r;
            }

            pushDirty(r, ...updates);

            // Allow 3 levels of triggers to run
            if (depth < 3) {
              pushDirty(
                r,
                ...flatMap(updates, (u) => runOnChange(entity, u, depth + 1))
              );
            }

            return r;
          }) || r,
        [] as Update<T>[]
      );
    },
    [stores, toContext]
  );

  return runOnChange;
};

export const useDidUpdateWorkflows = <T extends Entity = Entity>() => {
  const me = useCurrentUser();
  const stores = useAllStores();
  const toContext = useToWorkflowContext();

  // Runs asyncronous
  const runOnChange = useCallback(
    async (
      entity: T,
      update: Update<T>,
      depth: number = 0
    ): Promise<Update<T>[]> => {
      // Pull out all props for this entity
      const context = toContext(entity.source);
      const inflated = inflateStatuses(entity, context.props);
      const triggers = getTriggers(
        update.source.type
      ) as WorkflowDefinition<Entity>[];

      return reduceAsync(
        triggers,
        async (r, w) =>
          whenDidUpdate(
            w,
            update.source.type as TypeForEntity<T>,
            async (w) => {
              const data = { entity: inflated, update };

              if (!w.allowed(data, context)) {
                return r;
              }

              const updates = carryMode(
                update,
                (await w.execute(data, context)) || []
              );

              if (!updates?.length) {
                return r;
              }

              pushDirty(r, ...updates);

              // Allow 3 levels of triggers to run
              if (depth < 3) {
                pushDirty(
                  r,
                  ...flatten(
                    await mapAll(updates, (u) =>
                      runOnChange(entity, u, depth + 1)
                    )
                  )
                );
              }

              return r;
            }
          ) || r,
        [] as Update<T>[]
      );
    },
    [stores, toContext]
  );

  return runOnChange;
};

export const useActionWorkflows = <E extends Entity>(
  entity: Maybe<E>
): [WorkflowAction<E>[], Maybe<WorkflowData<E>>] => {
  const stores = useAllStores();
  const toContext = useToWorkflowContext();
  const [actions, setActions] = useState<WorkflowAction<E>[]>([]);
  const data = useMemo(() => when(entity, (entity) => ({ entity })), [entity]);

  useEffect(() => {
    if (!entity) {
      return;
    }

    const context = toContext(entity.source);
    const inflated = inflateStatuses(entity, context.props);
    setActions(
      maybeMap(
        getActions(entity.source.type) as WorkflowDefinition<Entity>[],
        (d) =>
          whenActionWorkflow(d, entity.source.type as TypeForEntity<E>, (d) =>
            d.allowed({ entity: inflated }, context) ? d : undefined
          )
      )
    );
  }, [entity, stores, entity]);

  return useMemo(
    () => [actions, data] as [WorkflowAction<E>[], Maybe<WorkflowData<E>>],
    [actions, data]
  );
};

export const useRunAction = <T extends EntityType, E extends Entity>(
  type: T,
  data: Maybe<WorkflowData<E>>
) => {
  const stores = useAllStores();
  const mutate = useQueueUpdates();
  const toContext = useToWorkflowContext();

  const context = useMemo(
    () => when(data?.entity?.source, toContext),
    [data?.entity?.source, toContext]
  );

  const run = useCallback(
    (
      a: WorkflowAction<E> | WorkflowSuggestion<E>,
      collected: PropertyValueRef<E>[] = []
    ) => {
      if (!data || !context) {
        return;
      }

      const updates = ensureMany(
        a.execute({ ...data, collected }, context) || []
      );

      if (!!updates?.length) {
        mutate(updates as Update<Entity>[]);
      }
    },
    [context, data, stores]
  );

  return { run, context };
};

export function useRunWorkflowState<
  T extends EntityType,
  E extends EntityForType<T>
>(type: T, data: Maybe<WorkflowData<E>>) {
  const { run, context } = useRunAction<T, E>(type, data);
  const [collecting, setCollecting] = useState<Maybe<WorkflowAction<E>>>();

  const onRun = useCallback(
    (action: WorkflowAction<E>, values?: PropertyValueRef<E>[]) => {
      if (action.collect && !values) {
        setCollecting(action);
      } else {
        run(action, values);
        setCollecting(undefined);
      }
    },
    [run, setCollecting]
  );

  const cancelCollecting = useCallback(() => setCollecting(undefined), []);

  return useMemo(
    () => ({ run: onRun, context, collecting, cancelCollecting }),
    [onRun, context, collecting, cancelCollecting]
  );
}
