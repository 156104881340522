import { forEach } from "lodash";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import TextStyle from "@tiptap/extension-text-style";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import FileHandler from "@tiptap-pro/extension-file-handler";

import { RichText, Link as APILink, Update, Entity } from "@api";

import { useDebouncedCallback } from "@utils/hooks";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { debug } from "@utils/debug";
import { Maybe } from "@utils/maybe";
import { isEmpty, toHtml } from "@utils/rich-text";

import { NoSelectable } from "@ui/selectable-items";
import { withLoading } from "@ui/notifications";
import { Size } from "@ui/types";

import { GlobalSuggestion, Mentioner } from "./mention-extension";
import { Link, useInternalNavigator } from "./link-extension";
import { FormattingMenu, TrailingParagraph } from "./menus";
import { Embed } from "./embed-extension";
import { PageExtension } from "./page-extension";
import { BoardExtension } from "./board-extension";
import { extensions as tableExtensions, TableMenuBar } from "./table-extension";
import { SlashExtension, suggestions } from "./slash-extension";

import { isFocused } from "./utils";

import styles from "./document-editor.module.css";
import { useQueueUpdates } from "@state/generic";
import { OneOrMany } from "@utils/array";

export interface DocumentEditorProps {
  content: Maybe<RichText>;
  scope?: string;
  placeholder?: string;
  textSize?: Size;
  editable?: boolean;
  autoFocus?: boolean;
  onChanged: Fn<RichText, void>;
  onBlur?: Fn<RichText, void>;
  onPaste?: Fn<File[], Promise<APILink[]>>;
  updateDelay?: number;
  newLineSpace?: "small" | "large";
  className?: string;
}

interface ExtensionOpts {
  scope?: string;
  placeholder?: string;
  mutate?: Fn<OneOrMany<Update<Entity>>, void>;
  onPaste?: Fn<File[], Promise<APILink[]>>;
}

const configureExtensions = ({
  scope,
  placeholder,
  onPaste,
  mutate,
}: ExtensionOpts) => [
  Placeholder.configure({
    emptyEditorClass: styles.placeholder,
    placeholder: placeholder,
  }),

  TextStyle.configure({}),

  PageExtension.configure({ mutate, scope }),

  Embed.configure({}),

  BoardExtension.configure({ scope }),

  FileHandler.configure({
    allowedMimeTypes: ["image/jpeg", "image/png", "image/gif"],
    onPaste: (editor, files, htmlContent) => {
      if (!onPaste) {
        return;
      }

      withLoading(onPaste(files), {
        message: "Uploading files...",
        position: "bottom-center",
      }).then((uploads) => {
        forEach(uploads, ({ url, text }) => {
          editor
            .chain()
            .focus()
            .setEmbed({ url, title: text, type: "image" })
            .run();
        });
      });
    },
  }),

  Mentioner.configure({
    suggestion: GlobalSuggestion({ scope }),
  }),

  SlashExtension.configure({
    suggestion: suggestions,
  }),

  TaskList,
  TaskItem.configure({
    nested: true,
  }),

  ...tableExtensions(),

  Link.configure({
    autolink: false,
    linkOnPaste: true,
  }),

  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
];

export const DocumentEditor = ({
  content,
  placeholder,
  onChanged,
  onPaste,
  autoFocus,
  newLineSpace,
  textSize = "medium",
  scope,
  className,
  editable = true,
  onBlur,
  updateDelay = 1000,
}: DocumentEditorProps) => {
  useInternalNavigator();
  const mutate = useQueueUpdates();
  const onChangedRef = useRef(onChanged);

  const onChangeDebounced = useDebouncedCallback(
    (html: string) => onChangedRef.current?.({ html }),
    updateDelay,
    { trailing: true }
  );
  const extensions = useMemo(
    () => configureExtensions({ scope, placeholder, onPaste, mutate }),
    [placeholder, onPaste]
  );

  const editor = useEditor({
    extensions: extensions,
    content: toHtml(content),

    editorProps: {
      attributes: {
        class: cx(styles.tiptap, styles.document, styles[textSize]),
      },
    },

    // triggered on every change
    onUpdate: ({ editor }) => {
      onChangeDebounced(editor.getHTML());
    },

    onBlur: ({ editor }) => {
      onBlur?.({ html: editor.getHTML() });
    },
  });

  // When content changes, check if the editor is focused and if not set the contnet
  useEffect(() => {
    if (
      !!editor &&
      (!isEmpty(content) || !editor.isEmpty) &&
      !isFocused(editor)
    ) {
      editor.commands.setContent(toHtml(content));
    } else {
      debug("Skipping update", {
        editor: editor,
        isEmpty: isEmpty(content),
        isFocused: editor && isFocused(editor),
        content: content,
      });
    }
  }, [content]);

  // Update editor editable when param changes
  useEffect(() => {
    // Important: Second param is to prevent the editor from firing the update event
    // Otherwise causes an infinite loop of updates with old values
    editor?.setEditable(editable, false);
  }, [editable]);

  // Keep onChange callback in sync
  useEffect(() => {
    onChangedRef.current = onChanged;
  }, [onChanged]);

  // Auto focus when needed
  useLayoutEffect(() => {
    if (autoFocus) {
      editor?.chain().focus().run();
    }
  }, [autoFocus, editor]);

  if (!editor) {
    return <></>;
  }

  return (
    <div
      className={cx(styles.wrapper, className)}
      onClick={() => editor.chain().focus().run()}
    >
      <NoSelectable>
        <EditorContent editor={editor} />
        {!!newLineSpace && (
          <TrailingParagraph size={newLineSpace} editor={editor} />
        )}
        <TableMenuBar editor={editor} />
        <FormattingMenu editor={editor} />
      </NoSelectable>
    </div>
  );
};

export const ReadonlyDocument = ({
  content,
  textSize = "medium",
  className,
}: Omit<DocumentEditorProps, "onChanged" | "newLineSpace" | "placeholder">) => {
  useInternalNavigator();

  const editor = useEditor({
    extensions: useMemo(() => configureExtensions({}), []),
    content: toHtml(content),
    editable: false,

    editorProps: {
      attributes: {
        class: cx(styles.tiptap, styles.document, styles[textSize]),
      },
    },
  });

  useEffect(() => {
    if (content && editor) {
      editor.commands.setContent(toHtml(content));
    }
  }, [content]);

  if (!editor) {
    return <></>;
  }

  return (
    <div
      className={cx(styles.wrapper, className)}
      onClick={() => editor.chain().focus().run()}
    >
      <EditorContent editor={editor} />
    </div>
  );
};
