import { getRecoil } from "recoil-nexus";
import {
  atom,
  AtomEffect,
  RecoilState,
  selector,
  selectorFamily,
} from "recoil";
import { map, omit, values } from "lodash";

import { User, Workspace, WorkspaceConfig } from "@api";
import { setNotionAuth } from "@api/integrations/notion";
import { setSlackAuth } from "@api/integrations/slack";
import { setTractionToken } from "@api/integrations/traction";

import { setConfig } from "@state/realtime/service";
import { getItem, setItem, StoreState } from "@state/store";
import { ID } from "@state/types";

import { Maybe, required, when } from "@utils/maybe";
import { onClient } from "@utils/ssr";
import { Fn, lazyCachedFunc, simpleSerializer } from "@utils/fn";
import { isDefault } from "@utils/recoil";

import { localStorageEffect, getStored } from "../local-storage-effect";
import { onUnauthed, toActive } from "./utils";
import { WorkspaceSessionState } from "./types";

/**
 * Pure Workspace Store
 */

export type WorkspaceStoreState = StoreState<Workspace>;

const dbStoreDefault = () => ({
  updatedAt: undefined,
  lookup: {},
  unsaved: [],
});

export const WorkspaceStoreAtom = atom<WorkspaceStoreState>({
  key: "WorkspaceStoreAtom",
  default: dbStoreDefault(),
  effects: [
    localStorageEffect<WorkspaceStoreState>({
      key: "traction.store.workspaces",
      props: ["lookup", "unsaved", "updatedAt"],
    }),
  ],
});

export const WorkspaceAtom = selectorFamily({
  key: "WorkspaceAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(WorkspaceStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(WorkspaceStoreAtom, setItem(newValue));
      }
    },
});

/*
 * Workspace Session Configs
 */

// Update all api services to use saves tokens
const updateTokens = (config: WorkspaceSessionState) =>
  onClient(() => {
    const active = toActive(config);

    // Redirect to login if no active workspace and not logging in
    if (!active) {
      return;
    }

    setTractionToken(active?.token);

    setConfig(active?.workspace?.id, active?.user?.id);

    map(values(omit(active?.auths, "__typename")), (auth) => {
      switch (auth?.source) {
        case "notion":
          return setNotionAuth(active);
        case "slack":
          return setSlackAuth(active);
        default:
          return;
      }
    });
    return config;
  });

const updateApiTokens =
  (): AtomEffect<WorkspaceSessionState> =>
  ({ onSet }) =>
    onClient(() => {
      onSet(updateTokens);
    });

const defaultWorkspaceState = () =>
  when(getStored("traction.auth.workspaces"), updateTokens) || {
    active: "",
    workspaces: [],
  };

export const WorkspaceSessionStateAtom = atom<WorkspaceSessionState>({
  key: "WorkspaceSessionStateAtom",
  default: defaultWorkspaceState(),
  effects: [
    localStorageEffect<WorkspaceSessionState>({
      key: "traction.auth.workspaces",
      skipDefault: true,
    }),
    updateApiTokens(),
  ],
});

export const ActiveWorkspaceId = selector<ID>({
  key: "ActiveWorkspaceId",
  get: ({ get }) =>
    required(
      get(WorkspaceSessionStateAtom)?.active,
      () => "No active workspace."
    ),
});

export const ActiveWorkspaceSessionAtom = selector<WorkspaceConfig>({
  key: "ActiveWorkspaceSessionAtom",
  get: ({ get }) => {
    const session = get(WorkspaceSessionStateAtom);
    return required(toActive(session), () => "No active workspace.");
  },
});

export const MaybeActiveWorkspaceSessionAtom = selector<Maybe<WorkspaceConfig>>(
  {
    key: "MaybeActiveWorkspaceSessionAtom",
    get: ({ get }) => {
      const session = get(WorkspaceSessionStateAtom);
      return toActive(session);
    },
  }
);

export const ActiveWorkspaceAtom = selector<Maybe<Workspace>>({
  key: "ActiveWorkspaceAtom",
  get: ({ get }) => {
    const activeId = get(ActiveWorkspaceId);
    return get(WorkspaceAtom(activeId));
  },
});

export const ActiveUserAtom = selector<Maybe<User>>({
  key: "ActiveUserAtom",
  get: ({ get }) => {
    const active = get(ActiveWorkspaceSessionAtom);

    if (!active?.user) {
      onUnauthed();
      return undefined;
    }

    return active?.user;
  },
});

export const WorkspaceWrappedAtom = <S>(
  key: string,
  Atom: Fn<string, RecoilState<S>>
) =>
  selector<S>({
    key: key,
    get: ({ get }) => {
      const wid = get(ActiveWorkspaceId);
      return get(Atom(wid || "unauthed"));
    },
    set: ({ get, set }, v) => {
      const wid = get(ActiveWorkspaceId);
      return set(Atom(wid || "unauthed"), v);
    },
  });

export const cachedFuncByWorkspace = <
  R,
  A extends any[],
  F extends (...args: A) => R
>(
  getFunc: Fn<void, F>,
  milliseconds: number,
  normalizer?: (args: Parameters<F>) => string
): F =>
  lazyCachedFunc<R, A, F>(
    getFunc,
    milliseconds,
    (args: Parameters<F>): string => {
      const workspaceId = getRecoil(ActiveWorkspaceId);
      return workspaceId + (normalizer || simpleSerializer)?.(args);
    }
  );
