import { ReactNode, useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { find, isString, map } from "lodash";

import { ID, Integration } from "@api";
import {
  searchAll,
  searchDatabases,
  searchPages,
} from "@api/integrations/notion";

import { cx } from "@utils/class-names";
import { Maybe, when } from "@utils/maybe";
import { useAsyncEffect } from "@utils/effects";
import { toIcon, toNotionUrl, toTitle } from "@utils/notion";
import { withHardHandle } from "@utils/event";
import { ComponentOrNode } from "@utils/react";
import { useGoTo } from "@utils/navigation";

import { ArrowUpRight, Notion, PageIcon } from "@ui/icon";
import { MenuItem } from "@ui/menu-item";
import { Button } from "@ui/button";
import { Text, TextSmall } from "@ui/text";
import { useOpenState } from "@ui/dropdown";
import { SpaceBetween } from "@ui/flex";
import { Select, SelectProps } from "./single-select";
import { Label } from "@ui/label";
import { DocumentLocation } from "@ui/document-location";

import styles from "./select.module.css";

interface Option {
  id: ID;
  name: string;
  icon?: ComponentOrNode;
}

type Props = {
  value: Maybe<string>;
  label?: string;
  mode?: "pages" | "databases" | "all";
  showFullPath?: boolean;
  onChange: (id: Maybe<string>, option?: Option) => void;
  children?: ReactNode;
} & Pick<
  SelectProps<Option>,
  "defaultOpen" | "portal" | "placeholder" | "className"
>;

export const NotionSelect = ({
  className,
  placeholder = "Search or link to notion...",
  mode = "all",
  label,
  value: _value,
  showFullPath = false,
  portal = true,
  children,
  defaultOpen,
  onChange,
  ...props
}: Props) => {
  const goTo = useGoTo();
  const [search, setSearch] = useState<Maybe<string>>(_value);
  const [options, setOptions] = useState<Option[]>([]);
  const [value, setValue] = useState<Option>();
  const [open, setOpen] = useOpenState(defaultOpen ?? false);

  const goToNotion = useCallback(
    (page?: string) => when(page, (p) => goTo(toNotionUrl(p))),
    [goTo]
  );

  useEffect(() => {
    setSearch(_value);
    setValue(find(options, (o) => o.id === _value));
  }, [_value]);

  const doSearch = useDebouncedCallback(
    (s: string, mode: Props["mode"]) =>
      mode === "all"
        ? searchAll(s)
        : mode === "databases"
        ? searchDatabases(s)
        : searchPages(s),
    800,
    { leading: true }
  );

  useAsyncEffect(async () => {
    if (search === value?.id && value?.name) {
      return;
    }

    const cleaned = search?.trim();
    if (cleaned) {
      const res = await doSearch(cleaned, mode);

      const options = map(res, (p) => ({
        id: p.id,
        name: toTitle(p) || "",
        icon: when(toIcon(p), (i) => (
          <PageIcon icon={i} />
        )) as Maybe<ComponentOrNode>,
      }));

      setOptions(options);

      if (!value || value.id !== _value) {
        setValue(find(options, (o) => o.id === _value));
      }
    }
  }, [search]);

  return (
    <Select
      {...props}
      portal={portal}
      open={open}
      setOpen={setOpen}
      value={value}
      options={options}
      onSearch={setSearch}
      placeholder={placeholder}
      onChange={(v) => onChange?.(v?.id, v)}
      overrides={{
        /*
          TODO: Need to pull all these out into components
          for performance reasons aparently
        */
        Option: ({ innerRef, innerProps, isSelected, isFocused, data }) => (
          <div ref={innerRef} {...innerProps}>
            <MenuItem
              className={cx(styles.menuItem, isFocused && styles.focused)}
              icon={data.icon || Notion}
            >
              <SpaceBetween gap={6}>
                {data.name}
                <Button
                  size="tiny"
                  icon={ArrowUpRight}
                  onClick={withHardHandle(() => goToNotion(value?.id))}
                />
              </SpaceBetween>
            </MenuItem>
          </div>
        ),
      }}
    >
      <Button
        className={cx(
          styles.button,
          !isString(className) && className?.trigger
        )}
        subtle
        size="small"
      >
        <SpaceBetween gap={6}>
          {showFullPath &&
            (when(_value || value?.id, (id) => (
              <DocumentLocation
                docId={id}
                source={Integration.Notion}
                clickable={false}
              />
            )) || <Text subtle>{placeholder}</Text>)}

          {!showFullPath && (
            <Label icon={value?.icon || Notion}>
              {_value && !value && <Text subtle>Loading...</Text>}
              {!!value && value.name}
              {!_value && !value && <Text subtle>{placeholder}</Text>}
            </Label>
          )}
          {value && (
            <Button
              size="tiny"
              icon={ArrowUpRight}
              onClick={withHardHandle(() => goToNotion(value?.id))}
            />
          )}
        </SpaceBetween>
        {label && <TextSmall subtle>{label}</TextSmall>}
      </Button>
    </Select>
  );
};
