import slackifyMarkdown from "slackify-markdown";
import { reduce } from "lodash";
import { User as SlackUser } from "@slack/web-api/dist/response/UsersInfoResponse";
import { KnownBlock } from "@slack/web-api";

import {
  Integration,
  PersonRef,
  Entity,
  ID,
  Project,
  Task,
  Person,
  toTitleOrName,
  HasCode,
} from "@api";

import { Maybe, defs, safeAs, when } from "@utils/maybe";

import { toLink } from "./navigation";
import { switchEnum } from "./logic";
import { Fn } from "./fn";
import { prefix, suffix } from "./string";
import { toParams } from "./url";
import { omitEmpty } from "./object";

export const formatMarkdown = slackifyMarkdown;

export const toBlocks = (text: string): KnownBlock[] => [
  {
    type: "section",
    text: {
      type: "mrkdwn",
      text: formatMarkdown(text),
    },
  },
];

export const toTaskLink = (task: Task) =>
  `<${toLink(task)}|${task.code}: ${task.title}>`;

export const toProjectLink = (project: Project) =>
  `<${toLink(project)}|${project.icon || ""} ${project.name}>`;

export const toTaskThreadText = (task: Task, me: Person) =>
  `Thread started from task [${task.code}: ${task.title}](${toLink(
    task
  )}) by @${me.id}`;

export const toProjectThreadText = (project: Project, me: Person) =>
  `Thread started from project [${project.name}](${toLink(project)}) by @${
    me?.id
  }`;

export const toThreadText = (e: Entity, me: Person) =>
  switchEnum(e?.source?.type, {
    task: () => toTaskThreadText(e as Task, me),
    project: () => toProjectThreadText(e as Project, me),
    else: () => undefined,
  });

export const toUpdateText = (e: Entity, me: Person) =>
  `@${me.id} posted an update for [${when(
    safeAs<HasCode>(e)?.code,
    suffix(": ")
  )}${toTitleOrName(e)}](${toLink(e)})`;

const REGEX = {
  channelId: /archives\/(\w+).*$/gi,
  messageId: /archives\/(\w+)\/(\w+).*$/gi,
  parentThreadId: /thread_ts=([\d\.]+).*$/gi,
};

export const toUser = (u: SlackUser): PersonRef => ({
  id: defs(u.id, "Missing user id."),
  source: Integration.Slack,
  avatar: u.profile?.image_512,
  email: u.profile?.email,
  name: u.name,
});

export const toSlackUrl = (channel?: ID, thread?: ID) =>
  `https://slack.com/app_redirect?${toParams(
    omitEmpty({ channel: channel, thread: thread })
  )}`;

export const toChannelId = (link: string) =>
  new RegExp(REGEX.channelId).exec(link)?.[1];

export const toThreadId = (link: string) => {
  const messageId = new RegExp(REGEX.messageId).exec(link)?.[2];
  const parentId = new RegExp(REGEX.parentThreadId).exec(link)?.[1];
  const permaId = parentId || messageId;
  return when(permaId, (thread) =>
    thread.startsWith("p")
      ? `${thread.substring(1, thread.length - 6)}.${thread.substring(
          thread.length - 6
        )}`
      : thread
  );
};

// Takes a string message and a function for looking up users by mention
// and replaces all @mentions with the user's name
export const replaceMentions = (
  message: string,
  replace: Fn<ID, Maybe<string>>
) =>
  reduce(
    message.match(/<?@([a-zA-Z]+[a-zA-Z\-\._0-9]+[a-zA-Z0-9]+)>?/g) || [],
    (res, mention) =>
      res.replace(
        mention,
        when(replace(mention.replace(/[<>@]/g, "")), (v) =>
          v?.includes?.("@") ? v : prefix("@")(v)
        ) || mention
      ),
    message
  );
