import { isEqual } from "lodash";
import { useCallback, useMemo, useRef } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  BaseEdge,
  Controls,
  EdgeLabelRenderer,
  EdgeProps,
  NodeProps,
  SelectionMode,
  Handle as FlowHandle,
  HandleProps,
  Position,
  MarkerType,
  useStore,
  getBezierPath,
  ConnectionLineComponentProps,
  getSmoothStepPath,
  ReactFlowProps,
  getNodesBounds,
  getStraightPath,
  ReactFlowProvider,
} from "reactflow";

import { Entity, EntityType, ID, PropertyRef } from "@api";

import { useUpdateEntity } from "@state/generic";

import { useGoTo, usePushTo } from "@utils/navigation";
import { getEdgeParams } from "@utils/flow";
import { cx } from "@utils/class-names";

import { render, useEngine } from "@ui/engine";
import { Card } from "@ui/card";
import { FillSpace, SpaceBetween, VStack } from "@ui/flex";
import { GoToButton } from "@ui/go-to-button";
import { Container } from "@ui/container";
import { TextLarge, TextMedium, TextSmall } from "@ui/text";
import { WithViewingWithin } from "@ui/viewing-within";

import "reactflow/dist/base.css";

import styles from "./flow.module.css";

type Props = ReactFlowProps & { showControls?: boolean };

const NODE_TYPES = {
  group: GroupNode,
  "entity-type": EntityTypeNode,
  entity: EntityNode,
  "entity-card": EntityCardNode,
};
const EDGE_TYPES = {
  step: VStepEdge,
  hstep: HStepEdge,
  floating: FloatingEdge,
};

const defaultEdgeOptions = {
  style: { strokeWidth: 2, stroke: "var(--color-primary)" },
  type: "floating",
  data: {},
  markerEnd: {
    type: MarkerType.Arrow,
    color: "var(--color-primary)",
  },
};

export const Flow = ({
  nodes,
  edges,
  className,
  showControls = true,
  nodeTypes: _nodeTypes,
  edgeTypes: _edgeTypes,
  children,
  ...rest
}: Props) => {
  const nodeTypes = useMemo(
    () => ({ ...NODE_TYPES, ..._nodeTypes }),
    [_nodeTypes]
  );
  const edgeTypes = useMemo(
    () => ({ ...EDGE_TYPES, ..._edgeTypes }),
    [_edgeTypes]
  );
  return (
    <div
      data-selectable-ignore-drags="true"
      style={{ width: "100%", height: "100%" }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        selectionMode={SelectionMode.Full}
        fitView={true}
        fitViewOptions={{ maxZoom: 2, duration: 2 }}
        defaultEdgeOptions={defaultEdgeOptions}
        connectionLineComponent={CustomConnection}
        {...rest}
        elementsSelectable={!!rest.onNodeClick}
        className={cx(styles.flow, className)}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
      >
        {children || (
          <>
            {showControls === true && <Controls showInteractive={false} />}
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
          </>
        )}
      </ReactFlow>
    </div>
  );
};

export function EntityTypeNode({
  data: { label, subTitle },
}: NodeProps<{ label: string; subTitle?: string; entity: EntityType }>) {
  return (
    <Card className={styles.entity}>
      <Handle
        type="target"
        isConnectableStart={false}
        position={Position.Top}
      />
      <VStack fit="container" gap={0}>
        <TextMedium bold>{label}</TextMedium>
        {!!subTitle && <TextSmall subtle>{subTitle}</TextSmall>}
      </VStack>
      <Handle
        type="source"
        isConnectableEnd={false}
        position={Position.Bottom}
      />
    </Card>
  );
}

export function EntityNode({
  data: { label, entity, selected, parentId, direction },
}: NodeProps<{
  label: string;
  entity: Entity;
  parentId?: ID;
  selected?: boolean;
  direction: "horizontal" | "vertical";
}>) {
  if (!entity) {
    return <></>;
  }

  const pushTo = useGoTo();
  const engine = useEngine(entity.source.type);
  return (
    <Card className={styles.entity} onDoubleClick={() => pushTo(entity)}>
      <Handle
        type="target"
        position={direction === "horizontal" ? Position.Left : Position.Top}
      />
      <SpaceBetween>
        <FillSpace direction="horizontal">
          <WithViewingWithin scope={parentId || ""}>
            {render(engine.asMenuItem, {
              key: entity.id,
              item: entity,
              disabled: true,
              className: styles.clip,
            })}
          </WithViewingWithin>
        </FillSpace>
        <GoToButton item={entity} />
      </SpaceBetween>
      <Handle
        type="source"
        position={direction === "horizontal" ? Position.Right : Position.Bottom}
      />
    </Card>
  );
}

export function EntityCardNode({
  data: { label, entity, showProps, selected, parentId, direction },
}: NodeProps<{
  label: string;
  entity: Entity;
  parentId?: ID;
  selected?: boolean;
  direction: "horizontal" | "vertical";
  showProps?: PropertyRef[];
}>) {
  const pushTo = usePushTo();
  const engine = useEngine(entity.source.type);
  const mutate = useUpdateEntity(entity.id);

  if (!entity) {
    return <></>;
  }

  return (
    <div className={styles.entityCard} onDoubleClick={() => pushTo(entity)}>
      <Handle
        className={styles.handle}
        type="target"
        position={direction === "horizontal" ? Position.Left : Position.Top}
      />
      <WithViewingWithin scope={parentId || ""}>
        {render(engine.asListCard, {
          key: entity.id,
          item: entity,
          onChange: mutate,
          showProps: showProps,
          className: styles.clip,
        })}
      </WithViewingWithin>
      <Handle
        type="source"
        position={direction === "horizontal" ? Position.Right : Position.Bottom}
      />
    </div>
  );
}

export function FloatingEdge({
  id,
  label,
  source,
  target,
  markerEnd,
  markerStart,
}: EdgeProps) {
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={{
          strokeWidth: "2px",
          stroke: "var(--color-primary)",
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(round(-50%, 1px), round(-50%, 1px)) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className={cx("nodrag", "nopan", styles.label)}
        >
          {label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function VStepEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  selected,
  data,
  markerEnd,
  markerStart,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX: sourceX,
    sourceY: sourceY,
    targetX: targetX,
    targetY: targetY,
    borderRadius: 20,
    sourcePosition: Position.Bottom,
    targetPosition: Position.Top,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={{
          strokeWidth: "2px",
          stroke: "var(--color-primary)",
          opacity: selected || data?.selected ? 1 : 0.5,
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(round(-50%, 1px), round(-50%, 1px)) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className={cx("nodrag", "nopan", styles.label)}
        >
          {label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function HStepEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  selected,
  data,
  markerEnd,
  markerStart,
}: EdgeProps) {
  const [edgePath, labelX, labelY] =
    Math.abs(sourceY - targetY) < 10
      ? getStraightPath({
          sourceX: sourceX,
          sourceY: targetY, // Make them look straight
          targetY: targetY, // Make them look straight
          targetX: targetX,
        })
      : getSmoothStepPath({
          sourceX: sourceX,
          sourceY: sourceY,
          targetX: targetX,
          targetY: targetY,
          borderRadius: 20,
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={{
          strokeWidth: "2px",
          stroke: "var(--color-primary)",
          opacity: selected || data?.selected ? 1 : 0.5,
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(round(-50%, 1px), round(-50%, 1px)) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className={cx("nodrag", "nopan", styles.label)}
        >
          {label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function CustomConnection({
  fromX,
  fromY,
  toX,
  toY,
  fromPosition,
  toPosition,
}: ConnectionLineComponentProps) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
    borderRadius: 20,
    sourcePosition: fromPosition,
    targetPosition: toPosition,
  });
  return (
    <g>
      <path
        fill="none"
        stroke="var(--color-primary)"
        strokeWidth="2px"
        className="animated"
        d={edgePath}
      />
      <circle
        cx={toX}
        cy={toY}
        fill="#fff"
        r={6}
        stroke="var(--color-primary)"
        strokeWidth="4px"
      />
    </g>
  );
}

export function GroupNode({ id, data }: NodeProps) {
  const PADDING = 10;
  const headerRef = useRef<HTMLParagraphElement>(null);
  const { minWidth, minHeight, hasChildNodes } = useStore((store) => {
    const parent = store.nodeInternals.get(id);
    const childNodes = Array.from(store.nodeInternals.values()).filter(
      (n) => n.parentNode === id
    );
    const rect = getNodesBounds(childNodes);

    if (!childNodes.length) {
      return { minHeight: 20, minWidth: 20, hasChildNodes: false };
    }

    return {
      minWidth: rect.x - (parent?.position.x || 0) + rect.width + PADDING * 2,
      minHeight: rect.y - (parent?.position.y || 0) + rect.height + PADDING * 2,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  return (
    <Container
      padding="none"
      className={styles.group}
      style={{
        minWidth: Math.max(minWidth, 200),
        minHeight: Math.max(minHeight, 100),
      }}
    >
      <Container
        padding="none"
        className={styles.outline}
        style={{
          top: `-${PADDING + (headerRef?.current?.offsetHeight || 0)}px`,
          left: `-${PADDING}px`,
          /* Offset the padding required for groups to grow */
          width: `calc(100% + 10px - ${PADDING}px)`,
          height: `calc(100% + 10px - ${PADDING}px + ${
            headerRef?.current?.offsetHeight || 0
          }px)`,
        }}
      >
        <Handle type="target" position={Position.Top} />
        <div ref={headerRef}>
          <TextLarge bold subtle className={styles.heading}>
            {data.name || data.label || data.title || "Group"}
          </TextLarge>
        </div>
      </Container>

      {/* <Handle
        className={styles.handle}
        type="source"
        position={Position.Bottom}
      /> */}
    </Container>
  );
}

export function withFlowProvider<T>(Component: React.FC<T>) {
  return (props: T) => (
    <ReactFlowProvider>
      {/* @ts-ignore */}
      <Component {...props} />
    </ReactFlowProvider>
  );
}

export const Handle = ({
  className,
  ...rest
}: HandleProps & { className?: string }) => (
  <FlowHandle className={cx(styles.handle, className)} {...rest} />
);
