import { find, values } from "lodash";
import { Maybe } from "./maybe";

export const asEnumValue = <T, F extends T[keyof T]>(
  v: string,
  enumm: T,
  fallback?: F
): Maybe<T[keyof T]> => find(values(enumm), (e) => e === v) || fallback;

export const asEnum = asEnumValue;
