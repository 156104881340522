import { map, some } from "lodash";
import {
  Entity,
  EntityType,
  EntityTypeMap,
  ID,
  Person,
  Ref,
  RelationRef,
  Update,
} from "@api";
import { EntityForType } from "@api/mappings";

import { persistedID, StoreState } from "@state/store";

import { Fn } from "@utils/fn";
import { Maybe, SafeRecord } from "@utils/maybe";
import { isMatch, overlaps } from "@utils/scope";

import { AllStores } from "./types";

type Nameable = Extract<Entity, { name: Maybe<string> }>;
type Titleable = Extract<Entity, { title: Maybe<string> }>;

export const toLabel = <T extends Entity>(e: T) =>
  (e as Nameable)?.name || (e as Titleable)?.title;

export const switchEntity = <T extends Entity, R>(
  entity: Maybe<T>,
  callbacks: { [K in EntityType]?: Fn<EntityTypeMap[K], R> } & {
    else?: Fn<T, R>;
  }
): Maybe<R> => entity && callbacks[entity?.source?.type]?.(entity as any); // trust me bro

export const toStore = <T extends EntityType, E extends EntityForType<T>>(
  stores: AllStores,
  type: T
): StoreState<E> => stores[type as EntityType] as any as StoreState<E>;

export const isInflated = (entity: Maybe<Entity | RelationRef | Ref>) =>
  !!(entity as Maybe<Entity>)?.source;

export const shouldSyncUpdate = <T extends Entity>(
  update: Update<T>,
  aliases: SafeRecord<ID, ID>
) =>
  // Don't sync team creates
  !(update.method === "create" && update.source.type === "team") &&
  // Only sync updates that have a persisted ID
  !!persistedID(update.id, aliases);

export const isUpdateRelevant = <T extends Entity>(
  update: Update<T>,
  allowed?: string[]
) => some(allowed, (s) => overlaps(update.source.scope, s));
