import { useDebouncedCallback } from "use-debounce";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { useActiveWorkspaceId, useCurrentUser } from "@state/workspace";

import { Fn } from "@utils/fn";
import { now } from "@utils/now";
import { useAsyncEffect } from "@utils/effects";
import { getEnv } from "@utils/env";

import {
  broadcast,
  listen,
  syncPresence,
  unlisten,
  updatePresence,
} from "./service";
import {
  RealtimeChannel,
  RealtimeEvent,
  RealtimePayload,
  RealtimePresence,
  RealtimePresences,
  TractionPresence,
} from "./types";

// Fully qualified channelId prevents going across envs
const toChannelId = (channelId: string) => `${getEnv()}-${channelId}`;

export const useRealtimeChannel = <E extends RealtimeEvent>(
  channelId: string,
  event: E,
  onMessage: Fn<RealtimePayload<E>, void>
) => {
  const channel = useRef<RealtimeChannel>();
  const callback = useRef<Fn<RealtimePayload<E>, void>>(onMessage);

  const handleMessage = useCallback((e: RealtimePayload<E>) => {
    callback.current(e);
  }, []);

  useEffect(() => {
    callback.current = onMessage;
  }, [onMessage]);

  useAsyncEffect(async () => {
    // Just for safety, keep the reference to the handler
    const handler = handleMessage;

    if (!channel.current) {
      channel.current = await listen(toChannelId(channelId), event, handler);
    }
    return async () => {
      channel.current && unlisten(channel.current, event, handler);
    };
  }, [handleMessage]);

  return useMemo(
    () => ({
      send: (payload: RealtimePayload<E>) =>
        channel.current && broadcast(channel.current, event, payload),
    }),
    [channel]
  );
};

const never = () => {};

export const useRealtimePresence = <
  S extends TractionPresence = TractionPresence
>(
  channelId: string,
  onChanged?: Fn<RealtimePresences<S>, void>
) => {
  const workspaceId = useActiveWorkspaceId();
  const me = useCurrentUser();
  const base = useMemo(
    () => ({ workspace: workspaceId, person: me.id } as S),
    [workspaceId, me.id]
  );
  const slowOnChanged = useDebouncedCallback(onChanged || never, 1000);

  const channel = useMemo(async () => {
    return await syncPresence<S>(
      toChannelId(channelId),
      { ...base, updatedAt: now() },
      slowOnChanged
    );
  }, []);

  return useMemo(
    () => ({
      update: async (state: Partial<RealtimePresence<S>>) => {
        // Wait for channel to be ready
        const current = await channel;

        await updatePresence<S>(current, {
          ...base,
          ...state,
          updatedAt: now(),
        });
      },
    }),
    [channel]
  );
};
