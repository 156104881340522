import { RecoilState, useRecoilCallback } from "recoil";
import { useCallback } from "react";

import { EntityType } from "@api";

import { getStore, useAllStores } from "@state/generic";
import { PropertyDefStoreAtom } from "@state/databases";
import { FetchResultsStoreAtom } from "@state/fetch-results";

import { pause, debug } from "@utils/debug";

export function useClearStore() {
  const reset = useRecoilCallback(({ reset }) => (store: RecoilState<any>) => {
    reset(store);
  });
  return useCallback((type: EntityType | "fetchResults" | "props") => {
    try {
      if (type === "props") {
        reset(PropertyDefStoreAtom);
      } else if (type === "fetchResults") {
        reset(FetchResultsStoreAtom);
      } else {
        const Store = getStore(type);
        reset(Store);
        reset(FetchResultsStoreAtom);
      }
    } catch (err) {
      debug("Cant find store.", type);
    }
  }, []);
}

export function useInspectStoresEffect() {
  const stores = useAllStores();
  return async () => {
    pause();
    return stores;
  };
}
