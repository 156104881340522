import { Project } from "@api";

import { useUpdateEntity } from "@state/generic";

import { when } from "@utils/maybe";
import { cx } from "@utils/class-names";
import { asMutation } from "@utils/property-mutations";

import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Button } from "@ui/button";
import { Swatch } from "@ui/icon";
import { Centered, Container } from "@ui/container";
import { useCurrentPage } from "@ui/app-page";
import { EditableHeading } from "@ui/editable-heading";
import { ColorSelect } from "@ui/select/color";
import { WorkflowActions } from "@ui/workflow-action-button";
import { LabelledPropertyValueList } from "./property-value-tile";
import { TextHighlight } from "@ui/text-highlight";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { EntityHeaderBar } from "@ui/entity-header-bar";

import styles from "./project-header.module.css";

interface Props {
  project: Project;
}

export const ProjectHeader = ({ project }: Props) => {
  const pageId = useCurrentPage();
  const mutate = useUpdateEntity(project.id, pageId);

  if (!project) {
    return <h1>Not found.</h1>;
  }

  return (
    <VStack className={styles.wrapper} gap={0}>
      <Container>
        <EntityHeaderBar entity={project} />
      </Container>

      <Centered padding="none" className={cx(styles.header)}>
        <VStack className={styles.panel} gap={12} fit="container">
          <SpaceBetween>
            <HStack gap={8}>
              <TextHighlight color={project.color}>
                <MagicEmojiSelect
                  key={`${project.id}-emoji`}
                  entity={project}
                  className={styles.icon}
                  size="large"
                  emoji={project.icon || "💼"}
                  onChange={(icon) =>
                    when(icon, (i) =>
                      mutate(asMutation({ field: "icon", type: "text" }, i))
                    )
                  }
                />

                <EditableHeading
                  key={`${project.id}-heading`}
                  text={project.name || ""}
                  className={styles.heading}
                  onChange={(text) => {
                    when(text, (i) => {
                      mutate(asMutation({ field: "name", type: "text" }, i));
                    });
                  }}
                />
              </TextHighlight>
            </HStack>

            <HStack gap={4}>
              <ColorSelect
                color={project.color}
                onChange={(c) =>
                  mutate([asMutation({ field: "color", type: "text" }, c)])
                }
              >
                <Button
                  subtle
                  size="small"
                  icon={<Swatch color={project.color || "gray_5"} />}
                />
              </ColorSelect>
            </HStack>
          </SpaceBetween>

          <SpaceBetween>
            <LabelledPropertyValueList entity={project} onChange={mutate} />

            {!project.template && (
              <HStack gap={4} fit="container" justify="flex-end">
                <WorkflowActions entity={project} />
              </HStack>
            )}
          </SpaceBetween>
        </VStack>
      </Centered>
    </VStack>
  );
};
