import { useMemo, useState } from "react";

import { Note } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity } from "@state/generic";

import { useSyncPathnameID } from "@utils/url";
import { when } from "@utils/maybe";
import { toChildLocation } from "@utils/scope";
import { usePushTo } from "@utils/navigation";
import { getRelationValue } from "@utils/property-refs";

import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { OverlaySheet, StackContainer } from "@ui/sheet-layout";
import { ResourcesPane } from "@ui/resources-pane";
import TaskPane from "@ui/engine/task/task-pane";
import { EntityMessagesPane } from "@ui/messages-pane";
import { UpdateThread } from "@ui/update-thread";
import { DiscussionThread } from "@ui/discussion-thread";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { ClockHistory, Comments, EmojiIcon, Search } from "@ui/icon";
import {
  ScheduleInstancesPane,
  TemplateSchedulesPane,
} from "@ui/engine/schedule";
import { SearchPane } from "@ui/search-pane";
import { RelatedMeetingsPane } from "@ui/engine/meeting";

import AppPage from "./app-page";
import { isNoteId } from "@utils/id";

interface Props {
  taskId: string;
  showBack?: boolean;
}

export default function TaskPage({ taskId, showBack = true }: Props) {
  const task = useLazyEntity<"task">(taskId);
  const pushTo = usePushTo();
  const [page] = useRegisterPage(taskId, task);
  const [showNote, setShowNote] = useState<Note>();
  const fullLocation = useMemo(
    () => when(task, (t) => toChildLocation(t.location, t.id)),
    [task?.id, task?.location]
  );

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(taskId, task?.id);

  if (!task) {
    return <></>;
  }

  return (
    <AppPage page={page} title={task?.title} loading={!task}>
      <StackContainer>
        {showBack && <SmartBreadcrumbSheet />}

        <TaskPane id={taskId} item={task} />

        <PaneManager size="secondary">
          {!task.template && (
            <PaneItem id="search" title="Search" icon={Search}>
              <SearchPane
                parentId={task.id}
                onOpen={(n) =>
                  isNoteId(n.id) ? setShowNote(n as Note) : pushTo(n)
                }
              />
            </PaneItem>
          )}
          {!task.template && (
            <PaneItem
              id="resources"
              title="Resources"
              icon={<EmojiIcon emoji="🔗" />}
            >
              {fullLocation && <ResourcesPane location={fullLocation} />}
            </PaneItem>
          )}

          {!task.template && (
            <PaneItem
              id="messages"
              title="Messages"
              icon={<EmojiIcon emoji="💬" />}
            >
              <EntityMessagesPane entityId={task.id} onSelected={setShowNote} />
            </PaneItem>
          )}

          {!task.template && (
            <PaneItem id="meetings" title="Meetings" icon={Comments}>
              <RelatedMeetingsPane entityId={task.id} />
            </PaneItem>
          )}

          {!task.template ? (
            when(getRelationValue(task, "refs.repeat"), (ref) => (
              <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
                <ScheduleInstancesPane schedule={ref} instanceId={task.id} />
              </PaneItem>
            ))
          ) : (
            <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
              <TemplateSchedulesPane template={task} />
            </PaneItem>
          )}
        </PaneManager>

        {showNote && (
          <OverlaySheet
            size="secondary"
            height="container"
            onDismiss={() => setShowNote(undefined)}
          >
            {showNote?.type === "update" ? (
              <UpdateThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            ) : (
              <DiscussionThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            )}
          </OverlaySheet>
        )}
      </StackContainer>
    </AppPage>
  );
}
