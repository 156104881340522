import { forwardRef, Ref } from "react";

import { cx } from "@utils/class-names";

import styles from "./page-layout.module.css";

interface PageLayoutProps {
  className?: string;
  children: React.ReactNode;
  onScroll?: () => void;
}

export const AuthLayout = ({ className, children }: PageLayoutProps) => (
  <div
    className={cx(styles.container, styles.root, styles.centered, className)}
  >
    {children}
  </div>
);

export const AppRoot = ({ className, children }: PageLayoutProps) => (
  <div className={cx(styles.container, styles.root, className)}>{children}</div>
);

export const AppLayout = ({ className, children }: PageLayoutProps) => (
  <div className={cx(styles.container, className)}>{children}</div>
);

export const PageLayout = ({
  className,
  children,
  bar = "side",
}: PageLayoutProps & { bar?: "top" | "side" }) => (
  <div className={cx(styles.container, styles[bar + "Stack"], className)}>
    {children}
  </div>
);

export const Main = forwardRef(
  (
    { className, children, onScroll }: PageLayoutProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <div className={cx(styles.main, className)} onScroll={onScroll} ref={ref}>
      {children}
    </div>
  )
);

export const SideNav = ({ className, children }: PageLayoutProps) => (
  <div className={cx(styles.primaryNav, className)}>{children}</div>
);

export const SideBar = ({ className, children }: PageLayoutProps) => (
  <div className={cx(styles.sidebar, className)}>{children}</div>
);

export const TopBar = ({ className, children }: PageLayoutProps) => (
  <div className={cx(styles.topbar, className)}>{children}</div>
);
