import { first, last, map } from "lodash";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import tippy, { Instance } from "tippy.js";
import { Extension } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import Suggestion, {
  SuggestionOptions,
  SuggestionProps,
} from "@tiptap/suggestion";

import { InnerType } from "@utils/array";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";

import { Card } from "@ui/card";
import { Text, TextSmall } from "@ui/text";
import {
  BracketsCurly,
  CheckIcon,
  Dot,
  Icon,
  MinusIcon,
  NumberIcon,
  DocumentInfo,
  RightIndentAlt,
  Table,
  TextAlt,
  TextField,
  ViewIcon,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import { HStack, SpaceBetween, VStack } from "@ui/flex";

import { isFocused as isEditorFocused } from "./utils";

import styles from "./slash-extension.module.css";

type CommandsListProps = SuggestionProps & {
  items: { id: string; label: string }[];
  command: Fn<{ id: string; label: string }, void>;
};

export const CommandsList = forwardRef(
  ({ command, items, query }: CommandsListProps, ref) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const itemsRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = useCallback(
      (item: InnerType<CommandsListProps["items"]>) => item && command(item),
      [command]
    );

    const upHandler = useCallback(() => {
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    }, [items, selectedIndex]);

    const downHandler = useCallback(() => {
      setSelectedIndex((selectedIndex + 1) % items.length);
    }, [items, selectedIndex]);

    const enterHandler = useCallback(() => {
      when(items[selectedIndex], selectItem);
    }, [items, selectedIndex, selectItem]);

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(
      ref,
      () => ({
        onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
          if (event.key === "ArrowUp") {
            upHandler();
            return true;
          }

          if (event.key === "ArrowDown") {
            downHandler();
            return true;
          }

          if (event.key === "Enter") {
            enterHandler();
            return true;
          }

          return false;
        },
      }),
      [enterHandler, downHandler, upHandler]
    );

    useLayoutEffect(() => {
      const menu = scrollRef.current;
      const item = itemsRef.current?.children?.[
        selectedIndex
      ] as Maybe<HTMLElement>;

      if (!item || !menu) {
        return;
      }

      const { top, bottom } = item.getBoundingClientRect();
      const { top: menuTop, bottom: menuBottom } = menu.getBoundingClientRect();

      if (top < menuTop) {
        menu.scrollTop -= menuTop - top;
      } else if (bottom > menuBottom) {
        menu.scrollTop += bottom - menuBottom;
      }
    }, [selectedIndex]);

    return (
      <Card className={styles.popover} width="content" ref={scrollRef}>
        <Menu className={styles.menu}>
          <MenuGroup ref={itemsRef}>
            {map(items, (item) => (
              <MenuItem
                key={item.id || item.title}
                className={cx(selectedIndex === items.indexOf(item) && "focus")}
                onClick={() => selectItem(item)}
              >
                <SpaceBetween>
                  <HStack>
                    <Icon
                      className={styles.icon}
                      size="medium"
                      icon={item.icon || NumberIcon}
                    />
                    <VStack gap={0}>
                      <Text>{item.title}</Text>
                      <TextSmall subtle>{item.subtitle}</TextSmall>
                    </VStack>
                  </HStack>
                </SpaceBetween>
              </MenuItem>
            ))}
            {!items.length && (
              <MenuItem>
                <SpaceBetween>
                  <HStack>
                    <VStack gap={0}>
                      <Text>No matching commands.</Text>
                    </VStack>
                  </HStack>
                </SpaceBetween>
              </MenuItem>
            )}
          </MenuGroup>
        </Menu>
      </Card>
    );
  }
);

export const SlashExtension = Extension.create({
  name: "slash-commands",

  // Override Embed/Task/etc
  priority: 2002,

  addOptions(): { suggestion: Partial<SuggestionOptions> } {
    return {
      suggestion: {
        char: "/",
        command: ({ editor, range, props }) => {
          props.command({ editor, range });
        },
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      // Command-A only selects the current value in the / search
      "Mod-a": ({ editor }) => {
        const state = editor.view.state;
        const { selection } = state;
        const { $anchor } = selection;

        // Check if the selection is a mention
        const text = selection.$from.nodeBefore?.textContent;
        const currentWord = last(
          text?.slice(0, selection.$anchor.parentOffset)?.split(" ")
        );

        const char = this.options.suggestion.char || "/";

        if (currentWord?.startsWith(char)) {
          editor.commands.setTextSelection({
            from: $anchor.pos - currentWord.length + 1,
            to: $anchor.pos,
          });
          return true;
        }

        return false;
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

export const suggestions: Partial<SuggestionOptions> = {
  items: ({ query }) => {
    if (!query) {
      return COMMANDS;
    }

    return COMMANDS.filter(
      (item) =>
        item.title.toLowerCase().startsWith(query.toLowerCase()) ||
        item.subtitle?.includes(query.toLowerCase())
    );
  },
  render: function () {
    let reactRenderer: Maybe<ReactRenderer>;
    let popup: Maybe<Instance>;

    const handleClose = (editor: SuggestionProps["editor"]) => {
      editor.commands.focus(); // Focus the editor to ensure the selection is correct
      editor.commands.setMeta("plugin-focused", false);
      popup?.destroy();
      reactRenderer?.destroy();
    };

    return {
      onStart: (props) => {
        if (!props.clientRect || !when(props.editor, isEditorFocused)) {
          return;
        }

        props.editor.commands.setMeta("plugin-focused", true);

        reactRenderer = new ReactRenderer(CommandsList, {
          props: {
            ...props,
            onClose: () => handleClose(props.editor),
          },
          editor: props.editor,
        });

        popup = first(
          tippy("body", {
            getReferenceClientRect: () =>
              props.clientRect?.() || DOM_RECT_FALLBACK,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          })
        );
      },

      onUpdate(props) {
        reactRenderer?.updateProps({
          ...props,
          onClose: () => handleClose(props.editor),
        });

        if (!props.clientRect) {
          return;
        }

        popup?.setProps({
          getReferenceClientRect: () =>
            props.clientRect?.() || DOM_RECT_FALLBACK,
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup?.hide();

          return true;
        }

        return (reactRenderer?.ref as Maybe<any>)?.onKeyDown(props);
      },

      onExit(props) {
        if (popup && reactRenderer) {
          handleClose(props.editor);
        }
      },
    };
  },
};

const DOM_RECT_FALLBACK: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {};
  },
};

const COMMANDS = [
  {
    id: "heading",
    title: "Heading",
    subtitle: "Big section heading. Use ## for smaller.",
    icon: TextField,
    command: ({ editor, range }: SuggestionProps) =>
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 1 })
        .run(),
  },
  {
    id: "check",
    title: "Checkbox",
    subtitle: "Track things to do and easily convert to work.",
    icon: <CheckIcon checked={false} />,
    command: ({ editor, range }: SuggestionProps) =>
      editor.chain().focus().deleteRange(range).toggleTaskList().run(),
  },
  {
    id: "page",
    title: "Page",
    subtitle: "Create a nested page within this location.",
    icon: DocumentInfo,
    command: ({ editor, range }: SuggestionProps) =>
      editor.chain().focus().deleteRange(range).createPage({ title: "" }).run(),
  },
  {
    id: "board",
    title: "Board",
    subtitle: "Embed a board to show any work.",
    icon: <ViewIcon layout="board" />,
    command: ({ editor, range }: SuggestionProps) =>
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setBoard({ id: "", title: "" })
        .run(),
  },
  {
    id: "text",
    title: "Text",
    icon: TextAlt,
    subtitle: "Just start writing with plain text.",
    command: ({ editor, range }: SuggestionProps) =>
      editor.chain().focus().deleteRange(range).setNode("paragraph").run(),
  },
  {
    id: "bullet",
    title: "Bullet",
    subtitle: "Create a simple bulleted list.",
    icon: Dot,
    command: ({ editor, range }: SuggestionProps) =>
      editor.chain().focus().deleteRange(range).toggleBulletList().run(),
  },
  {
    id: "ordered",
    title: "List",
    subtitle: "Create a list with numbering.",
    icon: NumberIcon,
    command: ({ editor, range }: SuggestionProps) =>
      editor.chain().focus().deleteRange(range).toggleOrderedList().run(),
  },
  {
    id: "code",
    title: "Code",
    subtitle: "Create a code snippet block.",
    icon: BracketsCurly,
    command: ({ editor, range }: SuggestionProps) =>
      editor.chain().focus().deleteRange(range).toggleCodeBlock().run(),
  },
  {
    id: "divider",
    title: "Divider",
    subtitle: "Visually separate ideas.",
    icon: MinusIcon,
    command: ({ editor, range }: SuggestionProps) =>
      editor.chain().focus().deleteRange(range).setHorizontalRule().run(),
  },
  {
    id: "quote",
    title: "Quote",
    subtitle: "Big bold quote for important ideas.",
    icon: RightIndentAlt,
    command: ({ editor, range }: SuggestionProps) =>
      editor.chain().focus().deleteRange(range).setBlockquote().run(),
  },
  {
    id: "table",
    title: "Table",
    subtitle: "Add simple tabular data to your page.",
    icon: Table,
    command: ({ editor, range }: SuggestionProps) =>
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
        .run(),
  },
];
