import { atomFamily } from "recoil";
import { Color, ID } from "@api";
import { appendKey, localStorageEffect } from "@state/local-storage-effect";
import { WorkspaceWrappedAtom } from "@state/workspace";

type ThemeState = {
  color: Color;
};

export const WorkspaceThemeAtom = atomFamily<ThemeState, ID>({
  key: "WorkspaceThemeAtom",
  default: { color: "gray_4" },
  effects: (wid) => [
    localStorageEffect<ThemeState>({
      key: appendKey("traction.settings.theme", wid),
    }),
  ],
});

export const ThemeAtom = WorkspaceWrappedAtom("ThemeAtom", WorkspaceThemeAtom);
