import { Entity, Outcome, PropertyRef } from "@api";

import { useLazyEntities } from "@state/generic";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { asPropertyValueRef } from "@utils/property-refs";
import { withHandle } from "@utils/event";

import { MenuItem } from "@ui/menu-item";
import { PropertyTypeVariant, PropertyValue } from "@ui/property-value";
import { Label } from "@ui/label";
import { Container } from "@ui/container";
import { SpaceBetween } from "@ui/flex";
import { WorkProgression } from "@ui/child-work-progression";
import { LocationLabel } from "@ui/location-button";

import styles from "./menu-item.module.css";

interface MenuItemOpts<T extends Entity> {
  key: string;
  item: T;
  className?: string;
  showProps?: PropertyRef[];
  showCode?: boolean;
  showParent?: "code" | "icon" | "none";
  focused?: boolean;
  variant?: PropertyTypeVariant;
  indent?: number;
  onOpen?: Fn<T, void>;
}

export const OutcomeMenuItem = ({
  item,
  focused,
  onOpen,
  showProps,
  ...props
}: MenuItemOpts<Outcome>) => {
  const childTasks = useLazyEntities(item.refs?.tasks || [], false);
  return (
    <MenuItem
      className={cx(styles.menuItem, focused && styles.focused)}
      {...props}
      onClick={withHandle(() => onOpen?.(item))}
    >
      <SpaceBetween>
        <Container padding="none" stack="horizontal" align="baseline" gap={4}>
          <PropertyValue
            variant="no-icon"
            size="tiny"
            inset={false}
            valueRef={asPropertyValueRef(
              { field: "status", type: "status" },
              { status: item.status }
            )}
            editable={false}
            source={item?.source}
          />
          <Label fit="content">{item?.title || "Loading..."}</Label>
          <LocationLabel
            showTeam={false}
            showIcons={false}
            size="small"
            subtle
            location={item.location}
          />
        </Container>
        {!!childTasks?.length && <WorkProgression items={childTasks} />}
      </SpaceBetween>
    </MenuItem>
  );
};
