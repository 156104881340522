import { last } from "lodash";
import { Fragment, useMemo } from "react";

import { Entity, ID, PropertyRef } from "@api";

import {
  useLazyItemsForView,
  toSortKey,
  useAddToView,
  useDefaultsForView,
  useSmartProps,
} from "@state/views";
import { useEntitySource, useLazyEntity } from "@state/generic";

import { Fn } from "@utils/fn";
import { toKey } from "@utils/property-refs";
import { maybeMap, whenNotEmpty } from "@utils/maybe";
import { respectHandled } from "@utils/event";
import { GroupedItems, toViewingWithinScope } from "@utils/grouping";

import { Container } from "@ui/container";
import { VStack } from "@ui/flex";
import { AddEntityInput, Props as AddInputProps } from "@ui/add-entity-input";
import { DropTarget } from "@ui/entity-drag-drop";
import { WithSuggestedProps } from "@ui/suggested-props";
import { WithViewingWithin } from "@ui/viewing-within";
import {
  EntityList,
  EntityListGroup,
  Props as EntityListProps,
} from "./entity-list";
import { CanvasLayout } from "./view-layouts/canvas";

import styles from "./view-results-list.module.css";

export type Props = {
  viewId: ID;
  limit?: number;
  onOpen?: Fn<Entity, void>;
  editable?: boolean;
  className?: string;
  showFilter?: boolean;
  showProps?: PropertyRef[];
  addButton?: Partial<AddInputProps>;
} & Omit<EntityListProps, "items" | "addButton">;

export const ViewResultsList = ({
  viewId,
  editable = true,
  className,
  limit,
  showFilter = true,
  onOpen,
  showProps: _showProps,
  addButton,
  ...rest
}: Props) => {
  const { items } = useLazyItemsForView(viewId);
  const view = useLazyEntity<"view">(viewId);
  const onAdded = useAddToView(viewId);
  const viewDefaults = useDefaultsForView(viewId);
  const defaults = useMemo(
    () => ({
      ...viewDefaults,
      orders: {
        default: String((items?.all?.length || 0) + 1),
      },
    }),
    [viewDefaults, items?.all?.length]
  );
  const itemsSource = useEntitySource(view?.entity, view?.source);
  const suggested = useSmartProps(view);
  const showProps = useMemo(
    () => whenNotEmpty(_showProps, () => _showProps) || view?.showProps,
    [_showProps, view?.showProps]
  );

  return (
    <Container padding="none" className={className} fit="container" gap={0}>
      <WithSuggestedProps props={suggested}>
        <div className={styles.list}>
          {!!view?.grouping && !!items?.grouped && itemsSource && (
            <VStack gap={4} fit="container">
              {maybeMap(
                items?.grouped?.groups,
                (group) =>
                  !!(group as GroupedItems)?.items.length && (
                    <Fragment key={toKey(group.value)}>
                      <WithViewingWithin scope={toViewingWithinScope(group)}>
                        <EntityListGroup
                          {...rest}
                          source={itemsSource}
                          group={group as GroupedItems}
                          limit={limit}
                          editable={false}
                          onOpen={onOpen}
                          showCode={true}
                          showProps={showProps}
                          orderKey={toSortKey(view)}
                        />
                      </WithViewingWithin>
                    </Fragment>
                  )
              )}
            </VStack>
          )}

          {view?.layout === "canvas" && (
            <div
              data-selectable-ignore-drags="true"
              className={styles.canvas}
              onClick={respectHandled(() => onOpen?.(view))}
            >
              <CanvasLayout id={view.id} variant="preview" />
            </div>
          )}

          {view?.layout !== "canvas" && !view?.grouping && (
            <EntityList
              {...rest}
              items={items?.sorted || items?.all}
              limit={limit}
              layout={
                view?.layout === "card" || view?.layout === "calendar"
                  ? "card"
                  : "list"
              }
              editable={false}
              onOpen={onOpen}
              showCode={true}
              showProps={showProps}
              orderKey={toSortKey(view)}
            />
          )}
        </div>

        {editable && itemsSource && (
          <Container className={styles.addButton} padding="none">
            <DropTarget
              position="after"
              item={last(items?.sorted || items?.all)}
              type={itemsSource.type}
            >
              <AddEntityInput
                {...addButton}
                source={itemsSource}
                onAdded={onAdded}
                defaults={defaults}
              />
            </DropTarget>
          </Container>
        )}
      </WithSuggestedProps>
    </Container>
  );
};
