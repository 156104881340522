import { useCallback, useMemo } from "react";
import { groupBy, map } from "lodash";

import { Ref } from "@api";
import { useCurrentUser } from "@state/workspace";

import { Note, useLazyGetNotes } from "@state/notes";

import { Maybe, when } from "@utils/maybe";
import { isUnseen } from "@utils/person";
import { daysAgo } from "@utils/date";
import { switchEnum } from "@utils/logic";
import { CollapsibleSection } from "@ui/collapsible-section";
import { useQueueUpdates } from "@state/generic";
import { useCurrentPage } from "@ui/app-page";
import { toUpdate } from "@utils/property-mutations";
import { omitEmpty } from "@utils/array";

import { Button } from "@ui/button";
import { Text } from "@ui/text";
import { HStack, HalfSpace } from "@ui/flex";
import { UpdateItem } from "@ui/engine/note";
import { fromPointDate } from "@utils/date-fp";

interface Props {
  refs: Maybe<Ref[]>;
  mode?: "important" | "unseen" | "all";
  hideOnEmpty?: boolean;
  onClick?: (update: Note) => void;
}

export const UpdatesSection = ({
  refs,
  mode = "important",
  hideOnEmpty = true,
  onClick,
}: Props) => {
  const pageId = useCurrentPage();
  const me = useCurrentUser();
  const notes = useLazyGetNotes(refs);
  const update = useQueueUpdates<Note>(pageId);
  const grouped = useMemo(
    () =>
      groupBy(notes, (n) =>
        n.pinned
          ? "pinned"
          : isUnseen(n, me) && daysAgo(fromPointDate(n.createdAt)) < 30
          ? "unseen"
          : "other"
      ),
    [notes]
  );
  const filtered = useMemo(
    () =>
      switchEnum(mode, {
        unseen: () => grouped?.unseen,
        important: () => [
          ...(grouped?.unseen || []),
          ...(grouped?.pinned || []),
        ],
        all: () => notes,
      }),
    [grouped]
  );

  const onTogglePin = useCallback(
    (note: Note) =>
      update(
        toUpdate(note, { field: "pinned", type: "boolean" }, !note.pinned)
      ),
    [update]
  );

  const label = useMemo(() => {
    const parts = omitEmpty([
      when(grouped?.unseen?.length || undefined, (us) => `${us} Unseen`),
      when(grouped?.pinned?.length || undefined, (us) => `${us} Pinned`),
    ]);
    return parts?.length ? `Updates (${parts?.join(" ")})` : "Updates";
  }, [grouped]);

  if (hideOnEmpty && !filtered.length) {
    return <></>;
  }

  return (
    <CollapsibleSection
      title={label}
      actions={
        <Button subtle size="small">
          <Text subtle>Mark as seen</Text>
        </Button>
      }
    >
      <HStack gap={8} wrap fit="container" align="flex-start">
        {map(filtered, (u) => (
          <HalfSpace key={u.id}>
            <UpdateItem
              note={u}
              onClick={() => onClick?.(u)}
              showSource={false}
              onTogglePin={() => onTogglePin(u)}
            />
          </HalfSpace>
        ))}
      </HStack>
    </CollapsibleSection>
  );
};
