import { subMinutes } from "date-fns";
import { pick } from "lodash/fp";
import { useCallback, useMemo, useState } from "react";
import { filter, find, map, omit, orderBy, reduce, some } from "lodash";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  DatabaseID,
  Entity,
  EntityType,
  ID,
  PropertyDef,
  PropertyDefChanges,
  PropertyRef,
  PropertyType,
  PropertyValueRef,
  createPropertyDef,
  deletePropertyDef,
  getPropertyDefinition,
  updatePropertyDef,
} from "@api";
import { EntityForType } from "@api/mappings";

import {
  FetchResultsAtom,
  FetchResultsStoreAtom,
  GlobalFetchOptionsAtom,
  setFetchResults,
  toFetchResultKey,
} from "@state/fetch-results";
import { GenericItem, useGetItemFromAnyStore } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";
import { useInstalledEntities } from "@state/packages";
import { modifySimpleStoreState } from "@state/store";

import { Maybe, maybeMap, when } from "@utils/maybe";
import { useAsyncEffect } from "@utils/effects";
import {
  toPropertyValueRef,
  hasValue,
  inflateValue,
  isAnyRelation,
  isEditableProp,
  isEmptyRef,
  isPropertyDef,
  asPropertyValueRef,
} from "@utils/property-refs";
import { hashable } from "@utils/serializable";
import { composel, isFunc, Pred } from "@utils/fn";
import { fromScope, toBaseScope } from "@utils/scope";
import { now } from "@utils/now";
import { isLocalID } from "@utils/id";
import { mapMost } from "@utils/promise";
import { ensureMany, pushDirty } from "@utils/array";

import { PropertyDefAtom, PropertyDefStoreAtom } from "./atoms";
import { getPropertyDef, toFetchKey, toPropertyDefID } from "./utils";
import { allPropertiesForSource } from "./selectors";
import {
  getPropertyDefinitionLoader,
  getPropertyDefinitionsLoader,
  getPropertyValuesLoader,
} from "./queries";
import {
  addPropertyDefinition,
  addPropertyValues,
  removePropertyDefinition,
  replacePropertyDefinition,
  setPropertyDefinition,
  setPropertyDefinitions,
} from "./actions";
import { toPointDate, usePointDate } from "@utils/date-fp";

// Should be used sparingly, only needed in settings packages when you NEED to have every single relationship fetched
export function useFetchAllEntityProperties(scope: ID) {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);
  const [resultsStore, setResultsStore] = useRecoilState(FetchResultsStoreAtom);
  const baseScope = useMemo(() => toBaseScope(scope), [scope]);
  const installed = useInstalledEntities(baseScope);
  const { archived } = useRecoilValue(GlobalFetchOptionsAtom);

  // Fetch prop defs from the api that were updated
  useAsyncEffect(async () => {
    await mapMost(installed, async (type) => {
      const source = { type, scope: baseScope };
      const key = toFetchKey(source);
      const results = resultsStore.lookup[key];
      const lastFetched = results?.fetchedAt;

      // Can reduce fetching here by not re-fetching for a longer period of time using above results.fetchedAt
      // however will then need some sort of cache invalidation strategy
      if (
        source ||
        (!!lastFetched &&
          usePointDate(lastFetched, (lf) => lf < subMinutes(now(), 1)))
      ) {
        await getPropertyDefinitionsLoader(source, lastFetched, (defs) => {
          if (defs?.length) {
            setProps(setPropertyDefinitions(defs));
          }

          setResultsStore(
            modifySimpleStoreState(
              toFetchResultKey(key, archived),
              setFetchResults(maybeMap(defs, (d) => toPropertyDefID(d)))
            )
          );
        });
      }
    });
  }, [installed, scope]);
}

export function useGetAnyProperty() {
  const wID = useActiveWorkspaceId();
  const propStore = useRecoilValue(PropertyDefStoreAtom);

  return useCallback(
    (prop: PropertyRef, source: DatabaseID) => {
      return (
        getPropertyDef(propStore, source, prop) ||
        getPropertyDef(propStore, { type: source.type, scope: wID }, prop)
      );
    },
    [propStore]
  );
}

export function useInflateStatus() {
  const getProp = useGetAnyProperty();
  return useCallback(
    (status: Maybe<ID>, source: DatabaseID) => {
      return find(
        getProp({ type: "status", field: "status" }, source)?.values.status,
        (s) => s.id === status
      );
    },
    [getProp]
  );
}

export function useLazyProperties<
  T extends EntityType,
  E extends Entity = EntityForType<T>
>(source: Maybe<DatabaseID<T>>, fetch: boolean = true) {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);
  const baseScope = useMemo(
    () => when(source?.scope, toBaseScope),
    [source?.scope]
  );
  const [results, setResults] = useRecoilState(
    FetchResultsAtom(
      useMemo(() => (source ? toFetchKey(source) : ""), [source])
    )
  );
  const defs = useRecoilValue(
    allPropertiesForSource(
      when(source, composel(pick(["type", "scope"]), hashable))
    )
  ) as PropertyDef<E>[];

  // Fetch prop defs from the api that were updated
  useAsyncEffect(async () => {
    // Can reduce fetching here by not re-fetching for a longer period of time using above results.fetchedAt
    // however will then need some sort of cache invalidation strategy
    if (source && baseScope && (fetch || defs?.length <= 1)) {
      await getPropertyDefinitionsLoader(
        { type: source.type, scope: baseScope },
        results?.fetchedAt,
        (defs) => {
          if (defs?.length) {
            setProps(setPropertyDefinitions(defs));
          }
          setResults({
            // IDs will not be accurate because we only fetch changed
            ids: map(defs, (d) => toPropertyDefID(d)),
            fetchedAt: toPointDate(now()),
          });
        }
      );
    }
  }, [fetch, source?.type, source?.scope]);

  return source ? defs : [];
}

export const useLazyPropertyDef = <E extends Entity, T extends PropertyType>(
  source: Maybe<DatabaseID>,
  prop: Maybe<PropertyRef<E, T> | PropertyValueRef<E, T>>
): Maybe<PropertyDef<E, T>> => {
  const def = useRecoilValue(
    PropertyDefAtom(source && prop ? toPropertyDefID(source, prop) : "")
  );
  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  // Fetch values from the api and set in the db definition
  useAsyncEffect(async () => {
    if (prop && source && !def && source?.scope && source?.scope !== "never") {
      await getPropertyDefinitionLoader(
        source,
        prop,
        composel(setPropertyDefinition, setProps)
      );
    }
  }, [source?.type, source?.scope, prop?.field, prop?.type]);

  return def as Maybe<PropertyDef<E, T>>;
};

export const useUpdatePropertyDef = <E extends Entity, T extends PropertyType>(
  dbRef: Maybe<DatabaseID>
) => {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  return useCallback(
    async (
      ref: Maybe<PropertyRef<E, T> | PropertyDef<E, T>>,
      changes: PropertyDefChanges<E, T>
    ) => {
      if (!ref || !dbRef) {
        return;
      }

      const newDef = await updatePropertyDef<E, T>(dbRef, ref, changes);

      if (!newDef) {
        throw new Error("Failed to update property definition.");
      }

      if (isPropertyDef(ref)) {
        setProps(replacePropertyDefinition(ref, newDef));
      } else {
        setProps(setPropertyDefinition(newDef));
      }

      return newDef;
    },
    [dbRef?.type, dbRef?.scope]
  );
};

export const useCreatePropertyDef = <E extends Entity, T extends PropertyType>(
  source?: Maybe<DatabaseID>
) => {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  return useCallback(
    async (
      ref: PropertyRef<E, T>,
      def: Partial<PropertyDef<E, T>>,
      sourceOverride?: DatabaseID
    ) => {
      const finalSource = sourceOverride || source;

      if (!def || !finalSource) {
        return;
      }

      if (some(fromScope(finalSource.scope), isLocalID)) {
        throw new Error("Cannot create schema for unpersisted entities.");
      }

      const newDef = await createPropertyDef<E, T>(
        finalSource,
        { type: ref.type, field: ref.field },
        omit(def, "scope", "field", "type", "entity", "createdAt", "updatedAt")
      );

      if (!newDef) {
        throw new Error("Failed to update property definition.");
      }

      setProps(addPropertyDefinition(newDef));

      return newDef;
    },
    [source?.scope, source?.type]
  );
};

export const useDeletePropertyDef = <E extends Entity, T extends PropertyType>(
  source?: Maybe<DatabaseID>
) => {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  return useCallback(
    async (def: PropertyDef<E, T>, sourceOverride?: DatabaseID) => {
      const finalSource = sourceOverride || source;

      if (!finalSource) {
        throw new Error("No source provided to delete property definition.");
      }

      await deletePropertyDef<E, T>(finalSource, {
        type: def.type,
        field: def.field,
      });

      setProps(removePropertyDefinition(def));
    },
    [source]
  );
};

export function useLazyGetPropertyValueRefs(id: ID) {
  const entity = useRecoilValue(GenericItem(id));
  const props = useLazyProperties(entity?.source, true);

  return useMemo(
    (): PropertyValueRef[] =>
      entity && props
        ? map(props || [], (def) => toPropertyValueRef(entity, def))
        : [],
    [props, entity]
  );
}

export const useGetAllPropertyValues = <
  E extends Entity,
  T extends PropertyType
>(
  source: Maybe<DatabaseID>,
  prop: PropertyRef<E, T>
) => {
  const def = useLazyPropertyDef<E, T>(source, prop);
  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  const fetch = useCallback(async () => {
    if (!source || !def) {
      return;
    }

    switch (prop.type) {
      case "status":
      case "select":
      case "multi_select": {
        const values = await getPropertyValuesLoader(source, prop);
        setProps(addPropertyValues(def, values));
      }

      default:
        return;
    }
  }, [def, source]);

  return useMemo(
    () => ({ def, values: def?.values || {}, fetch }),
    [fetch, def]
  );
};

export const useLazyPropertyValues = <E extends Entity, T extends PropertyType>(
  source: Maybe<DatabaseID>,
  prop: Maybe<PropertyRef<E, T>>
) => {
  const def = useLazyPropertyDef<E, T>(source, prop);
  return def?.values || {};
};

export const useInflatedPropertyValue = <
  E extends Entity,
  T extends PropertyType
>(
  value: PropertyValueRef<E, T>,
  source: DatabaseID
) => {
  const [once, setOnce] = useState(false);
  const setProps = useSetRecoilState(PropertyDefStoreAtom);
  const def = useLazyPropertyDef<E, T>(source, value);
  const getItem = useGetItemFromAnyStore();

  const values = useMemo(() => {
    switch (value.type) {
      // Fill values with items from store
      case "relation":
        return {
          [value.type]: maybeMap(
            when(value.value?.relation, ensureMany) || [],
            (v) => getItem(v.id)
          ),
        };
      case "relations":
        return {
          [value.type]: maybeMap(value.value?.relations, (v) => getItem(v.id)),
        };

      // Return values from definition
      default:
        return def?.values;
    }
  }, [def?.values]);

  const inflated = useMemo(() => {
    return values ? inflateValue(value, values, def) : value;
  }, [value.value?.[value.type], values]);

  useAsyncEffect(async () => {
    // Is the current value missing in the property definition
    if (!once && def && !hasValue(value, def.values) && !isAnyRelation(value)) {
      // Don't use the loader since it will be cached...
      const latest = await getPropertyDefinition(source, value);
      setProps(setPropertyDefinition(latest));
      setOnce(true);
    }
  }, [def?.values, inflated?.value?.[value.type], once]);

  return inflated;
};
export const useLazyPropertyValue = <E extends Entity, T extends PropertyType>(
  thing: Maybe<E>,
  prop: PropertyRef<E, T>
) => {
  if (!thing) {
    return asPropertyValueRef(prop, { [prop.type]: undefined });
  }

  return useInflatedPropertyValue(
    toPropertyValueRef(thing, prop),
    thing.source
  );
};

export function useGetEditableProperties(
  source: Maybe<DatabaseID>,
  additional?: Pred<PropertyDef>
) {
  const props = useLazyProperties(source);

  return useMemo(
    () =>
      filter(props, (p) => isEditableProp(p) && (!additional || additional(p))),
    [props]
  );
}

export const useVisiblePropertyRefs = <T extends Entity>(
  item: T,
  showProps: Maybe<PropertyRef<T>[] | Pred<PropertyDef<T>>>,
  {
    blacklist = [],
    whitelist = [],
    hideEmpty,
  }: {
    blacklist?: PropertyRef<T>["field"][];
    whitelist?: PropertyRef<T>["field"][];
    hideEmpty?: boolean;
  }
) => {
  const unsorted = useLazyProperties(item.source);
  const allProps = useMemo(() => orderBy(unsorted, (p) => p.order), [unsorted]);
  return useMemo(
    () =>
      reduce(
        isFunc(showProps)
          ? filter(allProps, showProps)
          : maybeMap(showProps, (p) =>
              find(allProps, (pp) => pp.field === p.field)
            ) || [],
        (curr, def) => {
          // Always hidden in the UI
          if (blacklist?.includes(def.field)) {
            return curr;
          }

          if (!def) {
            return curr;
          }

          const val = toPropertyValueRef(item, def);

          // Hide empty values
          if (
            isEmptyRef(val) &&
            hideEmpty &&
            def.visibility !== "show_always" &&
            !whitelist?.includes(val.field)
          ) {
            pushDirty(curr.hidden, val);
          } else {
            pushDirty(curr.visible, val);
          }

          return curr;
        },
        {
          visible: [] as PropertyValueRef<T>[],
          hidden: [] as PropertyValueRef<T>[],
        }
      ),
    [item, showProps, allProps, hideEmpty]
  );
};

export const useIsShowing = (props: Maybe<PropertyRef[] | PropertyDef[]>) => {
  const showing = useMemo(() => map(props, (p) => p.field), [props]);
  return useCallback((field: string) => showing?.includes(field), [showing]);
};
