import { RelationRef } from "@api";
import { useLazyAllTeams } from "@state/teams";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { Button, Props as PureButtonProps } from "@ui/button";
import { RelationSelect, RelationSelectProps } from "@ui/select";
import { AngleDownIcon, Icon, TeamIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { RelationIcon, RelationLabel } from "./relation-label";

type ButtonProps = {
  team: RelationRef | undefined;
  caret?: boolean;
} & PureButtonProps;

type SelectProps = {
  team: RelationRef | undefined;
  onChanged?: Fn<Maybe<RelationRef>, void>;
} & Omit<RelationSelectProps, "value" | "options" | "onChange">;

export const TeamButton = ({
  team,
  variant,
  caret = true,
  ...props
}: ButtonProps) => (
  <Button
    size="small"
    subtle
    iconRight={caret ? AngleDownIcon : undefined}
    {...props}
    icon={
      variant === "icon-only" ? (
        <Icon
          icon={
            team ? (
              <RelationIcon relation={team} />
            ) : (
              <TeamIcon team={undefined} />
            )
          }
        />
      ) : undefined
    }
  >
    {variant !== "icon-only" && (
      <>
        {team ? (
          <RelationLabel relation={team} />
        ) : (
          <Label icon={<TeamIcon team={undefined} />} subtle>
            Private
          </Label>
        )}
      </>
    )}
  </Button>
);

export const TeamSelect = ({
  team,
  onChanged,
  children,
  caret = true,
  portal = false,
  ...props
}: SelectProps) => {
  const teams = useLazyAllTeams();

  return (
    <RelationSelect
      {...props}
      value={team}
      options={teams}
      portal={portal}
      onChange={onChanged}
      placeholder="Choose team..."
    >
      {children || <TeamButton team={team} caret={caret} />}
    </RelationSelect>
  );
};
