import {
  Entity,
  hasNotes,
  HasNotes,
  HasStatus,
  Link,
  Note,
  NoteType,
  Ref,
  SettingsData,
  Update,
} from "@api";
import { find, map, orderBy, uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  useDeleteEntitys,
  useLazyEntity,
  useNestedEntitiesOfType,
  useQueueUpdates,
} from "@state/generic";
import { useCreateNote, useLazyGetNotes } from "@state/notes";
import { useEntitySettings } from "@state/settings";
import { useCurrentUser } from "@state/workspace";

import { useQueryParams, useSetQueryParams } from "@utils/hooks";
import { Maybe, when } from "@utils/maybe";
import { toUpdate } from "@utils/property-mutations";
import { Fn } from "@utils/fn";
import { getSetting, toRef } from "@utils/property-refs";
import { isSlack } from "@utils/link";

import { useCurrentPage } from "@ui/app-page";
import { DiscussionThread } from "./discussion-thread";
import { PaneHeader } from "@ui/pane-header";
import { Container } from "@ui/container";
import { Sheet } from "@ui/sheet-layout";
import { SlackCreateDialog } from "@ui/slack-create-dialog";
import { NoteCreateDialog } from "@ui/note-create-dialog";
import { UpdateThread } from "@ui/update-thread";
import { CheckIcon, LinkAdd, TrashAlt } from "@ui/icon";
import { DiscussionItem } from "@ui/discussion-item";
import { MessageBox } from "@ui/message-box";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Button } from "@ui/button";
import { LinkDialog } from "./link-dialog";
import { showError } from "@ui/notifications";
import { Label } from "@ui/label";
import { Text } from "@ui/text";
import { UpdateItem } from "@ui/engine/note";

import styles from "./messages-pane.module.css";

interface Props {
  entity: HasNotes;
  settings?: Maybe<SettingsData>;
  onSelected?: Fn<Note, void>;
}

export const MessagesPane = ({ entity, settings, onSelected }: Props) => {
  const me = useCurrentUser();
  const pageId = useCurrentPage();
  const mutate = useQueueUpdates(pageId);
  const createNote = useCreateNote(entity, pageId, true);
  const [includeRelated, setIncludeRelated] = useState(true);
  const [open, setOpen] = useState<Maybe<Note>>();
  const [creating, setCreating] = useState<Maybe<"update" | "thread">>();
  const [linking, setLinking] = useState(false);
  const params = useQueryParams();
  const setParams = useSetQueryParams();
  const deletee = useDeleteEntitys(pageId);
  const { children: nestedNotes } = useNestedEntitiesOfType(entity, "note");
  const linkedNotes = useLazyGetNotes(
    (entity as any).notes || (entity as any)?.refs?.notes,
    "asc"
  );

  const notes = useMemo(
    () =>
      includeRelated
        ? orderBy(
            uniqBy([...(nestedNotes || []), ...linkedNotes], (n) => n.id),
            (n) => n.createdAt
          )
        : linkedNotes,
    [includeRelated, nestedNotes, linkedNotes]
  );

  const onNewMessage = useCallback(
    (ref: Ref) =>
      entity &&
      mutate([
        {
          id: entity.id,
          method: "update",
          source: entity.source,
          changes: [
            {
              field: "refs.notes",
              type: "relations",
              op: "add",
              value: { relations: [toRef(ref)] },
            },
          ],
        },
      ]),
    [entity, mutate]
  );

  const onTogglePin = useCallback(
    (resource: Note) =>
      mutate([
        toUpdate(
          resource,
          { field: "pinned", type: "boolean" },
          !resource?.pinned,
          resource?.pinned
        ) as Update<Entity>,
      ]),
    [mutate]
  );

  const onLinkNote = useCallback((l: Link) => {
    if (!isSlack(l.url)) {
      showError("Only slack links can be linked here.");
      return false;
    }

    createNote([
      { field: "type", type: "text", value: { text: "discussion" } },
      {
        field: "body",
        type: "rich_text",
        value: { rich_text: { markdown: l.text || "" } },
      },
      {
        type: "links",
        field: "links",
        value: { links: [l] },
      },
    ]);
    setLinking(false);

    return true;
  }, []);

  const onNoteSelected = useCallback(
    (r: Note) => {
      if (onSelected) {
        onSelected?.(r);
      } else {
        setOpen(r);
      }
    },
    [onSelected, setOpen]
  );

  const onClose = useCallback(() => {
    setOpen(undefined);
    setCreating(undefined);
    setParams({ ...params, open: undefined });
  }, [params]);

  useEffect(() => {
    if (params?.open) {
      setOpen(find(notes, (n) => n.id === params.open));
    }
  }, [params]);

  if (open?.type === "discussion") {
    return (
      <Sheet size="secondary" height="content" className={styles.pane}>
        <DiscussionThread noteId={open?.id} onClose={onClose} />
      </Sheet>
    );
  }

  if (open) {
    return (
      <Sheet size="secondary" height="content" className={styles.pane}>
        <UpdateThread noteId={open?.id} onClose={onClose} />
      </Sheet>
    );
  }

  return (
    <Sheet size="secondary" height="content" className={styles.pane}>
      <PaneHeader title="Messaging" count={notes?.length}>
        <Label
          subtle
          fit="content"
          iconRight={<CheckIcon checked={includeRelated} />}
          onClick={() => setIncludeRelated(!includeRelated)}
        >
          Include related
        </Label>
      </PaneHeader>

      {linking && (
        <LinkDialog
          link={undefined}
          message="Link existing slack thread"
          onValidLink={onLinkNote}
          onChanged={() => setLinking(false)}
          onCancel={() => setLinking(false)}
        />
      )}

      <Container stack="vertical" className={styles.paneContainer}>
        {!notes?.length && (
          <Text subtle>
            No messages here{includeRelated ? " or on all related work" : ""}.
          </Text>
        )}
        <Container
          gap={8}
          stack="vertical"
          inset="horizontal"
          fit="container"
          height="container"
          padding="none"
          className={styles.timeline}
        >
          {map(notes, (n) => (
            <ContextMenu
              key={n.id}
              actions={
                <ContextItem
                  icon={TrashAlt}
                  text="Delete"
                  onClick={() => deletee([n.id])}
                />
              }
            >
              {n?.type === "discussion" ? (
                <DiscussionItem
                  key={n.id}
                  discussion={n}
                  showSource={false}
                  showSeenStatus={false}
                  onClick={onNoteSelected}
                />
              ) : (
                <UpdateItem
                  key={n.id}
                  note={n}
                  showSource={false}
                  showSeenStatus={false}
                  onClick={onNoteSelected}
                  onTogglePin={onTogglePin}
                />
              )}
            </ContextMenu>
          ))}
        </Container>
      </Container>

      <Container stack="horizontal" gap={0} className={styles.paneActions}>
        <MessageBox
          className={styles.messageBox}
          placeholder="Update your team..."
          me={me}
          onClick={() => setCreating("update")}
          onMessage={() => {}}
        />
        <Button
          icon={LinkAdd}
          subtle
          size="small"
          onClick={() => setLinking(true)}
        />
      </Container>

      {creating === "update" && (
        <NoteCreateDialog
          entity={entity}
          type={NoteType.Update}
          status={(entity as HasStatus)?.status}
          channel={when(settings, (s) => getSetting<string>(s, "channel"))}
          onSaved={() => setCreating(undefined)}
          onCancel={() => setCreating(undefined)}
        />
      )}

      {creating === "thread" && (
        <SlackCreateDialog
          entity={entity}
          channel={when(settings, (s) => getSetting<string>(s, "channel"))}
          onSaved={(note) => {
            onNewMessage?.({ id: note?.id });
            setCreating(undefined);
          }}
          onCancel={() => setCreating(undefined)}
        />
      )}
    </Sheet>
  );
};

export const EntityMessagesPane = ({
  entityId,
  ...props
}: Omit<Props, "entity" | "settings"> & {
  entityId: string;
}) => {
  const entity = useLazyEntity(entityId);
  const settings = useEntitySettings(entityId);

  if (!entity || !hasNotes(entity)) {
    return <></>;
  }
  return <MessagesPane entity={entity} settings={settings} {...props} />;
};
