import { lowerCase } from "lodash";
import { EntityType, SettingsData } from "@api";
import { composel } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { plural, sentenceCase } from "@utils/string";

export const toSettingKey = (pkg: string, key: string) => `${pkg}.${key}`;

export interface ToLabelOptions {
  plural?: boolean;
  case?: "lower" | "title";
}

export const toEntityLabel = (
  type: Maybe<EntityType>,
  options: ToLabelOptions,
  settings: SettingsData
) => {
  const alias =
    when(type, (t) => settings[`${t}_alias`] as Maybe<string>) ||
    (type === "outcome" && "objective") ||
    type ||
    "item";

  return composel(
    (n) => plural(n, !!options?.plural ? 2 : 1),
    options?.case === "lower" ? lowerCase : sentenceCase
  )(alias);
};
