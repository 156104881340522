import { useCallback, useState } from "react";
import { map } from "lodash";

import { ID, EntityType, Person } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useInstalledEntities } from "@state/packages";
import { useLazyViewsForParent } from "@state/views";
import { useCurrentUser } from "@state/workspace";
import { useEntityLabels } from "@state/settings";

import ViewPane from "@ui/view-pane";
import { Maybe } from "@utils/maybe";
import { Fn } from "@utils/fn";
import { useShowMore } from "@utils/hooks";
import { useGoTo } from "@utils/navigation";
import { toRef } from "@utils/property-refs";

import { ViewCreateDialog } from "@ui/engine/view";
import { Container } from "@ui/container";
import { SpaceBetween, VStack } from "@ui/flex";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import AppPage from "./app-page";
import { Divider } from "@ui/divider";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { render, useEngine } from "@ui/engine";
import { Heading, Text } from "@ui/text";
import { PlusIcon } from "@ui/icon";
import { MenuItem, MoreMenuItem } from "@ui/menu-item";

import styles from "./my-work-page.module.css";

export const MyWorkPage = ({ viewId }: { viewId: Maybe<ID> }) => {
  const me = useCurrentUser();
  const goTo = useGoTo();

  const setViewId = useCallback((id: string) => {
    goTo(["/boards", id]);
  }, []);

  const [page] = useRegisterPage();
  usePageUndoRedo(page.id);

  return (
    <AppPage page={page}>
      <StackContainer>
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <SideHeader
              pageId={page.id}
              active={viewId}
              onActiveChanged={setViewId}
              me={me}
            />

            <Main className={styles.main}>
              {viewId && (
                <ViewPane
                  viewId={viewId}
                  className={styles.viewPane}
                  onChangeView={(v) => setViewId(v.id)}
                  // showTitle={false}
                />
              )}
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

type SideHeaderProps = {
  me: Person;
  active: Maybe<ID>;
  onActiveChanged: Fn<ID, void>;
  pageId: ID;
};

const SideHeader = ({ me, ...rest }: SideHeaderProps) => {
  const entities = useInstalledEntities(me.id);
  const toLabel = useEntityLabels(me.id);
  const [creating, setCreating] = useState<EntityType>();

  return (
    <SideNav className={styles.nav}>
      {creating && (
        <ViewCreateDialog
          defaults={{
            entity: creating,
            for: toRef(me),
            location: me.id,
            source: { type: "view", scope: me.id },
          }}
          onSaved={(saved) => {
            setCreating(undefined);
            rest.onActiveChanged?.(saved.id);
          }}
          onCancel={() => setCreating(undefined)}
        />
      )}
      <SpaceBetween direction="vertical">
        <VStack gap={10} width="container">
          <VStack gap={20} width="container">
            <Container gap={10} padding="none" inset="bottom" stack="vertical">
              <Heading bold>My Work</Heading>
            </Container>
          </VStack>

          <Container padding="vertical">
            <Divider />
          </Container>

          <Menu>
            {map(entities, (type) => (
              <BoardsMenuGroup key={type} type={type} {...rest} me={me} />
            ))}

            <MenuGroup>
              <MenuItem icon={PlusIcon} onClick={() => setCreating("task")}>
                <Text subtle>New board</Text>
              </MenuItem>
            </MenuGroup>
          </Menu>
        </VStack>
      </SpaceBetween>
    </SideNav>
  );
};

type BoardsMenuGroupProps = SideHeaderProps & { type: EntityType };

const BoardsMenuGroup = ({
  me,
  type,
  active,
  onActiveChanged,
}: BoardsMenuGroupProps) => {
  const all = useLazyViewsForParent(me.id, type);
  const toLabel = useEntityLabels(me.id);
  const engine = useEngine("view");
  const { visible: views, hasMore, moreCount, showMore } = useShowMore(all, 5);

  if (!views?.length) {
    return <></>;
  }

  return (
    <MenuGroup label={`${toLabel(type)} boards`}>
      {map(views, (view) =>
        render(engine.asMenuItem, {
          key: view.id,
          item: view,
          selected: view.id === active || view.alias === active,
          onOpen: (v) => onActiveChanged(v.id),
        })
      )}
      {hasMore && <MoreMenuItem count={moreCount} onClick={showMore} />}
    </MenuGroup>
  );
};
