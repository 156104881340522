import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import { Entity, EntityType, FetchOptions, FilterQuery, ID, Ref } from "@api";
import * as api from "@api";
import { EntityForType } from "@api/mappings";

import { getStore } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";
import { mergeItems } from "@state/store";

import { useAsyncEffect } from "@utils/effects";
import { hashable } from "@utils/serializable";
import { ensureMany, OneOrMany, pickIds } from "@utils/array";
import { Maybe } from "@utils/maybe";
import { Fn } from "@utils/fn";

import {
  FetchResultsAtom,
  FetchResultsState,
  GlobalFetchOptionsAtom,
  itemsForFetchResults,
} from "./atoms";
import {
  addRefsToFetchResults,
  setFetchedAt,
  setFetchResults,
} from "./actions";
import { now } from "@utils/date-fp";

export const useLazyFetchResults = <T extends EntityType = EntityType>(
  queryKey: string,
  type: T,
  filter: FilterQuery<EntityForType<T>>,
  opts?: FetchOptions
): EntityForType<T>[] => {
  const globalOpts = useRecoilValue(GlobalFetchOptionsAtom);
  const workspaceId = useActiveWorkspaceId();
  const hashed = useMemo(
    () => hashable({ key: queryKey, type }),
    [queryKey, type]
  );
  const onLoaded = useOnFetchResults(queryKey, type);
  const results = useRecoilValue(FetchResultsAtom(queryKey));
  const items = useRecoilValue(itemsForFetchResults(hashed));

  useAsyncEffect(async () => {
    if (opts?.fetch !== false) {
      const { changed, all } = await api.getOptimizedForFilter(
        { type, scope: workspaceId },
        filter,
        {
          archived: globalOpts?.archived,
          since: opts?.since || results?.fetchedAt,
        }
      );
      onLoaded(changed, all);
    }
  }, [globalOpts.archived, queryKey, type]);

  return items as EntityForType<T>[];
};

export function useOnFetchResults(
  key: ID,
  type: EntityType,
  Atom: Fn<string, RecoilState<Maybe<FetchResultsState>>> = FetchResultsAtom
) {
  const Store = useMemo(() => getStore(type), [type]);
  const setStore = useSetRecoilState(Store);
  const [results, setResults] = useRecoilState(Atom(key || ""));

  return useCallback(
    async (items: Entity[], ids?: string[]) => {
      if (items.length) {
        setStore(mergeItems(items));
      }

      const itemIds = ids || pickIds(items) || [];

      if (!isEqual(results?.ids, itemIds)) {
        setResults(setFetchResults(itemIds, now()));
      } else {
        setResults(setFetchedAt(now()));
      }
    },
    [results?.ids, type]
  );
}

export function useAddToFetchResults(key: ID) {
  const setResults = useSetRecoilState(FetchResultsAtom(key));

  return useCallback(
    async (ts: OneOrMany<Ref>) => {
      setResults(addRefsToFetchResults(ensureMany(ts)));
    },
    [setResults]
  );
}
