import { isString } from "lodash";
import { CSSProperties, ReactNode, forwardRef } from "react";

import { cx } from "@utils/class-names";
import { ComponentOrNode } from "@utils/react";
import { Fn } from "@utils/fn";

import { SpinnerIcon } from "./icon";
import { SpaceBetween } from "@ui/flex";
import { Divider } from "./divider";
import { SectionLabel } from "./text";

import styles from "./menu-group.module.css";

export interface MenuGroupProps {
  label?: string | ReactNode;
  icon?: ComponentOrNode;
  divider?: boolean;
  loading?: boolean;
  indent?: number;
  inset?: boolean;
  className?: string;
  labelClassName?: string;
  listClassName?: string;
  onLabelClicked?: Fn<React.MouseEvent, void>;
  onClick?: Fn<React.MouseEvent, void>;
  style?: CSSProperties;
  children?: ReactNode;
  actions?: ReactNode;
}

export type MenuSeparatorProps = Omit<MenuGroupProps, "listClassName">;

export const MenuSeparator = ({
  label,
  icon,
  actions,
  loading,
  className,
  labelClassName,
  onLabelClicked,
  onClick,
}: MenuSeparatorProps) => {
  return (
    <SpaceBetween
      align="center"
      gap={6}
      className={cx(styles.header, className)}
      onClick={onClick}
    >
      <div className={styles.title}>
        {!isString(label) ? (
          label
        ) : (
          <SectionLabel
            className={cx(onLabelClicked && styles.clickable, labelClassName)}
            icon={icon}
            iconRight={loading ? <SpinnerIcon /> : undefined}
            onClick={onLabelClicked}
          >
            {label}
          </SectionLabel>
        )}
      </div>

      <Divider subtle />

      {actions}
    </SpaceBetween>
  );
};

export const MenuDivider = MenuSeparator;

export const MenuGroup = forwardRef<HTMLDivElement, MenuGroupProps>(
  (
    {
      label,
      children,
      inset = true,
      divider,
      style,
      labelClassName,
      listClassName,
      className,
      onLabelClicked,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={cx(styles.group, inset && styles.inset, className)}
        style={style}
      >
        {(label || divider) && (
          <MenuSeparator
            {...rest}
            labelClassName={labelClassName}
            label={label}
            onLabelClicked={onLabelClicked}
          />
        )}
        <div ref={ref} className={cx(styles.items, listClassName)}>
          {children}
        </div>
      </div>
    );
  }
);
