import DataLoader from "dataloader";
import { ID, getEntityByIds, getForFilter, getOutcomes } from "@api";
import { cachedFuncByWorkspace } from "@state/workspace";

import { lookup } from "@utils/array";
import { toMilliSeconds } from "@utils/time";

const outcomeLoader = new DataLoader(
  async (ids: readonly string[]) => {
    const outcomes = await getOutcomes(ids as ID[]);
    const hashed = lookup(outcomes || [], (i) => i.id);
    return ids.map(hashed);
  },
  { cache: false }
);

export const getOutcomeLoader = cachedFuncByWorkspace(
  () => (id) => outcomeLoader.load(id),
  toMilliSeconds("1 minute"),
  ([v]) => v
);

export const getOutcomesLoader = cachedFuncByWorkspace(
  () => getOutcomes,
  toMilliSeconds("1 minute")
);
