import { getRecoil } from "recoil-nexus";
import { atomFamily } from "recoil";

import { Color, FilterQuery, ID, Task } from "@api";

import { activeUserId } from "@state/workspace";

import { Maybe } from "@utils/maybe";
import { subDays } from "date-fns";
import { now } from "@utils/now";

export interface QuickFilter {
  id: ID;
  name: string;
  viewId: ID;
  color: Color;
  filter: FilterQuery<Task>;
}

export type ViewQuickFilterState = {
  selected: Maybe<ID>;
  peaking: Maybe<ID>;
  available: QuickFilter[];
  search: Maybe<string>;
};

const defaultQuickFilters = (viewId: ID): ViewQuickFilterState => ({
  selected: undefined,
  peaking: undefined,
  search: undefined,
  available: [
    {
      id: "your-tasks",
      name: "My work",
      color: "gray",
      viewId,
      filter: {
        and: [
          {
            field: "assigned",
            type: "relation",
            op: "equals",
            value: { relation: { id: getRecoil(activeUserId) } },
          },
        ],
      },
    },

    {
      id: "blocked",
      name: "Blocked",
      color: "red",
      viewId,
      filter: {
        or: [
          {
            field: "blocked",
            type: "boolean",
            op: "equals",
            value: { boolean: true },
          },
          {
            field: "status",
            type: "status",
            op: "equals",
            value: { status: { name: "Blocked" } },
          },
        ],
      },
    },

    {
      id: "overdue",
      name: "Overdue",
      color: "red",
      viewId,
      filter: {
        field: "end",
        type: "date",
        op: "on_or_before",
        value: { date: now() },
      },
    },

    {
      id: "stale",
      name: "Stale",
      color: "gray",
      viewId,
      filter: {
        field: "updatedAt",
        type: "date",
        op: "before",
        value: { date: subDays(now(), 30) },
      },
    },
  ] as QuickFilter[],
});

export const ViewQuickFilterAtom = atomFamily({
  key: "ViewQuickFilter",
  default: defaultQuickFilters,
});
