import { useMemo } from "react";
import { map } from "lodash";

import { Action, Entity, Meeting, Update } from "@api";

import { useQueueUpdates } from "@state/generic";
import { toTemplateViewId, useLazyItemsForView } from "@state/views";

import { asUpdate } from "@utils/property-mutations";
import { useGoTo } from "@utils/navigation";
import { GroupedItems } from "@utils/grouping";
import { toKey } from "@utils/property-refs";

import { RelationLabel } from "@ui/relation-label";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Sheet } from "@ui/sheet-layout";
import { useCurrentPage } from "@ui/app-page";

import { render, useEngine } from "..";

import styles from "./styles.module.css";

interface Props {
  meeting: Meeting;
}

export const MeetingActionsPane = ({ meeting }: Props) => {
  const goTo = useGoTo();
  const pageId = useCurrentPage();
  const engine = useEngine("action");
  const viewId = useMemo(
    () => toTemplateViewId("meeting-actions", { parent: meeting.id }),
    [meeting.id]
  );
  const { items } = useLazyItemsForView(viewId);
  const mutate = useQueueUpdates(pageId);

  if (!items.all.length) {
    return <></>;
  }

  return (
    <Sheet size="secondary" className={styles.meetingPane}>
      <PaneHeader title="Actions" />

      <PaneContainer>
        <Menu>
          {map(items.grouped?.groups as GroupedItems[], (group) => (
            <MenuGroup
              key={toKey(group.value)}
              label={
                group?.value.value.relation ? (
                  <RelationLabel
                    icon={false}
                    relation={group?.value.value.relation}
                  />
                ) : (
                  <Label subtle>Not assigned</Label>
                )
              }
            >
              {map(group.items as Action[], (item) =>
                render(engine.asMenuItem, {
                  key: item.id,
                  item: item,
                  onChange: (cs) =>
                    mutate(asUpdate(item, cs) as Update<Entity>),
                })
              )}
            </MenuGroup>
          ))}
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};
