import { useSetRecoilState } from "recoil";

import { useCurrentUser } from "@state/workspace";
import { toTemplateViewId } from "@state/views";
import { AppCommandsAtom, setCommandsSearching } from "@state/app";

import { useGoTo } from "@utils/navigation";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { EmojiIcon, PersonIcon, Search } from "@ui/icon";

import { AppCommandsProps } from "./types";
import { useCommandSearch } from "./utils";

export const SearchCommand = ({}: AppCommandsProps) => {
  const query = useCommandSearch();
  const setAppCommands = useSetRecoilState(AppCommandsAtom);

  return (
    <CommandItem
      value="skip: search or find everything"
      icon={Search}
      onClick={() => setAppCommands(setCommandsSearching())}
      onSelectAction="none"
    >
      Search {query ? `everywhere for "${query}"` : ""}
    </CommandItem>
  );
};
