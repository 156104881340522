import { JsonArray, JsonObject, SettingsData } from "@api";
import { PropertyDefStoreState } from "@state/databases";
import { AllStores } from "@state/generic";

type AiCaseContext = {
  stores: AllStores & { props: PropertyDefStoreState };
  settings: SettingsData;
};

// Helper function for inference
export function asAiUseCase<I, R, D>(useCase: AiUseCase<I, R, D>) {
  return useCase;
}

export type AiUseCase<I, R, D> = {
  prepareData?: (inp: I, c: AiCaseContext) => Promise<D>;
  toPrompt(data: D, c: AiCaseContext): string;
  parseCompletion(
    response: string | JsonObject | JsonArray,
    data: D,
    c: AiCaseContext
  ): R;
};
