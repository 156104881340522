import { useCallback, useEffect, useMemo } from "react";
import { map, some } from "lodash";

import {
  DatabaseID,
  Entity,
  HasTemplate,
  PropertyType,
  RelationRef,
  toTitleOrName,
} from "@api";

import { useLazyPropertyDef } from "@state/databases";
import { getItem } from "@state/store";
import { useSmartSearch, useStore } from "@state/generic";

import { toUpdate } from "@utils/property-mutations";
import { getPropertyValue } from "@utils/property-refs";
import { justOne } from "@utils/array";
import { Maybe, maybeMap } from "@utils/maybe";

import { useMultiCheckedState } from "@ui/checked";
import { Text } from "@ui/text";
import { CommandItem } from "@ui/command-menu";
import { RelationMenuItem } from "@ui/relation-label";
import { GoToButton } from "@ui/go-to-button";
import { SpaceBetween } from "@ui/flex";

import { SetPropertyCommands } from "./types";
import { useCommandSearch } from "../utils";

import styles from "./styles.module.css";

export const RelationSelectCommands = ({
  property,
  entities,
  mutate,
  onDismiss,
}: SetPropertyCommands) => {
  const propType = property.type;
  if (propType !== "relation") {
    throw new Error(
      'Relation Commands can only be used on "relation" properties.'
    );
  }

  const source = entities[0]?.source as DatabaseID;
  const def = useLazyPropertyDef(source, property);
  const store = useStore(source.type);
  const realEntities = maybeMap(entities, (e) => getItem(store, e.id));
  const toCheckedState = useMultiCheckedState(realEntities, property);
  const toOption = useCallback(
    (e: Entity) => ({ item: e, checked: toCheckedState(e.id) }),
    [toCheckedState]
  );

  const relationType = useMemo(
    () => justOne(def?.options?.references) || source.type,
    [def?.options?.references, source.type]
  );
  const { onSearch: setQuery, options } = useSmartSearch(
    relationType,
    ["team", "person"]?.includes(relationType)
      ? "" // Allow searching across whole workspace
      : source.scope,
    useMemo(
      () => ({
        archived: false,
        templates:
          def?.field === "useTemplate" ||
          some(realEntities, (e) => !!(e as Maybe<HasTemplate>)?.template),
        toOption,
      }),
      [realEntities, def?.field]
    )
  );
  const query = useCommandSearch();
  useEffect(() => setQuery(query), [query]);

  const handleChange = useCallback(
    (value: RelationRef, action: "add" | "remove") => {
      mutate(
        map(realEntities, (entity) => {
          const prev = getPropertyValue<Entity, PropertyType>(
            entity,
            property
          ) as RelationRef;
          const newValue = action === "add" ? value : undefined;

          return toUpdate(entity, property, newValue, prev);
        })
      );
    },
    [mutate]
  );

  return (
    <>
      <CommandItem
        value="clear unset value"
        onClick={() => mutate({ [propType]: undefined })}
      >
        <Text subtle>Clear value</Text>
      </CommandItem>

      {map(options, ({ item, checked }) => (
        <CommandItem
          key={item.id}
          value={`${toTitleOrName(item)} ${item.id}`}
          onSelectAction="close"
          onClick={() =>
            handleChange(item, checked === true ? "remove" : "add")
          }
        >
          <SpaceBetween>
            <RelationMenuItem
              className={styles.relationMenuItem}
              relation={item}
              disabled={true}
            />
            <GoToButton item={item} onClick={() => onDismiss?.()} />
          </SpaceBetween>
        </CommandItem>
      ))}
    </>
  );
};
