import { every, filter, map, omit, some, values } from "lodash";
import { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  PropertyMutation,
  Update,
  Person,
  Integration,
  Ref,
  Entity,
  HasRefs,
} from "@api";
import { getPersonLoader, getPersonsLoader } from "./queries";

import { ID } from "@state/types";
import { getItem, setItems } from "@state/store";
import { useCurrentUser } from "@state/workspace";
import { useLazyEntities, useLazyEntity } from "@state/generic";

import { containsRef } from "@utils/relation-ref";
import { isLocalID } from "@utils/id";
import { useAsyncEffect } from "@utils/effects";
import { getPropertyValue } from "@utils/property-refs";
import { Maybe, isDefined, maybeMap, when } from "@utils/maybe";
import { indexBy } from "@utils/array";

import {
  useApplyUpdateEffect,
  useQueueDeleteEffect,
  useQueueUpdateEffect,
} from "../store";
import { PersonAtom, PersonStoreAtom } from "./atoms";
import { hasAlphaFeatures, toDisplayName } from "./utils";

export function useLazyGetPerson(id: ID) {
  const [person, setPerson] = useRecoilState(PersonAtom(id));

  useAsyncEffect(async () => {
    if (!id || isLocalID(id)) {
      return;
    }

    if (!person?.fetchedAt) {
      const latest = await getPersonLoader(id);
      setPerson(latest);
    }
  }, [id]);

  return person;
}

export function useLazyAllPersons() {
  const [store, setStore] = useRecoilState(PersonStoreAtom);

  useAsyncEffect(async () => {
    const latest = await getPersonsLoader();
    setStore(setItems(latest || []));
  }, []);

  return useMemo(() => filter(store.lookup, isDefined), [store?.lookup]);
}

export function useLazyGetPersons(refs?: Ref[]) {
  const [store, setStore] = useRecoilState(PersonStoreAtom);

  useAsyncEffect(async () => {
    if (!refs || some(refs, (r) => isLocalID(r.id))) {
      return;
    }

    const latest = await getPersonsLoader(map(refs, (r) => r.id));
    setStore(setItems(latest || []));
  }, [refs]);

  return useMemo(
    () => maybeMap(refs, (r) => getItem(store, r.id)),
    [store?.lookup, refs]
  );
}

export function useUpdatePerson(id: ID) {
  const queueUpdate = useQueueUpdateEffect(PersonStoreAtom);
  const applyUpdate = useApplyUpdateEffect(PersonStoreAtom);
  const person = useRecoilValue(PersonAtom(id));

  return useCallback(
    async (changes: PropertyMutation<Person>[]) => {
      // Nothing changed
      if (!person || every(changes, (c) => c.prev === c.value)) {
        return;
      }

      const update: Update<Person> = {
        id: id,
        source: person.source,
        method: "update",
        changes: map(changes, (c) => ({
          prev: getPropertyValue(person, c),
          ...c,
        })),
      };
      queueUpdate(update);
    },
    [person, applyUpdate, queueUpdate]
  );
}

export function useQueueDeletePerson(id: ID) {
  const queueDelete = useQueueDeleteEffect(PersonStoreAtom);
  const personStore = useRecoilValue(PersonStoreAtom);

  return useCallback(() => {
    const person = getItem(personStore, id);
    if (!person) {
      return;
    }

    queueDelete(person, {
      id: person.id,
      method: "delete",
      source: person.source,
      previous: person,
    });

    return undefined;
  }, [id]);
}

export function useLookupPersonByAlias(integration?: Integration) {
  const store = useRecoilValue(PersonStoreAtom);
  const lookup = useMemo(
    () =>
      indexBy(values(store.lookup), (p) =>
        integration ? p?.aka?.[integration] : values(omit(p?.aka, "__typename"))
      ),
    [store]
  );
  const toPerson = useCallback(
    (id: Maybe<string>) => (id ? lookup?.[id] : undefined),
    [lookup]
  );
  return { lookup, toPerson };
}

export function useLookupAliasForPerson(integration: Integration) {
  const store = useRecoilValue(PersonStoreAtom);
  const lookup = useMemo(
    () =>
      indexBy(values(store.lookup), (p) =>
        p ? [p.id, toDisplayName(p)] : undefined
      ),
    [store]
  );
  const toAlias = useCallback(
    (id: Maybe<string>) => when(id, (id) => lookup?.[id]?.aka?.[integration]),
    [lookup]
  );

  return { lookup, toAlias };
}

export function useHasAlphaFeatures() {
  const me = useCurrentUser();
  return useMemo(() => hasAlphaFeatures(me), [me]);
}

export const useHasSeen = (item: Entity) => {
  const me = useCurrentUser();
  return useMemo(
    () => containsRef((item as HasRefs)?.refs?.seenBy, me),
    [(item as HasRefs)?.refs?.seenBy, me.id]
  );
};

export const useMe = () => {
  const me = useCurrentUser();
  return useLazyEntity<"person">(me.id, false) || me;
};

export const useMyPinned = () => {
  const me = useMe();
  return useLazyEntities(me?.refs?.pins);
};
