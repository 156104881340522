import { map, keys, reduce } from "lodash";
import { ReactNode, useCallback, useMemo, useState } from "react";

import { Entity, EntityType, Update } from "@api";

import { useAllStores, useCombinedStore } from "@state/generic";
import { useJobQueue } from "@state/jobs";
import { estimateMemoryCost, formatBytes, useClearStore } from "@state/debug";

import { Fn } from "@utils/fn";
import { useShortcut } from "@utils/event";
import { reverse } from "@utils/array";
import { secondsAgo } from "@utils/time";
import { toArray } from "@utils/set";
import { useStickyState } from "@utils/hooks";
import { debug } from "@utils/debug";
import { SafeRecord } from "@utils/types";
import {
  isSelected,
  SelectionState,
  SetSelectionState,
  useKeyboardSelectable,
  useMouseSelectable,
  useSelectable,
  useSelectableState,
} from "@utils/selectable";

import { DialogSplit } from "@ui/dialog-split";
import { Database, LaptopConnection, LayersAlt } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { TextXLarge, Text, TextSmall, SectionLabel } from "@ui/text";
import { Button } from "@ui/button";
import { ClickableSection } from "@ui/clickable-section";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { SaveErrorDialog, UpdateListItem } from "./saving-queue";
import { Tag } from "@ui/tag";
import { ListItem } from "@ui/list-item";
import { SimpleStoreState } from "@state/store";
import { Divider } from "@ui/divider";

import styles from "./debug-dialog.module.css";
import { usePointDate } from "@utils/date-fp";

type Props = {
  onDismiss: Fn<void, void>;
};

export const DebugDialog = ({ onDismiss }: Props) => {
  const [selected, setSelected] = useStickyState<string>(
    "queue",
    "debug-dialog-tab"
  );

  const side = useMemo(
    () => (
      <SpaceBetween direction="vertical" fit="container">
        <VStack>
          <TextXLarge bold>Debugging</TextXLarge>
          <Menu>
            <MenuGroup>
              <MenuItem
                icon={LayersAlt}
                text="Sync Queue"
                selected={selected === "queue"}
                onClick={() => setSelected("queue")}
              />
              <MenuItem
                icon={LaptopConnection}
                text="Distributed Jobs"
                selected={selected === "jobs"}
                onClick={() => setSelected("jobs")}
              />
              <MenuItem
                icon={Database}
                text="Data Stores"
                selected={selected === "stores"}
                onClick={() => setSelected("stores")}
              />
            </MenuGroup>
          </Menu>
        </VStack>

        <Text subtle>
          Press cmd + shift + c at any time to open the debugging dialog.
        </Text>
      </SpaceBetween>
    ),
    [selected]
  );

  return (
    <DialogSplit
      side={side}
      actions={
        <>
          <Button onClick={() => onDismiss?.()}>Close</Button>
        </>
      }
    >
      {selected === "queue" && <QueueDebugger onDismiss={onDismiss} />}

      {selected === "jobs" && <JobsDebugger onDismiss={onDismiss} />}

      {selected === "stores" && <StoresDebugger onDismiss={onDismiss} />}
    </DialogSplit>
  );
};

export const DebugDialogObserver = () => {
  const [open, setOpen] = useState(false);

  useShortcut({ key: "KeyC", shift: true, command: true }, () =>
    setOpen((o) => !o)
  );

  if (!open) {
    return <></>;
  }

  return <DebugDialog onDismiss={() => setOpen(false)} />;
};

const QueueDebugger = ({}: Props) => {
  const [selected, setSelected] = useState<Update<Entity>[]>([]);
  const { updating, unsaved, history } = useCombinedStore();
  const [mode, setMode] = useState<"queue" | "history">("queue");
  return (
    <VStack fit="container" gap={16}>
      {!!selected?.length && (
        <SaveErrorDialog updates={selected} onDismiss={() => setSelected([])} />
      )}
      <HStack fit="container" justify="flex-end">
        <ButtonGroup fit="container">
          <SplitButton
            size="small"
            selected={mode === "queue"}
            onClick={() => setMode("queue")}
          >
            Queue
          </SplitButton>
          <SplitButton
            size="small"
            selected={mode === "history"}
            onClick={() => setMode("history")}
          >
            History
          </SplitButton>
        </ButtonGroup>
      </HStack>

      {mode === "history" && (
        <VStack fit="container">
          {map(reverse(history), (u) => (
            <UpdateListItem
              key={`${u.id}/${u.queued}`}
              update={u}
              onClick={() => setSelected([u])}
            />
          ))}
          {!history?.length && <Text subtle>No saved changes.</Text>}
        </VStack>
      )}

      {mode === "queue" && (
        <>
          {!!updating?.length && (
            <ClickableSection title="Saving...">
              {map(updating, (u) => (
                <UpdateListItem
                  key={`${u.id}/${u.queued}`}
                  update={u}
                  onClick={() => setSelected([u])}
                />
              ))}
            </ClickableSection>
          )}
          {!!unsaved?.length && (
            <ClickableSection title="Queued">
              {map(unsaved, (u) => (
                <UpdateListItem
                  key={`${u.id}/${u.queued}`}
                  update={u}
                  onClick={() => setSelected([u])}
                />
              ))}
            </ClickableSection>
          )}
          {!unsaved?.length && <Text subtle>Waiting for changes...</Text>}
        </>
      )}
    </VStack>
  );
};

const JobsDebugger = ({}: Props) => {
  const jobs = useJobQueue();

  return (
    <ListItem>
      <VStack fit="container" gap={0}>
        {map(jobs, (job) => (
          <SpaceBetween>
            <HStack gap={4}>
              <SectionLabel>{job.id}</SectionLabel>
              <Text>{job.key}</Text>
            </HStack>

            <HStack gap={4}>
              <TextSmall subtle>
                {usePointDate(job.lockedAt, secondsAgo)} seconds
              </TextSmall>
              <Tag color={job.status === "running" ? "blue" : "gray"}>
                {job.status}
              </Tag>
            </HStack>
          </SpaceBetween>
        ))}

        {!jobs?.length && <Text subtle>No running/queued jobs.</Text>}
      </VStack>
    </ListItem>
  );
};

const StoresDebugger = ({}: Props) => {
  const allStores = useAllStores() as SafeRecord<
    string,
    SimpleStoreState<Object>
  >;
  const allKeys = useMemo(() => keys(allStores), [allStores]);
  const [selection, setSelection] = useSelectableState();
  const clear = useClearStore();

  const estSize = useMemo(
    () =>
      formatBytes(
        reduce(
          toArray(selection.selected),
          (acc, id) => acc + estimateMemoryCost(allStores[id]),
          0
        )
      ),
    [selection.selected]
  );

  useKeyboardSelectable(selection, setSelection, true);
  useMouseSelectable(selection, setSelection, true);

  const handleClearSelected = useCallback(() => {
    map(toArray(selection.selected), (id) => clear(id as EntityType));
    window.location.reload();
  }, [selection.selected]);

  const handleDumpSelected = useCallback(() => {
    map(toArray(selection.selected), (id) => debug(allStores[id]));
  }, [selection.selected]);

  const handleClearAll = useCallback(() => {
    map(allKeys, (id) => clear(id as EntityType));
    window.location.reload();
  }, [allKeys]);

  return (
    <VStack fit="container" gap={10}>
      <HStack fit="container" justify="flex-end">
        {!!selection.selected.size && (
          <HStack>
            <Text className={styles.blue}>
              {selection.selected.size} selected
            </Text>
            <Text>{estSize}</Text>
            <Button size="small" onClick={handleDumpSelected}>
              Dump ({selection.selected.size})
            </Button>
            <Button size="small" onClick={handleClearSelected}>
              Clear ({selection.selected.size})
            </Button>
          </HStack>
        )}
        {!selection.selected.size && (
          <Button size="small" onClick={handleClearAll}>
            Clear All
          </Button>
        )}
      </HStack>

      <Divider />

      <VStack gap={0}>
        {map(allKeys, (storeKey) => (
          <SelectableListItem
            key={storeKey}
            id={storeKey}
            selection={selection}
            setSelection={setSelection}
          >
            <SpaceBetween className={styles.noMouse}>
              <SectionLabel>{storeKey}</SectionLabel>
              <TextSmall subtle>
                {keys(allStores[storeKey]?.lookup)?.length} items
              </TextSmall>
            </SpaceBetween>
          </SelectableListItem>
        ))}
      </VStack>
    </VStack>
  );
};

interface ListItemProps {
  id: string;
  selection: SelectionState;
  setSelection: SetSelectionState;
  children: ReactNode;
  className?: string;
}

const SelectableListItem = ({
  children,
  selection,
  setSelection,
  className,
  id,
}: ListItemProps) => {
  const selectableProps = useSelectable(id);
  const selected = useMemo(
    () => !!selection && isSelected(selection, id),
    [selection, id]
  );
  return (
    <ListItem
      selectable={true}
      {...selectableProps}
      selected={selected}
      className={className}
    >
      {children}
    </ListItem>
  );
};
