import { Task } from "@api";
import { ViewEngine } from "../types";
import { TaskListItem } from "./list-item";
import { TaskListCard } from "./list-card";
import { TaskMenuItem } from "./menu-item";
import TaskPane from "@ui/engine/task/task-pane";

export const TaskEngine: ViewEngine<Task> = {
  asMenuItem: TaskMenuItem,
  asListItem: TaskListItem,
  asListCard: TaskListCard,
  asPrimaryPane: TaskPane,
};
