import { Heading } from "@ui/text";
import styles from "./template-banner.module.css";
import { Sheet } from "./sheet-layout";

export const TemplateBanner = () => (
  <div className={styles.banner}>Template</div>
);

export const TemplateSideBar = () => (
  <Sheet mode="sizing" size="secondary" height="container">
    <Heading className={styles.emptyMessage}>
      App toolbar not available in templates
    </Heading>
  </Sheet>
);
