import { useCallback, useState } from "react";

import { Entity, Link, Task, Project } from "@api";

import { useCreateDocument } from "@state/resources";
import { useLazySettings } from "@state/tasks";
import { useLazyTeam, useTeamId } from "@state/teams";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { getSetting } from "@utils/property-refs";

import { Button } from "@ui/button";
import { Field, TextInput } from "@ui/input";
import { HStack, VStack } from "@ui/flex";
import { NotionSelect } from "@ui/select/notion";
import { Dialog } from "@ui/dialog";

import styles from "./create-document-dialog.module.css";

export interface Props {
  defaultName?: string;
  entity: Entity;
  onChanged?: Fn<Link, void>;
  onCancel?: Fn<void, void>;
}

export const CreateDocumentDialog = ({
  defaultName,
  entity,
  onCancel,
  onChanged,
}: Props) => {
  const [title, setTitle] = useState(defaultName || "");
  const [saving, setSaving] = useState(false);
  const team = useLazyTeam(useTeamId(entity) || "");
  const createDocument = useCreateDocument(entity.id, entity.source);
  const settings = useLazySettings(entity.id);
  const [location, setLocation] = useState<Maybe<string>>(
    (entity as Task | Project).notionId ||
      getSetting<string>(settings, "notion_page") ||
      team?.notionId
  );

  const onCreateDoc = useCallback(
    async (title?: string, location?: string) => {
      if (saving) {
        return;
      }

      setSaving(true);
      const doc = await createDocument(
        { title: title || "New document" },
        location
      );
      onChanged?.({
        url: doc.url,
        text: doc.title || "New document",
      });
      setSaving(false);
    },
    [saving, createDocument]
  );

  return (
    <Dialog
      title="New document"
      mode="blocking"
      onDismiss={onCancel}
      actions={
        <HStack>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button
            variant="primary"
            onClick={() => onCreateDoc(title, location)}
          >
            Create document
          </Button>
        </HStack>
      }
    >
      <VStack gap={16} fit="container">
        <Field label="Title">
          <TextInput
            value={title}
            autoFocus={true}
            onChange={(text) => setTitle(text)}
            updateOn="blur"
            placeholder="What should this document be called..."
          />
        </Field>
        <Field label="Location">
          <NotionSelect
            className={{ trigger: styles.control }}
            value={location}
            onChange={(p) => setLocation(p)}
            showFullPath={true}
          />
        </Field>
      </VStack>
    </Dialog>
  );
};
