import { useEffect, useMemo } from "react";
import Markdown from "react-markdown";

import { ID, isTemplate, Project } from "@api";

import { useAutoUpdate } from "@state/ai";
import { useLazyEntity, useQueueUpdates } from "@state/generic";
import { useLazyPropertyValue } from "@state/databases";

import { timeAgo } from "@utils/date";
import { now } from "@utils/now";
import { toUpdate } from "@utils/property-mutations";
import { Maybe } from "@utils/maybe";
import { toMarkdown } from "@utils/rich-text";
import { isStartedStatus } from "@utils/status";

import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Magic } from "@ui/icon";
import { Text, TextSmall } from "@ui/text";
import { useCurrentPage } from "@ui/app-page";
import { VStack } from "@ui/flex";

import styles from "./summary-section.module.css";
import { usePointDate } from "@utils/date-fp";

interface Props {
  entityId: ID;
  sectionLabel?: string;
  hideOnEmpty?: boolean;
}

export const SummarySection = ({
  entityId,
  sectionLabel,
  hideOnEmpty = false,
}: Props) => {
  const pageId = useCurrentPage();
  const entity = useLazyEntity(entityId);
  const update = useQueueUpdates(pageId);
  const { completion, generate, loading } = useAutoUpdate(entity as Project);
  const summary = useMemo(
    () => completion || toMarkdown((entity as Maybe<Project>)?.summary),
    [entity, completion, loading]
  );
  const status = useLazyPropertyValue(entity, {
    field: "status",
    type: "status",
  });

  if (hideOnEmpty && !completion) {
    return <></>;
  }

  useEffect(() => {
    if (!(entity as Project)?.summary) {
      generate();
    }
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (entity && completion && (entity as Project)?.summary !== completion) {
      update(
        toUpdate(
          entity,
          { field: "summary", type: "rich_text" },
          { markdown: completion }
        )
      );
    }
  }, [loading, completion]);

  if (
    (!!entity && isTemplate(entity)) ||
    (status && !isStartedStatus(status.value?.status))
  ) {
    return <></>;
  }

  return (
    <CollapsibleSection
      title={sectionLabel || "Auto-Summary"}
      labelSize="medium"
      actions={
        <Button
          subtle
          size="small"
          icon={Magic}
          onClick={() => generate()}
          loading={loading}
        >
          <Text subtle>Regenerate</Text>
        </Button>
      }
    >
      <VStack>
        {summary && <Markdown className={styles.text}>{summary}</Markdown>}
        {completion && !loading && (
          <TextSmall subtle>
            Last updated{" "}
            {usePointDate(entity?.updatedAt, (d) => timeAgo(d || now()))}
          </TextSmall>
        )}
      </VStack>
    </CollapsibleSection>
  );
};
