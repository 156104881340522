import { useCallback, useRef, useState } from "react";

import { Fn } from "@utils/fn";
import { Maybe, isDefined } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";

import { Icon, PlusIcon } from "@ui/icon";
import { TextInput } from "@ui/input";
import { Text } from "@ui/text";

import { MenuItemProps, MenuItem } from "./default";

import styles from "./styles.module.css";

interface Props {
  icon?: ComponentOrNode;
  title: string;
  indent?: number;
  subtle?: boolean;
  onClick?: Fn<void, void>;
  className?: string;
}

export function AddMenuItem({ icon, subtle, title, onClick, ...props }: Props) {
  return (
    <MenuItem
      icon={icon || <Icon icon={PlusIcon} />}
      onClick={() => onClick?.()}
      {...props}
    >
      <Text subtle={subtle}>{title}</Text>
    </MenuItem>
  );
}

export function AddInlineMenuItem({
  icon,
  title,
  onAdd,
  onClick,
  placeholder,
  onType,
  ...props
}: Partial<Props> & {
  placeholder?: string;
  onAdd: Fn<string, void>;
  onType?: Fn<string, boolean | void>;
} & MenuItemProps) {
  const input = useRef<HTMLInputElement>(null);
  const [editing, setEditing] = useState<Maybe<string>>(undefined);

  const onEditingChanged = useCallback(
    (text: Maybe<string>) => {
      if (onType?.(text || "") === true) {
        setEditing(undefined);
        input?.current?.blur?.();
      } else {
        setEditing(text);
      }
    },
    [onType, setEditing]
  );

  return (
    <MenuItem
      icon={icon || <Icon icon={PlusIcon} />}
      onClick={() => setEditing("")}
      {...props}
    >
      {isDefined(editing) ? (
        <TextInput
          ref={input}
          autoFocus={true}
          value={editing}
          placeholder={placeholder}
          onChange={onEditingChanged}
          updateOn="change"
          className={styles.inlineAdd}
          onBlur={() => {
            if (editing) {
              onAdd(editing);
            }
            onEditingChanged(undefined);
          }}
        />
      ) : (
        <Text subtle>{title || placeholder}</Text>
      )}
    </MenuItem>
  );
}
