import { atomFamily, selectorFamily } from "recoil";

import { ID, Sprint } from "@api";

import {
  getItem,
  localStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type SprintStoreState = StoreState<Sprint>;

export const WorkspaceSprintStoreAtom = atomFamily<SprintStoreState, ID>({
  key: "WorkspaceSprintStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [localStorageForStore(wid, "sprint")],
});

export const SprintStoreAtom = WorkspaceWrappedAtom(
  "SprintStoreAtom",
  WorkspaceSprintStoreAtom
);

export const SprintAtom = selectorFamily({
  key: "SprintAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(SprintStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(SprintStoreAtom, mergeItem<Sprint>(newValue));
      }
    },
});
