import { filter, find, last, map, some } from "lodash";
import { Fragment, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

import {
  DatabaseID,
  Entity,
  GroupByProp,
  GroupByPropDef,
  PropertyMutation,
  PropertyRef,
  PropertyType,
  Ref,
  View,
} from "@api";

import { ViewQuickFilterAtom, useQuickSearch } from "@state/quick-filters";
import { useStableViewKey } from "@state/store";
import { ID } from "@state/types";
import {
  isTriaging,
  useAddToView,
  useDefaultsForView,
  useDropInView,
  useLazyGetView,
  useLazyItemsForView,
  useUpdateView,
} from "@state/views";
import { useLazyProperties } from "@state/databases";
import { useEntityLabels } from "@state/settings";
import { getStore, useEntitySource, useQueueUpdates } from "@state/generic";

import { OneOrMany, ensureArray, reverse, whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { usePushTo } from "@utils/navigation";
import { asMutation, asUpdate } from "@utils/property-mutations";
import {
  SelectionState,
  SetSelectionState,
  usePageSelection,
} from "@utils/selectable";
import {
  GroupedItems,
  GroupedValue,
  NestedGroup,
  isNested,
  toViewingWithinScope,
} from "@utils/grouping";

import { AddEntityInput } from "@ui/add-entity-input";
import { useCurrentPage } from "@ui/app-page";
import { Container } from "@ui/container";
import { GroupColor, GroupHeading, HiddenGroups } from "@ui/nested-groups";
import { DropTarget, useItemDrop } from "@ui/entity-drag-drop";
import { Fn } from "@utils/fn";
import { useQueryParams, useShowMore } from "@utils/hooks";
import { maybeMap, when } from "@utils/maybe";
import {
  isAnySelect,
  isEmptyRef,
  toFieldName,
  toKey,
} from "@utils/property-refs";
import { render, useEngine } from "ui/engine";
import { MoreMenuItem } from "@ui/menu-item";
import { EmptyState } from "@ui/empty-state";
import { useSuggestedProps } from "@ui/suggested-props";
import { PlusIcon } from "@ui/icon";
import { Text } from "@ui/text";
import { Button } from "@ui/button";
import { PropertyRefEditDialog } from "@ui/property-edit-dialog";
import { VStack } from "@ui/flex";
import { WithViewingWithin } from "@ui/viewing-within";

import styles from "./list.module.css";

interface Props {
  id: ID;
}

interface NestedGroupsProps {
  view: View;
  groups: NestedGroup[];
  hidden?: NestedGroup[];
  parents: GroupedValue[];
  itemSource: DatabaseID;
  onChanged?: Fn<GroupByPropDef<Entity, PropertyType>, void>;
  showProps?: PropertyRef[];
  selection: SelectionState;
  setSelection: SetSelectionState;
  onAdded: (ts: OneOrMany<Ref>) => void;
  updateView: (changes: PropertyMutation<View>[]) => void;
  collapsible?: boolean;
}

export function ListLayout({ id }: Props) {
  const view = useLazyGetView(id);
  const setView = useUpdateView(id, true);
  const { items: tasks } = useLazyItemsForView(id);
  const onAdded = useAddToView(id);

  const itemsSource = useEntitySource(view?.entity || "task", view?.source);
  const defaults = useDefaultsForView(id);
  const quickFilters = useRecoilValue(ViewQuickFilterAtom(id));
  const toLabel = useEntityLabels(view?.source.scope, {
    case: "lower",
    plural: true,
  });
  const [selection, setSelection] = usePageSelection();
  const suggestedProps = useSuggestedProps();
  const showProps = useMemo(
    () => reverse(whenEmpty(view?.showProps, suggestedProps || [])),
    [view?.showProps, suggestedProps]
  );
  const groups = useMemo(
    () =>
      filter(
        tasks.grouped?.groups,
        (g) => !isTriaging(view) || !isEmptyRef(g.value)
      ),
    [tasks?.grouped?.groups]
  );

  const collapsible = useMemo(
    () =>
      some(view?.group || [], (g) => g?.collapse === true) &&
      // and there are no quick filters or searching
      !(quickFilters?.search || quickFilters?.selected?.length),
    [quickFilters?.search, quickFilters?.selected, view?.group]
  );

  if (!view) {
    return <></>;
  }

  return (
    <div className={cx(styles.rootContainer)}>
      {tasks?.grouped && (
        <>
          {!tasks.grouped.groups?.length && (
            <EmptyState
              image="/IMG-list-empty.png"
              text={`No ${toLabel(view.entity)} here yet.`}
            >
              <AddEntityInput
                source={itemsSource}
                defaults={defaults}
                onAdded={onAdded}
              />
            </EmptyState>
          )}

          <NestedGroups
            groups={groups}
            view={view}
            collapsible={collapsible}
            hidden={tasks.grouped.hidden}
            itemSource={itemsSource}
            selection={selection}
            setSelection={setSelection}
            showProps={showProps}
            parents={[]}
            onAdded={onAdded}
            updateView={(changes) => setView(changes)}
            onChanged={({ values, ...group }) =>
              setView([
                asMutation(
                  { field: "group", type: "json" },
                  maybeMap(
                    view?.group,
                    (g) => g && (g.field === group.field ? group : g)
                  )
                ),
              ])
            }
          />
        </>
      )}

      {!tasks?.grouped && !!tasks?.sorted && (
        <UngroupedList
          view={view}
          items={tasks.sorted}
          itemsSource={itemsSource}
          selection={selection}
          setSelection={setSelection}
          showProps={showProps}
          onAdded={onAdded}
          defaults={defaults}
        />
      )}
    </div>
  );
}

interface UngroupedListProps {
  view: View;
  items: Entity[];
  itemsSource: DatabaseID;
  selection: SelectionState;
  setSelection: SetSelectionState;
  showProps: PropertyRef[];
  onAdded: (ts: OneOrMany<Ref>) => void;
  defaults: Partial<Entity>;
}

const UngroupedList = ({
  view,
  items,
  itemsSource,
  defaults,
  onAdded,
  selection,
  setSelection,
  showProps,
}: UngroupedListProps) => {
  const pageId = useCurrentPage();
  const engine = useEngine(view?.entity || "task");
  const pushTo = usePushTo();
  const mutate = useQueueUpdates(pageId);
  const onReorder = useDropInView(view, pageId);
  const toStableKey = useStableViewKey(getStore(view?.entity || "task"));
  const toLabel = useEntityLabels(view?.source.scope, {
    case: "lower",
    plural: true,
  });
  const { visible, showMore, moreCount, hasMore } = useShowMore(items, 100);

  return (
    <ul
      className={cx(
        !view.grouping ? styles.listContainer : styles.cardContainer
      )}
    >
      {map(visible, (t) =>
        render(engine.asListItem, {
          key: toStableKey(t.id),
          item: t,
          selection: selection,
          setSelection: setSelection,
          showProps: showProps,
          onReorder: onReorder,
          onOpen: pushTo,
          onChange: (change) => mutate(asUpdate(t, ensureArray(change))),
        })
      )}

      {hasMore && <MoreMenuItem onClick={showMore} count={moreCount} />}

      {!visible.length && (
        <EmptyState
          image="/IMG-list-empty.png"
          text={`No ${toLabel(view.entity)} here yet.`}
        />
      )}

      <DropTarget position="after" item={last(visible)} type={view.entity}>
        <AddEntityInput
          source={itemsSource}
          onAdded={onAdded}
          defaults={defaults}
        />
      </DropTarget>
    </ul>
  );
};

const NestedGroupView = ({
  group,
  view,
  index,
  collapsible: _collapsible = true,
  showProps,
  onChanged,
  parents,
  ...rest
}: Omit<NestedGroupsProps, "groups" | "hidden"> & {
  group: NestedGroup<Entity>;
  index: number;
}) => {
  const pageId = useCurrentPage();
  const containerRef = useRef<HTMLDivElement>(null);
  const [{ dropping }] = useItemDrop({
    type: view.entity,
    ref: containerRef,
    group: group,
    item: undefined,
  });
  const params = useQueryParams();
  const pushTo = usePushTo();
  const engine = useEngine(view.entity);
  const g = group.value;
  const key = String(toKey(g));
  const isTopGroup = useMemo(() => !parents?.length, [parents]);
  const defaults = useDefaultsForView(
    view.id,
    group.value ? [group.value, ...(parents || [])] : undefined
  );
  const viewingWithin = useMemo(() => toViewingWithinScope(group), [group]);
  const toStableKey = useStableViewKey(getStore(view?.entity || "task"));
  const onReorder = useDropInView(view, pageId);
  const itemSource = useEntitySource(view.entity, view.source);
  const mutate = useQueueUpdates(pageId);
  const collapsible = useMemo(() => _collapsible, [_collapsible, isTopGroup]);
  const [expanded, setExpanded] = useState(
    // Expanded query param is set to the group value
    when(params?.expanded, (ex) => {
      if (ex === key || some(parents, (p) => toKey(p) === ex)) {
        return true;
      }
      return undefined;
    }) ||
      // Is set to alwaysShow in view group settings
      group.def?.alwaysShow?.includes(key)
  );

  const props = useLazyProperties({ ...view.source, type: view.entity });
  const aggProp = useMemo(
    () =>
      when(
        find(
          view?.showProps,
          (p) =>
            p.field !== group?.def?.field &&
            [
              "status",
              "relation",
              "relations",
              "select",
              "multi_select",
              "number",
            ]?.includes(p.type)
        ),
        (ref) => find(props, { field: ref.field })
      ),
    [(view?.showProps, props)]
  );

  if (isNested(group)) {
    return <></>;
  }

  const { hasMore, showMore, visible } = useShowMore(group.items, 50);

  return (
    <div
      ref={containerRef}
      key={key}
      className={cx(styles.group, collapsible && expanded && styles.open)}
    >
      <WithViewingWithin scope={viewingWithin}>
        <GroupHeading
          group={group}
          view={view}
          inset
          className={cx(styles?.groupHeader, dropping && styles.dropping)}
          aggregateProp={aggProp}
          onChanged={onChanged}
          open={!collapsible || expanded}
          onOpen={collapsible ? setExpanded : undefined}
        >
          <GroupColor group={group?.value} dropping={!!dropping} />
        </GroupHeading>

        {(!collapsible || expanded) && (
          <>
            <ul className={cx(styles.listContainer)}>
              {map(visible, (t) => (
                <Fragment key={toStableKey(t.id) || t.id}>
                  {render(engine.asListItem, {
                    key: t.id,
                    item: t,
                    group: group,
                    selection: rest.selection,
                    setSelection: rest.setSelection,
                    showProps: showProps ?? view?.showProps,
                    hideEmpty: view?.settings?.hideEmptyFields,
                    onReorder: onReorder,
                    onOpen: pushTo,
                    onChange: (change) =>
                      mutate(asUpdate(t, ensureArray(change))),
                  })}
                </Fragment>
              ))}

              {hasMore && (
                <MoreMenuItem
                  onClick={showMore}
                  count={group.items?.length - visible.length}
                />
              )}

              <DropTarget
                group={group}
                position="after"
                item={last(group.items)}
                type={view.entity}
              >
                <AddEntityInput
                  className={styles.addInput}
                  source={itemSource}
                  defaults={defaults}
                  onAdded={rest.onAdded}
                />
              </DropTarget>
            </ul>
          </>
        )}
      </WithViewingWithin>
    </div>
  );
};

export const NestedGroups = (props: NestedGroupsProps) => {
  const { view, groups, hidden, parents = [], ...rest } = props;
  const { isSearching } = useQuickSearch(props.view.id);
  const [editProp, setEditProp] = useState<GroupByProp<Entity>>();
  const groupDef = view?.group?.[0];

  return (
    <div className={cx(styles.groupContainer, styles[view.grouping || "rows"])}>
      {maybeMap(
        groups,
        (group, index) =>
          (!isSearching || !!(group as GroupedItems)?.items?.length) && (
            <NestedGroupView
              key={toKey(group?.value)}
              {...rest}
              view={view}
              parents={parents}
              group={group}
              index={index}
            />
          )
      )}

      <VStack gap={10}>
        {groupDef && isAnySelect(groupDef) && (
          <Container
            padding="none"
            fit="container"
            className={styles.newSection}
          >
            <Button
              size="small"
              inset
              subtle
              icon={PlusIcon}
              className={styles.addInput}
              onClick={() => setEditProp(groupDef)}
            >
              <Text subtle>Add {toFieldName(groupDef)}</Text>
            </Button>
            {editProp && (
              <PropertyRefEditDialog
                prop={editProp}
                source={rest.itemSource}
                onClose={() => setEditProp(undefined)}
              />
            )}
          </Container>
        )}

        {!!hidden?.length && (
          <HiddenGroups
            hidden={hidden}
            onChanged={props.onChanged}
            className={{
              container: styles.hiddenGroupsContainer,
              list: styles.hiddenGroups,
            }}
          />
        )}
      </VStack>
    </div>
  );
};
