import { find, map } from "lodash";
import { ReactNode, useMemo, useState } from "react";

import { Entity, PropertyMutation } from "@api";

import {
  useLazyGetPropertyValueRefs,
  useVisiblePropertyRefs,
} from "@state/databases";
import { toManageFields } from "@state/teams";

import { Fn } from "@utils/fn";
import { asMutation } from "@utils/property-mutations";
import { toFieldName, toKey } from "@utils/property-refs";
import { sentenceCase } from "@utils/string";
import { extractTeam } from "@utils/scope";
import { when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";

import { EllipsisH, OpenIn, PlusIcon } from "@ui/icon";
import { ActionDivider, ActionItem, ActionMenu } from "@ui/action-menu";
import { Button } from "@ui/button";
import { Container, Props as ContainerProps } from "@ui/container";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { LabelledPropertyValue } from "./property-value";
import { PropertyCreateDialog } from "./property-edit-dialog";

type TileProps = {
  entity: Entity;
  onChange: Fn<PropertyMutation<Entity>[], void>;
  field: string;
  label?: boolean;
};

export const PropertyValueTile = ({
  field,
  entity,
  onChange,
  label = true,
}: TileProps) => {
  const props = useLazyGetPropertyValueRefs(entity?.id || "");
  const prop = useMemo(() => find(props, (p) => p.field === field), [props]);

  if (!prop) {
    return <></>;
  }

  return (
    <LabelledPropertyValue
      label={!label ? "" : prop.def?.label || sentenceCase(prop.field)}
      valueRef={prop}
      source={entity.source}
      fit="content"
      parent={entity}
      onChange={(c) => onChange([asMutation(prop, c[prop.type])])}
    />
  );
};

export const LabelledPropertyValueList = ({
  entity,
  onChange,
  blacklist = ["name", "title"],
  stack = "horizontal",
  children,
}: {
  entity: Entity;
  blacklist?: string[];
  stack?: ContainerProps["stack"];
  onChange: Fn<PropertyMutation<Entity>[], void>;
  children?: ReactNode;
}) => {
  const goTo = useGoTo();
  const [whitelist, setWhiteList] = useState<string[]>([]);
  const [creating, setCreating] = useState(false);
  const { visible, hidden } = useVisiblePropertyRefs(
    entity,
    (p) =>
      p.visibility !== "hidden" &&
      p.displayAs === "property" &&
      !p.readonly &&
      !blacklist?.includes(p.field),
    { whitelist, hideEmpty: true }
  );

  return (
    <Container padding="none" stack={stack} wrap gap={20} align="flex-end">
      {creating && (
        <PropertyCreateDialog
          source={entity.source}
          onSaved={(prop) => {
            setCreating(false);
            setWhiteList((l) => [...l, prop.field]);
          }}
          onClose={() => setCreating(false)}
        />
      )}
      {children}
      {map(visible, (prop) => (
        <LabelledPropertyValue
          key={toKey(prop)}
          parent={entity}
          source={entity.source}
          label={prop.def?.label || sentenceCase(prop.field)}
          valueRef={prop}
          fit={stack === "horizontal" ? "content" : "container"}
          onChange={(c) => onChange([asMutation(prop, c[prop.type])])}
        />
      ))}
      <ActionMenu
        actions={
          <>
            {map(hidden, (p) => (
              <ActionItem
                key={toKey(p)}
                onClick={() => setWhiteList((l) => [...l, p.field])}
                icon={<PropertyTypeIcon {...p} />}
              >
                {toFieldName(p)}
              </ActionItem>
            ))}
            <ActionDivider />
            <ActionItem icon={PlusIcon} onClick={() => setCreating(true)}>
              Create new field
            </ActionItem>
            <ActionItem
              icon={OpenIn}
              onClick={() =>
                when(extractTeam(entity.source.scope), (id) =>
                  goTo(toManageFields(id, entity.source.type))
                )
              }
            >
              Manage fields
            </ActionItem>
          </>
        }
      >
        <Button icon={EllipsisH} subtle />
      </ActionMenu>
    </Container>
  );
};
