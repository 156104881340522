import _pluralize from "pluralize";
import { escapeRegExp, keys, reduce } from "lodash";
import { join } from "lodash/fp";
import {
  sentenceCase as _sentenceCase,
  capitalCase as _capitalCase,
} from "change-case";
import spaceCase from "to-space-case";
import { titleCase as titleNoSpaceCase } from "title-case";

import { Maybe } from "./maybe";
import { composel } from "./fn";
import { omitEmpty } from "./array";

const STRIPE_REGEX = /[^a-zA-Z0-9\(\)\&\"\'\/]/g;

export const capitalCase = (text: string) =>
  _capitalCase(text, {
    stripRegexp: STRIPE_REGEX,
  });

export const sentenceCase = (text: string) =>
  _sentenceCase(text, {
    stripRegexp: STRIPE_REGEX,
  });

export const lowerSentenceCase = (s?: string) =>
  _sentenceCase(s || "")?.toLowerCase();

export const titleCase = composel(spaceCase, titleNoSpaceCase);

export const limit = (s: string, limit: number) => s.substring(0, limit);
export const clipLimit = (s: string, limit: number) =>
  s.length > limit ? s.substring(0, limit) + "..." : s;

_pluralize.addUncountableRule("content");
_pluralize.addUncountableRule("work");

export function plural(word: string, num?: Maybe<number>): string;
export function plural(word: Maybe<string>, num?: Maybe<number>) {
  return word && _pluralize(word, num);
}
// Alias
export const pluralize = plural;

export const prefix = (pre: string) => (s: string) => pre + s;
export const suffix = (post: string) => (s: string) => s + post;

export const replaceAll = (str: string, find: string, replace: string) =>
  str.replace(new RegExp(escapeRegExp(find), "g"), replace);

export const replaceAll_ = (find: string, replace: string) => (val: string) =>
  replaceAll(val, find, replace);

// Takes a template string which includes named variables like {this} and an object with the values like {this: "that"}
export const format = (template: string, values: Record<string, string>) =>
  reduce(
    keys(values),
    (acc, key) => replaceAll(acc, `{${key}}`, values[key]),
    template
  );

export const toSentence = composel(omitEmpty, join(" "), sentenceCase);

export const wrap = (pre: string, post: string) => (s: string) =>
  pre + s + post;
