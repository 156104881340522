import { atomFamily, selectorFamily } from "recoil";
import { ID } from "@api";
import { WorkspaceWrappedAtom } from "@state/workspace/atoms";
import { TractionPresence } from "./types";

export interface PresenceStoreState {
  lookup: Record<string, TractionPresence>;
}

export const WorkspacePresenceStoreAtom = atomFamily<PresenceStoreState, ID>({
  key: "WorkspaceTeamPresenceAtom",
  default: { lookup: {} },
});

export const PresenceStoreAtom = WorkspaceWrappedAtom(
  "PresenceStoreAtom",
  WorkspacePresenceStoreAtom
);

export const PersonPresenceAtom = selectorFamily<TractionPresence, ID>({
  key: "PersonPresenceAtom",
  get:
    (personId) =>
    ({ get }) =>
      get(PresenceStoreAtom).lookup[personId],
});
