import { ReactNode, useMemo } from "react";

import { cx } from "@utils/class-names";

import { Container } from "@ui/container";
import { SectionLabel, TextMedium, TextSmall, Text } from "@ui/text";
import { HStack, SpaceBetween, VStack } from "./flex";
import { ComponentOrNode } from "@utils/react";
import { Icon } from "@ui/icon";

import styles from "./section.module.css";
import { Divider } from "@ui/divider";
import { Color } from "@api";
import { toColorVar } from "@utils/color";

export interface Props {
  title?: string | ReactNode;
  size?: "small" | "default" | "medium";
  icon?: ComponentOrNode;
  inset?: boolean;
  divider?: boolean;
  actions?: ReactNode;
  children: ReactNode;
  className?: string;
}

export const Section = ({
  title,
  icon,
  children,
  className,
  actions,
  size = "small",
  inset = true,
  divider = true,
}: Props) => {
  return (
    <VStack fit="container" gap={6} className={cx(styles.section, className)}>
      <SpaceBetween
        gap={8}
        className={cx(styles.header, inset && styles.inset)}
      >
        <HStack gap={4} className={styles.noWrap}>
          {icon && <Icon icon={icon} />}
          {size === "medium" && <TextMedium subtle>{title}</TextMedium>}
          {size === "small" && <TextSmall subtle>{title}</TextSmall>}
          {size === "default" && <Text subtle>{title}</Text>}
        </HStack>
        {divider && <Divider />}
        {actions}
      </SpaceBetween>

      {children}
    </VStack>
  );
};

export const ColoredSection = ({
  title,
  icon,
  children,
  className,
  actions,
  size = "small",
  color,
  inset,
  divider = true,
}: Props & { color?: Color | "background" }) => {
  const css = useMemo(
    () =>
      color
        ? {
            background:
              color === "background"
                ? "var(--color-background)"
                : toColorVar(color),
          }
        : {},
    [color]
  );
  return (
    <Container
      fit="container"
      gap={6}
      inset={inset ? "horizontal" : "none"}
      stack="vertical"
      className={cx(styles.section, styles.secondary, className)}
      style={css}
    >
      {!!(title || actions) && (
        <SpaceBetween gap={8}>
          <HStack gap={4}>
            {icon && <Icon icon={icon} />}
            {size === "medium" && <TextMedium subtle>{title}</TextMedium>}
            {size === "small" && <SectionLabel subtle>{title}</SectionLabel>}
          </HStack>
          {actions}
        </SpaceBetween>
      )}

      {children}
    </Container>
  );
};
