import {
  CSSProperties,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  Portal,
} from "@radix-ui/react-dialog";

import { Fn } from "@utils/fn";
import { cx } from "@utils/class-names";
import { useShortcut, withHandle } from "@utils/event";
import { useSelectableIgnoreClicks } from "@utils/selectable";

import { Props as ContainerProps, Container } from "./container";
import { Button } from "./button";
import { TimesIcon } from "./icon";

import styles from "./modal.module.css";
import { NoSelectable } from "@ui/selectable-items";
import { useClickAway } from "@utils/hooks";

export const ModalClose = () => (
  <DialogClose asChild>
    <Button variant="icon-only" aria-label="Close" icon={TimesIcon} />
  </DialogClose>
);

export type Props = {
  open?: boolean;
  mode?: "blocking" | "passive";
  size?: "primary" | "secondary";
  onOpenChanged?: Fn<boolean, void>;
  onAutoFocus?: (event: Event) => void;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
} & Omit<ContainerProps, "size">;

export const Modal = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      mode = "blocking",
      style,
      size,
      className,
      open,
      onOpenChanged,
      onAutoFocus,
      ...rest
    },
    _ref
  ) => {
    const localRef = useRef<HTMLDivElement>(null);
    const ref = (_ref || localRef) as React.MutableRefObject<HTMLDivElement>;
    const [entering, setEntering] = useState(true);
    useShortcut("Escape", () => onOpenChanged?.(false), [onOpenChanged]);
    const selectableProps = useSelectableIgnoreClicks();

    useEffect(() => {
      setTimeout(() => setEntering(false), 300);
    }, []);

    useClickAway(
      ref,
      () => onOpenChanged?.(false),
      open && mode === "blocking",
      [onOpenChanged]
    );

    return (
      <Dialog open={open} modal={false}>
        <Portal>
          <DialogContent
            ref={ref}
            style={style}
            {...selectableProps}
            onOpenAutoFocus={onAutoFocus}
            className={cx(
              styles.container,
              size && styles[size],
              entering && styles.entering,
              mode === "blocking" ? styles.center : styles.bottomRight,
              className
            )}
          >
            <NoSelectable>
              <Container {...rest}>{children}</Container>
            </NoSelectable>
          </DialogContent>
        </Portal>
      </Dialog>
    );
  }
);
