import { ReactNode, useState } from "react";
import { map } from "lodash";

import {
  DatabaseID,
  Entity,
  ID,
  PropertyDef,
  PropertyMutation,
  PropertyValueRef,
} from "@api";

import { useCurrentUser } from "@state/workspace";

import { cx } from "@utils/class-names";
import { toFieldName } from "@utils/property-refs";

import { Button } from "@ui/button";
import { PropertyValue } from "@ui/property-value";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { PropertyEditDialog } from "@ui/property-edit-dialog";
import { EditAlt, Icon } from "@ui/icon";
import { TextSmall } from "@ui/text";
import { HStack, SpaceBetween } from "./flex";

import styles from "./property-values-list.module.css";

interface Props {
  values: PropertyValueRef[];
  parent?: Entity;
  source: DatabaseID;
  className?: string;
  children?: ReactNode;
  editable?: boolean;
  onMutate?: (
    changes: PropertyMutation<Entity> | PropertyMutation<Entity>[],
    transaction?: ID
  ) => void;
}

export const PropertyValuesList = ({
  values,
  source,
  className,
  onMutate,
  children,
  parent,
  editable = true,
}: Props) => {
  const me = useCurrentUser();
  const [editing, setEditing] = useState<PropertyDef<Entity>>();

  return (
    <div className={cx(styles.container, styles.oneColumn, className)}>
      {map(values, (valueRef) => (
        <div className={styles.row} key={valueRef.field}>
          <Button
            className={styles.colProp}
            inset
            subtle
            size="small"
            icon={<PropertyTypeIcon {...(valueRef.def || valueRef)} />}
            onClick={() =>
              editable &&
              valueRef.def &&
              setEditing(valueRef.def as PropertyDef<Entity>)
            }
          >
            <SpaceBetween>
              {toFieldName(valueRef)}
              <HStack gap={0}>
                {valueRef.def?.scope === me.id && (
                  <TextSmall className={styles.private} subtle>
                    Private
                  </TextSmall>
                )}
                <Icon className={styles.editIcon} icon={EditAlt} />
              </HStack>
            </SpaceBetween>
          </Button>

          <div className={styles.colVal}>
            <PropertyValue
              valueRef={valueRef}
              source={source}
              parent={parent}
              editable={editable}
              propTypeIcon={false}
              variant="labelled"
              className={styles.value}
              onChange={(v) =>
                onMutate?.([
                  {
                    field: valueRef.field,
                    type: valueRef.type,
                    value: v,
                    prev: valueRef.value,
                  },
                ])
              }
            />
          </div>
        </div>
      ))}
      {children}
      {editing && (
        <PropertyEditDialog
          source={source}
          prop={editing}
          onClose={() => setEditing(undefined)}
        />
      )}
    </div>
  );
};
