import { addDays, subWeeks } from "date-fns";
import { last, map, now, sortBy } from "lodash";
import { useSetRecoilState } from "recoil";
import { useCallback, useMemo, useState } from "react";

import { Note, Sprint } from "@api";

import { AppCommandsAtom, usePageUndoRedo, useRegisterPage } from "@state/app";
import {
  useLazyEntity,
  useLazyQuery,
  useMarkAsSeen,
  useUpdateEntity,
} from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";
import { useTeamId } from "@state/teams";

import { Maybe, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { useSyncPathnameID } from "@utils/url";
import { asMutation } from "@utils/property-mutations";
import { getRelationValue } from "@utils/property-refs";
import { setOrders } from "@utils/ordering";
import { fromPointDate, useCalDate } from "@utils/date-fp";
import { isNoteId } from "@utils/id";

import { useCurrentPage } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Centered, Container } from "@ui/container";
import { SprintCreateDialog, SprintPeopleCards } from "@ui/engine/sprint";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { ClockHistory, Comments, EmojiIcon, Search } from "@ui/icon";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { PropertyValueTile } from "@ui/property-value-tile";
import { OverlaySheet, Sheet, StackContainer } from "@ui/sheet-layout";
import { WorkflowActions } from "@ui/workflow-action-button";
import { Button } from "@ui/button";
import { CodeLabel } from "@ui/code-label";
import { DateRangePicker } from "@ui/date-picker";
import { EditableHeading } from "@ui/editable-heading";
import { EmojiSelect } from "@ui/select/emoji";
import { TemplateBanner } from "@ui/template-banner";
import { EntityMessagesPane } from "@ui/messages-pane";
import { UpdateThread } from "@ui/update-thread";
import { DiscussionThread } from "@ui/discussion-thread";
import { ViewRelationsSection } from "@ui/view-results-section";
import { SearchPane } from "@ui/search-pane";
import { ScheduleInstancesPane } from "@ui/engine/schedule";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { render, useEngine } from "@ui/engine";
import { RelatedMeetingsPane } from "@ui/engine/meeting";
import { EntityHeaderBar } from "@ui/entity-header-bar";

import AppPage from "./app-page";

import styles from "./sprint-page.module.css";

interface Props {
  sprintId: string;
}

const SprintPage = ({ sprintId }: Props) => {
  const pageId = useCurrentPage();
  const pushTo = usePushTo();
  const goTo = useGoTo();
  const sprint = useLazyEntity<"sprint">(sprintId);
  const teamId = useTeamId(sprint);
  const [showNote, setShowNote] = useState<Note>();
  const installed = useHasPackages(teamId || "", [SystemPackages.Outcomes]);

  const [page] = useRegisterPage(sprintId, sprint);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(sprintId, sprint?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(sprintId, pageId);

  if (!sprint) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!sprint} title={sprint?.name}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        <Sheet size="primary">
          {!!sprint.template && <TemplateBanner />}

          <SprintHeader sprint={sprint} />

          <Centered stack="vertical" gap={30}>
            {installed[SystemPackages.Outcomes] && (
              <ViewRelationsSection
                parentId={sprint.id}
                childType="outcome"
                onOpen={pushTo}
              />
            )}
            <SprintPeopleCards sprintId={sprintId} />
            <ViewRelationsSection
              parentId={sprint.id}
              childType="task"
              onOpen={pushTo}
            />
          </Centered>
        </Sheet>

        <PaneManager size="secondary">
          {!sprint.template && (
            <>
              <PaneItem id="search" title="Search" icon={Search}>
                <SearchPane
                  parentId={sprintId}
                  onOpen={(n) =>
                    isNoteId(n.id) ? setShowNote(n as Note) : pushTo(n)
                  }
                />
              </PaneItem>
              <PaneItem
                id="messages"
                title="Messages"
                icon={<EmojiIcon emoji="💬" />}
              >
                <EntityMessagesPane
                  entityId={sprintId}
                  onSelected={(n) => setShowNote(n)}
                />
              </PaneItem>

              <PaneItem id="meetings" title="Meetings" icon={Comments}>
                <RelatedMeetingsPane entityId={sprintId} />
              </PaneItem>

              <PaneItem
                id="related"
                title="Related Sprints"
                icon={ClockHistory}
              >
                <RelatedSprints sprint={sprint} />
              </PaneItem>
            </>
          )}

          {when(getRelationValue(sprint, "refs.repeat"), (ref) => (
            <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
              <ScheduleInstancesPane schedule={ref} instanceId={sprint.id} />
            </PaneItem>
          ))}
        </PaneManager>

        {showNote && (
          <OverlaySheet
            size="secondary"
            height="container"
            onDismiss={() => setShowNote(undefined)}
          >
            {showNote?.type === "update" ? (
              <UpdateThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            ) : (
              <DiscussionThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            )}
          </OverlaySheet>
        )}
      </StackContainer>
    </AppPage>
  );
};

export default SprintPage;

interface HeaderProps {
  sprint: Sprint;
}

const SprintHeader = ({ sprint }: HeaderProps) => {
  const pageId = useCurrentPage();
  const setAppCommands = useSetRecoilState(AppCommandsAtom);
  const mutate = useUpdateEntity(sprint.id, pageId);

  if (!sprint) {
    return <h1>Not found.</h1>;
  }

  return (
    <VStack gap={0}>
      <Container>
        <EntityHeaderBar entity={sprint} />
      </Container>

      <Centered>
        <SpaceBetween>
          <VStack gap={20}>
            <Container gap={0} padding="none" inset="bottom">
              <HStack align="flex-end" gap={0}>
                <EmojiSelect
                  size="large"
                  emoji={sprint.icon || "🏃‍♂️"}
                  onChange={(emoji) =>
                    mutate(asMutation({ field: "icon", type: "text" }, emoji))
                  }
                />
                <CodeLabel code={sprint?.code} />
              </HStack>

              <EditableHeading
                key={sprint.id}
                text={sprint.name || ""}
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(asMutation({ field: "name", type: "text" }, i))
                  );
                }}
              />
            </Container>

            <Container
              padding="none"
              stack="horizontal"
              inset="bottom"
              gap={16}
            >
              <PropertyValueTile
                entity={sprint}
                field={"status"}
                onChange={mutate}
              />

              <PropertyValueTile
                entity={sprint}
                field={"start"}
                onChange={mutate}
              />
              <PropertyValueTile
                entity={sprint}
                field={"duration"}
                onChange={mutate}
              />
              <PropertyValueTile
                entity={sprint}
                field={"end"}
                onChange={mutate}
              />
            </Container>

            {!sprint.template && (
              <HStack fit="container" gap={4}>
                <WorkflowActions entity={sprint} />
              </HStack>
            )}
          </VStack>

          <DateRangePicker
            className={styles.calendar}
            dates={{
              from: fromPointDate(sprint.start),
              to: fromPointDate(sprint.end),
            }}
            onChanged={() => {}}
          />
        </SpaceBetween>
      </Centered>
    </VStack>
  );
};

function RelatedSprints({ sprint }: { sprint: Maybe<Sprint> }) {
  const goTo = useGoTo();
  const engine = useEngine("sprint");
  const [creating, setCreating] = useState<
    Partial<Sprint> & Pick<Sprint, "source">
  >();
  const related = useLazyQuery(
    "sprint",
    useMemo(
      () => ({
        field: "start",
        type: "date",
        op: "after",
        value: {
          date: useCalDate(sprint?.start, (s) => subWeeks(s || now(), 6)),
        },
      }),
      [sprint?.start]
    )
  );
  const sorted = useMemo(() => sortBy(related, (r) => r.start), [related]);

  const handleCreate = useCallback(() => {
    const latest = last(sorted);

    if (!latest) {
      return;
    }

    setCreating({
      start: useCalDate(latest.end, (d) => addDays(d || now(), 1)),
      duration: latest.duration,
      source: latest.source,
      orders: setOrders(
        latest.orders,
        "default",
        (Number(latest?.orders?.default) || 0) + 1
      ),
    });
  }, [sprint]);

  return (
    <Sheet size="secondary" height="content" className={styles.pane}>
      <PaneHeader title="Related Sprints">
        <Button size="small" variant="secondary" onClick={handleCreate}>
          Create Next
        </Button>
      </PaneHeader>

      {creating && (
        <SprintCreateDialog
          defaults={creating}
          onCancel={() => setCreating(undefined)}
          onSaved={(s) => {
            setCreating(undefined);
            goTo(s);
          }}
        />
      )}

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(sorted, (sprint) =>
              render(engine.asMenuItem, {
                key: sprint.id,
                item: sprint,
                onOpen: goTo,
              })
            )}
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
}
