import { isArray, isEmpty, isString, map, orderBy, sortBy } from "lodash";
import { useRecoilValue } from "recoil";
import { useCallback, useMemo, useState } from "react";

import { Entity, ID, PropertyValueRef, PropertyVisibility } from "@api";

import { useLazyGetPropertyValueRefs } from "@state/databases";
import { GenericItem, useQueueUpdates } from "@state/generic";

import { isDefined } from "@utils/maybe";
import { cx } from "@utils/class-names";
import { toFieldName } from "@utils/property-refs";
import { groupByMany, isLastIndex } from "@utils/array";
import { asUpdate } from "@utils/property-mutations";

import { useAppPageContext } from "@ui/app-page";
import { PropertyValuesList } from "@ui/property-values-list";
import { Container } from "@ui/container";
import { Button } from "@ui/button";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { PlusIcon } from "@ui/icon";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { Tag } from "@ui/tag";
import { PropertyValueSection } from "@ui/property-value-section";
import { Divider } from "@ui/divider";
import { PropertyCreateDialog } from "./property-edit-dialog";

import styles from "./entity-properties.module.css";

interface Props {
  entityId: ID;
  editable?: boolean;
  hideEmptyProps?: boolean;
  hideSections?: boolean;
  hideProperties?: boolean;
  className?: string | { container?: string; propsList?: string };
}

const toVisibility = (
  p: PropertyValueRef<Entity>,
  hideEmpty: boolean,
  whitelist: string[]
) => {
  const hasValue = !!(isArray(p.value[p.type])
    ? !isEmpty(p.value[p.type])
    : isDefined(p.value[p.type]));

  if (
    p.def?.displayAs === "section" &&
    p.def?.options?.hierarchy === "parent"
  ) {
    return hasValue
      ? ["parentSections", "visibleProps"]
      : whitelist?.includes(p.field)
      ? "visibleProps"
      : "hidden";
  }

  // Props to hide from the entity panel
  if (["team", "title", "icon", "code"]?.includes(p.field)) {
    return "system";
  }

  // Show system properties when they are set and have a displayAs field set
  if (p.def?.displayAs === "section") {
    if (p.def?.visibility === PropertyVisibility.Hidden) {
      return "system";
    }

    return whitelist.includes(p.field) ||
      hasValue ||
      p.def.visibility === "show_always"
      ? "visibleSections"
      : "hiddenSections";
  }

  // Else return system fields in a bucket that is not shown
  if (p.def?.visibility === PropertyVisibility.Hidden || p.def?.readonly) {
    return "system";
  }

  // Return properties that don't have a value and don't force show in a hidden bucket
  if (
    hideEmpty &&
    !hasValue &&
    !whitelist.includes(p.field) &&
    p.def?.visibility !== PropertyVisibility.ShowAlways
  ) {
    return "hidden";
  }

  // ELse return the right visible section
  return "visibleProps";
};

export const EntityProperties = ({
  className,
  editable = true,
  entityId,
  hideEmptyProps = false,
  hideSections = false,
  hideProperties = false,
  ...props
}: Props) => {
  const pageId = useAppPageContext();
  const [creating, setCreating] = useState(false);
  const [whitelist, setWhitelist] = useState<string[]>([]);
  const entity = useRecoilValue(GenericItem(entityId));
  const values = useLazyGetPropertyValueRefs(entityId);
  const mutate = useQueueUpdates(pageId);

  const forceShow = useCallback(
    (field: string) => setWhitelist((w) => [...w, field]),
    []
  );

  const {
    visibleProps,
    parentSections,
    visibleSections,
    hiddenSections,
    hidden,
  } = useMemo(
    () =>
      groupByMany(
        orderBy(values as PropertyValueRef<Entity>[], (v) => {
          // If defined on the prop use it
          if (isDefined(v.def?.order)) {
            return v.def?.order;
          }

          if (["status"]?.includes(v.type)) {
            // Statuses first
            return 0;
          }

          // Then special assigned fields
          if (["assigned"]?.includes(v.field)) {
            return 1;
          }

          // Relations last
          if (["relation", "relations"]?.includes(v.type)) {
            return 5;
          }

          // All others in the middle
          return 2;
        }),
        (v) => toVisibility(v, true, whitelist)
      ),
    [whitelist, values, entity?.source.scope]
  );

  const hiddenProps = useMemo(
    () => sortBy(hidden, (h) => h.def?.label || h.field),
    [hidden]
  );

  if (!entity) {
    return <></>;
  }

  return (
    <VStack
      fit="container"
      gap={20}
      className={cx(
        styles.container,
        isString(className) ? className : className?.container
      )}
    >
      <VStack fit="container">
        {parentSections?.length &&
          map(parentSections, (prop, i) => (
            <Container padding="none" key={prop.field}>
              <PropertyValueSection
                entity={entity}
                valueRef={prop}
                isLast={isLastIndex(i, parentSections)}
                source={entity.source}
              />
            </Container>
          ))}

        {hideProperties !== true && (
          <PropertyValuesList
            values={visibleProps || []}
            source={entity?.source}
            parent={entity}
            className={isString(className) ? "" : className?.propsList}
            onMutate={(changes) =>
              entity && mutate(asUpdate<Entity>(entity, changes))
            }
            editable={editable}
            {...props}
          />
        )}

        {!hideEmptyProps && hideProperties !== true && editable && (
          <Container padding="none" fit="container">
            <SpaceBetween align="flex-start">
              <Container
                stack="horizontal"
                inset={"left"}
                padding="none"
                className={styles.stackedProps}
                gap={0}
              >
                {map(hiddenProps, (p, i) => (
                  <Button
                    key={p.field}
                    className={styles.superSubtle}
                    onClick={() => forceShow(p.field)}
                    size="small"
                    subtle
                    inset
                  >
                    <Tag className={styles.available}>{toFieldName(p)}</Tag>
                  </Button>
                ))}

                <Button
                  className={styles.superSubtle}
                  onClick={() => setCreating(true)}
                  size="small"
                  subtle
                  inset
                  icon={PlusIcon}
                />
              </Container>
            </SpaceBetween>
          </Container>
        )}
      </VStack>

      {!hideSections && hiddenSections?.length && editable && (
        <>
          <Divider />
          <HStack>
            {map(hiddenSections, (p) => (
              <Button
                key={p.field}
                className={styles.superSubtle}
                onClick={() => forceShow(p.field)}
                size="small"
                icon={<PropertyTypeIcon type={p.type} field={p.field} />}
              >
                {toFieldName(p)}
              </Button>
            ))}
          </HStack>
        </>
      )}

      {!hideSections &&
        !!visibleSections?.length &&
        map(visibleSections, (prop, i) => (
          <PropertyValueSection
            key={prop.field}
            entity={entity}
            valueRef={prop}
            isLast={isLastIndex(i, visibleSections)}
            source={entity.source}
          />
        ))}

      {creating && (
        <PropertyCreateDialog
          source={entity.source}
          onSaved={(prop) => {
            setCreating(false);
            forceShow(prop.field);
          }}
          onClose={() => setCreating(false)}
        />
      )}
    </VStack>
  );
};
