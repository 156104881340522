import { sentenceCase } from "change-case";
import { isEmpty, isString } from "lodash";
import { useCallback, useMemo } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { Entity, PropertyDef } from "@api";

import {
  AppCommandsAction,
  AppCommandsAtom,
  currentPage,
  editPropertyInCmdK,
  setCommandsAction,
  setCommandsOpen,
  setCommandsSearching,
  setMode,
} from "@state/app";
import { useEntityLabels } from "@state/settings";

import { ensureArray } from "@utils/array";
import { Maybe, when } from "@utils/maybe";

import { CommandGroup, CommandMenu } from "@ui/command-menu";

import { cx } from "@utils/class-names";
import { validShortcut, useShortcut } from "@utils/event";
import { fallback } from "@utils/fn";
import { ifDo, switchEnum } from "@utils/logic";
import { useSelectableIgnoreClicks } from "@utils/selectable";
import { plural } from "@utils/string";

import { HStack } from "@ui/flex";
import { ArrowRight, Icon } from "@ui/icon";
import { SectionLabel } from "@ui/text";

import { PinnedCommands } from "./pinned";
import { EntityCommands } from "./generic";
import { NavigationCommands } from "./navigation";
import { EntitySearchCommands } from "./search-commands/entity-search";
import { SetCommands } from "./set-commands";
import { AppCommandsProps, AppCommandsSource } from "./types";
import { AppActions } from "./app-actions";
import { TeamsCommands } from "./teams";
import { ArchiveCommands } from "./archive";
import { useCommandSearch } from "./utils";
import { SearchCommand } from "./search";
import { RecentsCommands } from "./recents";

import styles from "./styles.module.css";

export const AppCommands = () => {
  const [page, setPage] = useRecoilState(currentPage);
  const [commands, setCommands] = useRecoilState(AppCommandsAtom);
  const selectableProps = useSelectableIgnoreClicks();

  const editingProp = useMemo(() => commands?.property, [commands]);

  const actingOn = useMemo(
    () =>
      fallback(
        () => when(commands?.editing, ensureArray),
        () => ifDo(!isEmpty(page?.selected), () => page?.selected),
        () => when(page?.entity, ensureArray)
      ),
    [page?.selected, page?.entity, commands?.editing]
  );

  const onOpen = useCallback(
    (open: boolean) => {
      if (open && !!commands?.open) {
        return;
      }

      if (!open) {
        return setCommands(setCommandsOpen(false));
      }

      setCommands(actingOn?.length ? setMode("commands") : setMode("default"));
    },
    [setCommands, actingOn, commands?.open]
  );

  const onClose = useCallback(() => {
    if (commands?.property) {
      setCommands(editPropertyInCmdK(undefined));
    }

    onOpen(false);
  }, [commands]);

  const onBack = useCallback(
    () =>
      switchEnum(commands?.mode || "default", {
        property: () => setCommands(editPropertyInCmdK(undefined)),
        commands: () => setCommands(setMode("default")),
        searching: () => setCommands(setCommandsSearching(false)),
        else: () => onClose(),
      }),
    [commands, onClose]
  );

  const onEdit = useCallback(
    (prop: Maybe<PropertyDef<Entity> | AppCommandsAction>) =>
      setCommands(
        isString(prop)
          ? setCommandsAction(prop, true)
          : editPropertyInCmdK(prop)
      ),
    [actingOn, commands, setCommands]
  );

  const mode = commands?.mode || "default";

  const actingType = actingOn?.[0]?.source?.type;
  const actingSource: AppCommandsSource = useMemo(
    () =>
      fallback(
        ifDo(!!page?.selected?.length, () => "selection"),
        ifDo(!!page, () => "page"),
        () => "global"
      ),
    [page]
  );
  const toEntityLabel = useEntityLabels(actingOn?.[0]?.source?.scope);

  const header = useMemo(() => {
    if (mode === "searching") {
      return (
        <SectionLabel bold={true} className={cx(styles.label, styles.blue)}>
          Search
        </SectionLabel>
      );
    }

    if (mode === "recents") {
      return (
        <SectionLabel bold={true} className={cx(styles.label, styles.blue)}>
          Recently Viewed
        </SectionLabel>
      );
    }

    if (mode !== "default" && !!actingOn?.length) {
      return (
        <HStack gap={4}>
          {actingType && (
            <SectionLabel bold={true} className={cx(styles.label, styles.blue)}>
              {actingOn.length}{" "}
              {plural(toEntityLabel(actingType), actingOn.length)}
            </SectionLabel>
          )}
          {editingProp && (
            <>
              <Icon icon={ArrowRight} />
              <SectionLabel className={styles.label}>
                Change{" "}
                {editingProp.label ||
                  sentenceCase(editingProp.field).toLowerCase()}
              </SectionLabel>
            </>
          )}
        </HStack>
      );
    }
  }, [mode, actingType, editingProp, actingOn, setCommands]);

  const commandsProps = useMemo(
    () => ({ mode, page, actingOn, actingType, actingSource, onEdit }),
    [page, mode, onEdit]
  );

  const placeholder = useMemo(
    () =>
      switchEnum(mode, {
        property: "Filter options...",
        commands: actingOn?.length ? "Type to modify..." : "Filter actions...",
        default: "Filter commands, actions, and recent work...",
        recents: "Search your history...",
        searching: "Search for anything...",
      }),
    [page, mode]
  );

  // Open the menu when ⌘K is pressed
  useShortcut(
    [{ command: true, key: "KeyK" }],
    [() => true, () => setCommands(setMode("default"))],
    [onOpen]
  );

  useShortcut([{ command: true, key: "Slash" }], () => onOpen(true), [onOpen]);

  // Toggle the menu when / is pressed and items are selected
  useShortcut(
    [{ key: "Slash" }],
    [(e) => validShortcut(e) && !commands?.open, () => onOpen(true)],
    [commands?.open, onOpen]
  );

  if (commands?.action) {
    return (
      <AppActions
        {...commandsProps}
        action={commands.action}
        entities={actingOn}
      />
    );
  }

  return (
    <CommandMenu
      key="commands"
      {...selectableProps}
      mode={!commands?.open ? "peaking" : "blocking"}
      placeholder={placeholder}
      prefix={mode === "commands" ? "/" : undefined}
      open={commands?.open}
      onSearched={(v) => !!v?.trim() && onOpen(true)}
      onOpen={onOpen}
      onBack={onBack}
      onClose={onClose}
      header={header}
    >
      {mode === "property" && editingProp && actingOn && actingType && (
        <SetCommands
          {...commandsProps}
          property={editingProp}
          entities={actingOn}
          type={actingType}
        />
      )}

      {mode === "commands" && <EntityCommands {...commandsProps} />}

      {mode === "recents" && (
        <RecentsCommands
          {...commandsProps}
          showSize={1000}
          groupLabel={false}
        />
      )}

      {mode === "searching" && <SearchModeCommands {...commandsProps} />}

      {mode === "default" && <DefaultModeCommands {...commandsProps} />}
    </CommandMenu>
  );
};

const SearchModeCommands = (props: AppCommandsProps) => {
  const query = useCommandSearch();

  return (
    <>
      {!query.length && <RecentsCommands {...props} showSize={3} />}

      <CommandGroup>
        <EntitySearchCommands {...props} />
      </CommandGroup>
    </>
  );
};

const DefaultModeCommands = (props: AppCommandsProps) => {
  const query = useCommandSearch();

  return (
    <>
      <NavigationCommands {...props} />

      <PinnedCommands {...props} />

      <RecentsCommands {...props} />

      <TeamsCommands {...props} />

      <ArchiveCommands {...props} />

      {!!query.length && <SearchCommand {...props} />}
    </>
  );
};
