import { filter, find, map, orderBy } from "lodash";
import { useMemo, useState } from "react";

import {
  DatabaseID,
  Entity,
  PropertyDef,
  PropertyRef,
  PropertyType,
} from "@api/types";

import { useLazyProperties } from "@state/databases";

import { composel, Fn, isFunc, Pred } from "@utils/fn";
import { sentenceCase } from "@utils/string";
import { Maybe, when } from "@utils/maybe";
import { useAsyncEffect } from "@utils/effects";
import { LazyLatest } from "@utils/promise";
import { ComponentOrNode } from "@utils/react";
import { isVisibleProp } from "@utils/property-refs";
import { pick_ } from "@utils/object";
import { cx } from "@utils/class-names";
import { isPersonId } from "@utils/id";

import { Select, SelectProps, TextSubtextOption } from "@ui/select";
import { Text } from "@ui/text";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { AngleDownIcon, NoneIcon } from "@ui/icon";
import { PropertyDefLabel } from "@ui/property-def-label";
import { Button } from "@ui/button";

import { ManageFieldsFooter } from "./footers";

import styles from "./property.module.css";

type PropSelectOption = {
  id: string;
  name: string;
  ref?: PropertyDef<Entity, PropertyType>;
};

export type Props = {
  subtitle?: string;
  options?:
    | PropertyDef<Entity, PropertyType>[]
    | Fn<void, LazyLatest<PropertyDef<Entity, PropertyType>[]>>;
  value?: PropertyDef<Entity, PropertyType>;
  portal?: boolean;
  emptyOption?: boolean;
  icon?: ComponentOrNode;
  onChanged?: Fn<Maybe<PropertyRef>, void>;
  className?: string;
} & Omit<
  SelectProps<PropSelectOption>,
  "value" | "options" | "className" | "onChange"
>;

const toOption = (p: PropertyDef<Entity, PropertyType>) => ({
  id: p.field,
  name: p.label || sentenceCase(p.field),
  subtext: p.scope && isPersonId(p.scope) ? "(Private)" : undefined,
  icon: when(p, (r) => <PropertyTypeIcon {...r} />) ?? <NoneIcon />,
  ref: p,
});

export function PropertySelect({
  options: getOptions,
  placeholder,
  subtitle,
  value,
  icon,
  emptyOption,
  className,
  onChanged,
  children,
  ...rest
}: Props) {
  const selected = when(value, toOption);
  const [options, setOptions] = useState<
    Maybe<PropertyDef<Entity, PropertyType>[]>
  >([]);

  useAsyncEffect(async () => {
    if (isFunc(getOptions)) {
      const { current, latest } = getOptions();
      setOptions(current);
      setOptions(await latest());
    } else {
      setOptions(getOptions);
    }
  }, [getOptions]);

  const menuOptions: PropSelectOption[] = useMemo(() => {
    const items = map(options, toOption);
    // Add empty option to start of list when not turned off
    if (emptyOption ?? true) {
      return [{ id: "{none}", name: "None", ref: undefined }, ...items];
    } else {
      return items;
    }
  }, [options]);

  return (
    <Select
      {...rest}
      placeholder={placeholder}
      options={menuOptions}
      value={selected}
      className={{ popover: styles.select }}
      closeOnBlur={false}
      closeOnSelect={true}
      overrides={{ Option: TextSubtextOption }}
      onChange={(item) => {
        onChanged?.(when(item?.ref, pick_("type", "field")));
      }}
    >
      {children ?? (
        <div className={cx(styles.buttonContainer, className)}>
          <Button subtle size="small" className={styles.button}>
            {selected ? (
              <PropertyDefLabel
                prop={selected.ref}
                icon={icon}
                iconRight={!subtitle || !selected ? AngleDownIcon : undefined}
                subtitle={subtitle}
              />
            ) : (
              <Text subtle>{placeholder}</Text>
            )}
          </Button>
        </div>
      )}
    </Select>
  );
}

export const ScopedPropertySelect = ({
  value,
  source,
  blacklist,
  ...rest
}: Omit<Props, "options" | "value"> & {
  value?: PropertyRef;
  source: DatabaseID;
  blacklist?: Pred<PropertyDef> | string[];
}) => {
  const props = useLazyProperties(source);
  const valueDef = useMemo(
    () => find(props, { field: value?.field }),
    [props, value]
  );
  const available = useMemo(
    () =>
      composel(
        (ts) =>
          filter(
            ts,
            (p: PropertyDef<Entity>) =>
              (isVisibleProp(p) || p.field === "location") &&
              (isFunc(blacklist)
                ? !blacklist(p)
                : !blacklist?.includes(p.field))
          ),
        (ts) =>
          orderBy(
            ts, // Preferred group by field types, sorts left to right here
            (p) =>
              `${[
                "status",
                "multi_select",
                "select",
                "date",
                "checklist",
                "relation",
                "relations",
              ].indexOf(p.type)}.${p.order}`,
            "asc"
          )
      )(props),
    [props]
  );

  return (
    <PropertySelect
      {...rest}
      options={available}
      value={valueDef}
      footer={<ManageFieldsFooter {...source} />}
    />
  );
};

export default PropertySelect;
