import { ClearIndicatorProps } from "react-select";
import { last } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  DatabaseID,
  EntityType,
  RelationRef,
  hasLocation,
  isPerson,
  isTeam,
} from "@api";

import { useActiveWorkspaceId, useCurrentUser } from "@state/workspace";
import { useGetItemFromAnyStore } from "@state/generic";

import { isDefined, Maybe, when } from "@utils/maybe";
import { toRef } from "@utils/property-refs";
import { fromScope, toBaseScope, toScope } from "@utils/scope";
import { maybeTypeFromId } from "@utils/id";
import { Fn, composel } from "@utils/fn";

import { GlobalEntitySelect, RelationSelectProps } from "@ui/select";
import { Button } from "@ui/button";
import {
  LocationButton,
  ButtonProps as LocationButtonProps,
} from "@ui/location-button";
import { Option } from "./single-select";

type LocationSelectProps = {
  onChange: Fn<string, void>;
  source: Maybe<DatabaseID>;
  allowed?: EntityType[];
} & LocationButtonProps &
  Omit<RelationSelectProps, "options" | "onChange" | "value">;

export const PrivateIndicator = <T extends Option, M extends boolean>({
  selectOption,
}: ClearIndicatorProps<T, M>) => {
  const me = useCurrentUser();
  return (
    <Button
      size="small"
      subtle
      onClick={() => selectOption({ id: me.id } as T)}
    >
      Private
    </Button>
  );
};

export const LocationSelect = ({
  location,
  onChange,
  variant,
  source,
  allowed,
  open: _open,
  setOpen: _setOpen,
  ...rest
}: LocationSelectProps) => {
  const me = useCurrentUser();
  const wID = useActiveWorkspaceId();
  const getItem = useGetItemFromAnyStore();
  const isPrivate = useMemo(
    () => location?.includes(me?.id),
    [me?.id, location]
  );
  const isTeamSelect = useMemo(
    () => allowed?.length === 1 && allowed[0] === "team",
    [allowed]
  );

  const localOpen = useState(rest.defaultOpen || false);
  const [open, setOpen] = isDefined(_setOpen) ? [_open, _setOpen] : localOpen;

  const filter = useMemo(() => {
    // When private then default to picking a team
    if (location === me?.id) {
      return { type: "team", scope: wID };
    }

    const type = composel(fromScope, last, maybeTypeFromId)(location);
    return { type, scope: toBaseScope(location || wID) };
  }, [location]);

  const value = useMemo(
    () => composel(fromScope, last, toRef)(location),
    [location]
  );

  const handleChange = useCallback(
    (value: Maybe<RelationRef>) => {
      const newParent = when(value?.id || me.id, getItem);

      if (!newParent) {
        return undefined;
      }

      // Team and person don't nest the location
      const newLocation =
        hasLocation(newParent) && !isTeam(newParent) && !isPerson(newParent)
          ? toScope(newParent.location, newParent.id)
          : toScope(newParent.id);

      return onChange(newLocation);
    },
    [me, onChange]
  );

  const handleTeamChange = useCallback(
    (value: Maybe<RelationRef>) => {
      handleChange(value);
      setOpen(true);
    },
    [me, handleChange]
  );

  const handleLocationChange = useCallback(
    (value: Maybe<RelationRef>) => {
      handleChange(value);
      setOpen(false);
    },
    [me, handleChange]
  );

  if (!location) {
    return <></>;
  }

  if (isPrivate || isTeamSelect) {
    return (
      <GlobalEntitySelect
        key="team-selector"
        {...rest}
        value={value}
        open={open}
        setOpen={setOpen}
        type="team"
        scope={wID}
        allowed={["team"]}
        showOtherTeams={false}
        closeOnSelect={isTeamSelect}
        closeOnBlur={false}
        onChange={handleTeamChange}
        portal={true}
        overrides={{ ClearIndicator: PrivateIndicator }}
      >
        <LocationButton location={location} variant={variant} {...rest} />
      </GlobalEntitySelect>
    );
  }

  return (
    <GlobalEntitySelect
      key="entity-selector"
      value={value}
      open={open}
      setOpen={setOpen}
      closeOnBlur={false}
      type={filter.type}
      allowed={allowed}
      scope={filter.scope}
      onChange={handleLocationChange}
      portal={true}
      overrides={{ ClearIndicator: PrivateIndicator }}
      {...rest}
    >
      <LocationButton location={location} variant={variant} {...rest} />
    </GlobalEntitySelect>
  );
};
