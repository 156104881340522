import { useLocation } from "react-router-dom";
import { parse } from "papaparse";

import {
  filter as filter_,
  find,
  flatMap,
  isNumber,
  isString,
  map,
  mapValues,
  reduce,
  sortBy,
  split,
  toPairs,
  keys,
  some,
} from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import {
  Entity,
  EntityType,
  FilterQuery,
  HasLocation,
  ID,
  JsonObject,
  Person,
  PropertyDef,
  PropertyRef,
  PropertyType,
  PropertyValue,
  Ref,
  SingleFilterQuery,
  Task,
} from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import {
  useLazyEntities,
  useQueueUpdates,
  useRollbackTempChanges,
  useSaveTempChanges,
} from "@state/generic";
import { useActiveWorkspaceId, useCurrentUser } from "@state/workspace";
import { allStatusesForTeam, useLazyProperties } from "@state/databases";

import { fuzzyMatch } from "@utils/search";
import {
  isAnyRelation,
  isAnyText,
  toFieldName,
  toRef,
} from "@utils/property-refs";
import {
  OneOrMany,
  ensureMany,
  findPreferential,
  lookup,
  replace,
  omitEmpty as omitEmptyArr,
  uniqBy,
} from "@utils/array";
import { fallback, Fn, use } from "@utils/fn";
import { useGoTo } from "@utils/navigation";
import { usePageSelection } from "@utils/selectable";
import { toBaseScope, toChildLocation } from "@utils/scope";
import { isHumanId, isTeamId, newLocalHumanId } from "@utils/id";
import { ifDo_, switchEnum } from "@utils/logic";
import { passes } from "@utils/filtering";
import { maybeValues, omitEmpty, setDirty } from "@utils/object";
import { asAppendMutation, asMutation } from "@utils/property-mutations";

import { useCurrentPage } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { render, toEngine } from "@ui/engine";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import AppPage from "@ui/page/app-page";
import { LocationSelect } from "@ui/select";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { Text, TextXLarge } from "@ui/text";
import { Field } from "@ui/input";
import {
  maybe,
  Maybe,
  maybeMap,
  Primitive,
  SafeRecord,
  when,
} from "@utils/maybe";
import { showError } from "@ui/notifications";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import PropertySelect from "@ui/select/property";
import { ArrowRight, PlusIcon } from "@ui/icon";
import { PropertyFilter } from "@ui/property-filter";
import { Divider } from "@ui/divider";

import styles from "./import-work-page.module.css";
import { useRecoilValue } from "recoil";
import { setOrders } from "@utils/ordering";
import { useLazyAllPersons } from "@state/persons";
import { isValid } from "date-fns";
import { fromPointDate, toPointDate } from "@utils/date-fp";

interface Props {
  allowed?: EntityType[];
  onSaved?: Fn<OneOrMany<Ref>, void>;
  onCancel?: Fn<void, void>;
}

type ImportRule = {
  type: EntityType;
  fields: SafeRecord<string, PropertyDef>;
  filter?: FilterQuery;
};

const parseLocationFromPath = (path: string) => {
  if (!path.endsWith("/import")) {
    return undefined;
  }
  return filter_(split(path, "/"), isHumanId)?.join("/");
};

const lookupPerson = (raw: Maybe<Primitive>, people: Person[]): Maybe<Ref> =>
  when(
    findPreferential(
      people,
      (p) => p.id === raw,
      (p) => p.fullName === raw,
      (p) => p.email === raw,
      (p) => fuzzyMatch(raw as string, p.fullName),
      (p) => fuzzyMatch(raw as string, p.fullName?.split(" ")[0])
    ),
    toRef
  );

const parseValue = (
  raw: Maybe<Primitive>,
  prop: PropertyDef,
  aliases: SafeRecord<string, ID>
) =>
  prop.field === "id"
    ? { [prop.type]: aliases[String(raw)] || undefined }
    : switchEnum<PropertyType, PropertyValue>(prop.type, {
        rich_text: () => ({
          rich_text: { markdown: raw as string },
        }),
        status: () => ({
          status: raw
            ? findPreferential(
                prop.values.status || [],
                (s) => s.name === raw,
                (s) => fuzzyMatch(s.name || "", String(raw))
              ) || { name: String(raw) }
            : undefined,
        }),
        select: () => ({
          select: raw
            ? find(prop.values.select, { name: String(raw) }) || {
                name: String(raw),
              }
            : undefined,
        }),
        multi_select: () => ({
          multi_select: maybeMap(split(String(raw), ","), (r) =>
            r
              ? find(prop.values.multi_select, { name: r }) || { name: r }
              : undefined
          ),
        }),
        date: () => {
          if (!isString(raw)) {
            return { date: undefined };
          }
          let date: Maybe<Date> = new Date(raw);
          const indexToUse = prop.field?.includes("end") ? 1 : 0;
          if (!isValid(date)) {
            date = when(raw?.split(" → ")[indexToUse], fromPointDate);
          }
          if (!isValid(date)) {
            date = when(raw?.split(",")[indexToUse], fromPointDate);
          }
          if (!isValid(date)) {
            date = when(parseInt(raw, 10), (ts) => new Date(ts));
          }

          return date && isValid(date)
            ? { date: toPointDate(date) }
            : { date: undefined };
        },
        number: () => ({
          number:
            isString(raw) || isNumber(raw)
              ? parseFloat(String(raw)) || 0
              : undefined,
        }),
        relation: () => ({
          relation: when(
            isString(raw) && isHumanId(raw) ? raw : aliases[String(raw)],
            toRef
          ),
        }),
        relations: () => ({
          relations: maybeMap(split(String(raw), ","), (r) =>
            when(isHumanId(r) ? r : aliases[r], toRef)
          ),
        }),
        else: () => ({ [prop.type]: raw }),
      });

export const ImportWorkPage = ({ onCancel, allowed, onSaved }: Props) => {
  // const [page] = useRegisterPage("ai-work-create", undefined);
  const pageId = useCurrentPage();
  const workspaceId = useActiveWorkspaceId();
  const loc = useLocation();
  const me = useCurrentUser();
  const goTo = useGoTo();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState<string>(
    () => parseLocationFromPath(loc.pathname) || me.id
  );
  const teamId = useMemo(
    () => use(toBaseScope(location), (id) => (isTeamId(id) ? id : undefined)),
    [location]
  );
  const people = useLazyAllPersons();

  const [file, setFile] = useState<File>();
  const [rows, setRows] = useState<JsonObject[]>([]);
  const [created, setCreated] = useState<Ref[]>([]);
  const [rules, setRules] = useState<ImportRule[]>([]);
  const [fieldMap, setFieldMap] = useState<SafeRecord<string, PropertyDef>>();
  const [rawFields, setRawFields] = useState<PropertyDef[]>();
  const statuses = useRecoilValue(allStatusesForTeam(teamId || ""));
  const showProps = useMemo(
    () =>
      uniqBy(
        maybeValues(rules[0]?.fields || {}, (v) => !isAnyText(v)),
        (v) => v.field,
        "first"
      ),
    [rules]
  );
  const mutate = useQueueUpdates(pageId, true);
  const commit = useSaveTempChanges(pageId, true);
  const rollback = useRollbackTempChanges();

  const filteredRows = useMemo(
    () =>
      filter_(
        rows,
        (r) =>
          !!r &&
          some(rules, (rule) =>
            passes(r as any, rule.filter || { and: [] }, {})
          )
      ),
    [rows, rules]
  );

  const addCreated = useCallback((e: OneOrMany<Ref>) => {
    setCreated((created) => [...created, ...ensureMany(e)]);
  }, []);

  const [page] = useRegisterPage();
  usePageUndoRedo(page.id);

  const rollbackAll = useCallback(() => {
    setCreated((created) => {
      map(created, (e) => rollback(e.id));
      return [];
    });
  }, []);

  const handleDismiss = useCallback(() => {
    rollbackAll();
    onCancel?.();
  }, [created, rollbackAll, onCancel]);

  const handleCommit = useCallback(() => {
    map(created, (e) => commit(e.id));
    onSaved?.(created);
    setCreated([]);
  }, [created, onSaved, rollbackAll]);

  const handleGenerate = useCallback(() => {
    rollbackAll();

    setLoading(true);

    // Create a lookup of old id/title to the new traction ID for any internal references
    const aliases = reduce(
      rows,
      (agg, row) => {
        // Find the first rule that will create this row
        const rule = find(
          rules,
          (r) => !r.filter || passes(row as any as Task, r.filter, {})
        );

        if (!rule) {
          return agg;
        }

        const fieldPairs = toPairs(rule.fields);
        const [idField] =
          find(fieldPairs, ([_key, def]) => def?.field === "id") || [];
        const [titleField] =
          find(fieldPairs, ([_key, def]) =>
            ["title", "name"]?.includes(def?.field || "")
          ) || [];

        // Generate an ID for this row
        const id = newLocalHumanId(rule.type);
        setDirty(agg, row[idField || "id"] as string, id);
        titleField && setDirty(agg, row[titleField] as string, id);

        return agg;
      },
      {} as SafeRecord<ID, ID>
    );

    // For each row from the CSV, pull the values that have a property defined against them
    const refs = flatMap(rows, (row, rowIndex) => {
      const rule = find(
        rules,
        (rule) => !rule.filter || passes(row as any as Task, rule.filter, {})
      );

      // If no rule matches row, then skip the row
      if (!rule) {
        return [];
      }

      const finalFields = toPairs(omitEmpty(rule.fields)) as [
        string,
        PropertyDef
      ][];

      let id: Maybe<ID>;

      const changes = flatMap(finalFields, ([field, def]) => {
        const rawValue = maybe(row[field]) as Maybe<Primitive>;
        const value =
          def.type === "relation" && def?.options?.references === "person"
            ? // Lookup people by fuzzy match
              lookupPerson(rawValue, people || [])
            : // Try parse the value out, with smart fallback
              fallback(
                () => parseValue(rawValue, def, aliases)[def.type],
                // Fallback to first status if no value set
                ifDo_(def.type === "status", () => def.values.status?.[0])
              );

        // Don't include ID in the changes as it's on the update.id only
        if (def.field === "id") {
          id = value as ID;
          return [];
        }

        // Not settable
        if (def.readonly) {
          return [];
        }

        const mutation = ["multi_select", "relations"].includes(def.type)
          ? asAppendMutation(
              def as PropertyRef<Entity, "relations">,
              value as Ref[]
            )
          : asMutation(def, value);

        // Change it's location to be a nested location
        if (isAnyRelation(def) && def.options?.hierarchy === "parent") {
          const parentId =
            mutation.value.relation?.id || mutation.value.relations?.[0]?.id;

          if (parentId) {
            return [
              mutation,
              asMutation(
                { field: "location", type: "text" },
                toChildLocation(location, parentId)
              ),
            ];
          }
        }

        return [mutation];
      });

      if (!id) {
        id = newLocalHumanId(rule.type);
      }

      mutate({
        id: id,
        method: "create",
        changes: omitEmptyArr([
          // Set default location
          asMutation({ field: "location", type: "text" }, location),

          // Set default order from position in import
          asMutation(
            { field: "orders", type: "json" },
            setOrders({}, "default", rowIndex)
          ),

          // Set default status whene there is a status field for this entityt
          when(
            find(statuses, (p) => ensureMany(p.entity)?.includes(rule.type)),
            (status) =>
              asMutation(
                { field: "status", type: "status" },
                // First non-planning status else first status
                find(status?.values.status, (s) => s.group !== "planning") ||
                  status?.values.status?.[0]
              )
          ),

          // Set all changes from mapped fields
          ...changes,
        ]),
        mode: "temp",
        source: { type: rule.type, scope: location },
      });

      return { id };
    });

    addCreated(refs);

    setLoading(false);
  }, [rules, rows, location]);

  useEffect(() => {
    if (!file) {
      return;
    }

    try {
      parse(file, {
        header: true,
        complete: ({ data }) => {
          setRows(data as JsonObject[]);
          const fieldMap = mapValues(data[0] || {}, () => undefined);
          setRules((rs) => map(rs, (r) => ({ ...r, fields: fieldMap })));
          setFieldMap(fieldMap);
          setRawFields(
            map(
              keys(data[0]),
              (field) => ({ field, type: "text", label: field } as PropertyDef)
            )
          );
        },
      });
    } catch (e) {
      showError("Failed to parse file.");
    }
  }, [file]);

  return (
    <AppPage page={page}>
      <StackContainer>
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <SideNav className={styles.nav}>
              <SpaceBetween
                className={styles.pane}
                width="container"
                height="container"
                direction="vertical"
                align="flex-start"
                gap={10}
              >
                <VStack gap={16}>
                  <TextXLarge bold>Import Work</TextXLarge>

                  <Field label="Location">
                    <LocationSelect
                      fit="container"
                      location={location}
                      onChange={setLocation}
                      source={undefined}
                      className={styles.control}
                      showOpen={false}
                      variant="full"
                      showCaret={true}
                    />
                  </Field>

                  {!!location && (
                    <Field label="Data file (.csv or .json)">
                      <div className={styles.fileInput}>
                        <input
                          onChange={({ target: { files } }) =>
                            when(files?.[0], setFile)
                          }
                          type="file"
                        />
                      </div>
                    </Field>
                  )}

                  {map(rules, (rule, i) => (
                    <Fragment key={rule.type}>
                      <ImportRuleDefinition
                        rule={rule}
                        location={location}
                        rawFields={rawFields || []}
                        onChange={(updates) =>
                          setRules((rules) =>
                            map(rules, (r, j) =>
                              i === j ? { ...r, ...updates } : r
                            )
                          )
                        }
                      />
                      {i !== rules.length - 1 && <Divider />}
                    </Fragment>
                  ))}

                  {!!rows?.length && (
                    <Button
                      subtle
                      icon={PlusIcon}
                      onClick={() =>
                        setRules([
                          ...rules,
                          {
                            type: allowed?.[0] || "task",
                            fields: fieldMap || {},
                          },
                        ])
                      }
                    >
                      Add import rule
                    </Button>
                  )}
                </VStack>

                <SpaceBetween direction="horizontal">
                  {!!created.length && (
                    <Button subtle onClick={handleDismiss}>
                      Discard
                    </Button>
                  )}

                  <HStack justify="flex-end" fit="container" gap={8}>
                    {!!filteredRows && (
                      <Text subtle>{filteredRows?.length} rows</Text>
                    )}

                    <Button
                      variant="primary"
                      subtle={!loading && !!created?.length}
                      disabled={location === workspaceId || !location}
                      loading={loading}
                      onClick={handleGenerate}
                    >
                      {created?.length ? "Retry" : "Preview Work"}
                    </Button>

                    {!loading && created?.length > 0 && (
                      <Button
                        variant="primary-alt"
                        onClick={() => handleCommit()}
                      >
                        Save All
                      </Button>
                    )}
                  </HStack>
                </SpaceBetween>
              </SpaceBetween>
            </SideNav>

            <Main className={styles.main}>
              <WorkPreview
                work={created}
                location={location}
                showProps={showProps}
                loading={loading}
              />
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

type ImportRuleDefinitionProps = {
  location: string;
  rule: ImportRule;
  rawFields: PropertyDef[];
  onChange: Fn<Partial<ImportRule>, void>;
};

const ImportRuleDefinition = ({
  location,
  rule,
  rawFields,
  onChange,
}: ImportRuleDefinitionProps) => {
  const { type, fields, filter } = rule;
  const getRawField = useMemo(
    () => lookup(rawFields, (f) => f.field),
    [rawFields]
  );
  const singleFilters = useMemo(
    () => (filter as Maybe<Extract<FilterQuery, { and: any }>>)?.and || [],
    [filter]
  );

  const props = useLazyProperties({ scope: location, type: type });
  const allProps = useMemo(
    () =>
      [
        { field: "id", type: "text" },
        ...sortBy(props, (p) => p.label || p.field),
      ] as PropertyDef[],
    [props]
  );

  const handleDefChanged = useCallback(
    (field: string, def: Maybe<PropertyDef>) =>
      onChange({ fields: { ...fields, [field]: def } }),
    [fields]
  );

  return (
    <>
      <Field label="When row matches">
        {map(singleFilters, (f: SingleFilterQuery, i) => (
          <PropertyFilter
            filter={f}
            definition={getRawField}
            onChanged={(updated) =>
              onChange({
                filter: { and: replace(singleFilters, i, updated) },
              })
            }
            source={{ type: type, scope: "" }}
          />
        ))}

        <PropertySelect
          options={rawFields}
          portal
          onChanged={(v) =>
            v &&
            onChange({
              filter: {
                and: [
                  ...(singleFilters || []),
                  {
                    field: v.field,
                    type: v.type,
                    op: "is_not_empty",
                    value: { text: "" },
                  },
                ],
              },
            })
          }
        >
          <Button size="small" subtle icon={PlusIcon}>
            <Text subtle>Add filter</Text>
          </Button>
        </PropertySelect>
      </Field>

      <Field label="Import">
        <EntityTypeSelect
          value={type}
          onChange={(type) => onChange({ type })}
          scope={location}
          portal
        />
      </Field>

      {!!fields && (
        <Field label="Using field mappings">
          <div className={styles.fileInput}>
            {map(fields, (def, field) => (
              <SpaceBetween key={field}>
                <Button
                  className={styles.half}
                  size="small"
                  disabled
                  subtle
                  iconRight={ArrowRight}
                >
                  {field}
                </Button>

                <div className={styles.half}>
                  <PropertySelect
                    onChanged={(d) =>
                      handleDefChanged(
                        field,
                        d && find(allProps, { field: d?.field })
                      )
                    }
                    options={allProps}
                    portal
                  >
                    <Button
                      size="small"
                      fit="container"
                      subtle
                      icon={
                        def ? (
                          <PropertyTypeIcon
                            type={def?.type}
                            field={def?.field}
                          />
                        ) : undefined
                      }
                    >
                      {!!def && toFieldName(def)}
                      {!def && <Text subtle>Not used.</Text>}
                    </Button>
                  </PropertySelect>
                </div>
              </SpaceBetween>
            ))}
          </div>
        </Field>
      )}
    </>
  );
};

type WorkPreviewProps = {
  work: Ref[];
  showProps: PropertyDef[];
  location?: string;
  loading?: boolean;
};

const WorkPreview = ({
  work,
  showProps,
  location,
  loading,
}: WorkPreviewProps) => {
  const entities = useLazyEntities(work);
  const [selection, setSelection] = usePageSelection();
  const visible = useMemo(
    () =>
      filter_(
        entities,
        (e) =>
          !location ||
          !(e as HasLocation)?.location ||
          (e as HasLocation)?.location.endsWith(location)
      ),
    [entities]
  );

  return (
    <Container fit="container" stack="vertical" gap={10} padding="none">
      <FillSpace direction="vertical" height="container" width="container">
        {!loading && !entities?.length && (
          <Text subtle>No work created yet.</Text>
        )}
        {map(visible, (e) =>
          render(toEngine(e)?.asListItem, {
            key: e.id,
            item: e,
            showProps,
            selection,
            setSelection,
          })
        )}
        {loading && <Text subtle>Generating...</Text>}
      </FillSpace>
    </Container>
  );
};
