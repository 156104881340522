import { HasTemplate } from "@api";

import { useCreateFromTemplate } from "@state/generic";

import { useGoTo } from "@utils/navigation";

import { ViewEngine } from "@ui/engine";
import { SpaceBetween } from "@ui/flex";
import { Button } from "@ui/button";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { SelectableListItem } from "@ui/selectable-items";
import { RelationLabel } from "@ui/relation-label";
import { withHardHandle } from "@utils/event";

export const TemplateEngine: ViewEngine<HasTemplate> = {
  asMenuItem: () => <>Not supported.</>,
  asListCard: () => <>Not supported.</>,
  asListItem: function TemplateListItem({
    item,
    selection,
    setSelection,
    ...props
  }) {
    const goTo = useGoTo();

    const { create, loading } = useCreateFromTemplate(item.source, goTo, {
      appendName: false,
    });

    return (
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListItem
          item={item}
          selection={selection}
          setSelection={setSelection}
          {...props}
        >
          <SpaceBetween>
            <RelationLabel relation={item} />

            <Button
              variant="primary"
              loading={loading}
              size="small"
              onClick={withHardHandle(() => create?.(item))}
            >
              Use
            </Button>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
};

export default TemplateEngine;
