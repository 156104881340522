import { addDays, addMonths, addQuarters, addWeeks, addYears } from "date-fns";
import { useMemo } from "react";
import {
  map,
  orderBy,
  reduceRight,
  reduce,
  concat,
  keys,
  flatMap,
  first,
  isArray,
  find,
  last,
  findIndex,
  has,
  uniq,
  filter,
  times,
} from "lodash";

import {
  PropertyValueRef,
  SortByProp,
  View,
  FilterQuery,
  GroupByPropDef,
  PropertyType,
  ID,
  PropertyMutation,
  Entity,
  SelectOption,
  PropertyDef,
  GroupByProp,
  EntityType,
  HasLocation,
  PropertyRef,
  Roadmap,
  SingleFilterQuery,
  Status,
} from "@api/types";

import {
  isDefined,
  Maybe,
  maybeMap,
  safeAs,
  SafeRecord,
  when,
} from "@utils/maybe";
import {
  toSortable,
  toKey,
  asPropertyValueRef,
  toGroupKeys,
  toRef,
  toLabel,
  isEmptyValue,
  isArrayType,
  asValue,
  isAnyRelation,
  getPropertyValue,
  toFieldName,
} from "@utils/property-refs";
import {
  isTeamId,
  isWorkspaceId,
  newLocalHumanId,
  typeFromId,
} from "@utils/id";
import {
  fromISO,
  fromPointDate,
  now,
  toPointDate,
  useISODate,
} from "@utils/date-fp";
import {
  isAnd,
  isEmptyFilter,
  isOr,
  isValidFilter,
  toList,
} from "@utils/filtering";
import {
  ensureArray,
  ensureMany,
  findPreferential,
  groupByMany,
  justOne,
  omitEmpty,
  pushDirty,
  replace,
  uniqBy,
  whenEmpty,
} from "@utils/array";
import { equalsAny, ifDo, ifDo_, switchEnum } from "@utils/logic";
import { cachedFunc, composel, fallback, use } from "@utils/fn";
import { asMutation } from "@utils/property-mutations";
import { fromScope, toLocation } from "@utils/scope";
import {
  GroupedItems,
  NestedGroup,
  isNested,
  toGroupValueRef,
} from "@utils/grouping";
import { toMilliSeconds } from "@utils/time";
import { toSentence } from "@utils/string";
import { setDirty } from "@utils/object";
import { applyExtensions } from "./filter-extensions";

export const EMPTY_KEY = "EmPTy";

export const toValue = (filter: SingleFilterQuery) =>
  justOne(filter.value?.[filter.type as Exclude<PropertyType, "json">]) ||
  justOne(
    filter.values?.[
      filter.type as Exclude<PropertyType, "json">
    ] as SelectOption[]
  );

export const isFiltering = (view: View) =>
  (!!view.filter && !isEmptyFilter(view.filter)) || view?.settings?.hideNested;

export const isGrouping = (view: View) =>
  !!view.group?.length || !!view.grouping;

export const newView = (v: Partial<View> & Pick<View, "source">): View => ({
  id: newLocalHumanId("view"),
  name: "",
  alias: undefined,
  icon: undefined,
  order: undefined,
  layout: "list",
  grouping: undefined,
  entity: "task",
  filter: undefined,
  sort: undefined,
  group: undefined,
  showProps: [],
  location: toLocation(v.source.scope),
  template: undefined,

  createdAt: now(),
  updatedAt: now(),
  fetchedAt: undefined,
  createdBy: undefined,
  updatedBy: undefined,
  ...v,
});

export const sortItems = (
  items: Entity[],
  sorts?: SortByProp[],
  scope: string = "default",
  props?: SafeRecord<string, PropertyDef>
) =>
  // Reduce right so that the last sort is the first to be applied
  reduceRight(
    [
      ...(sorts || []),
      { direction: "asc", field: "orders", type: "json" },
    ] as SortByProp[],
    (results, sort) =>
      orderBy(
        results,
        (t) =>
          use(
            toSortable(toGroupValueRef(t, props?.[sort.field] ?? sort), scope),
            // Sort all empty values to end of list (regardless of sort direction)
            (sortVal) =>
              isEmptyValue(sortVal)
                ? sort?.empty === "first"
                  ? "0"
                  : "1"
                : sort.direction === "desc"
                ? `1${sortVal}`
                : `0${sortVal}`
          ),
        sort.direction
      ),
    items
  );

export const groupItems = (
  items: Entity[],
  [group, ...subGroups]: GroupByPropDef<Entity, PropertyType>[],
  hideEmpty?: boolean,
  whitelist?: string[]
): { groups: NestedGroup[]; hidden: NestedGroup[] } | undefined => {
  if (!group) {
    return undefined;
  }

  // First go through all the allowed values defined on the group and add
  // a group for them.
  const allGroups: PropertyValueRef<Entity>[] = [];

  // Extend allGroups out with all the possible values for this group
  pushDirty(
    allGroups,
    ...switchEnum<PropertyType | "default", PropertyValueRef<Entity>[]>(
      group.values?.[group.type] ? group.type : "default",
      {
        status: () =>
          map(group.values.status, (v) =>
            asPropertyValueRef(group, { [group.type]: v })
          ),
        select: () =>
          map(group.values.select, (v) =>
            asPropertyValueRef(group, { [group.type]: v })
          ),
        multi_select: () =>
          map(group.values.multi_select, (v) =>
            asPropertyValueRef(group, { [group.type]: v })
          ),
        date: () => {
          const [min, max] = reduce(
            allGroups,
            (res, g) => {
              const date = fromISO(g.value.date);
              if (!date) {
                return res;
              }
              const [min, max] = res;
              return [
                !min || date < min ? date : min,
                !max || date > max ? date : max,
              ];
            },
            [fromPointDate(now()), fromPointDate(now())] as [
              Maybe<Date>,
              Maybe<Date>
            ]
          );

          if (!max || !min) {
            return [];
          }

          const bumperFn = switchEnum(group.format || "day", {
            // Determine the function to use to add time based on the group format
            day: () => addDays,
            week: () => addWeeks,
            month: () => addMonths,
            quarter: () => addQuarters,
            year: () => addYears,
            else: () => addDays,
          });

          return [
            // Add equal units of time between min/max unless it's a day
            ...reduce(
              times(20),
              (acc, i) =>
                use(bumperFn(min, i), (bumped) =>
                  bumped > max
                    ? acc
                    : pushDirty(
                        acc,
                        asPropertyValueRef(group, {
                          date: toPointDate(bumped),
                        })
                      )
                ),
              [] as PropertyValueRef<Entity>[]
            ),
            // Add 3 units of time (based on group.format) ahead of the max date
            ...times(3, (i) =>
              asPropertyValueRef(group, {
                date: toPointDate(bumperFn(max, i + 1)),
              })
            ),
          ];
        },
        else: () => [],
      }
    )
  );

  // Always add Empty Group
  pushDirty(allGroups, asPropertyValueRef(group, { [group.type]: undefined }));

  // Group all items by group key, adding groups to allGroups as we go
  const groupedItems = groupByMany(items, (task) => {
    const valueRef = toGroupValueRef(task, group);

    // Add all uniq value for this group and add the individual value
    map(
      ensureArray(valueRef.value[group.type as Exclude<PropertyType, "json">]),
      (v) => {
        pushDirty(allGroups, {
          ...valueRef,
          value: { [group.type]: isArrayType(group.type) ? [v] : v },
        });
      }
    );

    const valKeys = toGroupKeys(
      valueRef,
      group.format || valueRef?.def?.format,
      EMPTY_KEY
    );

    return isArray(valKeys)
      ? whenEmpty(valKeys, [EMPTY_KEY])
      : valKeys || EMPTY_KEY;
  });

  // Uniq groups by formatted values
  const uniqGroups = orderBy(
    uniqBy(
      allGroups,
      (g) => toKey(g, group.format || g.def?.format, EMPTY_KEY),
      "first" // values from definition are added first
    ),
    (v, i) =>
      v.type === "date"
        ? useISODate(v.value.date, (d) => d?.getTime())
        : isEmptyValue(v.value[v.type])
        ? "~~" // Last
        : equalsAny(v.type, ["status", "select", "multi_select"])
        ? findIndex(
            (group.values[group.type] as SelectOption[]) || [],
            (val) => val.id === toKey(v, group.format || v.def?.format)
          ) ?? i
        : ["relation", "relations"]?.includes(v.type)
        ? toLabel(v)
        : v.type === "number"
        ? v.value.number
        : toKey(v, group.format || v.def?.format),
    "asc"
  );

  return reduce(
    uniqGroups,
    (res, valueRef) => {
      const key = toKey(
        valueRef,
        group.format || valueRef.def?.format,
        EMPTY_KEY
      );
      const items = groupedItems[key || EMPTY_KEY];
      const valueKey = key || EMPTY_KEY;

      // If has subroups keep nesting downwards
      const nextGroup = fallback(
        () =>
          ifDo(!!subGroups?.length, () =>
            when(groupItems(items || [], subGroups), (sub) => ({
              value: valueRef,
              def: group,
              ...sub,
            }))
          ),
        // Return a simple GroupedItems group
        () =>
          ({ value: valueRef, def: group, items: items || [] } as NestedGroup)
      );

      const isHidden =
        // View is setup to hide empty
        ((hideEmpty || group?.hideEmpty) &&
          // And this group is empty
          !items?.length &&
          // And it's now marked to always show by parent group or view
          (!group.alwaysShow?.includes(valueKey) ||
            whitelist?.includes(valueKey))) ||
        // OR view wants to always hide this group
        group.alwaysHide?.includes(valueKey);

      return isHidden
        ? setDirty(res, "hidden", concat(res.hidden, nextGroup))
        : setDirty(res, "groups", concat(res.groups, nextGroup));
    },
    { groups: [] as NestedGroup[], hidden: [] as NestedGroup[] }
  );
};

export const groupItemsBy2Dimensions = (
  items: Entity[],
  [dimension1, dimension2, ...rest]: GroupByPropDef<Entity, PropertyType>[],
  hideEmpty?: boolean
): { groups: NestedGroup[]; hidden: NestedGroup[] } | undefined => {
  if (rest?.length) {
    throw new Error("A max of 2 levels of grouping is currently supported.");
  }

  // Separately group by each dimension so that determining empty groups is easier
  const grouped1 = groupItems(items, [dimension1], hideEmpty);
  const grouped2 = groupItems(items, [dimension2], hideEmpty);

  // If there's one or no dimensions, return it
  if (!grouped1 || !grouped2) {
    return grouped1;
  }

  const groups = map(
    // We can assume there is no nested as we only passed in 1 above
    grouped1.groups as GroupedItems[],
    (outer) => {
      const items = outer.items || [];

      // Ditto as above re: assumption
      const subGroups = map(grouped2.groups as GroupedItems[], (inner) => {
        const items = inner.items || [];
        return {
          ...inner,
          items: filter(items, (i) => outer.items?.includes(i)),
        };
      });

      return {
        ...outer,
        items,
        groups: subGroups,
      };
    }
  );

  return { groups, hidden: grouped1.hidden };
};

export const toGroupByProps = (view: View): GroupByProp<Entity>[] =>
  view.layout === "calendar"
    ? [
        {
          field:
            getPropertyValue(view, {
              field: "settings.calStart",
              type: "property",
            })?.property?.field ||
            (view.entity === "content" ? "publish" : "start"),
          type: "date",
          hideEmpty: false,
          format: "day",
        },
      ]
    : !!view.group?.length
    ? maybeMap(
        view.group || [],
        (g) =>
          g && {
            ...g,
            hideEmpty: g.hideEmpty,
          }
      )
    : view.layout === "columns"
    ? [{ field: "status", type: "status", hideEmpty: false }]
    : [];

export const replaceFilter = (
  curr: Maybe<FilterQuery>,
  index: number,
  filter: Maybe<FilterQuery>
): Maybe<FilterQuery> => {
  if (!curr) {
    return filter;
  }

  if (isAnd(curr)) {
    return {
      and: filter ? replace(curr.and, index, filter) : replace(curr.and, index),
    };
  }

  if (isOr(curr)) {
    return {
      or: filter ? replace(curr.or, index, filter) : replace(curr.or, index),
    };
  }

  return filter;
};

export const addNewFilter = (
  curr: Maybe<FilterQuery>,
  filter: Maybe<FilterQuery>,
  combine: "and" | "or" = "and"
): FilterQuery => {
  if (!filter) {
    return curr || { and: [] };
  }

  if (!curr) {
    return { [combine || "and"]: [filter] } as FilterQuery;
  }

  if (isAnd(curr) && combine !== "or") {
    return { and: [...curr.and, filter] };
  }

  if (isOr(curr) && combine !== "and") {
    return { or: [...curr.or, filter] };
  }

  return { [combine || "and"]: [curr, filter] } as FilterQuery;
};

export const readyToFetchViewItems = (view: View, props: PropertyDef[]) =>
  // The parent is fetched and the filter returns
  isValidFilter(toViewBaseFilter(view, props, "loose"));

export const toViewTarget = (view: Partial<View>) =>
  view.for?.id || last(fromScope(view.location));

// Views have a base filter that describes what they are "looking at", then view.filter is applied on top
export const toViewBaseFilter = (
  view: Partial<View>,
  props: PropertyDef[],
  mode: "strict" | "loose"
): Maybe<FilterQuery> => {
  // If the view does not define the for, then use it's location as the base filter
  // Useful for template views inside document fields
  const parentId = toViewTarget(view);
  const childType = view.entity;

  if (!parentId) {
    return undefined;
  }

  const parentType = typeFromId<EntityType>(parentId);

  // If the view is a workspace-wide and there is no filter setup, then return undefined (invalid filter)
  if (parentType === "workspace" && !view.filter) {
    return undefined;
  }

  if (parentType === "person" && childType === "meeting") {
    return {
      or: [
        {
          field: "refs.people",
          op: "contains",
          type: "relations",
          value: { relations: [{ id: parentId }] },
        },
        {
          field: "owner",
          op: "equals",
          type: "relation",
          value: { relation: { id: parentId } },
        },
      ],
    };
  }

  if (parentType === "team") {
    // Query team id with location filter
    return {
      field: "location",
      op: "contains",
      type: "text",
      value: { text: parentId },
    };
  }

  if (parentType === "person") {
    return when(
      findPreferential(
        props,
        // First try find owner/assigned fields to use for person relations
        (p) => isAnyRelation(p) && ["owner", "assigned"]?.includes(p.field),
        // Fallback to any person relation
        (p) => isAnyRelation(p) && p?.options?.references === "person"
      ),
      (r) => ({
        field: r.field,
        type: r.type,
        op: "contains",
        values: {
          [r.type]: [toRef(parentId)],
        },
      })
    );
  }

  // Loose: Any relation field points to the parent
  if (mode === "loose") {
    return {
      or: maybeMap(props, (p) =>
        isAnyRelation(p) &&
        p.options?.hierarchy !== "child" &&
        ensureArray(p.options?.references)?.includes(parentType)
          ? {
              field: p.field,
              type: p.type,
              op: "contains",
              values: {
                [p.type]: [toRef(parentId)],
              },
            }
          : undefined
      ),
    };
  }

  // Strict: Only  parent fields point to the parent
  return {
    or: maybeMap(props, (p) =>
      isAnyRelation(p) &&
      p.visibility !== "hidden" &&
      p.options?.hierarchy === "parent" &&
      ensureArray(p.options?.references)?.includes(parentType)
        ? {
            field: p.field,
            type: p.type,
            op: "contains",
            values: {
              [p.type]: [toRef(parentId)],
            },
          }
        : undefined
    ),
  };
};

export const toFullFilter = (
  view: Partial<View>,
  props: PropertyDef[],
  opts?: { archived?: boolean; templates?: boolean }
): Maybe<FilterQuery> => {
  // Show templates when asked or if the view is a template
  const includeTemplates = opts?.templates ?? !!view.template;
  const entityFilter = toViewBaseFilter(view, props, "loose");

  const additional = omitEmpty([
    // Exclude templates items unless explicitly asked for
    ifDo(
      !includeTemplates,
      () =>
        ({
          field: "template",
          op: "is_empty",
          type: "text",
        } as FilterQuery)
    ),

    // Exclude archived items unless explicitly asked for
    ifDo(
      opts?.archived !== true,
      () =>
        ({
          field: "archivedAt",
          type: "date",
          op: "is_empty",
        } as FilterQuery)
    ),

    ifDo(
      opts?.archived === true && view.entity !== "team",
      () =>
        ({
          field: "archivedAt",
          type: "date",
          op: "is_not_empty",
        } as FilterQuery)
    ),

    when(toViewTarget(view), (forId) => {
      if (isWorkspaceId(forId)) {
        return undefined;
      }

      if (!!view?.settings?.hideNested) {
        // Filter only things that are either live directly under the parent or have the relation set
        return {
          or: omitEmpty([
            // Teams don't have view relations, so just filter location
            ifDo(!isTeamId(forId), () => {
              // Only construct this if there is a property relation to the parent
              if (!isEmptyFilter(entityFilter)) {
                return {
                  and: [
                    {
                      field: "location",
                      op: "does_not_contain",
                      type: "text",
                      value: { text: forId },
                    },

                    entityFilter,
                  ],
                };
              }

              return undefined;
            }),
            {
              field: "location",
              op: "ends_with",
              type: "text",
              value: { text: forId },
            },
          ]),
        } as FilterQuery;
      }

      // Include things whose location is within the parent without nesc having the relation set
      return {
        or: [
          {
            field: "location",
            op: "contains",
            type: "text",
            value: { text: forId },
          },
          entityFilter,
        ],
      } as FilterQuery;
    }),
  ]);

  const base = applyExtensions(view.filter);

  return !!additional?.length
    ? addNewFilter(base, { and: additional }, "and")
    : base;
};

export const isTemplateViewId = (id: string) => id.startsWith("v_tmp-");

export const toTemplateIdBase = cachedFunc(
  (id: string) => first(id?.split(".")) || "",
  toMilliSeconds("1 minute")
);

export const toTemplateViewId = (
  template: string,
  params: Record<string, Maybe<string>>
) =>
  `v_tmp-${template.replace(/[\s_\.]/gi, "-")}${reduce(
    keys(params),
    (res, k) => (isDefined(params[k]) ? `${res}.${k}=${params[k]}` : res),
    ""
  )}`;

export const useTemplateViewId = (
  template: string,
  params: Record<string, Maybe<string>>
) => {
  return useMemo(
    () => toTemplateViewId(template, params),
    [template, params?.team, params?.parent]
  );
};

export const fromTemplateViewId = (
  id: string
): { key: string; params: Record<string, Maybe<string>> } => {
  const [keyPart, ...paramsParts] = id.split(".");
  const key = keyPart.replace("v_tmp-", "");
  const params = reduce(
    paramsParts,
    (res, p) => {
      const [k, v] = p.split("=");
      return { ...res, [k]: v };
    },
    {}
  );
  return { key, params };
};

export const propsToSatisfyFilter = <T extends Entity>(
  filter: FilterQuery<T>,
  available: PropertyDef<Entity>[]
): PropertyMutation<T>[] => {
  if (isAnd(filter)) {
    return flatMap(filter.and, (f) => propsToSatisfyFilter(f, available));
  }

  if (isOr(filter)) {
    return (
      when(first(filter.or), (f) =>
        ensureArray(propsToSatisfyFilter(f, available))
      ) || []
    );
  }

  const { type } = filter;

  if (type === "json") {
    return [];
  }

  const value = switchEnum(filter.op, {
    is_empty: () => undefined,
    does_not_contain: () => undefined,
    contains: () => toValue(filter),
    equals: () => toValue(filter),
    does_not_equal: () => undefined,
    else: () => toValue(filter),
  });

  if (!value) {
    return [];
  }

  // TODO: This does not support does_not_equal filters on status.group... especially inside an AND filter
  // https://dev.traction.team/t_BlU2luW2
  // If it's a status filter, filtering on a status group, then we need to find the first
  // status in that group and use that as the value
  if (
    filter?.type === "status" &&
    (value as Maybe<Status>)?.group &&
    !(value as Maybe<Status>)?.id
  ) {
    const group = (value as Maybe<Status>)?.group;
    const statuses = find(
      available,
      (a) => a?.type === "status" && a.field === filter?.field
    ) as Maybe<PropertyDef<Entity, "status">>;
    const firstInGroup = find(
      statuses?.values?.status,
      (a) => a?.group === group
    );

    return [asPropertyValueRef(filter, { status: firstInGroup })];
  }

  return [asPropertyValueRef(filter, asValue(type, value))];
};

export const propsToSatisfyView = (
  view: View,
  available: PropertyDef<Entity>[]
) =>
  omitEmpty([
    ...(when(
      // Strict filter only respects parent relations
      !when(view.for?.id, isWorkspaceId)
        ? toViewBaseFilter(view, available, "strict")
        : undefined,

      (f) => propsToSatisfyFilter(f, available)
    ) || []),

    view?.template
      ? asMutation({ field: "template", type: "text" }, "nested")
      : undefined,

    // Pull out props from filters
    ...(when(view?.filter, (f) => propsToSatisfyFilter<Entity>(f, available)) ||
      []),
  ]);

export const orderedItemsForGroup = (group: NestedGroup): Entity[] =>
  isNested(group)
    ? reduce(
        group.groups,
        (res, g) => [...res, ...orderedItemsForGroup(g)],
        [] as Entity[]
      )
    : group.items || [];

export const countForGroup = (group: NestedGroup): number =>
  orderedItemsForGroup(group).length;

export type PropertyValueRefCount = PropertyValueRef & { count: number };

export const countUniqValues = (
  items: Entity[],
  prop: PropertyDef<Entity>
): PropertyValueRefCount[] => {
  const uniqPropVals = map(prop.values[prop.type], (v) =>
    asPropertyValueRef(prop, { [prop.type]: v })
  );
  const usedVals = flatMap(items, (t) => toGroupValueRef(t, prop));

  const grouped = groupByMany(usedVals, (prop) => {
    const value = prop?.value[prop?.type];
    return isArray(value)
      ? maybeMap(value as SelectOption[], (v) =>
          toKey(asPropertyValueRef(prop, { [prop.type]: v }))
        )
      : toKey(prop);
  });

  return maybeMap(uniqPropVals, (val) => {
    const key = toKey(val);
    const vals = key && grouped[key];

    return key && vals?.length
      ? { ...val, count: vals?.length || 0 }
      : undefined;
  });
};

export const aggregateValues = (
  items: Entity[],
  prop: PropertyDef<Entity>,
  reducer: (values: number[]) => number
): PropertyValueRef<Entity> => {
  if (prop.type !== "number") {
    return { ...prop, value: { number: 0 } };
  }
  const values = maybeMap(items, (t) => toGroupValueRef(t, prop).value.number);
  const sum = reducer(values);
  return asPropertyValueRef(prop, { number: sum });
};

export const orderedItemsFromGroups = (groups: NestedGroup[]): Entity[] =>
  flatMap(groups, (g) =>
    isNested(g)
      ? orderedItemsFromGroups(g.groups)
      : (g.items || [])?.length
      ? g.items
      : []
  );

export const defaultViewsForParent = (parent: Entity, itemType: EntityType) =>
  fallback(
    // Don't have defaults for note/resource/action
    ifDo_(equalsAny(itemType, ["note", "resource", "agenda"]), () => []),

    ifDo_(parent.source?.type === "roadmap", () => [
      toTemplateViewId("roadmap-planned", {
        parent: parent.id,
        entity: itemType,
      }),
      toTemplateViewId("roadmap-all", {
        parent: parent.id,
        entity: itemType,
      }),
    ]),

    ifDo_(parent.source?.type === "backlog", () => [
      toTemplateViewId("backlog-list", {
        parent: parent.id,
        entity: itemType,
      }),
    ]),

    ifDo_(parent.source.type === "person" && itemType === "meeting", () => [
      toTemplateViewId("my-meetings", { parent: parent.id }),
      toTemplateViewId("past-meetings", { parent: parent.id }),
    ]),

    ifDo_(parent.source?.type === "calendar", () => [
      toTemplateViewId("calendar-content", {
        parent: parent.id,
        entity: itemType,
      }),
    ]),

    ifDo_(itemType === "content", () =>
      omitEmpty([
        toTemplateViewId(`content-list`, {
          parent: parent.id,
        }),
        parent?.source?.type === "team"
          ? undefined
          : toTemplateViewId(`content-canvas`, {
              parent: parent.id,
            }),
        toTemplateViewId(`content-cal`, {
          parent: parent.id,
        }),
      ])
    ),

    ifDo_(itemType === "meeting", () => [
      toTemplateViewId("upcoming-meetings", { parent: parent.id }),
      toTemplateViewId("past-meetings", { parent: parent.id }),
    ]),

    ifDo_((parent?.source?.type as EntityType) === "team", () => [
      toTemplateViewId(`team-${itemType}`, {
        parent: parent.id,
      }),
    ]),

    ifDo_(has(parent as HasLocation, "location"), () => [
      toTemplateViewId(`${parent.source.type}-${itemType}`, {
        parent: parent.id,
      }),
    ]),

    () => []
  );

export const toSortKey = (view: Maybe<View>) =>
  when(view?.location, composel(fromScope, last)) || view?.id || "default";

export const toSortProps = (
  view: Maybe<View>,
  parent?: Entity
): SortByProp[] => {
  if (view?.layout === "calendar") {
    return [
      { field: "start", type: "date", direction: "asc" },
      { field: "end", type: "date", direction: "asc" },
      { field: "publish", type: "date", direction: "asc" },
    ];
  }
  if (
    parent?.source.type === "roadmap" &&
    !!(parent as Maybe<Roadmap>)?.fields?.length
  ) {
    return maybeMap((parent as Roadmap).fields, (f) => ({
      ...f,
      direction: f.direction || "asc",
    }));
  }

  return view?.sort || [];
};

export const toRecommendedProps = (
  view: View,
  suggested: Maybe<PropertyRef[]>,
  props: PropertyDef[]
) => {
  const fields = uniq([
    ...maybeMap(suggested, (p) => p.field),
    ...maybeMap(view.group || [], (g) => g?.field),
    ...maybeMap(view.showProps || [], (p) => p.field),
  ]);
  return filter(props, (p) => fields.includes(p.field));
};

export const toLayoutName = (layout: View["layout"]) =>
  switchEnum(layout, {
    list: "List",
    card: "Cards",
    table: "Table",
    canvas: "Flowchart",
    timeline: "Timeline",
    calendar: "Calendar",
    else: () => "Unknown",
  });

export const compareViewIds = (a: ID, b: ID) =>
  toTemplateIdBase(a) === toTemplateIdBase(b);

export const toDateFields = (view: View) =>
  [
    getPropertyValue(view, {
      field: "settings.calStart",
      type: "property",
    })?.property,
    getPropertyValue(view, {
      field: "settings.calEnd",
      type: "property",
    })?.property,
  ] as [Maybe<PropertyRef>, Maybe<PropertyRef>];

export const isTriaging = (view: Maybe<View>) =>
  !!view?.settings?.triageEmpty &&
  (!!view?.group?.length || view.layout === "calendar");

export const isSetup = (view: View) =>
  switchEnum(view.layout, {
    canvas: () => !!view?.settings?.canvasBy,
    calendar: () => !!toDateFields(view)[0]?.field,
    else: () => undefined,
  }) ??
  switchEnum(view.grouping || "none", {
    rows: () => !!view?.group?.[0],
    columns: () => !!view?.group?.[0],
    columns_rows: () => !!view?.group?.[0] && !!view?.group?.[1],
    quadrants: () => !!view?.group?.[0],
    else: () => true,
  });

export const toGroupLabel = (view: View, props: PropertyDef[]) =>
  !!view.group?.length || view.layout === "columns"
    ? `by ${
        view.group?.[0]
          ? when(
              find(props, (p) => p.field === view.group?.[0]?.field),
              (d) => d.label || d.field
            ) ?? view.group?.[0]?.field
          : "status"
      }`
    : undefined;

export const toShortTitle = (view: View, props?: PropertyDef[]) =>
  toSentence(
    fallback(
      () => when(view?.name || undefined, ensureMany),
      () =>
        !!view.group?.length ? [toGroupLabel(view, props || [])] : undefined,
      () =>
        when(view.filter && toList(view.filter), (filters) => [
          "filtered by",
          when(
            find(
              props,
              (d) => d.field === safeAs<SingleFilterQuery>(filters[0])?.field
            ) || (filters[0] as PropertyRef<Entity>),
            toFieldName
          ),
          filters?.length > 1 ? "and more" : undefined,
        ]),
      () => [toLayoutName(view.layout)]
    )
  );
