import { atomFamily, selectorFamily } from "recoil";

import { ID, Backlog } from "@api";

import {
  getItem,
  localStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type BacklogStoreState = StoreState<Backlog>;

export const WorkspaceBacklogStoreAtom = atomFamily<BacklogStoreState, ID>({
  key: "WorkspaceBacklogStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [localStorageForStore(wid, "backlog")],
});

export const BacklogStoreAtom = WorkspaceWrappedAtom(
  "BacklogStoreAtom",
  WorkspaceBacklogStoreAtom
);

export const BacklogAtom = selectorFamily({
  key: "BacklogAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(BacklogStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(BacklogStoreAtom, mergeItem<Backlog>(newValue));
      }
    },
});
