import { first, map } from "lodash";
import { useCallback, useMemo } from "react";

import { Entity, ID, Team } from "@api";

import { useLazyMyTeams } from "@state/teams";
import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { toTemplateViewId } from "@state/views";
import { SystemPackages, useAnyHasPackages } from "@state/packages";
import { useActiveWorkspaceId, useCurrentUser } from "@state/workspace";
import { useLazyEntity, useLazyQuery } from "@state/generic";

import { usePushTo } from "@utils/navigation";
import { Maybe, maybeMap } from "@utils/maybe";
import { Fn } from "@utils/fn";
import { useStickyState } from "@utils/hooks";
import { omitEmpty } from "@utils/object";

import { Sheet, WrapContainer } from "@ui/sheet-layout";
import { PageLayout, Main, TopBar } from "@ui/page-layout";
import { Icon, PersonIcon, TeamIcon } from "@ui/icon";
import { Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Heading } from "@ui/text";
import { ViewCardCompact } from "@ui/view-card-compact";
import { TabbedViewCard, ViewCard } from "@ui/view-card";
import { ParentCard } from "@ui/parent-card";
import { Button } from "@ui/button";
import { Divider } from "@ui/divider";

import AppPage from "./app-page";

import styles from "./home-page.module.css";
import { isViewId } from "@utils/id";

interface Props {}

const HomePage = ({}: Props) => {
  const me = useCurrentUser();
  const [page] = useRegisterPage("home", undefined);
  const [filter, setFilter] = useStickyState<Maybe<ID>>(
    undefined,
    "home-team-filter"
  );
  const teams = useLazyMyTeams();

  usePageUndoRedo(page.id);

  // Ensure current user is always fetched on first load
  useLazyEntity(me.id, false);

  if (!me) {
    return <></>;
  }

  return (
    <AppPage page={page} title="Home">
      <PageLayout bar="top">
        <TopBar>
          <WrapContainer>
            {/* <CoverImage url="/IMG_focus-quote.jpg"> */}
            <Container stack={"horizontal"} inset="bottom">
              <SpaceBetween>
                <HStack gap={20} fit="container">
                  <HStack>
                    {!!me && (
                      <Icon icon={<PersonIcon person={me} />} size="xxlarge" />
                    )}
                    <VStack gap={6}>
                      <Heading bold>{me?.name || "Home"}</Heading>

                      <HStack gap={4}>
                        <Button
                          size="tiny"
                          subtle={!!filter}
                          variant={!filter ? "primary" : "secondary"}
                          className={styles.roundedButton}
                          onClick={() => setFilter(undefined)}
                        >
                          All Teams
                        </Button>

                        <Divider direction="vertical" />

                        {map(teams, (team, i) => (
                          <Button
                            key={team.id}
                            size="tiny"
                            className={styles.roundedButton}
                            subtle={filter !== team.id}
                            variant={
                              filter === team.id ? "primary" : "secondary"
                            }
                            icon={<TeamIcon team={team} />}
                            onClick={() => setFilter(team.id)}
                          >
                            {team.name}
                          </Button>
                        ))}
                      </HStack>
                    </VStack>
                  </HStack>
                </HStack>

                {/* <Quote height="content" size="full" /> */}
              </SpaceBetween>
            </Container>
            {/* </CoverImage> */}
          </WrapContainer>
        </TopBar>

        <Main>
          <HomePanes filter={filter} teams={teams} />
        </Main>
      </PageLayout>
    </AppPage>
  );
};

interface HomePanesProps {
  filter?: ID;
  teams: Team[];
}

const HomePanes = ({ filter, teams }: HomePanesProps) => {
  const pushTo = usePushTo();
  const me = useCurrentUser();
  const workspaceId = useActiveWorkspaceId();

  const toCheck = useMemo(
    () =>
      filter
        ? [workspaceId, filter]
        : [workspaceId, ...maybeMap(teams, (t) => t.id)],
    [workspaceId, teams]
  );
  const installed = useAnyHasPackages(toCheck, [
    SystemPackages.Meetings,
    SystemPackages.Outcomes,
    SystemPackages.Content,
    SystemPackages.Campaigns,
    SystemPackages.Sprints,
    SystemPackages.Projects,
  ]);

  const viewParams = useMemo(
    () =>
      filter
        ? // If filtering to a team need to setup view differently
          omitEmpty({ parent: filter, for: me.id })
        : // If just looking at all my tasks can do this
          { parent: me.id, for: me.id },
    [me, filter]
  );
  const toId = useCallback(
    (template: string) => toTemplateViewId(template, viewParams),
    [viewParams]
  );

  const taskViewIds = useMemo(
    () => [toId("my-tasks"), toId("my-recently-completed"), toId("my-planned")],
    [toId]
  );

  return (
    <WrapContainer>
      <SpaceBetween fit="container" align="stretch" gap={16}>
        <Sheet size="primary" mode="sizing" height="content">
          <VStack gap={10}>
            <TabbedViewCard
              viewIds={taskViewIds}
              onOpen={pushTo}
              limit={5}
              size="full"
            />

            {!!installed[SystemPackages.Campaigns] && (
              <ViewCardCompact
                viewId={toId("my-campaigns")}
                empty="collapse"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Projects] && (
              <ViewCardCompact
                viewId={toId("my-projects")}
                empty="collapse"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Content] && (
              <ViewCardCompact
                viewId={toId("my-contents")}
                empty="collapse"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}
          </VStack>
        </Sheet>
        <Sheet size="secondary" mode="sizing" height="content">
          <VStack fit="container">
            {installed[SystemPackages.Sprints] && (
              <CurrentSprintCard onClick={pushTo} />
            )}

            {!!installed[SystemPackages.Meetings] && (
              <>
                <ViewCardCompact
                  viewId={toId("my-today-meetings")}
                  onOpen={(ref) =>
                    isViewId(ref.id) ? pushTo(toId("my-meetings")) : pushTo(ref)
                  }
                  empty="collapse"
                  size="full"
                />
                <ViewCardCompact
                  viewId={toId("my-actions")}
                  onOpen={pushTo}
                  empty="collapse"
                  size="full"
                />
              </>
            )}

            {!!installed[SystemPackages.Outcomes] && (
              <ViewCardCompact
                viewId={toId("my-outcomes")}
                empty="collapse"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}
          </VStack>
        </Sheet>
      </SpaceBetween>
    </WrapContainer>
  );
};

const CurrentSprintCard = ({ onClick }: { onClick: Fn<Entity, void> }) => {
  const sprints = useLazyQuery("sprint", {
    field: "status",
    type: "status",
    op: "equals",
    value: { status: { group: "in-progress" } },
  });
  const sprint = useMemo(() => first(sprints), [sprints]);

  if (!sprint) {
    return <></>;
  }

  return (
    <ParentCard
      id={sprint.id}
      className={styles.sprintcard}
      label="Current"
      onClick={onClick}
    />
  );
};

export default HomePage;
