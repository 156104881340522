import { isPast } from "date-fns";
import { useMemo } from "react";
import { groupBy, last, map } from "lodash";

import { Entity, HasDates, HasRefs, ID, Ref } from "@api";

import { useLazyEntities, useLazyEntity } from "@state/generic";
import { toScheduleField } from "@state/schedule";

import { useGoTo, usePushTo } from "@utils/navigation";
import { toEndOfDay } from "@utils/date";
import { useShowMore } from "@utils/hooks";
import { maybeTypeFromId } from "@utils/id";
import { when } from "@utils/maybe";
import { plural } from "@utils/string";
import { fromCalDate, useISODate } from "@utils/date-fp";
import { inPast } from "@utils/time";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { HStack } from "@ui/flex";
import { ArrowUpRight } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem, MoreMenuItem } from "@ui/menu-item";
import { PaneHeader } from "@ui/pane-header";
import { Sheet } from "@ui/sheet-layout";
import { Text } from "@ui/text";

import styles from "./secondary-panes.module.css";

export const ScheduleInstancesPane = ({
  schedule: ref,
  instanceId,
}: {
  schedule: Ref;
  instanceId?: ID;
}) => {
  const pushTo = usePushTo();
  const schedule = useLazyEntity<"schedule">(ref.id);
  const goTo = useGoTo();
  const instances = useLazyEntities(schedule?.instances || []);
  const engine = useEngine(instances?.[0]?.source.type || "task");
  const grouped = useMemo(
    () =>
      groupBy((instances || []) as Entity[], (i: HasDates) =>
        // Exclude the template from the past/upcoming
        !!i.template
          ? "template"
          : when(i.start || i.end, (d) =>
              inPast(toEndOfDay(fromCalDate(d, "local")))
            ) ?? false
          ? "past"
          : "upcoming"
      ),
    [instances]
  );

  const upcoming = useShowMore(grouped.upcoming as Entity[], 5);
  const past = useShowMore(grouped.past as Entity[], 3);

  if (!schedule) {
    return <></>;
  }

  return (
    <Sheet size="secondary" height="content">
      <PaneHeader
        actions={
          <HStack gap={4}>
            {(!schedule.to || !useISODate(schedule.to, isPast)) && (
              <Button
                size="small"
                subtle
                onClick={() => goTo(schedule)}
                iconRight={ArrowUpRight}
              >
                Open
              </Button>
            )}
          </HStack>
        }
        title={"Recurring Schedule"}
      />
      <Container size="half">
        <Menu>
          <MenuGroup label="Upcoming">
            {map(upcoming.visible, (e) =>
              render(engine.asMenuItem, {
                key: e.id,
                item: e,
                showProps: [{ field: toScheduleField(schedule), type: "date" }],
                onOpen: pushTo,
                className: instanceId === e.id ? styles.selected : undefined,
              })
            )}
            {!upcoming.visible.length && (
              <MenuItem disabled>
                <Text subtle>
                  {" "}
                  No upcoming{" "}
                  {plural(
                    when(
                      schedule?.useTemplate?.id ||
                        last(schedule?.instances)?.id,
                      maybeTypeFromId
                    ) || "item"
                  )}{" "}
                  scheduled
                </Text>
              </MenuItem>
            )}
            {upcoming.hasMore && (
              <MoreMenuItem
                count={upcoming.moreCount}
                onClick={upcoming.showMore}
              />
            )}
          </MenuGroup>

          {!!past?.visible?.length && (
            <MenuGroup label="Past">
              {map(past.visible, (e) =>
                render(engine.asMenuItem, {
                  key: e.id,
                  item: e,
                  showProps: [
                    { field: toScheduleField(schedule), type: "date" },
                  ],
                  onOpen: pushTo,
                  className: instanceId === e.id ? styles.selected : undefined,
                })
              )}
              {past.hasMore && (
                <MoreMenuItem count={past.moreCount} onClick={past.showMore} />
              )}
            </MenuGroup>
          )}
        </Menu>
      </Container>
    </Sheet>
  );
};

export const TemplateSchedulesPane = ({ template }: { template: HasRefs }) => {
  const pushTo = usePushTo();
  const goTo = useGoTo();
  const schedules = useLazyEntities<"schedule">(template.refs?.schedules || []);
  const engine = useEngine("schedule");

  if (!schedules?.length) {
    return <></>;
  }

  return (
    <Sheet size="secondary" height="content">
      <PaneHeader title="Recurring Schedules" />
      <Container size="half">
        <Menu>
          <MenuGroup>
            {map(schedules, (e) =>
              render(engine.asListCard, {
                key: e.id,
                item: e,
                onOpen: pushTo,
              })
            )}
          </MenuGroup>
        </Menu>
      </Container>
    </Sheet>
  );
};
