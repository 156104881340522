import { useCallback, useMemo, useState } from "react";

import {
  Entity,
  JsonArray,
  PropertyMutation,
  Ref,
  Schedule,
  toTitleOrName,
  Update,
} from "@api";

import {
  useLazyEntity,
  useLocalChanges,
  useQueueUpdates,
} from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";
import { useEntityLabels } from "@state/settings";
import { ScheduleStoreAtom, useTempSchedule } from "@state/schedule";

import { Fn } from "@utils/fn";
import { omitEmpty, OneOrMany } from "@utils/array";
import { asMutation, asTempUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { when } from "@utils/maybe";

import { Button } from "@ui/button";
import { HStack, VStack } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { TemplateSelect } from "@ui/select";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { Dialog } from "@ui/dialog";
import { useCurrentPage } from "@ui/app-page";
import { showError } from "@ui/notifications";
import { RadioMenuGroup, RadioMenuItem } from "@ui/menu-item/radio";
import { Divider } from "@ui/divider";

import { ScheduleRepeatOptions } from "./editor";

import styles from "./styles.module.css";

interface DialogProps {
  defaults: Partial<Omit<Schedule, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const ScheduleCreateDialog = ({
  defaults,
  onCancel,
  onSaved,
}: DialogProps) => {
  const pageId = useCurrentPage();
  const wID = useActiveWorkspaceId();
  const [startFrom, setStartFrom] = useState<string>("template");
  const [templateRef, setTemplate] = useState<Ref>();
  const [name, setName] = useState("");

  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wID,
    [defaults?.source?.scope]
  );
  const queue = useQueueUpdates(pageId);
  const toLabel = useEntityLabels(scope);
  const template = useLazyEntity(templateRef?.id);

  const schedule = useTempSchedule(defaults, pageId);
  const type = schedule?.entity;
  const { changes, save, rollback } = useLocalChanges(
    schedule?.id || "",
    ScheduleStoreAtom
  );
  const handleChange = (changes: OneOrMany<PropertyMutation<Schedule>>) =>
    schedule && queue(asTempUpdate(schedule, changes) as Update<Entity>);

  const handleCreate = useCallback(() => {
    if (!schedule) {
      return showError("Not ready to create.");
    }

    if (!(name?.length || templateRef)) {
      return showError("Missing some values.");
    }

    const created = save([
      asTempUpdate(
        schedule,
        omitEmpty([
          asMutation(
            { field: "name", type: "text" },
            name || when(template, toTitleOrName)
          ),

          // Store either the template reference or the final item name
          startFrom === "template" && template
            ? asMutation(
                { field: "useTemplate", type: "relation" },
                toRef(template)
              )
            : asMutation({ field: "overrides", type: "json" }, [
                { field: "name", type: "text", value: { text: name } },
              ] as JsonArray),
        ])
      ),
    ]);

    if (created) {
      onSaved?.(toRef(created));
    }

    onCancel?.();
  }, [name, templateRef, template, scope, save]);

  const handleDismiss = useCallback(() => {
    rollback();
    onCancel?.();
  }, [rollback]);

  return (
    <Dialog
      title="Schedule recurring work"
      onDismiss={handleDismiss}
      actions={
        <HStack>
          <Button onClick={handleDismiss}>Cancel</Button>
          <Button
            disabled={!(name?.length || templateRef) || !type}
            variant="primary"
            onClick={handleCreate}
          >
            Create
          </Button>
        </HStack>
      }
    >
      <VStack gap={16} className={styles.body} fit="container">
        <Field label="What do you want to repeat?">
          <EntityTypeSelect
            plural={false}
            value={type}
            onChange={(type) =>
              handleChange(asMutation({ field: "entity", type: "text" }, type))
            }
            scope={scope}
            placeholder="Choose type of work..."
          />
        </Field>

        <Field label="From">
          <RadioMenuGroup value={startFrom} onChanged={setStartFrom}>
            <RadioMenuItem text="Use existing template" value="template" />
            <RadioMenuItem
              text={`New ${toLabel(type, { case: "lower" })}`}
              value="use-last"
            />
          </RadioMenuGroup>
          {type && startFrom === "template" && (
            <TemplateSelect
              className={styles.control}
              value={templateRef}
              type={type}
              scope={scope}
              allowed={[type]}
              allowNew={true}
              onChange={setTemplate}
              placeholder="Select template..."
            />
          )}

          {type && startFrom === "use-last" && (
            <TextInput
              value={name}
              onChange={setName}
              updateOn="change"
              placeholder={`${toLabel(type)} name...`}
            />
          )}
        </Field>

        <Divider />

        {schedule && (
          <Field>
            <ScheduleRepeatOptions schedule={schedule} mutate={handleChange} />
          </Field>
        )}
      </VStack>
    </Dialog>
  );
};
