import { equals } from "lodash/fp";
import { useDebouncedCallback } from "use-debounce";
import {
  Dictionary,
  filter,
  omit,
  find,
  first,
  flatMap,
  flatten,
  groupBy,
  set as loSet,
  isArray,
  isEmpty,
  map,
  reduce,
  some,
  uniq,
  values,
  without,
  last,
  isString,
  orderBy,
  every,
  take,
} from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";

import {
  CreateOrUpdate,
  DatabaseID,
  Entity,
  EntityType,
  HasCode,
  HasOrders,
  hasOrders,
  hasStatus,
  ID,
  Integration,
  Note,
  persist,
  PropertyDef,
  PropertyMutation,
  Ref,
  RelationRef,
  Status,
  toTitleOrName,
  Update,
  isError,
  isStatusable,
  FetchOptions,
  FilterQuery,
  PropertyRef,
  HasAssigned,
  HasOwner,
  PersonRole,
} from "@api";
import { EntityForType, EntityMap, TypeForEntity } from "@api/mappings";

import { addToStack, AppPageAtom } from "@state/app";
import {
  allStatusesForTeam,
  isPropForEntity,
  isPropInScope,
  PropertyDefStoreAtom,
  useLazyProperties,
  useLazyPropertyValues,
} from "@state/databases";
import {
  addChanges,
  clearTempUpdates,
  discardUpdate,
  failUpdate,
  finishChangeUpdate,
  finishCreate,
  finishRestore,
  finishUpdate,
  getItem,
  mergeUpdates,
  mutReferencesLocalIDs,
  nextUpdatesToProcess,
  persistedID,
  queueUpdate,
  queueUpdates,
  removeItem,
  replaceRelationRefs,
  retryUpdate,
  setItem,
  setItems,
  stableID,
  startUpdate,
  StoreState,
  syncUpdate,
  withPersistedID,
  withPersistedTimestamp,
} from "@state/store";
import {
  MaybeActiveWorkspaceSessionAtom,
  useActiveWorkspaceId,
  useCurrentUser,
} from "@state/workspace";
import { TeamStoreAtom } from "@state/teams/atoms";
import { ViewAtom } from "@state/views/atoms";
import {
  useDidUpdateWorkflows,
  useWillUpdateWorkflows,
} from "@state/workflows";
import { storage } from "@state/storage";
import { useAddToRecents } from "@state/recents";
import { isInflated } from "@state/generic";
import { useRealtimeChannel } from "@state/realtime";
import { FetchResultsStoreAtom } from "@state/fetch-results";

import { useAsyncEffect } from "@utils/effects";
import { composel, fallback, Fn } from "@utils/fn";
import {
  asLocal,
  isLocalID,
  isTemplateId,
  maybeTypeFromId,
  newADDDID,
  newHumanId,
  newID,
  newLocalHumanId,
  typeFromId,
} from "@utils/id";
import { equalsAny, ifDo, or, switchEnum } from "@utils/logic";
import {
  isDefined,
  Maybe,
  maybeMap,
  safeAs,
  when,
  whenTruthy,
} from "@utils/maybe";
import { all, mapAll } from "@utils/promise";
import { hashable } from "@utils/serializable";
import {
  asAppendMutation,
  asDeleteUpdate,
  asMutation,
  asTempUpdate,
  asUpdate,
  flattenUpdate,
  toMutation,
  withPrevValues,
} from "@utils/property-mutations";
import {
  ensureMany,
  groupByMany,
  maybeLookup,
  omitEmpty,
  OneOrMany,
  pushDirty,
  uniqBy,
} from "@utils/array";
import { fromPointDate } from "@utils/date-fp";
import {
  maybeValues,
  merge,
  omitEmpty as omitEmptyVals,
  set,
  setDirty,
} from "@utils/object";
import { DragRef, DragToRef } from "@utils/drag-drop";
import {
  DecimalOrdering,
  evenlyBetween,
  orderItems,
  setOrders,
  toNewOrders,
  toOrders,
} from "@utils/ordering";
import {
  getPropertyValue,
  isAnyRelation,
  isEmptyRef,
  isEmptyValue,
  toRef,
} from "@utils/property-refs";
import { toOrder } from "@utils/ordering";
import { log, debug, warn } from "@utils/debug";
import { useRefState } from "@utils/hooks";
import { toGroupUpdate } from "@utils/grouping";
import {
  fromScope,
  toBaseScope,
  toChildLocation,
  toLocation,
  toNestedLocation,
  toScope,
} from "@utils/scope";
import { now } from "@utils/now";

import { useMutate } from "@ui/mutate";

import {
  getEntitiesForSearch,
  getEntitiesLoader,
  getEntityLoader,
  getItemsNestedWithin,
  getOptimizedForFilter,
} from "./queries";

import { EntityStores, getStore } from "./atoms";
import { GenericItem, itemsForQuery, searchStore } from "./selectors";
import { isUpdateRelevant, shouldSyncUpdate } from "./utils";

export type EntityOverrides = Partial<Entity> & { name?: string }; //Omit<, "source" | "location">;

export function useEntitySource<T extends EntityType>(
  type: T,
  source?: DatabaseID
): DatabaseID<T>;
export function useEntitySource<T extends EntityType>(
  type: Maybe<T>,
  source?: DatabaseID
): Maybe<DatabaseID<T>>;
export function useEntitySource<T extends EntityType>(
  type: Maybe<T>,
  source?: DatabaseID
) {
  const workspace = useActiveWorkspaceId();

  return useMemo(
    () =>
      type
        ? ({
            integration: Integration.Traction,
            scope: workspace,
            ...source,
            type,
          } as DatabaseID<T>)
        : undefined,
    [type, source?.scope, source?.type]
  );
}

export function useNestedSource<
  E extends Entity,
  C extends EntityType = TypeForEntity<E>
>(item: E, childType?: Maybe<C>): DatabaseID<C>;
export function useNestedSource<
  E extends Entity,
  C extends EntityType = TypeForEntity<E>
>(item: Maybe<E>, childType?: Maybe<C>): Maybe<DatabaseID<C>>;
export function useNestedSource(
  item: Maybe<Entity>,
  childType?: Maybe<EntityType>
): Maybe<DatabaseID> {
  return useMemo(
    () =>
      item && {
        type: childType ?? item?.source.type,
        scope: toChildLocation(item.source.scope, item.id),
      },
    [item?.source.scope, item?.id, childType]
  );
}

export function useBaseSource(item: Maybe<Entity>): Maybe<DatabaseID> {
  return useMemo(
    () =>
      item && {
        type: item?.source.type,
        scope: toBaseScope(item.source.scope),
      },
    [item?.source.scope, item?.id]
  );
}

export function useStore<
  T extends EntityType,
  E extends Entity = EntityForType<T>
>(t: T): StoreState<E> {
  const Store = useMemo(() => getStore<T, E>(t), [t]);
  return useRecoilValue(Store);
}

export function useGetItemFromAnyStore() {
  const stores = useAllStores();
  return useCallback(
    <T extends Entity>(id: ID): Maybe<T> => {
      const type = maybeTypeFromId<EntityType>(id);
      return when(type && stores[type], (store) =>
        getItem<Entity>(store as StoreState<Entity>, id)
      ) as Maybe<T>;
    },
    [stores]
  );
}

export function useEntityStores() {
  return useRecoilValue(EntityStores);
}

export function useAllStores() {
  const props = useRecoilValue(PropertyDefStoreAtom);
  const fetchResults = useRecoilValue(FetchResultsStoreAtom);
  const entityStores = useRecoilValue(EntityStores);

  return useMemo(
    () => ({ props, fetchResults, ...entityStores }),
    [props, fetchResults, entityStores]
  );
}

export function useAllUpdatesToProcess() {
  const allStores = useEntityStores();
  return flatMap(
    values(allStores) as StoreState<Entity>[],
    (a) => a?.unsaved || []
  );
}

interface ProcessUpdatesResult {
  updating: Update<Entity>[];
  aliases: Record<string, string>;
  history: Update<Entity>[];
  unsaved: Update<Entity>[];
  saving: boolean;
}

export function useCombinedStore() {
  const stores = useEntityStores();
  return useMemo(
    () =>
      reduce(
        values(stores) as StoreState<Entity>[],
        (r, s) => ({
          updating: pushDirty(r.updating, ...(s.updating || [])),
          aliases: { ...r.aliases, ...s.aliases },
          history: pushDirty(r.history, ...(s.history || [])),
          unsaved: pushDirty(r.unsaved, ...(s.unsaved || [])),
          saving: r.saving || !!s.updating?.length,
        }),
        {
          updating: [],
          aliases: {},
          history: [],
          unsaved: [],
          saving: false,
        } as ProcessUpdatesResult
      ),
    [stores]
  );
}

export const useAllowedScopes = () => {
  const session = useRecoilValue(MaybeActiveWorkspaceSessionAtom);
  const teams = useRecoilValue(TeamStoreAtom);

  if (!session || !session.workspace || !session.user) {
    return [];
  }
  const { user: me, workspace } = session;

  const allTeamIds = useMemo(
    () =>
      equalsAny(me.role, [PersonRole.Owner, PersonRole.Admin])
        ? maybeMap(values(teams.lookup), (t) => t?.id)
        : [],
    [me.role, teams.lookup]
  );

  return useMemo(() => {
    if (me.role === "guest") {
      return [...map(me.teams, (t) => t.id), me.id];
    }

    if (equalsAny(me.role, [PersonRole.Owner, PersonRole.Admin])) {
      return uniq([
        ...allTeamIds,
        ...map(me.teams, (t) => t.id),
        workspace.id,
        me.id,
      ]);
    }

    return [...map(me.teams, (t) => t.id), workspace.id, me.id];
  }, [me.id, me.role, me.teams, workspace.id, allTeamIds.length]);
};

export function useProcessUpdates() {
  const workspace = useActiveWorkspaceId();
  const combined = useCombinedStore();
  const me = useCurrentUser();
  const allowedScopes = useAllowedScopes();
  const getPostSaveChanges = useDidUpdateWorkflows();

  const { send } = useRealtimeChannel(
    `${workspace}`,
    "sync_update",
    ({ update }) => {
      if (isUpdateRelevant(update, allowedScopes)) {
        setRecoil(getStore(update.source.type), syncUpdate(update));
      }
    }
  );

  useAsyncEffect(async () => {
    const { unsaved, aliases, saving } = combined;
    // Update already in progress
    if (saving) {
      return;
    }

    const next = nextUpdatesToProcess(unsaved, aliases);

    // Temp updates now stay in queue, could filter them out if we want this warning back
    // if (!next.length && unsaved.length) {
    // debug("Non-failing update stuck in queue!", unsaved);
    // }

    // Mark all updates as started
    const toPersist = maybeMap(next, (u) => {
      // Always mark update as started (even when immediately finishing below)
      // You can't finish something you never started 🧘
      setRecoil(getStore(u.source.type), startUpdate(u));

      // Don't make any requests for apply mutations
      if (u.method === "apply") {
        setRecoil(getStore(u.source.type), finishChangeUpdate(u));
        return undefined;
      }

      // Don't make any requests for mutations that are just syncing with other clients changes
      if (u.mode === "sync") {
        setRecoil(getStore(u.source.type), finishChangeUpdate(u));
        return undefined;
      }

      // Skip when deleting an unpersisted entity
      if (u.method === "delete" && isLocalID(u.id)) {
        setRecoil(getStore(u.source.type), finishUpdate(u, undefined));
        return undefined;
      }

      return u;
    });

    // No changes to persist
    if (!toPersist.length) {
      return;
    }

    try {
      // Persist all in one request, using update with persisted IDs
      const results = await persist(
        map(toPersist, (u) => withPersistedID(u, aliases))
      );

      if (results?.length !== toPersist?.length) {
        warn("Different number of process results/updates.");
      }

      // Update all stores with the results (immediuately)
      map(results, (saved, i) => {
        const update = toPersist[i] as Maybe<Update<Entity>>;

        if (!update) {
          log("Missing update found for result", { saved, toPersist });
          return undefined;
        }

        // Updated failed...
        if (isError(saved)) {
          debug("Error saving update", update, saved);
          return setRecoil(getStore(update.source.type), failUpdate(update));
        }

        // Finish application of update
        setRecoil(
          getStore(update.source.type),
          switchEnum(update.method, {
            create: () => finishCreate(update, saved),
            update: () => finishChangeUpdate(update, saved),
            delete: () => finishUpdate(update, saved),
            restore: () => finishRestore(update, saved),
            else: () => finishUpdate(update, saved),
          })
        );

        if (shouldSyncUpdate(update, aliases)) {
          // Push successful updates to all clients with persisted ID
          send({
            sender: me.id,
            update: withPersistedTimestamp(
              withPersistedID(update, aliases),
              saved
            ),
          });
        }
      });

      // Process post-save workflow updates.
      await all(
        map(results, async (saved, i) => {
          const update = toPersist[i] as Maybe<Update<Entity>>;

          if (!update) {
            return undefined;
          }

          // Updated failed...
          if (isError(saved)) {
            debug("Error saving update", update, saved);
            return setRecoil(getStore(update.source.type), failUpdate(update));
          }

          const asyncChanges = await getPostSaveChanges(saved, update);
          setRecoil(getStore(update.source.type), queueUpdates(asyncChanges));
        })
      );
    } catch (err) {
      log(err);
      map(next, (u) => setRecoil(getStore(u.source.type), failUpdate(u)));
    }
  }, [combined.saving, combined.unsaved, combined.aliases, combined.updating]);

  return combined;
}

export const useLazyEntity = <T extends EntityType = EntityType>(
  id: Maybe<ID>,
  fetch: boolean = true
): Maybe<EntityForType<T>> => {
  const [entity, setEntity] = useRecoilState(GenericItem(id || ""));
  const fetching = useRef(false);

  // This async effect is being triggered repetitively when called inside the select components
  // Hack in place to stop infinite loading
  useAsyncEffect(async () => {
    if (
      !id ||
      (!!entity && !fetch) ||
      fetching.current ||
      maybeTypeFromId(id) === "workspace" ||
      isTemplateId(id) ||
      isLocalID(id)
    ) {
      return;
    }

    fetching.current = true;

    try {
      await getEntityLoader(
        id,
        when(entity?.updatedAt, fromPointDate),
        setEntity
      );
    } finally {
      fetching.current = false;
    }
  }, [id, !entity]); // Reload when ID changes or if entity gets removed from store to trigger reload

  return entity as Maybe<EntityForType<T>>;
};

export const useLazyEntities = <T extends EntityType = EntityType>(
  refs: Maybe<Ref[]>,
  fetch: boolean = true
): Maybe<EntityForType<T>[]> => {
  const getItem = useGetItemFromAnyStore();

  useAsyncEffect(async () => {
    if (!fetch || isEmpty(refs) || some(refs, (r) => isLocalID(r.id))) {
      return;
    }

    await getEntitiesLoader(
      map(refs, (r) => r.id),
      // Prevent double up of being called with same values
      (latest) =>
        map(
          groupBy(
            latest,
            (l) => l?.source?.type || maybeTypeFromId(l.id) || ""
          ),
          (v, k) => !!k && setRecoil(getStore(k as EntityType), setItems(v))
        )
    );
  }, [map(refs, (r) => r.id)?.join("")]);

  return useMemo(
    () => maybeMap(refs, (r) => getItem(r.id)),
    [getItem, refs]
  ) as EntityForType<T>[];
};

export const useOrderedEntities = <T extends EntityType = EntityType>(
  refs: Ref[],
  fetch: boolean = true
): Maybe<EntityForType<T>[]> => {
  const items = useLazyEntities<T>(refs, fetch);
  return useMemo(() => orderItems<EntityForType<T>>(items || []), [items]);
};

export const useLazyQuery = <T extends EntityType = EntityType>(
  type: T,
  filter: FilterQuery<EntityForType<T>>,
  opts?: FetchOptions
): EntityForType<T>[] => {
  const workspaceId = useActiveWorkspaceId();
  const hashed = useMemo(
    () => hashable({ type, filter, opts }),
    [type, filter, opts]
  );
  const items = useRecoilValue(itemsForQuery(hashed));

  useAsyncEffect(async () => {
    if (opts?.fetch !== false) {
      await getOptimizedForFilter({ type, scope: workspaceId }, filter, opts);
    }
  }, [hashed.toJSON()]); // Cached by hashable

  return items as EntityForType<T>[];
};

export const useLazyRelation = (relation: Maybe<RelationRef>) => {
  return (
    useLazyEntity(isInflated(relation) ? undefined : relation?.id, false) ||
    relation
  );
};

export function useCreateEntity<
  T extends EntityType,
  E extends EntityForType<T>
>(type: T, scope: string, pageId?: ID, temp?: boolean) {
  const workspace = useActiveWorkspaceId();
  const me = useCurrentUser();
  const source = useMemo(() => {
    // TODO: Potentially can remove after making scope = location (not have workspace in it)
    if (scope === workspace && type !== "team") {
      return { type, scope: toScope(me.id), source: Integration.Traction };
    }
    return { type, scope: scope, source: Integration.Traction };
  }, [type, scope, workspace, me.id]);
  const queueUpdate = useQueueUpdates<E>(pageId);
  const props = useLazyProperties(source);
  const getAdditionalUpdates = useWillUpdateWorkflows();
  const defaultStatus = useDefaultStatus(
    source?.type,
    source?.scope || workspace
  );

  return useCallback(
    (
      changes: PropertyMutation<E>[],
      transaction?: ID
    ): Ref & { source: DatabaseID } => {
      const id =
        find(changes, (c) => c.field === "id")?.value?.text ||
        asLocal(newHumanId(type));

      const getChange = maybeLookup(changes, (c) => c.field as string);
      const getOverride = (field: string) => {
        const res = getChange(field);
        return !!res && isEmptyRef(res) ? undefined : res;
      };

      const update: Update<E> = {
        id: id,
        method: "create",
        source: source,
        ...when(transaction, () => ({ transaction })),
        ...ifDo(temp, () => ({ mode: "temp" })),

        changes: maybeMap(
          uniqBy([...props, ...changes], (p) =>
            // Treat title and name as same field
            ["title", "name"]?.includes(p.field as string)
              ? "name"
              : ["owner", "assigned"]?.includes(p.field as string)
              ? // Treat owner and assigned as same field
                "assigned"
              : (p.field as string)
          ),
          (p) => {
            const override = getOverride(p.field as string);

            return switchEnum(p.field as string, {
              // Don't process ID fields here, used above
              id: () => undefined,

              // Default status if not passed in
              status: () =>
                override ||
                asMutation({ field: "status", type: "status" }, defaultStatus),

              // Generate new code if not passed in
              code: () =>
                override ||
                asMutation({ field: "code", type: "text" }, newADDDID()),

              // Default location incase not set
              location: () =>
                override ||
                asMutation(
                  { field: "location", type: "text" },
                  toLocation(source.scope)
                ),

              // Allow passing in either name|title and the correct value being set
              name: () =>
                asMutation(
                  p as PropertyRef,
                  (getOverride("name") || getOverride("title"))?.value.text ||
                    ""
                ) as PropertyMutation<E>,
              title: () =>
                asMutation(
                  p as PropertyRef,
                  (getOverride("name") || getOverride("title"))?.value.text ||
                    ""
                ) as PropertyMutation<E>,

              // Allow passing in either owner|assigned and the correct value being set
              assigned: () =>
                asMutation(
                  p as PropertyRef,
                  (getOverride("assigned") || getOverride("owner"))?.value
                    .relation
                ) as PropertyMutation<E>,
              owner: () =>
                asMutation(
                  p as PropertyRef,
                  (getOverride("assigned") || getOverride("owner"))?.value
                    .relation
                ) as PropertyMutation<E>,

              else: () => override,
            });
          }
        ),
      };

      const entity = flattenUpdate(update);

      const additional = getAdditionalUpdates(
        entity as Entity,
        update as Update<Entity>
      );

      const mergable = filter(
        additional,
        (a) =>
          ["create", "update"]?.includes(a.method) &&
          // Don't allow merging in add/remove ops into create since they will conflict with sets
          every(safeAs<CreateOrUpdate>(a)?.changes, (c) => c.op === "set")
      );

      // Merge in additional changes from workflows that can be merged
      // so it goes through in the create update
      queueUpdate(
        setDirty(update, "changes", [
          ...update.changes,
          ...reduce(
            mergable,
            (r, a) => [...r, ...(safeAs<CreateOrUpdate<E>>(a)?.changes || [])],
            [] as PropertyMutation<E>[]
          ),
        ])
      );

      // Save remaining updates separately
      map(without(additional, ...mergable), queueUpdate);

      return { id, source };
    },
    [workspace, queueUpdate, defaultStatus, props, source]
  );
}

export function useCreateFromObject<T extends EntityType>(
  type: T,
  scope?: string,
  pageId?: ID,
  temp?: boolean
) {
  const workspaceId = useActiveWorkspaceId();
  const source = useMemo(
    (): DatabaseID<T> => ({
      type: type,
      scope: scope || toScope(workspaceId),
      source: Integration.Traction,
    }),
    [type, scope, workspaceId]
  );
  const createCore = useCreateEntity(type, source.scope, pageId, temp);
  const props = useLazyProperties(source);

  const create = useCallback(
    (ts: Partial<EntityForType<T>>[], transaction: string = newID()) => {
      const orders = whenTruthy(
        ts.length > 1 && hasOrders(ts[0]) && (first(ts) as Partial<HasOrders>),
        (first) => {
          const start = Number(toOrder(first?.orders, "default")) || 1;
          const end = DecimalOrdering.bump(start);
          return evenlyBetween([start, end], ts.length, DecimalOrdering);
        }
      );

      return map(ts, (t, i) =>
        createCore(
          maybeMap(props, (p) => {
            // Filter out empty changes
            const val = getPropertyValue(t, p);

            if (p.field === "orders" && !!orders) {
              return asMutation(p, toNewOrders("default", orders[i]));
            }

            return !isEmptyValue(val?.[p.type])
              ? toMutation(t, p, val?.[p.type])
              : undefined;
          }),
          transaction
        )
      );
    },
    [createCore, props]
  );

  // Hack for now: when not really fetched there is often 1 prop (status)
  // Matches hack inside useLazyProps
  return props?.length > 1 ? create : undefined;
}

// Queues updates but also runs all change workflows before
export function useQueueUpdates<T extends Entity>(
  pageId?: ID,
  skipWorkflows?: boolean
) {
  const setPage = useSetRecoilState(AppPageAtom(pageId || ""));
  const getAdditionalUpdates = useWillUpdateWorkflows<T>();

  return useCallback(
    (update: OneOrMany<Update<T>>) => {
      const updates = reduce(
        ensureMany(update),
        (res, u) => {
          const entity = fallback(
            () => getItem(getRecoil(getStore(u.source.type)), u.id) as Maybe<T>,

            () =>
              u.source.type === "view"
                ? (getRecoil(ViewAtom(u.id)) as T)
                : undefined,

            () => (u.method === "create" ? (flattenUpdate(u) as T) : undefined)
          );
          // Populate the prev value on all changes
          const update =
            entity && u.method !== "create" ? withPrevValues(entity, u) : u;

          // Don't queue empty updates
          if (update.method === "update" && !update.changes?.length) {
            return res;
          }

          // Add to core updates
          pushDirty(res.core, update);

          if (!entity) {
            debug(
              "Warning: Skipping update triggers as entity is not present."
            );
          }

          if (entity && !skipWorkflows) {
            const additional = getAdditionalUpdates(entity, update);
            pushDirty(
              res.additional,
              ...map(additional || [], (u) => withPrevValues(entity, u))
            );
          }

          return res;
        },
        { core: [] as Update<T>[], additional: [] as Update<T>[] }
      );

      const all = [...updates.core, ...updates.additional];
      const byStore = groupBy(
        all,
        (u) => u?.source.type || maybeTypeFromId(u.id)
      );

      // Add all updates to their respective stores.
      map(byStore, (updates, type) => {
        const Store = when(type as EntityType, getStore) as Maybe<
          RecoilState<StoreState<T>>
        >;

        if (!Store) {
          throw new Error(`Store unknown for update (${type}).`);
        }
        setRecoil(Store, queueUpdates(updates));
      });

      // Add all updates to the history stack
      setPage(addToStack(all));

      return all;
    },
    [setPage]
  );
}

export function useUpdateEntity(id: ID, pageId?: ID, temp?: boolean) {
  const entity = useLazyEntity(id);
  const mutate = useQueueUpdates(pageId);

  return useCallback(
    <T extends Entity = Entity>(changes: OneOrMany<PropertyMutation<T>>) => {
      return entity
        ? mutate(
            !temp
              ? asUpdate<Entity>(
                  entity,
                  ensureMany(changes) as PropertyMutation<Entity>[]
                )
              : asTempUpdate(
                  entity,
                  ensureMany(changes) as PropertyMutation<Entity>[]
                )
          )
        : undefined;
    },
    [entity?.id, entity?.source]
  );
}

export function useUpdateFromObject(source: DatabaseID, pageId?: ID) {
  const mutate = useQueueUpdates(pageId);
  const props = useLazyProperties(source);

  return useCallback(
    <T extends Entity = Entity>(item: Ref, changesObj: Partial<T>) => {
      const changes = maybeMap(props, (p) => {
        const mut = toMutation(changesObj as T, p);
        return !isEmptyRef(mut) ? mut : undefined;
      });

      return mutate({
        id: item.id,
        source: source,
        method: "update",
        changes: changes as PropertyMutation<Entity>[],
      });
    },
    [props]
  );
}

export function useRetryUpdate<T extends Entity = Entity>() {
  return useCallback((update: Update<T>) => {
    const Store = when(
      update.source.type || typeFromId(update?.id),
      getStore
    ) as Maybe<RecoilState<StoreState<T>>>;

    if (!Store) {
      return;
    }

    setRecoil(Store, retryUpdate(update));
  }, []);
}

export function useDiscardUpdate<T extends Entity = Entity>() {
  return useCallback((update: Update<T>) => {
    const Store = when(
      update.source.type || typeFromId(update?.id),
      getStore
    ) as Maybe<RecoilState<StoreState<T>>>;

    if (!Store) {
      return;
    }

    setRecoil(Store, discardUpdate(update));
  }, []);
}

export function useMarkAsSeen(entityId: string, pageId?: string) {
  const entity = useLazyEntity(entityId);
  const me = useCurrentUser();
  const mutate = useQueueUpdates(pageId);

  useAddToRecents(entityId);

  useAsyncEffect(async () => {
    // Not already marked as seen
    const seenBy = (entity as Maybe<Note>)?.refs?.seenBy || [];
    if (
      entity?.source.scope &&
      !["schedule", "workspace", "person", "team"]?.includes(
        entity?.source.type
      ) &&
      !!(me && entity && seenBy) &&
      !find(seenBy, (e) => e.id === me?.id)
    ) {
      mutate([
        asUpdate(entity, [
          asAppendMutation({ field: "refs.seenBy", type: "relations" }, [
            toRef(me),
          ]),
        ]),
      ]);
    }
  }, [entity?.id, me?.id]);
}

export const useLogout = () => {
  return useCallback(() => {
    storage().clear();
    // Hard refresh to clear all state
    window.location.href = "/auth/login";
  }, []);
};

export function useDeleteEntitys(pageId?: ID) {
  const save = useQueueUpdates(pageId);
  const getItem = useGetItemFromAnyStore();

  return useCallback(
    async (ids: ID[]) => {
      const transaction = newID();
      map(ids, (id) =>
        when(getItem(id), (entity) => save(asDeleteUpdate(entity)))
      );
    },
    [save, getItem]
  );
}

export function useDefaultStatus(
  type: Maybe<EntityType>,
  scope: Maybe<string>
) {
  const baseScope = useMemo(() => toBaseScope(scope || ""), [scope]);
  const hierarchy = useMemo(() => fromScope(scope), [scope]);
  const getItem = useGetItemFromAnyStore();
  const parents = useMemo(() => map(hierarchy, getItem), [hierarchy, getItem]);
  const props = useRecoilValue(allStatusesForTeam(baseScope));
  const getStatus = useMemo(
    () =>
      maybeLookup(
        flatMap(props, (p) => p.values.status),
        (s) => s?.id
      ),
    [props]
  );
  const { status: statuses } = useLazyPropertyValues(
    type && scope ? { type, scope } : undefined,
    when(type, isStatusable) ? { field: "status", type: "status" } : undefined
  );

  return useMemo(() => {
    const parentPreference = maybeMap(parents, (p) =>
      hasStatus(p)
        ? getStatus(p.status?.id || "none")?.group
        : ["roadmap", "backlog"]?.includes(p?.source.type || "")
        ? "planning"
        : undefined
    );

    if (!statuses?.length) {
      // Status is not supported on this entity
      return undefined;
    }

    const desiredGroup = fallback(
      // If there is a parent status then go of planning/not-planning
      () =>
        when(last(parentPreference), (group) =>
          group === "planning" ? "planning" : "not-started"
        ),

      // Else if the parrent isn't statusable then default to not-started
      () => "not-started"
    );

    return (
      // Try find ideal status
      find(statuses, (s) => s.group === desiredGroup) ||
      // Fallback to the first not-started
      find(statuses, (s) => s.group === "not-started") ||
      // Fallback to the first planning
      find(statuses, (s) => s.group === "planning") ||
      // Fallback to whatever we have lol
      first(statuses) ||
      ({ id: "TODO", name: "To do" } as Status)
    );
  }, [parents, props]);
}

const toNestedTypes = (props: PropertyDef[], type?: EntityType): EntityType[] =>
  uniq([
    ...flatMap(props, (p) =>
      (!type || isPropForEntity(p, type)) &&
      isAnyRelation(p) &&
      p.options?.hierarchy === "child"
        ? ensureMany(p.options?.references)
        : []
    ),
    "page",
    "view",
    "note",
    "resource",
  ]);

const getAllNestedEntities = async (
  entity: Entity,
  allProps: PropertyDef[]
): Promise<EntityMap> => {
  const childTypes = toNestedTypes(allProps);
  const result: EntityMap = {};

  await mapAll(childTypes, async <T extends EntityType>(type: T) => {
    const items = await getItemsNestedWithin(entity.id, {
      type: type as EntityType,
      scope: entity.source.scope,
    });
    // Only add if not empty
    if (items.length) {
      // @ts-ignore –– cant get lining up
      result[type] = items;
    }
  });

  return result;
};

export const useNestedEntities = (entity: Maybe<Entity>) => {
  const [children, setChildren] = useState<EntityMap>();
  const loading = useMemo(
    () => !!entity?.id && !children,
    [entity?.id, children]
  );
  const props = useLazyProperties(entity?.source);

  useEffect(() => {
    if (!entity) {
      return;
    }

    setChildren(undefined);
  }, [entity?.id]);

  useAsyncEffect(async () => {
    if (!entity) {
      return;
    }

    setChildren((await getAllNestedEntities(entity, props)) || {});
  }, [entity?.id, props]);

  return useMemo(() => ({ children, loading }), [children, loading]);
};

export const useNestedEntitiesOfType = <T extends EntityType>(
  entity: Maybe<Entity>,
  childType: T
) => {
  const [children, setChildren] = useState<EntityForType<T>[]>();
  const [loading, setLoading] = useState(false);

  useAsyncEffect(async () => {
    if (!entity) {
      return;
    }

    setLoading(true);

    setChildren(
      (await getItemsNestedWithin(entity.id, {
        type: childType,
        scope: entity.source.scope,
      })) as EntityForType<T>[]
    );
    setLoading(false);
  }, [entity?.id]);

  return useMemo(() => ({ children, loading }), [children, loading]);
};

export const useManyNestedEntities = (parents: Maybe<Entity[]>) => {
  const [children, setChildren] = useState<EntityMap>();
  const [loading, setLoading] = useState(false);
  const props = useLazyProperties(parents?.[0]?.source);
  const propsStore = useRecoilValue(PropertyDefStoreAtom);

  useAsyncEffect(async () => {
    // Props not really loaded yet
    if (props.length <= 1) {
      return;
    }

    setLoading(true);

    // Fetch nested children for every entity
    const nestedMaps = await mapAll(parents || [], async (parent) => {
      const props = maybeValues(propsStore.lookup, (p) =>
        isPropInScope(p, parent?.source)
      );
      return getAllNestedEntities(parent, props);
    });
    // Merge them all into one massive entity map
    const combinedMap = reduce(
      nestedMaps,
      (comb, m) => {
        return reduce(
          m,
          (c, v, k) =>
            loSet(c, k, [...(c[k as EntityType] || []), ...(v as Entity[])]),
          comb
        );
      },
      {} as EntityMap
    );

    setChildren(combinedMap);
    setLoading(false);
  }, [parents, props]);

  return useMemo(() => ({ children, loading }), [children, loading]);
};

export const useDuplicate = (
  destination: Maybe<DatabaseID>,
  overrides?: EntityOverrides,
  nestedOverrides?: EntityOverrides
) => {
  const propsStore = useRecoilValue(PropertyDefStoreAtom);
  const mutate = useMutate();
  const [duplicating, setDuplicating] = useState(false);

  const duplicate = useCallback(
    (entity: Entity, nested: Maybe<EntityMap>, opts?: CreateTemplateOpts) => {
      if (!destination) {
        throw new Error("No destination provided for duplication.");
      }

      // Warning: not breaking these out into separate variables as it causes typescript to hang indefinetly
      const finalOverrides = merge(overrides, opts?.overrides);
      const finalNestedOverrides = merge<Partial<Entity>>(
        nestedOverrides,
        opts?.nestedOverrides
      );
      const nestedDefaults = (opts?.nestedDefaults || {}) as Partial<Entity>;

      setDuplicating(true);

      // TODO: Shouldn't be relying on all properties to already be fetched...
      const props = groupByMany(
        maybeValues(propsStore.lookup),
        (l) => l?.entity
      );

      const parent = merge(
        // Don't duplcate the createdBy field
        omit(entity, "createdBy"),
        finalOverrides,
        { source: destination }
      ) as Entity;

      const children = map(
        flatten(maybeValues(nested as Dictionary<Entity[]>)),
        (v) =>
          merge(
            // Warning: not breaking these out into separate variables as it causes typescript to hang indefinetly
            nestedDefaults,
            // Don't duplicate the createdBy field
            omit(
              omitEmptyVals<Entity, keyof Entity>(v) as Partial<Entity>,
              "createdBy"
            ),
            finalNestedOverrides,
            {
              source: {
                type: v.source.type,
                scope: toNestedLocation(
                  v.source.scope,
                  entity.id,
                  destination.scope
                ),
              },
            }
          ) as Entity
      );

      const allEntities = [
        // Add the entity itself with defaults and the new destination source
        parent,

        // Add all nested entities with defaults and the new destination source
        ...children,
      ];

      // Generate new local IDs for all entities
      const newIds = reduce(
        allEntities,
        (acc, e) => set(acc, { [e.id]: newLocalHumanId(e.source?.type) }),
        {} as Record<ID, ID>
      );

      const transactions = {
        create: newID(),
        update: newID(),
      };

      // TODO: Abstract away this complexity. Would be nice to be able to process a bunch of creates that
      // inter-reference each other but not have the saving blocked...
      const updates = flatMap(allEntities, (e): Update<Entity>[] => {
        // First create all changes for this entity and replace any references
        // of duplicated IDs with new IDs
        const changes = replaceRelationRefs(
          maybeMap(props[e.source?.type], (p) => {
            if (!p) {
              return;
            }

            // Don't copy aliases as then they break views... however this means that when using view templates
            // the alias wont' be copied over, which I think is correct as aliases have IDs in them...
            if (p.field === "alias") {
              return;
            }

            // Change title of passed in entity to include (copy) at end
            if (e.id === entity.id && equalsAny(p?.field, ["name", "title"])) {
              const baseName =
                toTitleOrName(finalOverrides as Entity) || toTitleOrName(e);

              const name =
                opts?.appendName !== false
                  ? baseName +
                    (isString(opts?.appendName) ? opts?.appendName : " (copy)")
                  : baseName;

              return asMutation(p, name);
            }

            if (p.field === "code") {
              return asMutation(
                p,
                (e.id === entity.id
                  ? (finalOverrides as Partial<Maybe<HasCode>>)?.code
                  : undefined) || newADDDID()
              );
            }

            // Locations determined up above when overriding the source
            if (p.field === "location") {
              return asMutation(
                { field: "location", type: "text" },
                e.source.scope
              );
            }

            // Use defaults passed in for all values
            const mutation = toMutation(e, p);
            const val = mutation?.value[mutation.type];
            // Filter out empty values
            return (!isArray(val) ? isDefined(val) : !isEmpty(val))
              ? mutation
              : undefined;
          }),
          newIds
        );

        // Separate updates into two transactions, first the create with any safe fields
        // then an update with any local (unpersisted) fields
        const { create, update } = groupBy(changes, (c) =>
          c.field === "location" || !mutReferencesLocalIDs(c, {})
            ? "create"
            : "update"
        );

        return omitEmpty([
          create && {
            id: newIds[e.id],
            method: "create",
            source: e.source,
            transaction: transactions.create,
            changes: create,
          },

          update && {
            id: newIds[e.id],
            method: "update",
            changes: update,
            transaction: transactions.update,
            source: e.source,
          },
        ]);
      });

      // Queue all updates individually so they don't get added to the same store...
      mutate(updates);

      setDuplicating(false);

      return map(allEntities, (e) => ({ id: newIds[e.id], source: e.source }));
    },
    [propsStore, overrides, nestedOverrides]
  );

  return useMemo(
    () => ({ duplicate, ready: !!destination, duplicating }),
    [duplicate, duplicating]
  );
};

export interface CreateTemplateOpts {
  appendName?: boolean | string;
  overrides?: EntityOverrides;
  nestedDefaults?: EntityOverrides;
  nestedOverrides?: EntityOverrides;
}

// TODO: Need a new pattern for this kind of stuff...
// Lol this is gross. So the way that we store things in recoil and then pass back the Ref rather than the Entity
// means that people who try to lookup the ref using won't get it yet. I think this may be just an issue with useAllStores, not sure...
export const useCreateFromTemplate = (
  source: Maybe<DatabaseID>,
  onCreated?: Fn<Ref, void>,
  opts?: CreateTemplateOpts
) => {
  const runOnce = useRef<boolean>();
  const [runFor, setRunFor] = useState<{ id: ID; opts?: CreateTemplateOpts }>();
  const [loading, setLoading] = useState(false);
  const [completedId, setCompletedId] = useState<ID>();
  const completed = useRecoilValue(GenericItem(completedId || ""));

  // Fetch template an all nested entities
  const template = useLazyEntity(runFor?.id);
  const nested = useNestedEntities(template);

  const { duplicate, ready: readyToDuplicate } = useDuplicate(source);

  const create = useCallback(
    (template: Ref, opts2?: CreateTemplateOpts) => {
      setLoading(true);

      const parentOverrides = merge<Partial<Entity>>(
        opts?.overrides,
        opts2?.overrides,
        { template: undefined }
      );

      setRunFor({
        id: template.id,
        opts: {
          overrides: parentOverrides,
          appendName: opts?.appendName ?? opts2?.appendName ?? false,
          nestedDefaults: merge<Partial<Entity>>(
            // Hack at the moment to assign all unassigned nested work when assigning the parent
            // TODO: Do some sort of property placeholder or role solution for defining people in templates
            when(
              (parentOverrides as Partial<HasAssigned>).assigned ||
                (parentOverrides as Partial<HasOwner>).owner,
              (r) => ({ assigned: r })
            ),
            opts?.nestedDefaults,
            opts2?.nestedDefaults
          ),
          nestedOverrides: merge<Partial<Entity>>(
            opts?.nestedOverrides,
            opts2?.nestedOverrides,
            { template: undefined }
          ),
        } as CreateTemplateOpts,
      });
    },
    [setRunFor, opts]
  );

  // Wait for hooks to have fetched the nested children and template
  useAsyncEffect(async () => {
    if (runFor && template && !nested.loading) {
      // Reset for below usage...
      runOnce.current = false;

      const [saved] = duplicate(template, nested.children, runFor.opts);
      setRunFor(undefined);
      setCompletedId(saved.id);
    }
  }, [template, runFor, nested.loading]);

  // Wait for completed to be in the recoil stores before calling the onLink which will look it up by ID
  // and need to update it...
  useEffect(() => {
    if (!runOnce.current && completed && !isLocalID(completed.id)) {
      runOnce.current = true;
      setCompletedId(undefined);
      setLoading(false);
      onCreated?.(completed);
    }
  }, [completed]);

  // Requires an onCreated callback to be passed in
  return useMemo(
    () => ({
      create: readyToDuplicate ? create : undefined,
      ready: readyToDuplicate && !nested.loading,
      loading: loading || nested.loading,
      template: template,
    }),
    [create, loading, readyToDuplicate, nested.loading]
  );
};

export type SearchOptions = FetchOptions & {
  debounce?: number; // Debounce time for search
  max?: number; // Max time that debounces can occure for
  empty?: boolean; // Whether to return results when query string is empty
};

export const useSearch = (
  type: EntityType,
  scope: string = "",
  options?: SearchOptions
) => {
  const [localQuery, _setLocalQuery] = useState("");
  const [apiQuery, apiQueryRef, _setApiQuery] = useRefState<string>("");
  const [loading, setLoading] = useState(false);

  const setLocalQuery = useDebouncedCallback(_setLocalQuery, 80, {
    maxWait: options?.debounce || 100,
  });

  const setApiQuery = useDebouncedCallback(
    _setApiQuery,
    options?.debounce || 400,
    { maxWait: options?.max ?? (options?.debounce || 400) * 2 }
  );

  const localResults = useRecoilValue(
    searchStore(
      !!localQuery || options?.empty !== false
        ? hashable({
            type,
            query: localQuery,
            limit: options?.limit || 20,
            scope,
            ...options,
          })
        : undefined
    )
  );

  useAsyncEffect(async () => {
    if (!localQuery?.trim()) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const matches = await getEntitiesForSearch(type, localQuery, {
      limit: (options?.limit || 5) * 2,
      templates: options?.templates ?? false,
      archived: false,
      ...options,
    });
    if (apiQueryRef.current === localQuery) {
      setRecoil(getStore(type), setItems(matches));
    }
    setLoading(false);
  }, [apiQuery]);

  return useMemo(
    () => ({
      query: localQuery,
      loading,
      setQuery: (q: string) => {
        setLoading(!!q);
        setApiQuery(q);
        setLocalQuery(q);
      },
      results: localResults,
    }),
    [localQuery, loading, localResults]
  );
};

const isTeamOrPerson = or(equals("team"), equals("person"));

// Returns close by (local) results when no search query is present
export const useSmartSearch = <R = Entity>(
  type: EntityType,
  scope?: string,
  opts?: SearchOptions & { toOption?: Fn<Entity, R> }
) => {
  const wID = useActiveWorkspaceId();
  const fullSearchScope = useMemo(
    () => (isTeamOrPerson(type) ? wID : toBaseScope(scope || wID)),
    [scope]
  );
  const nearbySearchScope = useMemo(
    () => (isTeamOrPerson(type) ? wID : scope),
    [scope]
  );

  // Full search never runs on empty, with base scope
  const { query, setQuery, loading, results } = useSearch(
    type,
    fullSearchScope,
    opts
  );
  // Nearby search only runs on empty, with original scope, looking for items close to this
  const { results: nearby } = useSearch(type, nearbySearchScope, {
    ...opts,
    empty: opts?.empty,
    archived: false,
    limit: (opts?.limit || 5) * 2,
  });

  const final = useMemo(
    () =>
      !!query?.trim() || !nearby?.length
        ? results
        : orderBy(nearby, (n) =>
            hasOrders(n) ? toOrder(n.orders, "default") : nearby.length
          ),
    [query, nearby, results]
  );

  return useMemo(
    () => ({
      query,
      loading,
      onSearch: setQuery,
      options: map(
        take(final, opts?.limit || final.length),
        (v) => (opts?.toOption || ((i) => i))(v) as R
      ),
    }),
    [query, opts?.toOption, setQuery, results, nearby, loading]
  );
};

export function useReorderItems<T extends EntityType>(
  type: T,
  pageId?: ID,
  scope: string = "default",
  canReorder?: Fn<[DragRef<Entity>[], DragToRef<Entity>], boolean>
) {
  const mutate = useQueueUpdates(pageId);

  return useCallback(
    (source: DragRef<Entity>[], to: DragToRef<Entity>) => {
      // Useful for warnings etc.
      if (canReorder && !canReorder([source, to])) {
        return;
      }

      const store = getRecoil(getStore(type));
      const transaction = newID();
      const [id, id2] = when(to.entity, ensureMany) || [];

      const target = when(id, (id) => getItem(store, id)) as Maybe<Entity>;
      const target2 = when(id2, (id) => getItem(store, id)) as Maybe<Entity>;
      const items = map(source, (d) => getItem(store, d.entity)) as Entity[];

      const current = when((target as HasOrders)?.orders, (o) =>
        Number(toOrder(o, scope) || 0)
      );

      // No reordering, just setting groups
      if (!isDefined(current) && !!to.groups?.length) {
        return mutate(
          flatMap(items, (item): Update<Entity>[] => {
            if (!item) {
              return [];
            }

            if (!item) {
              debug("Can't find entity to reorder.");
              return [];
            }

            return [toGroupUpdate(item, to.groups || [], transaction)];
          })
        );
      } else if (!isDefined(current)) {
        debug("No known location for drop.");
        return [];
      }

      const next =
        when((target2 as HasOrders)?.orders, (o) =>
          Number(toOrder(o, scope))
        ) ?? DecimalOrdering.bump(current);

      const ordered = switchEnum(to.position, {
        before: () => [...items, target || target2],
        after: () => [target2 || target, ...items],
        between: () => [target, ...items, target2],
      });

      const newOrders = evenlyBetween(
        [current, next],
        source?.length,
        DecimalOrdering
      );

      const updates = maybeMap(ordered, (item, i) => {
        if (!item) {
          debug("Can't find entity to reorder.");
          return undefined;
        }

        // Get new order from calculated orders
        const newOrder = newOrders[i];

        // When not reorderable, just mutate applty the group values
        if (
          !hasOrders(item) ||
          (!!target && !hasOrders(target)) ||
          (!!target2 && !hasOrders(target2))
        ) {
          return toGroupUpdate(item, to.groups || [], transaction);
        }

        // Do nothing if order hasn't changed
        if (String(newOrder) === toOrder(item.orders, scope)) {
          return undefined;
        }

        // Update the order and the group values
        return addChanges(
          toGroupUpdate(item, to.groups || [], transaction),
          toMutation<Entity, "json">(
            item,
            { field: "orders", type: "json" },
            setOrders(toOrders(item.orders), scope, newOrder)
          )
        );
      });

      mutate([
        // Change the to entities if their order changed
        ...(target && isDefined(current) && current !== newOrders[0]
          ? [
              asUpdate(
                target,
                asMutation(
                  { field: "orders", type: "json" },
                  setOrders(
                    toOrders((target as HasOrders).orders),
                    scope,
                    newOrders[0]
                  )
                ),
                transaction
              ),
            ]
          : []),

        // Change the from entities if their order changed
        ...(target2 &&
        !!newOrders.length &&
        isDefined(next) &&
        next !== newOrders[0]
          ? [
              asUpdate(
                target2,
                asMutation(
                  { field: "orders", type: "json" },
                  setOrders(
                    toOrders((target as HasOrders).orders),
                    scope,
                    last(newOrders) ?? 1
                  )
                ),
                transaction
              ),
            ]
          : []),

        // Updates calculated above
        ...updates,
      ]);
    },
    [mutate, scope]
  );
}

export const useLocalChanges = <T extends Entity>(
  id: ID,
  atom: RecoilState<StoreState<T>>
) => {
  const [store, setStore] = useRecoilState(atom);
  const changes = useMemo(
    () =>
      filter(
        store.unsaved,
        (m) =>
          m.mode === "temp" &&
          [
            id,
            stableID(id, store.aliases),
            persistedID(id, store.aliases),
          ]?.includes(m.id)
      ),
    [id, store.unsaved]
  );

  const save = useCallback(
    (additional: Update<T>[] = []) => {
      const merged = mergeUpdates([...changes, ...additional]);
      merged && setStore(queueUpdate({ ...merged, mode: undefined }));
      setStore(clearTempUpdates(id));
      return merged;
    },
    [id, changes]
  );

  const rollback = useCallback(async () => {
    setStore(composel(removeItem<T>(id), clearTempUpdates<T>(id)));

    // Reload latest from api immediately if not a local entity
    if (!isLocalID(id) && !isTemplateId(id)) {
      await getEntityLoader(id, now(), (e) => setStore(setItem<T>(e as T)));
    }
  }, [id]);

  return useMemo(
    () => ({ changes, save, rollback }),
    [id, changes, save, rollback]
  );
};

export function useDetatchRelation(pageId?: string) {
  const update = useQueueUpdates(pageId);

  return useCallback(
    (
      entity: Entity,
      relation: PropertyDef<Entity, "relation" | "relations">,
      related: Entity
    ) => {
      if (relation.type === "relation") {
        // Remove the relation
        update(asUpdate(entity, [asMutation(relation, undefined)]));
      } else {
        update(
          asUpdate(entity, [
            asAppendMutation(
              relation as PropertyDef<Entity, "relations">,
              [related],
              "remove"
            ),
          ])
        );
      }
    },
    [pageId]
  );
}

export const useRollbackTempChanges = () => {
  return useCallback((id: ID) => {
    const Store = when(typeFromId<EntityType>(id), (type) => getStore(type));

    if (!Store) {
      return;
    }

    setRecoil(Store, composel(removeItem(id), clearTempUpdates(id)));
  }, []);
};

export const useSaveTempChanges = (
  pageId?: string,
  skipWorkflows?: boolean
) => {
  const save = useQueueUpdates(pageId, skipWorkflows);
  return useCallback((id: ID) => {
    const Store = when(typeFromId<EntityType>(id), (type) => getStore(type));

    if (!Store) {
      return;
    }

    const store = getRecoil(Store);
    const changes = filter(store.unsaved, (m) => m.id === id);
    const merged = mergeUpdates<Entity>(changes);

    if (!merged) {
      return;
    }

    // Save as a single update
    save({ ...merged, mode: undefined });
    // Clear the store of temp changes
    setRecoil(Store, clearTempUpdates(id));
  }, []);
};

export function usePersistedId(id: ID) {
  const type = useMemo(() => maybeTypeFromId<EntityType>(id), [id]);
  const store = useStore(type || "task");
  return useMemo(
    () => (id ? persistedID(id, store.aliases) : undefined),
    [id, store.aliases]
  );
}
