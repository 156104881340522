import Head from "next/head";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilEnv, RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as RadixTooltip from "@radix-ui/react-tooltip";

import { getEnvVar } from "@utils/env";

import NoSSR from "@ui/no-ssr";
import AuthedRoutes from "@ui/page/authed-routes";
import GoogleConnect from "@ui/page/google-connect";
import LoginPage from "@ui/page/login-page";
import NotionConnect from "@ui/page/notion-connect";
import SlackConnect from "@ui/page/slack-connect";
import WithParams from "@ui/with-params";
import { RecoilDebugger } from "@ui/debug-observer";
import { DebugDialogObserver } from "@ui/debug-dialog";

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

export default function Home() {
  return (
    <NoSSR>
      <GoogleOAuthProvider clientId={getEnvVar("GOOGLE_CLIENT_ID")}>
        <DndProvider backend={HTML5Backend}>
          <RadixTooltip.Provider>
            <BrowserRouter>
              <RecoilRoot>
                <RecoilNexus />
                <RecoilDebugger />
                <DebugDialogObserver />
                <Toaster />

                <Head>
                  <meta name="description" content="" />
                  <meta
                    name="viewport"
                    content="width=device-width, height=device-height, initial-scale=1, user-scalable=no"
                  />
                </Head>

                <Routes>
                  <Route path="auth">
                    <Route
                      path="slack-response"
                      element={
                        <WithParams
                          params={({ code, error }) => ({ code, error })}
                          component={SlackConnect}
                        />
                      }
                    />
                    <Route path="login" element={<LoginPage />} />
                    <Route
                      path="notion"
                      element={
                        <WithParams
                          params={({ code, error }) => ({
                            code: code,
                            error: error,
                          })}
                          component={NotionConnect}
                        />
                      }
                    />
                    <Route
                      path="ngoogleotion"
                      element={
                        <WithParams
                          params={({ code, error }) => ({
                            code: code,
                            error: error,
                          })}
                          component={GoogleConnect}
                        />
                      }
                    />
                  </Route>
                  <Route path="*" element={<AuthedRoutes />} />
                </Routes>
              </RecoilRoot>
            </BrowserRouter>
          </RadixTooltip.Provider>
        </DndProvider>
      </GoogleOAuthProvider>
    </NoSSR>
  );
}
