import { Root, Thumb } from "@radix-ui/react-switch";

import { Fn } from "@utils/fn";
import { cx } from "@utils/class-names";

import { HStack } from "@ui/flex";
import { Label } from "@ui/label";
import { Text } from "@ui/text";

import styles from "./switch.module.css";

interface Props {
  checked: boolean;
  onChange: Fn<boolean, void>;
  size?: "small" | "default" | "large";
  subtle?: boolean;
  label?: string;
  labelPosition?: "before" | "after";
}

export const Switch = ({
  checked,
  onChange,
  subtle,
  size = "default",
  label,
  labelPosition = "after",
}: Props) => (
  <HStack gap={6} className={cx(labelPosition === "before" && styles.reverse)}>
    <Root
      id={label}
      className={cx(styles.root, styles[size])}
      defaultChecked={checked}
      onCheckedChange={onChange}
    >
      <Thumb className={styles.thumb} />
    </Root>

    {!!label && (
      <Label subtle={subtle} htmlFor={label}>
        {label}
      </Label>
    )}
  </HStack>
);
