import { find, filter, map, some, isArray } from "lodash";
import { useCallback, useMemo } from "react";

import {
  Entity,
  HasArchivedAt,
  HasCode,
  HasStatus,
  PropertyDef,
  PropertyMutation,
  Status,
  hasCode,
  hasLocation,
} from "@api";

import {
  useLazyEntities,
  useLazyEntity,
  useQueueUpdates,
} from "@state/generic";
import { useLazyProperties } from "@state/databases";
import { useCurrentUser } from "@state/workspace";
import { useActionWorkflows, useRunWorkflowState } from "@state/workflows";

import { lowerSentenceCase } from "@utils/string";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import { isEditableProp, toRef } from "@utils/property-refs";
import { Maybe, maybeMap } from "@utils/maybe";
import { useShowMore } from "@utils/hooks";
import { extractTeam } from "@utils/scope";

import { CommandGroup, CommandItem, CommandSubItem } from "@ui/command-menu";
import { copyCodes, copyMarkdown, copyLinks } from "@ui/clipboard";
import {
  Archive,
  BoltAlt,
  CopyIcon,
  EllipsisH,
  EyeSlash,
  Home,
  LinkAlt,
  MarkdownIcon,
  NumberIcon,
  Pin,
  PinSlash,
  Restore,
  Save,
  StatusConvert,
  TrashAlt,
} from "@ui/icon";
import { Text } from "@ui/text";
import { StatusIcon } from "@ui/status-button";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { WorkflowCollectDialog } from "@ui/workflow-collect-dialog";

import { AppCommandsProps } from "./types";
import { useIsSearching } from "./utils";
import { useMe } from "@state/persons";
import { toLabel } from "@state/teams";

export const EntityCommands = ({
  page,
  actingOn,
  onEdit,
  ...rest
}: AppCommandsProps) => {
  const pageId = page?.id;
  const me = useCurrentUser();
  const items = useLazyEntities(actingOn || []);
  const allProps = useLazyProperties(items?.[0]?.source);
  const type = useMemo(() => items?.[0]?.source?.type, [items?.[0]]);
  const persist = useQueueUpdates(pageId);
  const locationProp = useMemo(
    () => find(allProps, (p) => p.field === "location"),
    [allProps]
  );

  const mutateMany = useCallback(
    (changes: PropertyMutation<Entity>[]) => {
      persist(map(items, (i) => asUpdate(i, changes)));
    },
    [items, persist]
  );

  const onDelete = useCallback(() => {
    onEdit("delete");
  }, []);

  if (!actingOn?.length) {
    return <></>;
  }

  return (
    <>
      <CommandGroup label="Copy">
        <CommandItem
          icon={LinkAlt}
          value={`copy ${type} link`}
          onClick={() => copyLinks(items || [])}
        >
          Copy link
        </CommandItem>

        {items?.[0] && hasCode(items?.[0]) && (
          <CommandItem
            icon={NumberIcon}
            value={`copy ${type} code`}
            onClick={() => copyCodes((items as Maybe<HasCode[]>) || [])}
          >
            Copy code
          </CommandItem>
        )}

        {
          <CommandItem
            icon={MarkdownIcon}
            value={`copy ${type} markdown list`}
            onClick={() => copyMarkdown(items || [])}
          >
            Copy markdown
          </CommandItem>
        }
      </CommandGroup>

      <CommandGroup label="Modify">
        <EditPropertyCommands
          page={page}
          actingOn={actingOn}
          onEdit={onEdit}
          {...rest}
        />
      </CommandGroup>

      <CommandGroup label="Actions">
        {actingOn?.length === 1 && (
          <WorkflowCommands
            page={page}
            actingOn={actingOn}
            onEdit={onEdit}
            {...rest}
          />
        )}

        {items?.length === 1 && (
          <CommandItem
            icon={CopyIcon}
            value={`duplicate ${type}`}
            onClick={() => onEdit("duplicate")}
            onSelectAction="clear"
          >
            Duplicate
          </CommandItem>
        )}

        {items?.length === 1 && (
          <CommandItem
            icon={Save}
            value={`save as template ${type}`}
            onClick={() => onEdit("save_template")}
            onSelectAction="clear"
          >
            Save as template
          </CommandItem>
        )}

        {some(items, (i) => !!(i as HasArchivedAt).archivedAt) && (
          <CommandItem
            icon={Restore}
            value={`restore ${type}`}
            onSelectAction="clear"
            onClick={() => onEdit("restore")}
          >
            Restore
          </CommandItem>
        )}

        <PinCommands
          page={page}
          actingOn={actingOn}
          onEdit={onEdit}
          {...rest}
        />

        {locationProp && (
          <CommandItem
            icon={EyeSlash}
            value={`make ${type} private`}
            onSelectAction={"close"}
            onClick={() =>
              mutateMany([
                asMutation({ field: "location", type: "text" }, me.id),
              ])
            }
          >
            Make private
          </CommandItem>
        )}

        {locationProp && (
          <CommandItem
            icon={Home}
            value={`move ${type} to new location`}
            onSelectAction={"clear"}
            onClick={() => onEdit("move_location")}
          >
            Move to new location
          </CommandItem>
        )}

        {type === "action" && (
          <CommandItem
            icon={StatusConvert}
            value={`convert work to other type`}
            onSelectAction={"clear"}
            onClick={() => onEdit("convert_work")}
          >
            Convert to...
          </CommandItem>
        )}

        {some(items, (i) => !(i as HasArchivedAt).archivedAt) && (
          <CommandItem
            icon={Archive}
            value={`archive ${type}`}
            onSelectAction="clear"
            onClick={() => onEdit("archive")}
          >
            Archive
          </CommandItem>
        )}
      </CommandGroup>

      <CommandGroup label="Destructive">
        <CommandItem
          icon={TrashAlt}
          value={`delete ${type}`}
          onClick={onDelete}
          onSelectAction="clear"
        >
          Delete
        </CommandItem>
      </CommandGroup>
    </>
  );
};

// Commands for pinning/unpinning to team
export const PinCommands = ({ page, actingOn }: AppCommandsProps) => {
  const pageId = page?.id;
  const me = useMe();
  const items = useLazyEntities(actingOn || []);
  const mutate = useQueueUpdates(pageId);
  const teamId = useMemo(
    () =>
      find(
        maybeMap(items, (i) =>
          hasLocation(i) ? extractTeam(i.location) : undefined
        )
      ),
    [items]
  );
  const team = useLazyEntity<"team">(teamId || "");
  const pinnedState = useMemo(() => {
    const ids = map(items, (i) => i.id);
    const teamPins = maybeMap(team?.refs?.pins, (p) =>
      ids?.includes(p.id) ? p.id : undefined
    );
    const myPins = maybeMap(me?.refs?.pins, (p) =>
      ids?.includes(p.id) ? p.id : undefined
    );

    if (myPins?.length) {
      return "me";
    }

    if (teamPins?.length) {
      return "team";
    }

    return undefined;
  }, [items, team]);

  const handlePin = useCallback(
    (entity: Entity) =>
      mutate(
        asUpdate(
          entity,
          asAppendMutation(
            { field: "refs.pins", type: "relations" },
            map(items, (i) => toRef(i))
          )
        )
      ),
    [items, mutate]
  );

  const handleUnpin = useCallback(
    (entity: Entity) =>
      mutate(
        asUpdate(
          entity,
          asAppendMutation(
            { field: "refs.pins", type: "relations" },
            map(items, (i) => toRef(i)),
            "remove"
          )
        )
      ),
    [items, mutate]
  );

  if (!actingOn?.length || !team) {
    return <></>;
  }

  return (
    <>
      {pinnedState !== "team" && (
        <CommandItem
          icon={Pin}
          value="pin to team"
          onClick={() => handlePin(team)}
        >
          Pin to {toLabel(team)}
        </CommandItem>
      )}

      {pinnedState === "team" && (
        <CommandItem
          icon={PinSlash}
          value="unpin from team"
          onClick={() => handleUnpin(team)}
        >
          Unpin from {toLabel(team)}
        </CommandItem>
      )}

      {pinnedState !== "me" && (
        <CommandItem icon={Pin} value="pin to me" onClick={() => handlePin(me)}>
          Pin to me
        </CommandItem>
      )}

      {pinnedState === "me" && (
        <CommandItem
          icon={PinSlash}
          value="unpin from me"
          onClick={() => handleUnpin(me)}
        >
          Unpin from me
        </CommandItem>
      )}
    </>
  );
};

// Commands for pinning/unpinning to team
export const WorkflowCommands = ({ actingOn }: AppCommandsProps) => {
  const entity = useLazyEntity(actingOn?.[0]?.id || "");

  const [workflowActions, actionData] = useActionWorkflows(entity);
  const { run, collecting, cancelCollecting, context } = useRunWorkflowState(
    entity?.source.type || "task",
    actionData
  );

  const primaryAction = useMemo(
    () => find(workflowActions, (a) => !a.collect || isArray(a.collect)),
    [workflowActions]
  );

  if (!entity || !actingOn?.length || !context) {
    return <></>;
  }

  return (
    <>
      {collecting && actionData && (
        <WorkflowCollectDialog
          action={collecting}
          source={entity.source}
          context={context}
          data={actionData}
          onCollected={run}
          onCancel={cancelCollecting}
        />
      )}

      {primaryAction && (
        <CommandItem
          icon={primaryAction.icon || BoltAlt}
          value={`run ${primaryAction.title}`}
          onSelectAction={primaryAction?.collect ? "none" : "close"}
          onClick={() => run(primaryAction)}
        >
          {primaryAction.title}
        </CommandItem>
      )}
    </>
  );
};

// Commands for editing properties
export const EditPropertyCommands = ({
  page,
  actingOn,
  onEdit,
  limit,
}: AppCommandsProps & { limit?: number }) => {
  const pageId = page?.id;
  const items = useLazyEntities(actingOn || []);
  const persist = useQueueUpdates(pageId);
  const allProps = useLazyProperties(items?.[0]?.source);
  const type = useMemo(() => items?.[0]?.source?.type, [items?.[0]]);
  const searching = useIsSearching();
  const allStatuses = find(allProps, (p) => p.field === "status")?.values
    ?.status;
  const editableProps = useMemo(
    () => filter(allProps, (p) => isEditableProp(p)),
    [allProps]
  );
  const { visible, showMore, hasMore } = useShowMore(
    editableProps,
    limit ?? 5,
    !!searching
  );

  const mutateMany = useCallback(
    (changes: PropertyMutation<Entity>[]) => {
      persist(map(items, (i) => asUpdate(i, changes)));
    },
    [items, persist]
  );

  if (!actingOn?.length) {
    return <></>;
  }

  return (
    <>
      {map(allStatuses, (s: Status) => (
        <CommandSubItem
          key={s.name}
          icon={<StatusIcon status={s} />}
          always={
            s.group === "done" &&
            (items?.[0] as Maybe<HasStatus>)?.status?.name === "Ready"
          }
          value={`${lowerSentenceCase(s.name)} status`}
          onClick={() =>
            mutateMany([asMutation({ field: "status", type: "status" }, s)])
          }
        >
          <Text subtle>Mark </Text>
          <strong>{lowerSentenceCase(s.name)}</strong>
          <Text subtle> status</Text>
        </CommandSubItem>
      ))}

      {map(visible, (prop) => (
        <CommandItem
          key={prop.field}
          icon={<PropertyTypeIcon {...prop} />}
          value={`${type} set ${prop.label || prop.field}`}
          onSelectAction="clear"
          onClick={() => onEdit(prop as PropertyDef<Entity>)}
        >
          {prop.label}
        </CommandItem>
      ))}

      {hasMore && (
        <CommandItem
          icon={EllipsisH}
          value={`show all fields`}
          onSelectAction="none"
          onClick={showMore}
        >
          More fields...
        </CommandItem>
      )}
    </>
  );
};
