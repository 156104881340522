import { ReactNode } from "react";
import styles from "./on-hover.module.css";
import { cx } from "@utils/class-names";

export const Trigger = ({ children }: { children: ReactNode }) => {
  return <div className={styles.parent}>{children}</div>;
};

export const Target = ({
  children,
  show,
}: {
  show?: boolean;
  children: ReactNode;
}) => {
  return (
    <div className={cx(styles.target, show && styles.show)}>{children}</div>
  );
};

export const OnHover = { Trigger, Target };
