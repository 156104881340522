import { Outcome } from "@api";
import { ViewEngine } from "../types";
import { OutcomeListItem } from "./list-item";
import { OutcomeListCard } from "./list-card";
import { OutcomeMenuItem } from "./menu-item";

export const OutcomeEngine: ViewEngine<Outcome> = {
  asMenuItem: OutcomeMenuItem,
  asListItem: OutcomeListItem,
  asListCard: OutcomeListCard,
};
