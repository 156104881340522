import { ReactNode, useMemo } from "react";
import { Color } from "@api";
import { toColorVar, toInverseColorVar } from "@utils/color";
import { Maybe } from "@utils/maybe";

import styles from "./text-highlight.module.css";

interface Props {
  children: ReactNode;
  color: Maybe<Color>;
}

export const TextHighlight = ({ children, color }: Props) => (
  <span
    className={styles.container}
    style={useMemo(
      () => (color ? { color: toInverseColorVar(color) } : {}),
      [color]
    )}
  >
    <span
      style={useMemo(
        () => (color ? { background: toColorVar(color) } : {}),
        [color]
      )}
      className={styles.highlight}
    />
    {children}
  </span>
);
