import { ReactNode } from "react";
import { Entity, ID, isOutcome, isTask } from "@api";

import { useMarkAsSeen } from "@state/generic";

import { TaskHeader } from "@ui/task-header";
import { EntityProperties } from "@ui/entity-properties";
import { VStack } from "@ui/flex";
import { Container } from "@ui/container";
import { Sheet, SheetProps } from "@ui/sheet-layout";
import { useAppPageContext } from "@ui/app-page";
import { TemplateBanner } from "@ui/template-banner";

interface Props {
  id: ID;
  item: Entity;
  size?: SheetProps["size"];
  actions?: ReactNode;
  className?: string;
}

export default function TaskPane({
  id: taskId,
  item: task,
  size,
  actions,
  className,
}: Props) {
  const pageId = useAppPageContext();

  if (!!task && !isTask(task) && !isOutcome(task)) {
    throw new Error("TaskPane only supports tasks and outcomes");
  }

  // Mark the note as seen by current user
  useMarkAsSeen(taskId, pageId);

  if (!task) {
    return <></>;
  }

  return (
    <Sheet height="container" size={size || "primary"} className={className}>
      {task.template && <TemplateBanner />}

      <TaskHeader id={taskId} actions={actions} />

      <VStack gap={0} fit="container">
        <Container
          stack="vertical"
          gap={20}
          fit="container"
          style={{ paddingTop: "10px" }}
        >
          <EntityProperties entityId={taskId} />
        </Container>
      </VStack>
    </Sheet>
  );
}
