import { useDebouncedCallback } from "use-debounce";
import { map } from "lodash";
import { useCommandState } from "cmdk";
import { useRef, useState } from "react";

import { Page, searchPages } from "@api/integrations/notion";

import { useAsyncEffect } from "@utils/effects";
import { Maybe, when } from "@utils/maybe";
import { toIcon, toNotionUrl, toTitle } from "@utils/notion";
import { useGoTo } from "@utils/navigation";

import { Text } from "@ui/text";
import { CommandGroup, CommandItem } from "@ui/command-menu";
import { Notion, PageIcon, SpinnerIcon } from "@ui/icon";
import { Label } from "@ui/label";

import { SearchCommandsProps } from "../types";
import { useCommandSearch } from "../utils";

export const NotionSearchCommands = ({
  onSelect,
  searching: _searching,
}: SearchCommandsProps) => {
  const goTo = useGoTo();
  const search = useCommandSearch();
  const lastSearch = useRef("");
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(_searching || false);
  const [pages, setPages] = useState<Maybe<Page[]>>();

  const doSearch = useDebouncedCallback(
    async (search: string) => {
      lastSearch.current = search;
      const pages = await searchPages(search);
      if (lastSearch.current === search) {
        setPages(pages);
        setLoading(false);
      }
    },
    600,
    { trailing: true }
  );

  useAsyncEffect(async () => {
    if (loading || search === lastSearch.current || !searching) {
      return;
    }

    if (!search || search.length < 2) {
      lastSearch.current = "";
      if (pages?.length) {
        setPages(undefined);
      }
    } else {
      setLoading(true);
      doSearch(search);
    }
  }, [search, searching, loading]);

  if (!searching || !search) {
    return <></>;
  }

  if (!searching) {
    return (
      <CommandGroup label="Notion" loading={loading}>
        <CommandItem
          value="skip: notion"
          icon={Notion}
          onClick={() => setSearching(true)}
          onSelectAction="none"
        >
          Seach notion
        </CommandItem>
      </CommandGroup>
    );
  }

  return (
    <CommandGroup label="Notion">
      {loading && (
        <CommandItem value="skip: notion searching">
          <Label icon={SpinnerIcon} subtle>
            Searching...
          </Label>
        </CommandItem>
      )}

      {!loading && !pages?.length && (
        <CommandItem value="skip: notion no results">
          <Text subtle>No results</Text>
        </CommandItem>
      )}

      {!loading &&
        map(pages || [], (p) => (
          <CommandItem
            key={p.id}
            // Tells the command menu to skip filtering
            value={`skip ${p.id}`}
            icon={when(toIcon(p), (i) => <PageIcon icon={i} />) || Notion}
            onClick={() =>
              onSelect
                ? onSelect({
                    text: toTitle(p),
                    url: toNotionUrl(p.id),
                    icon: toIcon(p),
                  })
                : when(toNotionUrl(p.id), goTo)
            }
          >
            {toTitle(p)}
          </CommandItem>
        ))}
    </CommandGroup>
  );
};
