import { useEffect } from "react";

import { useGoTo } from "@utils/navigation";

interface Props {
  to: string;
}

export const Redirect = ({ to }: Props) => {
  const redirect = useGoTo();

  // Todo using { replace: true } caused url to not update...
  useEffect(() => redirect(to), []);

  return <></>;
};
