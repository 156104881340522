import { EntityType, Team, TeamVisibility } from "@api";
import { now } from "@utils/date-fp";
import { newLocalHumanId } from "@utils/id";
import { omitEmpty } from "@utils/object";
import { toLocation } from "@utils/scope";
import { withParams } from "@utils/url";

export const newTeam = (t: Partial<Team> & Pick<Team, "source">): Team => ({
  id: newLocalHumanId("team"),

  notionId: undefined,
  name: "",
  visibility: TeamVisibility.Open,
  icon: undefined,
  color: undefined,
  owner: undefined,
  parent: undefined,
  subTeams: [],
  people: [],
  views: [],

  refs: {},
  custom: undefined,
  stamps: undefined,
  settings: undefined,
  location: toLocation(t.source.scope),

  createdAt: now(),
  updatedAt: now(),
  fetchedAt: undefined,
  createdBy: undefined,
  updatedBy: undefined,
  ...t,
});

export const toLabel = (t: Team) =>
  t.name?.toLowerCase()?.includes("team") || t?.name?.length > 20
    ? t.name
    : `${t.name} Team`;

export const toManageFields = (
  id: string,
  entity: EntityType,
  field?: string
) => withParams(`/${id}/settings/fields`, omitEmpty({ type: entity, field }));

export const toManagePackages = (id: string) => `/${id}/settings/packages`;
