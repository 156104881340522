import {
  DatabaseID,
  EntityType,
  ID,
  PropertyDef,
  PropertyMutation,
  Ref,
  View,
} from "@api";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isToday,
  isWeekend,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import {
  each,
  findLastIndex,
  groupBy,
  map,
  reduce,
  slice,
  times,
} from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import { Entity } from "@api";

import {
  toDateFields,
  useDefaultsForView,
  useDropInView,
  useLazyGetView,
  useLazyItemsForView,
} from "@state/views";

import {
  SelectionState,
  SetSelectionState,
  isSelected,
  usePageSelection,
  useSelectable,
} from "@utils/selectable";
import { useCurrentPage } from "@ui/app-page";
import { Container } from "@ui/container";
import { now } from "@utils/now";

import { useLazyPropertyDef } from "@state/databases";
import { useActiveWorkspaceId } from "@state/workspace";
import { useEntitySource, useQueueUpdates } from "@state/generic";

import { cx } from "@utils/class-names";
import { differenceInDays } from "@utils/date";
import { isValidDoubleClick } from "@utils/event";
import { useShowMore, useStickyState } from "@utils/hooks";
import { switchEnum } from "@utils/logic";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { OneOrMany, ensureMany, groupByMany, whenEmpty } from "@utils/array";
import { GroupedItems } from "@utils/grouping";
import { asUpdate } from "@utils/property-mutations";
import { toLocation } from "@utils/scope";
import { getPropertyValue, toPropertyValueRef } from "@utils/property-refs";
import {
  CalDate,
  DateMode,
  DirtyDate,
  fromISO,
  fromPointDate,
  ISODate,
  toISODate,
  useCalDate,
  useISODate,
} from "@utils/date-fp";
import { Fn } from "@utils/fn";

import { Button } from "@ui/button";
import { DropHighlight } from "@ui/drop-highlight";
import { ListItemOpts, ViewEngine, getEngine, render } from "@ui/engine";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { AngleLeftIcon, AngleRightIcon, CounterIcon, PlusIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { ListItem } from "@ui/list-item";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import {
  OnReorder,
  useItemDrag,
  useItemDragDrop,
  useItemDrop,
} from "@ui/entity-drag-drop";
import { AddWorkActionMenu } from "@ui/add-work-dialog";
import { Heading, Text } from "@ui/text";
import { useSuggestedProps } from "@ui/suggested-props";

import styles from "./calendar.module.css";

type CalFields = [PropertyDef, PropertyDef] | [PropertyDef];

interface CalendarProps {
  id: ID;
}

interface InnerView {
  view: View;
  items: Entity[];
  visible: [Date, Date];
  fields: CalFields;
  dateMode: DateMode;
  source: DatabaseID;
  onReorder: OnReorder<Entity>;
}

type MonthlyDayCellProps = {
  fields: CalFields;
  dateMode: DateMode;
  source: DatabaseID;
  type: EntityType;
  date: ISODate;
  spanning: Maybe<Entity>[];
  normal: Entity[];
  group: CalendarGroup;
  prev?: CalendarGroup;
  engine: ViewEngine<Entity>;
  defaults?: Partial<Entity>;
  format: "list" | "card";
  hideEmpty?: boolean;
  props: View["showProps"];
  subtle?: boolean;
  selection: SelectionState;
  setSelection: SetSelectionState;
  onSaved?: Fn<OneOrMany<Ref>, void>;
  onReorder: OnReorder<Entity>;
};

type DayItemProps = ListItemOpts<Entity> & {
  format: "list" | "card";
  fields: CalFields;
  props: View["showProps"];
  engine: ViewEngine<Entity>;
  onReorder: OnReorder<Entity>;
};

type CalendarGroup = GroupedItems<Entity> & {
  spanning: Maybe<Entity>[];
  normal: Entity[];
};

const toDateKey = (date: ISODate) =>
  useCalDate(date, (d) => format(d, "yyyy-MM-dd"));

export const CalendarLayout = ({ id }: CalendarProps) => {
  const pageId = useCurrentPage();
  const view = useLazyGetView(id);
  const [period] = useState<"month" | "week" | "day">("month");
  const [visibleDate, setVisibleDate] = useStickyState<Date>(
    startOfDay(now()),
    `calendar-${id}-visible`,
    (v) => fromPointDate(String(v))
  );
  const { items } = useLazyItemsForView(id);
  const itemSource = useEntitySource(view?.entity || "task", view?.source);
  const fieldRefs = useMemo(() => {
    const dates = view && toDateFields(view);
    return !!dates?.[0] ? dates : undefined;
  }, [view?.settings]);
  const startField = useLazyPropertyDef(itemSource, fieldRefs?.[0]);
  const endField = useLazyPropertyDef(itemSource, fieldRefs?.[1]);
  const fields = useMemo(
    () => (startField ? ([startField, endField] as CalFields) : undefined),
    [startField, endField]
  );

  const dateMode = useMemo(
    () => startField?.options?.mode || "calendar",
    [startField?.options?.mode]
  );

  const onReorder = useDropInView(view, pageId);

  const visibleRange = useMemo(() => {
    const nextStart = switchEnum(period, {
      month: () => startOfMonth(visibleDate),
      week: () => startOfWeek(visibleDate),
      day: () => startOfDay(visibleDate),
    });
    const nextEnd = switchEnum(period, {
      month: () => endOfMonth(nextStart),
      week: () => endOfWeek(nextStart),
      day: () => endOfDay(nextStart),
    });
    return [nextStart, nextEnd] as [Date, Date];
  }, [visibleDate]);

  const setVisibleForDay = useCallback(
    (day: Date) => setVisibleDate(day),
    [period]
  );

  const handleOnToday = useCallback(
    () => setVisibleForDay(now()),
    [setVisibleForDay]
  );

  const handleOnPrevious = useCallback(
    () =>
      setVisibleForDay(
        switchEnum(period, {
          month: () => subMonths(visibleDate, 1),
          week: () => subWeeks(visibleDate, 1),
          day: () => subDays(visibleDate, 1),
        })
      ),
    [setVisibleForDay, period, visibleRange]
  );

  const handleOnNext = useCallback(
    () =>
      setVisibleForDay(
        switchEnum(period, {
          month: () => addMonths(visibleDate, 1),
          week: () => addWeeks(visibleDate, 1),
          day: () => addDays(visibleDate, 1),
        })
      ),
    [period, visibleRange]
  );

  if (!visibleRange || !view) {
    return <></>;
  }

  return (
    <Container padding="none" className={styles.container}>
      <Container>
        <SpaceBetween>
          <HStack gap={4}>
            <Heading bold>{format(visibleRange[0], "MMMM")}</Heading>
            <Heading bold subtle>
              {format(visibleRange[0], "yyyy")}
            </Heading>
          </HStack>

          <HStack gap={0}>
            <Button subtle icon={AngleLeftIcon} onClick={handleOnPrevious} />
            <Button subtle onClick={handleOnToday}>
              Today
            </Button>
            <Button subtle icon={AngleRightIcon} onClick={handleOnNext} />
          </HStack>
        </SpaceBetween>
      </Container>

      <MonthView
        view={view}
        items={items.sorted || items.all}
        fields={fields as CalFields}
        dateMode={dateMode}
        visible={visibleRange}
        source={itemSource}
        onReorder={onReorder}
      />
    </Container>
  );
};

const toGroup = (date: ISODate, prop: PropertyDef): CalendarGroup => ({
  def: prop,
  spanning: [],
  normal: [],
  value: {
    field: prop.field,
    type: "date",
    value: { date: date },
  },
  items: [],
});

const toDates = (i: Entity, fields: CalFields) => {
  const start = toPropertyValueRef(i, fields[0])?.value.date;
  const end = when(fields[1], (p) => toPropertyValueRef(i, p))?.value.date;

  if (!start || !end) {
    return [start || end, end || start];
  }

  return [start, end];
};

const MonthView = ({
  view,
  source,
  items,
  dateMode,
  fields: dateFields,
  visible: [start, end],
  onReorder,
}: InnerView) => {
  const pushTo = usePushTo();
  const engine = useMemo(() => getEngine(source.type), [source]);
  const [selection, setSelection] = usePageSelection();
  const suggestedProps = useSuggestedProps();
  const showProps = whenEmpty(view?.showProps, suggestedProps || []);
  const showEmpty = useMemo(
    () =>
      !getPropertyValue(view, {
        field: "settings.triageEmpty",
        type: "boolean",
      })?.boolean,
    [view.settings]
  );
  const toDate = useCallback(
    (d: DirtyDate) => toISODate(d, dateMode),
    [dateMode]
  );
  const showWeekends = useMemo(
    () => view?.settings?.weekends ?? false,
    [view.settings]
  );
  const headers = useMemo(
    () =>
      showWeekends
        ? ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
        : ["MON", "TUE", "WED", "THU", "FRI"],
    [showWeekends]
  );
  const viewDefaults = useDefaultsForView(view.id);

  // Generate the days for the month, as ISOCalDate
  const isoDays = useMemo(() => {
    // Get the days from the previous and next month
    const daysFromLastMonth = getDay(start);
    const lastMonthDays = times(daysFromLastMonth, (n) =>
      subDays(start, daysFromLastMonth - n)
    );
    const daysFromNextMonth = 6 - getDay(end);
    const nextMonthDays = times(
      daysFromNextMonth,
      (n) => new Date(end.getFullYear(), end.getMonth(), end.getDate() + n + 1)
    );

    const thisMonthDays = times(
      end.getDate(),
      (n) => new Date(start.getFullYear(), start.getMonth(), n + 1)
    );
    return map([...lastMonthDays, ...thisMonthDays, ...nextMonthDays], (d) =>
      toISODate(d, "calendar")
    );
  }, [start, end]);

  // Group the days into rows
  // We want to remove the first and last day if we don't want to show weekends
  // as well as the last day if it's a weekend
  const rows = useMemo(
    () =>
      times(Math.ceil(isoDays?.length / 7), (n) =>
        isoDays.slice(
          n * 7 + (view?.settings?.weekends ? 0 : 1),
          n * 7 + (view?.settings?.weekends ? 7 : 6)
        )
      ),
    [isoDays, view?.settings?.weekends]
  );

  const groupedItems = useMemo(() => {
    if (!dateFields) {
      return {};
    }

    const today = toISODate(startOfDay(now()), dateMode);

    const itemsByDay = groupByMany(items, (i) => {
      const defaultt = showEmpty ? today : undefined;
      const [start = defaultt, end = defaultt] = toDates(i, dateFields);

      if (!start || !end) {
        return "";
      }

      const startDirty = fromISO(start);
      const endDirty = fromISO(end);
      const daySpan = differenceInDays(startDirty, endDirty);
      const isSpanning = daySpan > 0;

      return isSpanning
        ? times(daySpan + 1, (n) =>
            toDateKey(toISODate(addDays(startDirty, n), dateMode))
          )
        : toDateKey(start);
    });

    // For each day we want to group items by spanning and normal
    return reduce(
      isoDays,
      (res, calDay: CalDate) => {
        if (!dateFields) {
          return res;
        }

        const key = toDateKey(calDay);

        // Groups are constructed based on read
        const group =
          res[key] ||
          // Groups always store CalDates
          toGroup(
            // dateMode === "point" ? convertToPointDate(calDay, "local") : calDay,
            calDay,
            dateFields[0]
          );
        const items = itemsByDay[key] || [];

        const { spanning, normal } = groupBy(items, (i) => {
          const [start = today, end = today] = toDates(i, dateFields);
          const daySpan = differenceInDays(fromISO(start), fromISO(end));
          return daySpan > 0 ? "spanning" : "normal";
        });

        // Modify the group
        group.items = items;
        group.normal = normal || [];
        group.spanning = spanning || [];
        res[key] = group;

        return res;
      },
      {} as Record<string, CalendarGroup>
    );
  }, [isoDays, items, dateFields]);

  return (
    <div className={styles.monthContainer}>
      <SpaceBetween>
        {map(headers, (day) => (
          <Text key={day} className={styles.dayOfWeek} bold subtle>
            {day}
          </Text>
        ))}
      </SpaceBetween>

      {map(rows, (row, i) => {
        let prevSpanning: Maybe<Entity>[] = [];

        return (
          <HStack
            key={`${i}-row`}
            gap={0}
            className={styles.monthRow}
            align="stretch"
            fit="container"
          >
            {map(row, (day, i) => {
              if (!dateFields) {
                return <></>;
              }

              const group =
                groupedItems[toDateKey(day)] ||
                // Groups always store CalDates
                toGroup(
                  // dateMode === "point" ? convertToPointDate(day, "local") : day,
                  day,
                  dateFields[0]
                );
              let spanning: Maybe<Entity>[] = new Array(
                Math.max(prevSpanning?.length || 0, group.spanning.length)
              ).fill(undefined);

              // Fill today's spanning by using the previous day's spanning location
              // else the first empty spot
              each(group.spanning, (item) => {
                const lastPos = prevSpanning.indexOf(item);
                spanning[lastPos >= 0 ? lastPos : spanning.indexOf(undefined)] =
                  item;
              });

              // Trim trailing undefined so that we don't have empty spaces at the end
              spanning = slice(
                spanning,
                0,
                findLastIndex(spanning, Boolean) + 1
              );

              // Store trailing spanning for next day
              prevSpanning = spanning;

              return (
                <MonthDayCell
                  key={`${day}-cell`}
                  fields={dateFields}
                  dateMode={dateMode}
                  source={view.source}
                  type={view.entity}
                  format="card"
                  hideEmpty={view.settings?.hideEmptyFields}
                  props={showProps}
                  defaults={viewDefaults}
                  selection={selection}
                  setSelection={setSelection}
                  date={day}
                  group={group}
                  prev={when(row[i - 1], (d) => groupedItems[toDateKey(d)])}
                  spanning={spanning}
                  normal={group.normal}
                  engine={engine}
                  subtle={fromISO(day).getMonth() !== start.getMonth()}
                  onReorder={onReorder}
                />
              );
            })}
          </HStack>
        );
      })}
    </div>
  );
};

const MonthDayCell = ({
  fields,
  dateMode,
  date,
  type,
  engine,
  subtle = false,
  group,
  prev,
  spanning,
  normal,
  source,
  format,
  onSaved,
  defaults,
  ...props
}: MonthlyDayCellProps) => {
  const wId = useActiveWorkspaceId();
  const pushTo = usePushTo();
  const { hasMore, visible, showMore } = useShowMore(normal || [], 5);
  const today = useMemo(() => useISODate(date, isToday), [date]);
  const weekend = useMemo(() => useISODate(date, isWeekend), [date]);
  const containerRef = useRef<HTMLDivElement>(null);
  const itemDefaults = useMemo(
    () =>
      ({
        ...defaults,
        [fields[0]?.field]: date,
        location: toLocation(source?.scope) || wId,
        source: { type: type, scope: source?.scope || wId },
      } as Partial<Entity>),
    [date, source, type]
  );
  const [{ dropping }] = useItemDrop({
    type,
    ref: containerRef,
    group: group as GroupedItems<Entity>,
    parents: undefined,
    item: undefined,
    acceptsFields: true,
  });

  return (
    <VStack
      ref={containerRef}
      className={cx(
        styles.dayCell,
        weekend && styles.weekend,
        dropping && styles.dropping
      )}
      fit="content"
      gap={0}
    >
      <SpaceBetween>
        <Text
          bold
          subtle={subtle}
          className={cx(styles.day, today && styles.today)}
        >
          {useISODate(date, (d) => d.getDate())}
        </Text>

        <AddWorkActionMenu defaults={itemDefaults} onSaved={onSaved}>
          <Button
            icon={PlusIcon}
            size="small"
            subtle
            className={styles.addButton}
          />
        </AddWorkActionMenu>
      </SpaceBetween>
      <Menu>
        <MenuGroup className={styles.dayMenuGroup}>
          <VStack fit="container" gap={format === "card" ? 2 : 0}>
            {maybeMap(spanning, (item) =>
              !item ? (
                <FixedMonthSpacerItem />
              ) : (
                <FixedMonthDayItem
                  key={item.id}
                  fields={fields}
                  first={!prev || !prev.spanning?.includes(item)}
                  spans={
                    (when(toDates(item, fields), ([_s, e]) =>
                      date && e
                        ? differenceInDays(fromISO(date), fromISO(e))
                        : 0
                    ) ?? 0) + 1
                  }
                  item={item}
                  engine={engine}
                  onOpen={pushTo}
                  group={group}
                  format={format}
                  {...props}
                />
              )
            )}
          </VStack>
        </MenuGroup>
        <MenuGroup className={styles.dayMenuGroup}>
          <VStack fit="container" gap={format === "card" ? 2 : 0}>
            {map(visible, (item) => (
              <MonthDayItem
                key={item.id}
                fields={fields}
                item={item}
                engine={engine}
                onOpen={pushTo}
                group={group}
                format={format}
                {...props}
              />
            ))}
          </VStack>

          {hasMore && (
            <MenuItem
              icon={
                <CounterIcon
                  color="subtle"
                  count={normal.length - (visible.length || 0)}
                />
              }
              onClick={showMore}
            >
              <Label subtle>Show more</Label>
            </MenuItem>
          )}
        </MenuGroup>
      </Menu>
    </VStack>
  );
};

const FixedMonthSpacerItem = () => {
  return <div className={styles.fixedSpacer}></div>;
};

const FixedMonthDayItem = ({
  first,
  spans,
  format,
  ...props
}: DayItemProps & {
  first?: boolean;
  spans: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      className={styles.fixedSpacer}
      style={
        ref?.current?.clientHeight ? { height: ref?.current?.clientHeight } : {}
      }
    >
      <div
        ref={ref}
        className={cx(styles.fixedItem, !first && styles.fixedInvisible)}
        style={{
          width: `calc(${(spans || 0) * 100}% + ${
            (format === "card" ? 2 : 0) * 2 * (spans - 1)
          }px)`,
        }}
      >
        <MonthDayItem {...props} format={format} />
      </div>
    </div>
  );
};

const MonthDayItem = (props: DayItemProps) => {
  const {
    group,
    fields,
    engine,
    item,
    format,
    selection,
    className,
    onReorder,
    onOpen,
  } = props;
  const pageId = useCurrentPage();
  const selectableProps = useSelectable(item.id);
  const queue = useQueueUpdates(pageId);

  const handleChanged = useCallback(
    (changes: OneOrMany<PropertyMutation<Entity>>) => {
      queue(asUpdate(item, ensureMany(changes)));
    },
    [item]
  );

  const ref = useRef<HTMLLIElement>(null);
  const { opacity, dropping: before } = useItemDragDrop({
    item: item,
    selection,
    group: group,
    onReorder,
    ref,
  });
  const startRef = useRef<HTMLDivElement>(null);
  const [startProps] = useItemDrag({
    item: item,
    field: fields[0]?.field,
    group: group,
    selection,
    onReorder,
    ref: startRef,
  });

  const endRef = useRef<HTMLDivElement>(null);
  const [endProps] = useItemDrag({
    item: item,
    field: fields[1]?.field,
    group: group,
    selection,
    onReorder,
    ref: endRef,
  });

  const css = useMemo(() => ({ opacity }), [opacity]);
  const selected = useMemo(
    () => !!selection && isSelected(selection, item.id),
    [selection, item.id]
  );

  // Card view
  if (format === "card") {
    return (
      <div className={cx(styles.listItem, className)}>
        {fields && (
          <div
            ref={startRef}
            style={startProps}
            className={styles.resizeIndicator}
          />
        )}
        {before && <DropHighlight />}

        {render(engine.asListCard, {
          key: item.id,
          item,
          selection: selection,
          setSelection: props.setSelection,
          showProps: props.props,
          onDelete: props.onDelete,
          variant: props.variant,
          onChange: handleChanged,
          hideEmpty: props.hideEmpty,
          group: group,
          onOpen: onOpen,
          onReorder: onReorder,
        })}

        {fields && (
          <div
            ref={endRef}
            style={endProps}
            className={styles.resizeIndicator}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {before && <DropHighlight />}
      <ListItem
        ref={ref}
        {...selectableProps}
        className={cx(styles.listItem, className)}
        selected={selected}
        style={css}
        onDoubleClick={(e) => isValidDoubleClick(e) && onOpen?.(item)}
      >
        <div
          ref={startRef}
          style={startProps}
          className={styles.resizeIndicator}
        />

        {format === "list" &&
          render(engine.asMenuItem, {
            key: item.id,
            item,
            showProps: props.props,
            className: styles.menuItem,
          })}

        {!!fields[1] && (
          <div
            ref={endRef}
            style={endProps}
            className={styles.resizeIndicator}
          />
        )}
      </ListItem>
    </>
  );
};
