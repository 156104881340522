import { differenceInMinutes } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { now } from "@utils/now";
import { toMilliSeconds } from "@utils/time";
import { usePointDate } from "@utils/date-fp";

import { allJobs, JobStoreAtom } from "./atoms";
import { getJobQueueLoader } from "./queries";
import { setJobs } from "./actions";

export function useCheckForNewJobs() {
  const [store, setStore] = useRecoilState(JobStoreAtom);
  const check = useCallback(async () => {
    await getJobQueueLoader((jobs) => setStore(setJobs(jobs)));
  }, []);

  return useMemo(() => ({ check, lastChecked: store.lastChecked }), []);
}

export function useJobQueue() {
  const jobs = useRecoilValue(allJobs);
  const { check, lastChecked } = useCheckForNewJobs();

  // Check every hour minutes if we have new jobs
  useEffect(() => {
    if (
      !lastChecked ||
      usePointDate(
        lastChecked,
        (d) => Math.abs(differenceInMinutes(d, now())) > 60
      )
    ) {
      check();
    }

    // If page transition doesn't happen for an hour, catch it with this timer
    const timeout = setTimeout(check, toMilliSeconds("1 hour"));
    return () => clearTimeout(timeout);
  }, []);

  return jobs;
}
