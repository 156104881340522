import { map } from "lodash";
import toast, { Renderable, ToastOptions } from "react-hot-toast";

import { toMilliSeconds } from "@utils/time";
import { Fn } from "@utils/fn";
import { inLocal } from "@utils/env";
import { Maybe, Primitive } from "@utils/maybe";

import { HStack, VStack } from "@ui/flex";
import { Text, TextMedium, TextSmall } from "@ui/text";
import { Button } from "@ui/button";

type NotificationOptions = ToastOptions;

export const showLog = (
  message: string,
  icon?: Renderable,
  opts?: NotificationOptions
) =>
  inLocal(() => {
    const toastID = toast(message, {
      icon: icon,
      duration: toMilliSeconds("4 seconds"),
      position: "bottom-left",
      ...opts,
    });

    return () => toast.dismiss(toastID);
  });

export const withLoading = <T extends object | Primitive>(
  promise: Promise<T>,
  {
    message,
    success,
    error,
    ...opts
  }: NotificationOptions & {
    message?: string;
    success?: string;
    error?: string;
  } = {}
): Promise<T> => {
  return toast.promise(
    promise,
    {
      success: success || "Operation completed.",
      error: error ?? "Operation failed.",
      loading: message || "Processing...",
    },
    {
      position: "bottom-right",
      ...opts,
    }
  );
};

export const showSuccess = (
  message: string,
  icon?: Renderable,
  opts?: NotificationOptions
) => {
  const toastID = toast.success(message, {
    icon: icon,
    duration: toMilliSeconds("4 seconds"),
    position: "top-center",
    ...opts,
  });

  return () => toast.dismiss(toastID);
};

export const showError = (
  message: string,
  icon?: Renderable,
  opts?: NotificationOptions
) => {
  const toastID = toast.error(message, {
    icon: icon,
    duration: toMilliSeconds("4 seconds"),
    position: "top-center",
    ...opts,
  });

  return () => toast.dismiss(toastID);
};

export const showWarning = (
  message: string,
  icon?: Renderable,
  opts?: NotificationOptions
) => {
  const toastID = toast.error(message, {
    icon: icon || "⚠️",
    duration: toMilliSeconds("4 seconds"),
    position: "top-center",
    ...opts,
  });

  return () => toast.dismiss(toastID);
};

export const showSuggestion = (
  id: string,
  message: string,
  description: Maybe<string>,
  actions: { title: string; id: string; onClick?: Fn<void, void> }[],
  icon?: Renderable,
  opts?: NotificationOptions
) => {
  const toastID = toast(
    (t) => (
      <VStack gap={6} data-selectable-ignore-clicks="true">
        <VStack gap={2}>
          <TextSmall subtle>Suggestion</TextSmall>
          <TextMedium bold>{message}</TextMedium>
          {description && <Text subtle>{description}</Text>}
        </VStack>

        <HStack fit="container" justify="flex-end" gap={2}>
          {map(actions, (a, i) => (
            <Button
              key={a.id}
              size="small"
              subtle
              variant={i === actions?.length - 1 ? "primary" : "secondary"}
              onClick={() => {
                a.onClick?.();
                toast.dismiss(t.id);
              }}
            >
              {a.title}
            </Button>
          ))}
        </HStack>
      </VStack>
    ),
    {
      id: id,
      icon: icon,
      duration: toMilliSeconds("10 seconds"),
      position: "top-right",
      style: { padding: "8px 4px" },
      ...opts,
    }
  );

  return () => toast.dismiss(toastID);
};
