import { orderBy } from "lodash";
import { useEffect, useMemo } from "react";

import { Meeting } from "@api";

import { useLazyEntities, useUpdateEntity } from "@state/generic";

import { isHumanId, isLocalID, isTemplateId, maybeTypeFromId } from "@utils/id";
import { extractTractionIds } from "@utils/link";
import { maybeMap } from "@utils/maybe";
import { toHtml } from "@utils/rich-text";
import { asAppendMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { withoutRefs } from "@utils/relation-ref";
import { equalsAny } from "@utils/logic";

const isRelatable = (id: string) =>
  !equalsAny(maybeTypeFromId(id), [
    "schedule",
    "view",
    "agenda",
    "team",
    "person",
    "workspace",
  ]);

export const useExtractRelatedWork = (entity: Meeting) => {
  const agendas = useLazyEntities<"agenda">(entity?.refs.agendas || []);
  const combinedHTML = useMemo(
    () => maybeMap(agendas, (a) => toHtml(a.body)).join(" "),
    [agendas]
  );

  return useMemo(() => {
    const allIds = extractTractionIds(combinedHTML);
    return maybeMap(allIds, (r) =>
      isHumanId(r) && isRelatable(r) && !isLocalID(r) && !isTemplateId(r)
        ? r
        : undefined
    );
  }, [combinedHTML]);
};

export const useAutoLinkRelated = (meeting: Meeting, pageId?: string) => {
  const relatedWork = useExtractRelatedWork(meeting);
  const mutate = useUpdateEntity(meeting.id, pageId);

  useEffect(() => {
    const relatedRefs = maybeMap(relatedWork, toRef);
    const newRefs = withoutRefs(relatedRefs, meeting.refs.related || []);
    mutate(
      asAppendMutation({ field: "refs.related", type: "relations" }, newRefs)
    );
  }, [relatedWork]);
};

export const useMeetingAgendas = (meeting: Meeting) => {
  const _agendas = useLazyEntities<"agenda">(meeting.refs.agendas || []);
  return useMemo(() => orderBy(_agendas, (a) => a.order), [_agendas]);
};
