import { find, map } from "lodash";

import {
  Entity,
  EntityType,
  GroupByPropDef,
  HasLocation,
  PropertyDef,
  PropertyRef,
  PropertyType,
  PropertyValueRef,
} from "@api";

import { Maybe, safeAs, when } from "./maybe";
import {
  formatLocation,
  getPropertyValue,
  isAnyRelation,
  isEmptyRef,
  toPropertyValueRef,
} from "./property-refs";
import { fromScope } from "./scope";
import { maybeTypeFromId } from "./id";
import { equalsAny } from "./logic";
import { ensureMany, OneOrMany } from "./array";
import { asMutation, asUpdate } from "./property-mutations";
import { convertToPointDate, useISODate } from "./date-fp";
import { toTime, withTime } from "./time";

export type GroupedValue<T extends Entity = Entity> = PropertyValueRef<T>;
export type GroupedGroups<T extends Entity = Entity> = {
  value: GroupedValue;
  def: GroupByPropDef<T, PropertyType>;
  groups: NestedGroup<T>[];
  hidden: NestedGroup<T>[];
};
export type GroupedItems<T extends Entity = Entity> = {
  value: GroupedValue<T>;
  def: Maybe<GroupByPropDef<T, PropertyType>>;
  items: T[];
};

export type NestedGroup<T extends Entity = Entity> =
  | GroupedItems<T>
  | GroupedGroups<T>;

export const isNested = <T extends Entity>(
  g: NestedGroup<T>
): g is GroupedGroups<T> => !!(g as GroupedGroups<T>)?.groups;

export const isGroupedItems = <T extends Entity>(
  g: NestedGroup<T>
): g is GroupedItems<T> => !!(g as GroupedItems<T>)?.items;

export const toViewingWithinScope = <T extends Entity>(
  group: NestedGroup<T>
): Maybe<string> => {
  if (!isGroupedItems(group)) {
    return undefined;
  }

  return when(group.def || group.value.def, (d) =>
    isAnyRelation(d)
      ? group.value?.value?.relation?.id ||
        group.value?.value.relations?.[0]?.id
      : d.field === "location"
      ? when(group.value, (l) =>
          formatLocation(
            l as PropertyValueRef<Entity>,
            group.def?.format || group.value.def?.format
          )
        )
      : undefined
  );
};

export const toGroupValueRef = <T extends Entity>(
  thing: T,
  group: GroupByPropDef<T, PropertyType> | PropertyDef<T> | PropertyRef<T>
): PropertyValueRef<T, PropertyType> => {
  const valueRef = toPropertyValueRef(thing, group);

  // When grouping on a relation, also look at location if the field is empty
  if (isAnyRelation(group) && isEmptyRef(valueRef)) {
    const location = safeAs<HasLocation>(thing)?.location || thing.source.scope;
    const parents = fromScope(location);
    const targetTypes = ensureMany(
      safeAs<PropertyDef<T>>(group)?.options?.references
    );
    const matching = find(parents, (pId) =>
      equalsAny(maybeTypeFromId<EntityType>(pId), targetTypes)
    );

    if (matching) {
      return {
        ...valueRef,
        value: {
          [valueRef.type]:
            valueRef.type === "relation"
              ? { id: matching }
              : [{ id: matching }],
        },
      };
    }
  }

  return valueRef;
};

export const toGroupUpdate = (
  item: Entity,
  group: OneOrMany<GroupedValue<Entity>>,
  transaction: string
) =>
  asUpdate(
    item,
    map(ensureMany(group), (group) => {
      const currentValue = getPropertyValue<Entity>(item, group);

      // Groups store dates as CalDates always, depending on property, convert to PointDate or keep as CalDate
      if (group.type === "date") {
        if (!group.value.date) {
          return asMutation(group, undefined);
        }

        const isPointProp = group.def?.options?.mode === "point";
        const date = isPointProp
          ? convertToPointDate(group.value.date, "local")
          : group.value.date;
        const current = when(currentValue.date, (d) =>
          isPointProp ? convertToPointDate(d, "utc") : d
        );

        // If there is an existing date, preserve the time value since date groups just store the date
        if (current) {
          const ogTime = useISODate(currentValue.date, (d) => d && toTime(d));
          const timePreserved = useISODate(date, (d) => withTime(d, ogTime));

          return asMutation(group, timePreserved);
        }

        return asMutation(group, date);
      }

      return asMutation(group, group.value[group.type]);
    }),
    transaction
  );
