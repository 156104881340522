import { filter } from "lodash";
import { Fragment, useMemo } from "react";
import Markdown from "react-markdown";

import { Person, Ref, hasLocation, hasNotes, hasResources } from "@api";

import { toFullName } from "@state/persons";
import { useCurrentUser } from "@state/workspace";
import { useAssistantUpdateChat } from "@state/ai";
import { useLazyEntity } from "@state/generic";

import { cx } from "@utils/class-names";
import { formatHuman } from "@utils/date";
import { Maybe, maybeMap } from "@utils/maybe";
import { now } from "@utils/now";
import { Fn } from "@utils/fn";

import { Container } from "@ui/container";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Assistant, Icon, PersonIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { MessageBox } from "@ui/message-box";
import { CopyLinkButton } from "@ui/copy-link-button";
import { Text } from "@ui/text";

import styles from "./ai-update-pane.module.css";

interface Props {
  entity: Maybe<Ref>;
  onDismiss?: Fn<void, void>;
}

export const AiUpdatePane = ({ entity: ref, onDismiss }: Props) => {
  const entity = useLazyEntity(ref?.id || "");

  if (
    !!entity &&
    (!hasNotes(entity) || !hasResources(entity) || !hasLocation(entity))
  ) {
    throw new Error(
      "AiUpdatePane only supports things with notes & resources."
    );
  }

  const me = useCurrentUser();

  const {
    messages: _messages,
    status,
    reply,
    loading,
  } = useAssistantUpdateChat(entity);
  const messages = useMemo(() => {
    const filtered = filter(_messages, (m) => m?.role !== "system");
    return !!filtered?.length
      ? filtered
      : [{ id: "loading", role: "assistant", content: "" }];
  }, [_messages]);

  return (
    <SpaceBetween
      direction="vertical"
      className={styles.pane}
      width="container"
    >
      <FillSpace
        height="content"
        width="container"
        className={styles.paneContainer}
      >
        <Container stack="vertical" height="content" width="container" gap={26}>
          {maybeMap(
            messages,
            (message, i) =>
              message?.role !== "system" && (
                <Fragment key={message?.id}>
                  {i !== messages?.length - 1 ? (
                    <Message
                      text={message?.content}
                      from={message.role === "user" ? me : undefined}
                    />
                  ) : (
                    <div>
                      <Message
                        text={message.content}
                        from={message.role === "user" ? me : undefined}
                        message={status}
                        loading={loading}
                      />
                      {!loading && (
                        <HStack>
                          <CopyLinkButton inset={true} link={message?.content}>
                            Copy to clipboard
                          </CopyLinkButton>
                        </HStack>
                      )}
                    </div>
                  )}
                </Fragment>
              )
          )}
        </Container>
      </FillSpace>

      <Container stack="vertical" gap={0} className={styles.paneActions}>
        <MessageBox
          placeholder="Reply to assistant..."
          me={me}
          onMessage={reply}
        />
      </Container>
    </SpaceBetween>
  );
};

interface MessageProps {
  text: string;
  message?: string;
  from?: Person;
  loading?: boolean;
}

const Message = ({ text, from, message, loading }: MessageProps) => {
  return (
    <VStack className={cx(styles.note)} gap={10} fit="container">
      <VStack fit="container" gap={6}>
        <SpaceBetween fit="container">
          {!from && (
            <Label
              size="medium"
              bold
              icon={<Icon size="medium" icon={Assistant} />}
            >
              Ai Assistant
            </Label>
          )}
          {!!from && (
            <Label size="medium" bold icon={<PersonIcon person={from} />}>
              {toFullName(from)}
            </Label>
          )}
          <Text subtle>{message || formatHuman(now())}</Text>
        </SpaceBetween>

        <Markdown className={cx(styles.updateBody, styles.unbound)}>
          {text}
        </Markdown>
      </VStack>
    </VStack>
  );
};
