import { CSSProperties } from "react";

import { cx } from "@utils/class-names";

import styles from "./divider.module.css";

interface Props {
  direction?: "horizontal" | "vertical";
  margin?: "none" | "on";
  label?: string;
  subtle?: boolean;
  className?: string;
  style?: CSSProperties;
}

export const Divider = ({
  label,
  direction = "horizontal",
  margin = "none",
  subtle,
  style,
  className,
}: Props) => (
  <span
    style={style}
    className={cx(
      styles.divider,
      styles[direction],
      subtle && styles.subtle,
      margin === "none" && styles.noMargin,
      className
    )}
  >
    {direction === "horizontal" && label && (
      <span className={styles.label}>{label}</span>
    )}
  </span>
);
