import { Fn } from "@utils/fn";

import { Button } from "@ui/button";
import { LinkIcon } from "@ui/icon";

interface ButtonProps {
  text: string;
  url: string;
  icon?: string;
  className?: string;
  onClick?: Fn<void, void>;
}

export { LinkIcon } from "@ui/icon";

export const LinkButton = ({
  text,
  url,
  icon,
  className,
  onClick,
}: ButtonProps) => {
  return (
    <Button
      subtle
      size="small"
      icon={icon ? <LinkIcon icon={icon} url={url} /> : undefined}
      onClick={() => onClick?.()}
      className={className}
    >
      {text}
    </Button>
  );
};
