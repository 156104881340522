import { useEffect, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { last, map } from "lodash";

import { Calendar, ID } from "@api";

import {
  AppCommandsAtom,
  useOpenAppCommands,
  usePageUndoRedo,
  useRegisterPage,
} from "@state/app";
import {
  useLazyEntity,
  useLazyQuery,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { toTemplateViewId } from "@state/views";
import { useAddToRecents } from "@state/recents";

import ViewPane from "@ui/view-pane";
import { when } from "@utils/maybe";
import { fromScope, toChildLocation } from "@utils/scope";
import { useShowMore, useStickyState } from "@utils/hooks";
import { asMutation } from "@utils/property-mutations";
import { useSyncPathnameID } from "@utils/url";
import { getSetting } from "@utils/property-refs";
import { useGoTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { PlusIcon, Slash } from "@ui/icon";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { LabelledPropertyValueList } from "@ui/property-value-tile";
import { EmojiSelect } from "@ui/select/emoji";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { PackageTag } from "@ui/package-label";
import AppPage from "./app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Divider } from "@ui/divider";
import { AddWorkButton } from "@ui/add-work-dialog";
import { Menu } from "@ui/menu";
import { MoreMenuItem } from "@ui/menu-item";
import { render, useEngine } from "@ui/engine";
import { MenuGroup } from "@ui/menu-group";
import { LabelledValue } from "@ui/property-value";
import { FollowButton } from "@ui/follow-button";

import styles from "./calendar-page.module.css";

export const CalendarPage = ({ id }: { id: ID }) => {
  const calendar = useLazyEntity<"calendar">(id);
  const [viewId, setViewId] = useStickyState(
    toTemplateViewId("calendar-content", {
      parent: id,
      entity: getSetting(calendar?.settings, "child_type"),
    }),
    `calendar-${id}-view`
  );
  const view = useLazyEntity(viewId);

  const [page] = useRegisterPage(id, calendar);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, calendar?.id);

  // Add to recents
  useAddToRecents(id);

  // When calendar has loaded, set default view
  useEffect(() => {
    if (calendar && (!viewId || !view)) {
      setViewId(
        toTemplateViewId("calendar-content", {
          parent: id,
          entity: getSetting(calendar?.settings, "child_type"),
        })
      );
    }
  }, [calendar?.id, view?.id, id]);

  if (!calendar) {
    return <></>;
  }

  return (
    <AppPage page={page}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <SideHeader id={id} calendar={calendar} pageId={page.id} />

            <Main className={styles.main}>
              <ViewPane
                className={styles.viewPane}
                viewId={viewId}
                onChangeView={(v) => setViewId(v.id)}
                showTitle={false}
              />
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

type HeaderProps = {
  id: ID;
  pageId: ID;
  calendar: Calendar;
};

const SideHeader = ({ id, pageId, calendar: item }: HeaderProps) => {
  const setAppCommands = useSetRecoilState(AppCommandsAtom);
  const mutate = useUpdateEntity(item.id, pageId);
  const goTo = useGoTo();
  const engine = useEngine("calendar");
  const nestedSource = useNestedSource(
    item,
    getSetting(item?.settings, "child_type")
  );
  const allCalendars = useLazyQuery(
    "calendar",
    useMemo(
      () => ({
        and: [
          {
            field: "location",
            type: "text",
            op: "ends_with",
            value: { text: last(fromScope(item.location)) },
          },
          {
            field: "id",
            type: "text",
            op: "does_not_equal",
            value: { text: item.id },
          },
        ],
      }),
      [item.location, item.id]
    ),
    { limit: 10, fetch: false }
  );
  const {
    visible: otherCalendars,
    hasMore,
    showMore,
  } = useShowMore(allCalendars, 3);

  const openCmdK = useOpenAppCommands(item);

  return (
    <SideNav className={styles.nav}>
      <SpaceBetween direction="vertical">
        <VStack gap={10} fit="container">
          <VStack gap={20} fit="container">
            <Container gap={10} padding="none" inset="bottom" stack="vertical">
              <SpaceBetween>
                <EmojiSelect
                  size="xlarge"
                  emoji={item.icon || "🗓️"}
                  onChange={(emoji) =>
                    mutate(asMutation({ field: "icon", type: "text" }, emoji))
                  }
                />
                <HStack gap={2}>
                  <FollowButton
                    variant="icon-only"
                    entity={item}
                    mutate={mutate}
                  />
                  <PackageTag type="calendar" scope={item.source.scope} />
                </HStack>
              </SpaceBetween>

              <EditableHeading
                key={item.id}
                text={item.name || ""}
                size="h2"
                autoFocus={!item.name}
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(asMutation({ field: "name", type: "text" }, i))
                  );
                }}
              />
            </Container>
          </VStack>

          <SpaceBetween>
            <Button
              as="button"
              fit="container"
              icon={Slash}
              subtle
              onClick={openCmdK}
            >
              Modify
            </Button>

            <AddWorkButton
              fit="container"
              icon={PlusIcon}
              subtle
              defaults={{
                refs: { calendars: [{ id: item.id }] },
                location: toChildLocation(item.location, item.id),
              }}
              allowed={[nestedSource.type]}
            >
              Add new
            </AddWorkButton>
          </SpaceBetween>

          <Container padding="vertical">
            <Divider />
          </Container>

          <LabelledPropertyValueList
            entity={item}
            onChange={mutate}
            stack="vertical"
          >
            <LabelledValue label="Organizing" fit="container">
              <Button subtle disabled inset size="small" wrapLabel={false}>
                <PackageTag {...nestedSource} disabled={true} plural={true} />
              </Button>
            </LabelledValue>
          </LabelledPropertyValueList>
        </VStack>

        {!!otherCalendars?.length && (
          <Menu>
            <MenuGroup label="Other Calendars">
              {map(otherCalendars, (cal) =>
                render(engine.asMenuItem, {
                  key: cal.id,
                  item: cal,
                  onOpen: goTo,
                })
              )}
              {hasMore && <MoreMenuItem onClick={showMore} />}
            </MenuGroup>
          </Menu>
        )}
      </SpaceBetween>
    </SideNav>
  );
};

export default CalendarPage;
