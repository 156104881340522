import { useMemo, useState } from "react";

import {
  EntityType,
  HasLocation,
  HasRefs,
  hasResources,
  HasTemplate,
  ID,
  Note,
  toTitleOrName,
} from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity, useMarkAsSeen } from "@state/generic";

import { useSyncPathnameID } from "@utils/url";
import { getRelationValue } from "@utils/property-refs";
import { toChildLocation } from "@utils/scope";
import { maybeTypeFromId, typeFromId } from "@utils/id";
import { safeAs, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";

import { useCurrentPage } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { ClockHistory, Comments, EmojiIcon, Search } from "@ui/icon";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { OverlaySheet, StackContainer } from "@ui/sheet-layout";
import { EntityMessagesPane } from "@ui/messages-pane";
import { UpdateThread } from "@ui/update-thread";
import { DiscussionThread } from "@ui/discussion-thread";
import { ResourcesPane } from "@ui/resources-pane";
import { getEngine, render } from "@ui/engine";
import { RelatedMeetingsPane } from "@ui/engine/meeting";
import {
  ScheduleInstancesPane,
  TemplateSchedulesPane,
} from "@ui/engine/schedule";
import { SearchPane } from "@ui/search-pane";

import AppPage from "./app-page";

interface Props {
  id: ID;
}

const EntityPage = ({ id }: Props) => {
  const pageId = useCurrentPage();
  const entity = useLazyEntity(id);
  const engine = useMemo(() => getEngine(typeFromId<EntityType>(id)), [id]);
  const pushTo = usePushTo();
  const [showNote, setShowNote] = useState<Note>();
  const fullLocation = useMemo(
    () =>
      when(entity, (t) => toChildLocation((t as HasLocation).location, t.id)),
    [entity?.id, (entity as HasLocation)?.location]
  );

  const [page] = useRegisterPage(id, entity);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, entity?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!entity) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!entity} title={toTitleOrName(entity)}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        {render(engine.asPrimaryPane, { id, item: entity })}

        <PaneManager size="secondary">
          <PaneItem id="search" title="Search" icon={Search}>
            <SearchPane
              parentId={entity.id}
              onOpen={(n) =>
                maybeTypeFromId(n.id) === "note"
                  ? setShowNote(n as Note)
                  : pushTo(n)
              }
            />
          </PaneItem>

          {hasResources(entity) && fullLocation && (
            <PaneItem
              id="resources"
              title="Resources"
              icon={<EmojiIcon emoji="🔗" />}
            >
              <ResourcesPane location={fullLocation} />
            </PaneItem>
          )}

          {!safeAs<HasTemplate>(entity)?.template && (
            <PaneItem
              id="messages"
              title="Messages"
              icon={<EmojiIcon emoji="💬" />}
            >
              <EntityMessagesPane
                entityId={id}
                onSelected={(n) => setShowNote(n)}
              />
            </PaneItem>
          )}

          {!safeAs<HasTemplate>(entity)?.template ? (
            when(getRelationValue(entity, "refs.repeat"), (ref) => (
              <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
                <ScheduleInstancesPane schedule={ref} instanceId={entity.id} />
              </PaneItem>
            ))
          ) : safeAs<HasRefs>(entity)?.refs?.schedules ? (
            <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
              <TemplateSchedulesPane template={entity as HasRefs} />
            </PaneItem>
          ) : (
            <></>
          )}

          {!safeAs<HasTemplate>(entity)?.template && (
            <PaneItem id="meetings" title="Meetings" icon={Comments}>
              <RelatedMeetingsPane entityId={entity.id} />
            </PaneItem>
          )}
        </PaneManager>

        {showNote && (
          <OverlaySheet
            size="secondary"
            height="container"
            onDismiss={() => setShowNote(undefined)}
          >
            {showNote?.type === "update" ? (
              <UpdateThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            ) : (
              <DiscussionThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            )}
          </OverlaySheet>
        )}
      </StackContainer>
    </AppPage>
  );
};

export default EntityPage;
