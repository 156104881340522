import { map, omit } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { Entity, EntityType, HasLocation, Ref } from "@api";

import {
  useCreateFromObject,
  useLazyEntities,
  useQueueUpdates,
} from "@state/generic";

import { Fn } from "@utils/fn";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { plural } from "@utils/string";
import { SelectionState } from "@utils/selectable";
import { toRef } from "@utils/property-refs";
import { withHandle } from "@utils/event";
import { toArray } from "@utils/set";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { useCurrentPage } from "@ui/app-page";
import { Tooltip } from "@ui/tooltip";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { FillSpace, HStack } from "@ui/flex";
import { StatusConvert } from "@ui/icon";
import { Dialog } from "@ui/dialog";
import { LocationSelect } from "@ui/select";
import { Fields } from "@ui/input";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { showError } from "@ui/notifications";
import { toBaseScope } from "@utils/scope";
import { useActiveWorkspaceId } from "@state/workspace";

interface Props {
  targets: Ref[];
  onComplete?: Fn<void, void>;
  onCancel?: Fn<void, void>;
}

export const ConvertDialog = ({ targets, onCancel, onComplete }: Props) => {
  const pageId = useCurrentPage();
  const wID = useActiveWorkspaceId();
  const entities = useLazyEntities(targets);
  const [convertTo, setConvertTo] = useState<EntityType>("task");
  const [picking, setPicking] = useState(false);
  const mutate = useQueueUpdates(pageId);
  const [location, setLocation] = useState(
    () =>
      when(safeAs<Maybe<HasLocation>>(entities?.[0])?.location, toBaseScope) ||
      wID
  );
  const create = useCreateFromObject(convertTo, location, pageId);

  const handleSubmit = useCallback(() => {
    if (!entities?.length || !location) {
      return;
    }

    if (!create) {
      showError("Not ready to create. Try again.");
    }

    // Create new things for each entity
    const created = create?.(
      map(
        entities,
        (e) => omit(e, "id", "status", "source", "location") as Partial<Entity>
      )
    );

    // Go through each entity and link it to it's created counterpart
    mutate(
      map(entities, (e, i) =>
        asUpdate(
          e,
          asMutation(
            { field: "refs.converted", type: "relation" },
            created?.[i]
          )
        )
      )
    );

    // Callback
    onComplete?.();
  }, [entities, location, convertTo, create, onComplete, mutate]);

  if (!entities?.length) {
    return <></>;
  }

  return (
    <Dialog
      title="Convert work"
      description={"Convert and organise this work."}
      onDismiss={onCancel}
      actions={
        <HStack gap={4} fit="container" justify="flex-end">
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit}>
            Convert {entities?.length}{" "}
            {plural(entities[0]?.source.type, entities?.length)}
          </Button>
        </HStack>
      }
    >
      <Container stack="vertical" padding="none" gap={20}>
        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Fields label="Convert to">
              <EntityTypeSelect
                value={convertTo}
                scope={entities[0]?.source?.scope}
                onChange={(t) => t && setConvertTo(t)}
                plural={entities?.length > 1}
              />
            </Fields>

            <Fields label="Location">
              <LocationSelect
                fit="container"
                variant="full"
                open={picking}
                setOpen={setPicking}
                location={location}
                defaultOpen={true}
                source={entities[0]?.source}
                onChange={setLocation}
              />
            </Fields>
          </Container>
        </FillSpace>
      </Container>
    </Dialog>
  );
};

interface ButtonProps {
  item: Entity;
  selection?: SelectionState;
}

export const ConvertButton = ({ item, selection }: ButtonProps) => {
  const [open, setOpen] = useState(false);
  const targets = useMemo(
    () =>
      maybeMap(
        selection?.selected?.has(item.id)
          ? toArray(selection?.selected)
          : [item.id],
        toRef
      ) || [],
    [selection, item?.id]
  );

  return (
    <>
      <Tooltip text="Convert to task">
        <Button
          icon={StatusConvert}
          iconSize="small"
          size="small"
          subtle
          onClick={withHandle(() => setOpen(true))}
        />
      </Tooltip>
      {open && (
        <ConvertDialog
          targets={targets}
          onComplete={() => setOpen(false)}
          onCancel={() => setOpen(false)}
        />
      )}
    </>
  );
};
