import { useMemo, useState } from "react";

import { Note, isOutcome } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity } from "@state/generic";

import { useSyncPathnameID } from "@utils/url";
import { when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { toChildLocation } from "@utils/scope";
import { getRelationValue } from "@utils/property-refs";

import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { DiscussionThread } from "@ui/discussion-thread";
import { EntityMessagesPane } from "@ui/messages-pane";
import { ResourcesPane } from "@ui/resources-pane";
import { OverlaySheet, StackContainer } from "@ui/sheet-layout";
import TaskPane from "@ui/engine/task/task-pane";
import { TemplateSideBar } from "@ui/template-banner";
import { UpdateThread } from "@ui/update-thread";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { SearchPane } from "@ui/search-pane";
import { ClockHistory, Comments, EmojiIcon, Search } from "@ui/icon";
import {
  ScheduleInstancesPane,
  TemplateSchedulesPane,
} from "@ui/engine/schedule";
import { RelatedMeetingsPane } from "@ui/engine/meeting";

import AppPage from "./app-page";
import { maybeTypeFromId } from "@utils/id";

interface Props {
  outcomeId: string;
  showBack?: boolean;
}

export default function OutcomePage({ outcomeId, showBack = true }: Props) {
  const outcome = useLazyEntity(outcomeId);

  if (!!outcome && !isOutcome(outcome)) {
    throw new Error("OutcomePage only supports outcomes.");
  }

  const pushTo = usePushTo();
  const [page] = useRegisterPage(outcomeId, outcome);
  const [showNote, setShowNote] = useState<Note>();
  const fullLocation = useMemo(
    () => when(outcome, (t) => toChildLocation(t.location, t.id)),
    [outcome?.id, outcome?.location]
  );

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(outcomeId, outcome?.id);

  if (!outcome) {
    return <></>;
  }

  return (
    <AppPage page={page} title={outcome?.title} loading={!outcome}>
      <StackContainer>
        {showBack && <SmartBreadcrumbSheet />}

        <TaskPane id={outcomeId} item={outcome} />

        {!outcome.template ? (
          <PaneManager size="secondary">
            <PaneItem id="search" title="Search" icon={Search}>
              <SearchPane
                parentId={outcomeId}
                onOpen={(n) =>
                  maybeTypeFromId(n.id) === "note"
                    ? setShowNote(n as Note)
                    : pushTo(n)
                }
              />
            </PaneItem>

            <PaneItem
              id="resources"
              title="Resources"
              icon={<EmojiIcon emoji="🔗" />}
            >
              {fullLocation && <ResourcesPane location={fullLocation} />}
            </PaneItem>

            <PaneItem
              id="messages"
              title="Messages"
              icon={<EmojiIcon emoji="💬" />}
            >
              <EntityMessagesPane
                entityId={outcomeId}
                onSelected={setShowNote}
              />
            </PaneItem>

            {!outcome.template && (
              <PaneItem id="meetings" title="Meetings" icon={Comments}>
                <RelatedMeetingsPane entityId={outcome.id} />
              </PaneItem>
            )}

            {!outcome.template ? (
              when(getRelationValue(outcome, "refs.repeat"), (ref) => (
                <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
                  <ScheduleInstancesPane
                    schedule={ref}
                    instanceId={outcome.id}
                  />
                </PaneItem>
              ))
            ) : (
              <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
                <TemplateSchedulesPane template={outcome} />
              </PaneItem>
            )}
          </PaneManager>
        ) : (
          <TemplateSideBar />
        )}

        {showNote && (
          <OverlaySheet
            size="secondary"
            height="container"
            onDismiss={() => setShowNote(undefined)}
          >
            {showNote?.type === "update" ? (
              <UpdateThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            ) : (
              <DiscussionThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            )}
          </OverlaySheet>
        )}
      </StackContainer>
    </AppPage>
  );
}
