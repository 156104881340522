import { atomFamily, selectorFamily } from "recoil";

import { ID, Meeting } from "@api";

import {
  getItem,
  localStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type MeetingStoreState = StoreState<Meeting>;

export const WorkspaceMeetingStoreAtom = atomFamily<MeetingStoreState, ID>({
  key: "WorkspaceMeetingStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [localStorageForStore(wid, "meeting")],
});

export const MeetingStoreAtom = WorkspaceWrappedAtom(
  "MeetingStoreAtom",
  WorkspaceMeetingStoreAtom
);

export const MeetingAtom = selectorFamily({
  key: "MeetingAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(MeetingStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(MeetingStoreAtom, mergeItem<Meeting>(newValue));
      }
    },
});
