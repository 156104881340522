import { useCallback, useMemo, useState } from "react";

import { DatabaseID, Entity, Link } from "@api";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { cx } from "@utils/class-names";
import { useClipboardLink } from "@ui/clipboard";
import { useRefState } from "@utils/hooks";

import { isUrl } from "@utils/url";
import { LinkAdd, Notion } from "@ui/icon";
import { SectionLabel } from "@ui/text";
import { CommandGroup, CommandItem, CommandMenu } from "@ui/command-menu";
import { NotionSearchCommands } from "./app-commands/search-commands/notion-search";
import { LinkDialog } from "./link-dialog";
import { CreateDocumentDialog } from "./create-document-dialog";

import styles from "./resource-create-dialog.module.css";

type LinkType = "document" | "thread" | "url" | "all";
type Mode = "search" | "new-doc" | "new-link";

export interface Props {
  entity: Entity;
  link: Maybe<Link>;
  type?: LinkType;
  mode?: Mode;
  source?: DatabaseID;
  onChanged?: Fn<Link, void>;
  onCancel?: Fn<void, void>;
}

export const ResourceCreateDialog = ({
  entity,
  link: _link,
  type,
  mode: _mode,
  onCancel,
  onChanged,
}: Props) => {
  const [action, actionRef, setAction] = useRefState<Mode>(_mode || "search");
  const fromClipboard = useClipboardLink();

  const [search, setSearch] = useState("");
  const isSearchUrl = useMemo(() => isUrl(search?.trim()), [search]);

  const [link, setLink] = useState(
    _link || { url: "", text: "", icon: undefined }
  );

  const title = useMemo(
    () =>
      type === "document"
        ? "Add Document"
        : type === "thread"
        ? "Add Thread"
        : "Add Resource",
    [type]
  );

  const onAddLink = useCallback((l: string) => {
    setLink({ ...link, url: l });
    setAction("new-link");
  }, []);

  const onCreateDoc = useCallback(() => {
    setAction("new-doc");
  }, []);

  const onClose = useCallback(
    (source: "search" | "new-thread" | "new-doc" | "new-link") => {
      if (source === actionRef.current) {
        onCancel?.();
      }
    },
    [action]
  );

  if (action === "new-doc") {
    return (
      <CreateDocumentDialog
        defaultName={search}
        entity={entity}
        onChanged={onChanged}
        onCancel={() => onClose("new-doc")}
      />
    );
  }

  if (action === "new-link") {
    return (
      <LinkDialog
        link={link}
        onChanged={onChanged}
        onCancel={() => onClose("new-link")}
      />
    );
  }

  return (
    <CommandMenu
      key="upload-commands"
      placeholder={"Link or create a resource..."}
      open={true}
      onOpen={(o) => !o && onClose?.("search")}
      onClose={() => onClose?.("search")}
      onSearched={setSearch}
      header={
        <SectionLabel bold={true} className={cx(styles.label, styles.blue)}>
          {title || "Add a Resource"}
        </SectionLabel>
      }
    >
      <CommandGroup>
        {!!fromClipboard && (
          <CommandItem
            icon={LinkAdd}
            value={`from clipboard ${fromClipboard}`}
            onClick={() => onAddLink(fromClipboard)}
          >
            From Clipboard – {fromClipboard}
          </CommandItem>
        )}
        {(type === "document" || type === "all") && isSearchUrl && (
          <CommandItem
            icon={LinkAdd}
            value={`add link ${search}`}
            onClick={() => onAddLink(search?.trim())}
          >
            Add Link – {search}
          </CommandItem>
        )}
        {(type === "document" || type === "all") && !search && (
          <CommandItem
            icon={Notion}
            value="create new document"
            onClick={() => onCreateDoc()}
          >
            New Document
          </CommandItem>
        )}
        {(type === "document" || type === "all") &&
          !!search &&
          !isSearchUrl && (
            <CommandItem
              icon={Notion}
              value={`create new document ${search}`}
              onClick={() => onCreateDoc()}
            >
              New Document ("{search}")
            </CommandItem>
          )}
      </CommandGroup>
      {(type === "all" || type === "document") && (
        <NotionSearchCommands searching={true} onSelect={onChanged} />
      )}
    </CommandMenu>
  );
};
