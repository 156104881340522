import { atomFamily, selectorFamily } from "recoil";

import { ID, Page } from "@api";

import {
  getItem,
  localStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type PageStoreState = StoreState<Page>;

export const WorkspacePageStoreAtom = atomFamily<PageStoreState, ID>({
  key: "WorkspacePageStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [localStorageForStore(wid, "page")],
});

export const PageStoreAtom = WorkspaceWrappedAtom(
  "PageStoreAtom",
  WorkspacePageStoreAtom
);

export const PageAtom = selectorFamily({
  key: "PageAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(PageStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(PageStoreAtom, mergeItem<Page>(newValue));
      }
    },
});
