import {
  Dictionary,
  find,
  groupBy,
  initial,
  keys,
  reduce,
  split,
  values,
} from "lodash";
import {
  AiAssist,
  DatabaseID,
  DisplayAs,
  Entity,
  EntityType,
  Person,
  PropertyDef,
  PropertyRef,
  PropertyVisibility,
} from "@api";

import { Maybe, when } from "@utils/maybe";
import { Pred, composel, fallback, use } from "@utils/fn";
import { isAnyRelation, mergeDefs } from "@utils/property-refs";
import { isMatch, toBaseScope } from "@utils/scope";
import { prefix } from "@utils/string";
import { isPersonId } from "@utils/id";
import { reverse } from "@utils/array";
import { log } from "@utils/debug";

import { PropertyDefStoreState } from "./atoms";
import { now } from "@utils/date-fp";

type PropertyDefPair = [PropertyDef<Entity>, PropertyDef<Entity>];

export const toFetchKey = (source: DatabaseID) =>
  `${source.type}.schemas|${toBaseScope(source.scope)}`;

export const newPropertyDef = (
  defaults: Partial<PropertyDef<Entity>> &
    Pick<PropertyDef<Entity>, "scope" | "order">
): PropertyDef<Entity> => ({
  entity: ["task"],
  type: "select",
  field: "",
  values: {},
  label: "",
  assist: AiAssist.Off,
  options: {},
  visibility: PropertyVisibility.HideEmpty,
  displayAs: DisplayAs.Property,
  system: false,
  locked: false,
  readonly: false,
  createdAt: now(),
  updatedAt: now(),
  deletedAt: undefined,
  ...defaults,
});

export function toPropertyDefID<T extends Entity = Entity>(
  prop: PropertyDef<T>
): string;
export function toPropertyDefID<T extends Entity = Entity>(
  source: DatabaseID,
  prop: PropertyRef<T>
): string;
export function toPropertyDefID<T extends Entity = Entity>(
  p1: DatabaseID | PropertyDef<T>,
  p2?: Maybe<PropertyRef<T>>
): string {
  const prop = p2 || (p1 as PropertyDef<T>);
  const source = p2
    ? (p1 as DatabaseID)
    : use(prop as PropertyDef<T>, (def) => ({
        type: def.entity[0],
        scope: def.scope,
      }));

  // Returns in the format of "type.field|scope" with optional scope
  return `${source.type}.${String(prop.field)}${
    when(source.scope, composel(toBaseScope, prefix("|"))) ?? ""
  }`;
}

export const changeScope = (propDefId: string, scope: string) =>
  `${propDefId.split("|")[0]}|${scope}`;

export function toPropertyDefAliases<T extends Entity = Entity>(
  def: PropertyDef<T>
) {
  const isShared = def?.entity?.length > 1;
  const defId = isShared
    ? `shared.${String(def.field)}|${def.scope}`
    : toPropertyDefID(def);

  // Create aliases for all the entity types that this property supports
  return {
    aliases: isShared
      ? reduce(
          def.entity,
          (acc, type) => {
            acc[toPropertyDefID({ type, scope: def.scope }, def)] = defId;
            return acc;
          },
          {} as Record<string, string>
        )
      : {},
    id: defId,
  };
}

export const isPropForEntity = <T extends Entity = Entity>(
  def: PropertyDef<T>,
  type: EntityType
) => def?.entity.length === 0 || def.entity?.includes(type);

export const isPropInScope = <T extends Entity = Entity>(
  def: PropertyDef<T>,
  source: DatabaseID
) => isPropForEntity(def, source.type) && isMatch(source.scope, def.scope);

export const findPropertyDef = <T extends Entity = Entity>(
  props: PropertyDef<T>[],
  source: DatabaseID,
  prop: Partial<PropertyRef<T>> & Pick<PropertyRef<T>, "field">
): Maybe<PropertyDef<T>> =>
  find(props, (d) => d.field === prop.field && isPropInScope(d, source));

export const getPropertyDef = <T extends Entity = Entity>(
  store: PropertyDefStoreState,
  source: DatabaseID,
  prop: PropertyRef<T>
): Maybe<PropertyDef<T>> =>
  fallback(
    () => {
      const id = toPropertyDefID(source, prop);
      return (
        store.lookup[id] ||
        when(store.aliases?.[id], (alias) => store.lookup[alias])
      );
    },
    () => {
      const scope = initial(split(source.scope, "/"));

      if (!!scope.length) {
        // Look for a property in a scope above
        return getPropertyDef(
          store,
          { ...source, scope: scope.join("/") },
          prop
        );
      }

      return undefined;
    }
  );

const mapUniqProps = (
  props: Dictionary<Maybe<PropertyDef<Entity>>>,
  pred: Pred<PropertyDef<Entity>>,
  onDup: "merge" | "uniq" = "uniq"
) =>
  values(
    reduce(
      props,
      (acc, p) => {
        if (!!p && pred(p)) {
          const existing = acc[p.field];

          // When there is no existing property or the new property is more specific
          if (
            !existing ||
            (onDup === "uniq" && p.scope.length > existing.scope.length)
          ) {
            acc[p.field] = p;
          }
          // Merge properties of the same type
          else if (
            onDup === "merge" &&
            p.type === existing?.type &&
            ["select", "multi_select", "status"]?.includes(p.type)
          ) {
            acc[p.field] = mergeDefs(existing, p);
          }
        }
        return acc;
      },
      {} as Record<string, PropertyDef<Entity>>
    )
  ) as PropertyDef<Entity>[];

// Returns all available properties for an entity, when multiple exist with the same field (e.g. two status fields)
// the definitions are merged together
export const propertiesForEntity = (
  store: PropertyDefStoreState,
  type: EntityType
) =>
  mapUniqProps(
    store?.lookup || {},
    (p) => !!p && isPropForEntity(p, type),
    "merge"
  );

export const propertiesForScope = (
  store: PropertyDefStoreState,
  source: DatabaseID,
  me: Person
) =>
  isPersonId(source.scope)
    ? // Private scopes can use properties from the workspace and all teams
      mapUniqProps(store?.lookup, (p) => isPropForEntity(p, source.type))
    : // Matches either the source scope or your private scope (user.id)
      mapUniqProps(
        store?.lookup,
        (p) =>
          isPropInScope(p, source) ||
          isPropInScope(p, { type: source.type, scope: me.id })
      );

export const findAvailableStatuses = <T extends Entity = Entity>(
  props: PropertyDef<T>[],
  source: DatabaseID
) =>
  findPropertyDef(props, source, { field: "status", type: "status" })?.values
    ?.status;

export const findStatus = <T extends Entity = Entity>(
  idOrGroup: string,
  props: PropertyDef<T>[],
  source: DatabaseID
) =>
  find(
    findAvailableStatuses(props, source),
    (s) => s.id === idOrGroup || s.group === idOrGroup
  );

// Takes all defs for a team, and returns the matching parent/child relationships
export const toHierarchyPairs = (
  props: PropertyDef<Entity>[],
  relatingTo?: EntityType
) => {
  const paired = groupBy(props, (p) => {
    if (
      !isAnyRelation(p) ||
      !p.options?.hierarchy ||
      (!!relatingTo &&
        ![p.options?.references, p.entity[0]]?.includes(relatingTo))
    ) {
      return undefined;
    }

    if (relatingTo) {
      // Always put relatingTo entity first
      return p.options?.references === relatingTo
        ? `${p.options?.references}-${p.entity[0]}`
        : `${p.entity[0]}-${p.options?.references}`;
    }

    // Always put parent first
    return p.options?.hierarchy === "child"
      ? `${p.entity[0]}-${p.options?.references}`
      : `${p.options?.references}-${p.entity[0]}`;
  });

  return reduce(
    keys(paired),
    (acc, key) => {
      const defs = paired[key];
      if (defs?.length !== 2) {
        log(`Missing parent/child relationship for ${key}.`, {
          defs,
        });
        return acc;
      }

      // Order tuple the same as the above keys
      if (relatingTo) {
        acc[key] = (
          defs[0]?.entity[0] === relatingTo ? defs : reverse(defs)
        ) as PropertyDefPair;
      } else {
        acc[key] = (
          defs[0]?.options?.hierarchy === "child" ? defs : reverse(defs)
        ) as PropertyDefPair;
      }
      return acc;
    },
    {} as Record<string, PropertyDefPair>
  );
};
