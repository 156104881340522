import { useEffect, useMemo } from "react";

import { Color, Job, JobStatus } from "@api";

import { useLazyAllTeams } from "@state/teams";
import { useCreateNextForSchedule } from "@state/schedule";
import { useLazyEntity } from "@state/generic";

import { switchEnum } from "@utils/logic";
import { Maybe, Primitive } from "@utils/maybe";
import { sentenceCase } from "@utils/string";
import { useOnce } from "@utils/hooks";
import { log } from "@utils/debug";
import { useAsyncEffect } from "@utils/effects";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { SpaceBetween } from "@ui/flex";
import { Icon, SpinnerIcon, StatusIcon } from "@ui/icon";
import { Tag } from "@ui/tag";
import { withLoading } from "@ui/notifications";

import { JobRunner } from "./types";

export const ScheduleJobs: JobRunner = {
  accepts: (j: Job) => j.key?.startsWith("sd_"),
  runner: ({ job, onCompleted, onFailed }) => {
    const once = useOnce();
    const scheduleId = job.data?.id as Maybe<string>;
    const { create, ready } = useCreateNextForSchedule(
      scheduleId || "",
      onCompleted
    );

    useEffect(() => {
      if (!scheduleId) {
        return onFailed();
      }

      if (!ready) {
        return;
      }

      once(() => {
        try {
          create();
        } catch (err) {
          log(err);
          onFailed();
        }
      });
    }, [job.id, ready]);

    return <RunnerStatus status={job.status} />;
  },
};

export const ClosePastMeetings: JobRunner = {
  accepts: (j: Job) =>
    j.key?.startsWith("m_") && j.data?.action === "close-past",

  runner: ({ job, onCompleted, mutate }) => {
    const once = useOnce();
    const meetingId = job.data?.id as Maybe<string>;
    const meeting = useLazyEntity<"meeting">(meetingId);

    useEffect(() => {
      if (!meeting) {
        return;
      }

      once(() => {
        mutate(
          asUpdate(
            meeting,
            asMutation({ field: "status", type: "status" }, { id: "FNS" })
          )
        );
        onCompleted();
      });
    }, [job.id, meeting]);

    return <RunnerStatus status={job.status} />;
  },
};

export const SuccessTestJob: JobRunner = {
  accepts: (j: Job) => j.key?.startsWith("test-job-success-"),
  runner: ({ job, onCompleted, onFailed, mutate }) => {
    const once = useOnce();
    useEffect(() => {
      once(async () => {
        await withLoading(
          new Promise<Primitive>((resolve) => setTimeout(resolve, 10000))
        );

        onCompleted();
      });
    }, []);

    return <RunnerStatus status={job.status} />;
  },
};

export const FailTestJob: JobRunner = {
  accepts: (j: Job) => j.key?.startsWith("test-job-fail-"),
  runner: ({ job, onCompleted, onFailed, mutate }) => {
    const once = useOnce();
    // Fake load some data
    const teams = useLazyAllTeams();

    useAsyncEffect(async () => {
      once(async () => {
        await withLoading(
          new Promise<Primitive>((resolve) => setTimeout(resolve, 5000))
        );

        onFailed();
      });
    }, [teams]);

    return <RunnerStatus status={job.status} />;
  },
};

export const RUNNERS = [
  ScheduleJobs,
  FailTestJob,
  SuccessTestJob,
  ClosePastMeetings,
];

const RunnerStatus = ({ status }: { status: JobStatus }) => {
  const color = useMemo(
    () =>
      switchEnum<JobStatus, Color>(status, {
        queued: "blue",
        running: "yellow",
        completed: "green",
        failed: "red",
      }),
    [status]
  );
  return (
    <SpaceBetween>
      FUNC
      <Tag color={color}>{sentenceCase(status)}</Tag>
      {status === "running" && <Icon icon={SpinnerIcon} />}
      {status === "completed" && (
        <Icon icon={<StatusIcon status={{ group: "done" }} />} />
      )}
    </SpaceBetween>
  );
};
