import {
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import { useDebouncedCallback } from "use-debounce";

import { Entity, HasIcon, toTitleOrName } from "@api";

import { chooseEmoji, useAiUseCase } from "@state/ai";

import { Fn } from "@utils/fn";
import { cx } from "@utils/class-names";
import { useMagicDust } from "@utils/confetti";

import { Dropdown, useOpenState } from "@ui/dropdown";
import { Button } from "@ui/button";
import { EmojiIcon, Icon, Props as IconProps } from "@ui/icon";

import styles from "./select.module.css";

type Props = {
  emoji: string;
  onChange: Fn<string, void>;
  children?: ReactNode;
} & Omit<IconProps, "icon">;

export const EmojiSelect = forwardRef(
  (
    { emoji, children, className, onChange, ...rest }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const [open, setOpen] = useOpenState();
    const onSelected = useCallback(
      (e: EmojiClickData) => {
        const emoji = e.isCustom ? e.unified : e.emoji;
        onChange(emoji);
        setOpen(false);
      },
      [onChange, setOpen]
    );

    return (
      <Dropdown
        open={open}
        setOpen={setOpen}
        className={{ popover: styles.emojiPopover }}
        portal={true}
        trigger={
          children || (
            <Button
              ref={ref}
              subtle
              variant="secondary"
              inset
              className={styles.emojiPickerButton}
            >
              <Icon
                className={cx(className, styles.emojiPick)}
                icon={<EmojiIcon emoji={emoji || "🤷‍♂️"} />}
                {...rest}
              />
            </Button>
          )
        }
      >
        <EmojiPicker
          onEmojiClick={onSelected}
          autoFocusSearch={true}
          emojiStyle={EmojiStyle.APPLE}
          lazyLoadEmojis={true}
          skinTonesDisabled={true}
          previewConfig={{ showPreview: false }}
        />
      </Dropdown>
    );
  }
);

export const MagicEmojiSelect = ({
  entity,
  onChange,
  ...props
}: Props & { key: string; entity: Entity }) => {
  const [firstRun, setFirstRun] = useState(true);
  const [iconOverriden, setIconOverriden] = useState(false);
  const iconRef = useRef<HTMLDivElement>(null);
  const sprinkle = useMagicDust(iconRef);
  const { run } = useAiUseCase(
    chooseEmoji,
    useCallback(
      (icon: string) => {
        if (!iconOverriden && icon !== (entity as HasIcon)?.icon) {
          onChange(icon);
          sprinkle();
        }
      },
      [iconOverriden, (entity as HasIcon)?.icon]
    )
  );
  const slowRun = useDebouncedCallback(run, 400);

  const proxyOnChange = useCallback(
    (icon: string) => {
      setIconOverriden(true);
      onChange(icon);
    },
    [props.emoji, onChange]
  );

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }
    slowRun({ entity });
  }, [toTitleOrName(entity)]);

  return <EmojiSelect ref={iconRef} {...props} onChange={proxyOnChange} />;
};
