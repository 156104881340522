import { Component, ReactNode } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import { useLocation } from "react-router-dom";

import { useCurrentUser } from "@state/workspace";

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  const me = useCurrentUser();
  const location = useLocation();
  return (
    <SentryErrorBoundary
      key={location.pathname}
      showDialog
      dialogOptions={{ user: me }}
    >
      {children}
    </SentryErrorBoundary>
  );
};
