import { subMinutes } from "date-fns";
import { map, without } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { PersonRole, Ref } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useCurrentUser } from "@state/workspace";
import { useLazyAllTeams } from "@state/teams";
import { useAddToFetchResults } from "@state/fetch-results";

import { withHardHandle } from "@utils/event";
import { maybeMap } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { now } from "@utils/now";
import { toRef } from "@utils/property-refs";
import { equalsAny } from "@utils/logic";
import { fromPointDate } from "@utils/date-fp";

import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { TeamCreateDialog } from "@ui/engine/team";
import { SpaceBetween, VStack } from "@ui/flex";
import { Eye, EyeSlash } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { AddMenuItem, MenuItem } from "@ui/menu-item";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { RelationLabel } from "@ui/relation-label";
import { TeamStructureFlow } from "@ui/team-structure-flow";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { Heading, Text } from "@ui/text";
import AppPage from "./app-page";

import styles from "./teams-page.module.css";

interface Props {}

export function TeamsPage({}: Props) {
  const me = useCurrentUser();
  const goTo = useGoTo();
  const [page] = useRegisterPage();
  const [creating, setCreating] = useState(false);
  const teams = useLazyAllTeams();
  const onTeamAdded = useAddToFetchResults("all-teams"); // Matches internal cache key inside useAllLazyTeams
  const [editing, setEditing] = useState(false);
  const [forceShow, setForceShow] = useState<string[]>(() =>
    maybeMap(teams, (t) =>
      fromPointDate(t?.createdAt) < subMinutes(now(), 10) ? t.id : undefined
    )
  );
  const editable = useMemo(
    () => equalsAny(me.role, [PersonRole.Admin, PersonRole.Owner]),
    [me.role]
  );

  usePageUndoRedo(page.id);

  const addForceShow = useCallback(
    (t: Ref) => setForceShow((s) => [...s, t.id]),
    [setForceShow]
  );
  const removeForceShow = useCallback(
    (t: Ref) => setForceShow((s) => without(s, t.id)),
    [setForceShow]
  );

  return (
    <AppPage page={page}>
      <StackContainer>
        {creating && (
          <TeamCreateDialog
            defaults={{ owner: toRef(me) }}
            onCancel={() => setCreating(false)}
            onSaved={(t) => {
              onTeamAdded([t]);
              addForceShow(t);
              setCreating(false);
              setEditing(true);
            }}
          />
        )}

        <Sheet size="full" transparency="low" interactable={false}>
          <SpaceBetween direction="vertical" gap={0} fit="container">
            <div className={styles.header}>
              <SpaceBetween>
                <VStack gap={0}>
                  <Heading bold>Teams</Heading>
                  <Text subtle>All teams at a glance.</Text>
                </VStack>
                {editable && (
                  <Button
                    onClick={() => setEditing((e) => !e)}
                    variant={editing ? "primary" : "secondary"}
                  >
                    {editing ? "Done" : "Edit"}
                  </Button>
                )}
              </SpaceBetween>
            </div>

            <PageLayout>
              <Main className={styles.main}>
                <TeamStructureFlow
                  className={styles.flow}
                  editing={editing}
                  hideUnconnected={true}
                  whitelist={forceShow}
                />
              </Main>

              <SideNav className={styles.side}>
                <CollapsibleSection title="All teams">
                  <Menu>
                    <MenuGroup>
                      {map(teams, (t) => (
                        <MenuItem key={t.id} onClick={() => goTo(t)}>
                          <SpaceBetween>
                            <RelationLabel relation={t} />

                            {editing &&
                              !(t.parent || t.subTeams?.length) &&
                              !forceShow?.includes(t.id) && (
                                <Button
                                  size="small"
                                  icon={EyeSlash}
                                  onClick={withHardHandle(() =>
                                    addForceShow(t)
                                  )}
                                />
                              )}

                            {editing &&
                              !(t.parent || t.subTeams?.length) &&
                              !!forceShow?.includes(t.id) && (
                                <Button
                                  size="small"
                                  icon={Eye}
                                  onClick={withHardHandle(() =>
                                    removeForceShow(t)
                                  )}
                                />
                              )}
                          </SpaceBetween>
                        </MenuItem>
                      ))}
                    </MenuGroup>
                  </Menu>
                  {editable && (
                    <AddMenuItem
                      title={"Add team"}
                      onClick={() => setCreating(true)}
                    />
                  )}
                </CollapsibleSection>
              </SideNav>
            </PageLayout>
          </SpaceBetween>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
}
