import { useMemo } from "react";

import { useGoTo } from "@utils/navigation";
import { cx } from "@utils/class-names";

import { MenuItem, MenuItemProps } from "./default";

import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";

const trimTrailingSlash = (str: string) =>
  str.endsWith("/") ? str.slice(0, -1) : str;

export function RouteMenuItem({
  text,
  route,
  onClick,
  children,
  ...props
}: MenuItemProps & { route: string }) {
  const goTo = useGoTo();
  const location = useLocation();
  const active = useMemo(
    () =>
      trimTrailingSlash(location.pathname).endsWith(trimTrailingSlash(route)),
    [route, location]
  );

  return (
    <MenuItem
      className={cx(active && styles.routeActive)}
      onClick={(e) => (route ? goTo(route) : onClick?.(e))}
      {...props}
    >
      {text || children}
    </MenuItem>
  );
}
