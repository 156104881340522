import { last, map } from "lodash";
import { ReactNode, useCallback, useMemo, useState } from "react";

import { hasBody, HasRefs, RelationRef, RichText } from "@api";

import {
  useLazyEntity,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";

import { Maybe, safeAs, when } from "@utils/maybe";
import { composel, Fn } from "@utils/fn";
import { asMutation } from "@utils/property-mutations";
import { all } from "@utils/promise";
import { justOne } from "@utils/array";
import { useGoTo } from "@utils/navigation";
import { fromScope } from "@utils/scope";
import { toRef } from "@utils/property-refs";

import { Props as LabelProps } from "@ui/label";
import { useUpload } from "@ui/upload";
import { CollapsibleSection } from "@ui/collapsible-section";
import { DocumentEditor } from "@ui/rich-text";
import { isEmpty, isEqual } from "@utils/rich-text";
import { ShowMoreOverflow } from "@ui/show-more-overflow";
import { MagicPurple } from "@ui/icon";
import { Button } from "@ui/button";
import { AIAutoBrief } from "./ai-auto-brief";
import { useLazyPropertyDef } from "@state/databases";
import { ParentCard } from "@ui/parent-card";

interface Props {
  entity: RelationRef;
  body: Maybe<RichText>;
  placeholder?: string;
  label?: string | ReactNode;
  labelSize?: LabelProps["size"];
  showAll?: boolean;
  onBodyChanged?: Fn<RichText, void>;
}

export const GenericPageBody = ({
  body,
  label: sectionLabel,
  onBodyChanged,
  entity: ref,
  placeholder,
  showAll: _showAll = true,
  labelSize = "medium",
}: Props) => {
  const goTo = useGoTo();
  const mutate = useUpdateEntity(ref.id);
  const entity = useLazyEntity(ref.id);
  const nested = useNestedSource(entity);
  const upload = useUpload(nested?.scope || "");
  const [showAutoBrief, setShowAutoBrief] = useState(false);
  const [showAll, setShowAll] = useState(_showAll);
  const def = useLazyPropertyDef(entity?.source, {
    field: "body",
    type: "rich_text",
  });
  const parentRef = useMemo(
    () =>
      when(safeAs<HasRefs>(entity)?.refs?.parent, justOne) ||
      when(entity?.source.scope, composel(fromScope, last, toRef)),
    [entity]
  );
  const parent = useLazyEntity(parentRef?.id);
  const childScope = useNestedSource(entity);

  const handleChanged = useCallback(
    (rt: RichText) => {
      // Hasn't changed
      if (isEqual(rt, body)) {
        return;
      }

      if (onBodyChanged) {
        onBodyChanged(rt);
      } else {
        mutate(asMutation({ field: "body", type: "rich_text" }, rt));
      }
    },
    [mutate, onBodyChanged]
  );

  return (
    <>
      <CollapsibleSection
        title={sectionLabel || "Documentation"}
        labelSize={labelSize}
        actions={
          <>
            <Button
              size="small"
              icon={MagicPurple}
              onClick={() => setShowAutoBrief(true)}
            >
              Auto Brief
            </Button>
          </>
        }
      >
        <ShowMoreOverflow showAll={showAll}>
          <DocumentEditor
            key={entity?.id}
            scope={childScope?.scope}
            newLineSpace="large"
            placeholder={placeholder}
            content={body}
            onChanged={handleChanged}
            onPaste={async (files) =>
              all(
                map(files, async (f) => {
                  const uploaded = await upload(f);
                  return { url: uploaded.url, text: uploaded.name };
                })
              )
            }
          />
          {showAutoBrief && def && nested && (
            <AIAutoBrief
              original={body}
              prop={def}
              source={nested}
              onDismiss={() => setShowAutoBrief(false)}
              onSaved={(rt) => {
                handleChanged(rt);
                setShowAutoBrief(false);
                setShowAll(true);
              }}
            />
          )}
        </ShowMoreOverflow>
      </CollapsibleSection>
      {parent && hasBody(parent) && !isEmpty(parent.body) && (
        <CollapsibleSection
          title={"Parent" + (sectionLabel || "Documentation")}
          labelSize={labelSize}
        >
          <ParentCard
            id={parent.id}
            label={false}
            onClick={() => goTo(parent.id)}
            showProps={false}
          />
        </CollapsibleSection>
      )}
    </>
  );
};
