import {
  filter,
  find,
  map,
  some,
  sortBy,
  union,
  uniq,
  values,
  without,
} from "lodash";
import { selectorFamily } from "recoil";

import { Entity, EntityType, ID, View } from "@api";

import { allPropertiesForSource, allPropertiesForType } from "@state/databases";
import { Task } from "@state/tasks";
import { ViewStoreAtom } from "@state/views";
import { getItem } from "@state/store";
import { ViewQuickFilterAtom } from "@state/quick-filters";
import { GenericItem, getStore } from "@state/generic";

import { Maybe, maybeMap, when, whenNotEmpty } from "@utils/maybe";
import { passes } from "@utils/filtering";
import { hashable } from "@utils/serializable";
import { fuzzyMatch } from "@utils/search";
import { indexBy, indexById, uniqBy } from "@utils/array";
import { extractLast } from "@utils/scope";
import { NestedGroup } from "@utils/grouping";
import { use } from "@utils/fn";
import { isTypeId, typeFromId } from "@utils/id";

import { ViewAtom, ViewFetchResultsAtom } from "./atoms";
import {
  sortItems,
  groupItems,
  toFullFilter,
  isTemplateViewId,
  toGroupByProps,
  defaultViewsForParent,
  toSortKey,
  groupItemsBy2Dimensions,
  toSortProps,
  toTemplateIdBase,
} from "./utils";
import { GlobalFetchOptionsAtom } from "@state/fetch-results";

export interface ViewResults<T extends Entity = Task> {
  lookup: Record<string, Maybe<T>>;
  all: T[];
  sorted?: T[];
  grouped?: { groups: NestedGroup<T>[]; hidden: NestedGroup<T>[] };
}

export const itemsForQuickFilter = selectorFamily<
  Entity[],
  { viewId: string; filterId: string; archived?: boolean; templates?: boolean }
>({
  key: "itemsForQuickFilter",
  get:
    ({ viewId, filterId, archived, templates }) =>
    ({ get }) => {
      const viewFilters = get(ViewQuickFilterAtom(viewId));
      const results = get(ViewFetchResultsAtom(viewId));
      const quickFilter = find(
        viewFilters?.available,
        (s) => s.id === filterId
      );

      if (!quickFilter) {
        return [];
      }

      const view = get(ViewAtom(viewId));

      if (!view) {
        return [];
      }

      const store = get(getStore(view?.entity));
      const props = get(
        allPropertiesForSource(
          hashable({
            type: view.entity as EntityType,
            scope: view?.source.scope || "",
          })
        )
      );
      const propsByField = indexBy(props, (p) => p.field);

      if (!view) {
        return [];
      }

      const items = maybeMap(results?.ids || [], (id) => getItem(store, id));
      const fullViewFilter = toFullFilter(view, props, { archived, templates });
      return filter(
        items,
        (t) =>
          (!fullViewFilter || passes(t, fullViewFilter, propsByField)) &&
          passes(t, quickFilter.filter, propsByField)
      );
    },
});

export const countForQuickFilter = selectorFamily<
  number,
  { viewId: string; filterId: string }
>({
  key: "countForQuickFilter",
  get:
    (props) =>
    ({ get }) => {
      const globalOpts = get(GlobalFetchOptionsAtom);
      return (
        get(itemsForQuickFilter({ ...props, archived: globalOpts.archived })) ||
        []
      ).length;
    },
});

export const viewsForParent = selectorFamily({
  key: "viewsForParent",
  get:
    ({ parent, type }: { parent: ID; type: EntityType }) =>
    ({ get }): View[] => {
      const entity = get(GenericItem(parent));
      const savedIds = maybeMap(values(get(ViewStoreAtom).lookup), (v) =>
        !!v?.id &&
        !isTemplateViewId(v.id) &&
        v.location?.endsWith(parent) &&
        v.entity === type
          ? v?.id
          : undefined
      );

      if (!entity) {
        return [];
      }

      const defaultIds = defaultViewsForParent(entity, type);

      const views = uniqBy(
        maybeMap([...defaultIds, ...savedIds], (id) =>
          id ? get(ViewAtom(id)) : undefined
        ),
        (v) => toTemplateIdBase(v.alias || v.id),
        "last"
      );

      return sortBy(views, (v) => v?.order ?? views?.length);
    },
});

export const getViews = selectorFamily({
  key: "getViews",
  get:
    (ids: ID[]) =>
    ({ get }): View[] => {
      const views = uniqBy(
        maybeMap(ids, (id) => (id ? get(ViewAtom(id)) : undefined)),
        (v) => toTemplateIdBase(v.alias || v.id),
        "last"
      );

      return sortBy(views, (v) => v?.order ?? views?.length);
    },
});

/*
 * New generic stuff
 */

export const itemsForView = selectorFamily<
  ViewResults<Entity>,
  { id: ID; archived?: boolean; templates?: boolean }
>({
  key: "itemsForView",
  get:
    ({ id, archived, templates }) =>
    ({ get }) => {
      const view = get(ViewAtom(id));

      if (!view || !view.entity) {
        return { lookup: {}, all: [] };
      }

      const store = get(getStore(view.entity));
      const results = get(ViewFetchResultsAtom(view.id));
      const props = get(
        allPropertiesForSource({ type: view.entity, scope: view.source.scope })
      );

      const parent = when(view.for?.id, (id) =>
        getItem(get(getStore(typeFromId<EntityType>(id))), id)
      );

      const quickFilters = get(ViewQuickFilterAtom(view.id));
      const fullViewFilter = toFullFilter(view, props, {
        archived,
        templates,
      });

      const isPrivateView = !!!!when(
        extractLast(view.source.scope),
        isTypeId("person")
      );
      // When view is in a person scope, then use all available properties for this type across all teams.
      // Same logic as state/databases/effects.ts useLazyProperties
      const allProps = get(
        isPrivateView
          ? allPropertiesForType(view.entity)
          : allPropertiesForSource(
              hashable({ type: view.entity, scope: view.source.scope })
            )
      );
      const propsByField = indexBy(allProps, (p) => p.field);

      const entities = union(
        // Include all items from API excluding any dirty items
        maybeMap(without(results?.ids, ...(store?.dirty || [])) || [], (id) =>
          getItem(store, id)
        ),
        // Manually filter dirty items to check whether they should be included
        maybeMap(uniq(store?.dirty) || [], (id) =>
          when(getItem(store, id), (t) =>
            !fullViewFilter || passes(t, fullViewFilter, propsByField)
              ? t
              : undefined
          )
        )
      );

      const quickFilter = when(quickFilters.selected, (id) =>
        find(quickFilters.available, (f) => f.id === id)
      );

      // If quick filter or search is enabled
      const filtered =
        quickFilter?.filter || quickFilters.search
          ? filter(
              entities,
              (t) =>
                // Passes the quick filter
                (!quickFilter?.filter ||
                  passes(t, quickFilter.filter, propsByField)) &&
                // Passes quick search field
                (!quickFilters.search ||
                  fuzzyMatch(
                    quickFilters.search,
                    (t as Maybe<{ title: string }>)?.title ||
                      (t as Maybe<{ name: string }>)?.name ||
                      ""
                  ))
            )
          : entities;

      const sorted = sortItems(
        filtered,
        toSortProps(view, parent),
        toSortKey(view),
        propsByField
      );

      const grouped = whenNotEmpty(
        toGroupByProps(view),
        (propRefs): Maybe<{ groups: NestedGroup[]; hidden: NestedGroup[] }> => {
          const groupDefs = map(propRefs, (ref) =>
            use(propsByField[ref.field], (prop) => ({
              ...prop,
              ...ref,
              values: prop?.values || {},
              scope: prop?.scope,
            }))
          );

          if (!groupDefs.length) {
            return undefined;
          }

          if (groupDefs.length === 1) {
            return groupItems(
              sorted,
              groupDefs,
              // Hide empty groups when filtering or searching
              !!(quickFilter?.filter || quickFilters.search)
                ? true
                : some(view.group, (g) => g?.hideEmpty)
            );
          }

          if (groupDefs.length === 2) {
            return groupItemsBy2Dimensions(
              sorted,
              groupDefs,
              // Hide empty groups when filtering or searching
              !!(quickFilter?.filter || quickFilters.search)
                ? true
                : some(view.group, (g) => g?.hideEmpty)
            );
          }

          throw new Error("Only 1 or 2 groupings are supported.");
        }
      );

      return {
        lookup: indexById(filtered),
        all: filtered,
        sorted: sorted,
        grouped: grouped,
      };
    },
});
