import { atomFamily, selectorFamily } from "recoil";

import { ID, Content } from "@api";

import {
  getItem,
  localStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type ContentStoreState = StoreState<Content>;

export const WorkspaceContentStoreAtom = atomFamily<ContentStoreState, ID>({
  key: "WorkspaceContentStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [localStorageForStore(wid, "content")],
});

export const ContentStoreAtom = WorkspaceWrappedAtom(
  "ContentStoreAtom",
  WorkspaceContentStoreAtom
);

export const ContentAtom = selectorFamily({
  key: "ContentAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(ContentStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(ContentStoreAtom, mergeItem<Content>(newValue));
      }
    },
});
