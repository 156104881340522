import { forwardRef, ReactNode, Ref, useMemo, useState } from "react";
import { find, map, without } from "lodash";
import * as Tabs from "@radix-ui/react-tabs";

import { Fn } from "@utils/fn";
import { cx } from "@utils/class-names";
import { withHandle } from "@utils/event";
import { ComponentOrNode } from "@utils/react";

import { Button } from "./button";
import { EllipsisH, PlusIcon } from "./icon";
import { Divider } from "./divider";
import { Dropdown } from "./dropdown";
import { MenuItem } from "./menu-item";

import styles from "./tab-bar.module.css";

export interface TabItem {
  id: string;
  title: string;
  icon?: ComponentOrNode;
}
export interface TabDivider {
  divider: true;
}

interface TabBarItemProps {
  item: TabItem;
  inset?: boolean;
  active?: boolean;
  className?: string;
}

export const TabBarItem = forwardRef(
  (
    { item, inset, active, className }: TabBarItemProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <Tabs.Trigger
      key={item.id}
      id={item.id}
      className={cx(styles.item, className, active && styles.active)}
      value={item.id}
    >
      <Button
        ref={ref}
        subtle
        size="small"
        inset={inset}
        icon={item.icon}
        className={styles.button}
      >
        {item.title}
      </Button>
    </Tabs.Trigger>
  )
);

export interface Props {
  options?: (TabItem | TabDivider)[];
  active: string;
  addOption?: TabItem;
  gap?: number;
  showMax?: number;
  onActiveChanged?: Fn<string, void>;
  onAdded?: Fn<void, void>;
  className?: string;
  itemClassName?: string;
  children?: ReactNode;
}

const isDivider = (o: TabItem | TabDivider): o is TabDivider =>
  !!(o as TabDivider).divider;

export const TabBar = ({
  options,
  active,
  onActiveChanged,
  addOption,
  gap,
  showMax,
  onAdded,
  children,
  className,
  itemClassName,
}: Props) => {
  const [showMore, setShowMore] = useState(false);

  // Split up items into visible and ones behind ellipsis menu
  const { visible, hidden } = useMemo(() => {
    if (!options) {
      return { visible: [], hidden: [] };
    }

    const max = showMax ?? options.length;
    const activeItem = find(options, { id: active });
    let all = options;

    // If the active item is hidden, then move it to the last visible spot
    if (activeItem && all.indexOf(activeItem) >= max - 1) {
      all = without(all, activeItem);
      all?.splice(max - 1, 0, activeItem);
    }

    // Return visible and hidden separately
    const visible = all.slice(0, max);
    const hidden = all.slice(max);
    return { visible, hidden };
  }, [options, showMax, active]);

  return (
    <Tabs.Root
      className={cx(styles.tabbar, className)}
      value={active}
      onValueChange={onActiveChanged}
    >
      <Tabs.List className={styles.tablist} style={{ columnGap: gap ?? 2 }}>
        {map(visible, (o, i) =>
          isDivider(o) ? (
            <Divider key={i} direction="vertical" />
          ) : (
            <TabBarItem
              key={o.id}
              item={o}
              inset={i === 0}
              active={o.id === active}
              className={itemClassName}
            />
          )
        )}
        {children}
        {!!hidden?.length && (
          <span className={cx(styles.item, itemClassName)}>
            <Dropdown
              open={showMore}
              setOpen={setShowMore}
              trigger={<Button subtle icon={EllipsisH} />}
            >
              {map(hidden, (o, i) =>
                isDivider(o) ? (
                  <Divider key={i} direction="vertical" />
                ) : (
                  <MenuItem
                    key={o.id}
                    icon={o.icon}
                    text={o.title}
                    onClick={withHandle(() => onActiveChanged?.(o.id))}
                    className={itemClassName}
                  />
                )
              )}
            </Dropdown>
          </span>
        )}
        {addOption && (
          <span className={cx(styles.item, itemClassName)}>
            <Button
              subtle
              size="small"
              icon={
                addOption?.icon !== false
                  ? addOption.icon || PlusIcon
                  : undefined
              }
              className={styles.addOption}
              onClick={withHandle(() => onAdded?.())}
            >
              {addOption.title}
            </Button>
          </span>
        )}
      </Tabs.List>
    </Tabs.Root>
  );
};
