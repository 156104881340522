import { ID } from "@api";
import { filter, map, without } from "lodash";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Fn } from "@utils/fn";
import { ComponentOrNode } from "@utils/react";

import { Sheet, StackSheets } from "./sheet-layout";
import { Button } from "@ui/button";
import { HStack } from "./flex";
import { Icon } from "@ui/icon";

import styles from "./pane-manager.module.css";

interface PaneItem {
  id: ID;
  title: string;
  icon: ComponentOrNode;
}

interface PaneManagerState {
  register?: Fn<PaneItem, void>;
  unregister?: Fn<ID, void>;
  isVisible?: Fn<ID, boolean>;
}

interface PaneItemProps {
  id: ID;
  title: string;
  icon: ComponentOrNode;
  children: ReactNode;
}

const PaneManagerContext = createContext<PaneManagerState>({});

export const PaneItem = ({ title, icon, id, children }: PaneItemProps) => {
  const { register, unregister, isVisible } = useContext(PaneManagerContext);

  useEffect(() => {
    register?.({ id, title, icon });
    return () => unregister?.(id);
  }, []);

  return <>{!!isVisible?.(id) && children}</>;
};

interface PaneManagerProps {
  size: "primary" | "secondary";
  children: React.ReactNode;
  defaultActive?: string[];
}

export const PaneManager = ({
  size,
  children,
  defaultActive = ["*"],
}: PaneManagerProps) => {
  const [panes, setPanes] = useState<PaneItem[]>([]);
  const [active, setActive] = useState<ID[]>(defaultActive);

  const register = useCallback(
    (pane: PaneItem) => setPanes((panes) => [...panes, pane]),
    [setPanes]
  );

  const unregister = useCallback(
    (id: string) => setPanes((panes) => filter(panes, (p) => p.id !== id)),
    [setPanes]
  );

  const toggleActive = useCallback((id: ID) => {
    setActive((active) =>
      active.includes(id)
        ? // Remove current if already active
          without(active, id)
        : // Add new value
          [id, ...active]
    );
  }, []);

  const isVisible = useCallback(
    (id: string) => active?.includes(id) || active?.includes("*"),
    [active]
  );
  const hiddenPanes = useMemo(
    () => filter(panes, (p) => !isVisible(p.id)),
    [panes, isVisible]
  );

  return (
    <PaneManagerContext.Provider value={{ register, unregister, isVisible }}>
      <div className={styles.scrollable}>
        <StackSheets
          size={size}
          height="container"
          className={styles.container}
        >
          {children}

          {!!hiddenPanes.length && (
            <Sheet className={styles.paneBar} size={size} height="content">
              <HStack gap={4} align="center">
                {map(hiddenPanes, (pane) => (
                  <Button
                    key={pane.id}
                    className={styles.paneItem}
                    subtle
                    variant={isVisible(pane.id) ? "primary" : "secondary"}
                    icon={<Icon size="medium" icon={pane.icon} />}
                    onClick={() => toggleActive(pane.id)}
                    // tooltip={pane.title}
                  />
                ))}
              </HStack>
            </Sheet>
          )}
        </StackSheets>
      </div>
    </PaneManagerContext.Provider>
  );
};
