import { useMemo } from "react";

import { useThemeColor } from "@state/settings";

import { switchEnum } from "@utils/logic";
import { fromColor } from "@utils/color";
import { heading } from "@tryfabric/martian/build/src/markdown";

export function GlassBackground({ children }: { children: React.ReactNode }) {
  const [themeColor] = useThemeColor();
  const [base, shade] = useMemo(() => fromColor(themeColor), [themeColor]);
  const image = useMemo(
    () =>
      switchEnum(base, {
        pink: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/8738bf81381729.5cfe51b90ae43.jpg",
        blue: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/fa9dba81381729.5cfe53dde3ecd.jpg",
        orange:
          "https://mir-s3-cdn-cf.behance.net/project_modules/disp/804c0381381729.5cfe517a354b0.jpg",
        purple:
          "https://mir-s3-cdn-cf.behance.net/project_modules/disp/d9449681381729.5cfe517a357b2.jpg",
        turquoise:
          "https://mir-s3-cdn-cf.behance.net/project_modules/disp/c96f2081381729.5cfe535103ace.jpg",
        red: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/6d772f81381729.5cff3310ccfe9.jpg",
        brown:
          "https://mir-s3-cdn-cf.behance.net/project_modules/disp/8469e781381729.5cff34ff1873e.jpg",
        yellow:
          "https://mir-s3-cdn-cf.behance.net/project_modules/disp/3845fc81381729.5cfe5351044c3.jpg",
        green:
          "https://mir-s3-cdn-cf.behance.net/project_modules/disp/2685a381381729.5cff34962ed5b.jpg",

        else: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/c5ec5481381729.5cff3bc4a5c77.jpg",
        // else: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/27d81a81381729.5cfe5554256a6.jpg",
        // else: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/b9717a81381729.5cff36dae27c4.jpg",
        // else: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/4c74a881381729.5cfe517a35b40.jpg",
      }),
    [themeColor]
  );
  const [fadeFrom, fadeTo] = useMemo(
    () =>
      switchEnum(shade, {
        1: [1, 3],
        2: [2, 4],
        3: [3, 5],
        4: [4, 6],
        5: [5, 7],
        else: [6, 8],
      }),
    [shade]
  );

  return (
    <div
      style={useMemo(
        () => ({
          // prettier-ignore
          // backgroundImage: `
          //   linear-gradient(180deg, rgba(255, 255, 255, 0.${shade * 2}), rgba(255, 255, 255, 0.${shade * 2 + 3})),
          //   linear-gradient(180deg, ${toColorVar(base, shade)}, ${toColorVar(base,Math.min(shade + 2, 5))})
          // `,
          backgroundImage: `
            linear-gradient(180deg, rgba(255, 255, 255, 0.${fadeFrom}), rgba(255, 255, 255, 0.${fadeTo})), 
            url(${image})
          `,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          heading: "100%",
        }),
        [themeColor, image]
      )}
    >
      {children}
    </div>
  );
}
