import { map } from "lodash";
import { useSetRecoilState } from "recoil";
import { useCallback, useMemo } from "react";

import { JsonArray, PropertyMutation, Schedule } from "@api";

import {
  AppCommandsAtom,
  setCommandsOpen,
  useOpenAppCommands,
} from "@state/app";
import {
  useLazyEntities,
  useLazyEntity,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { toSentence, useCreateNextForSchedule } from "@state/schedule";

import { maybeMap, safeAs, when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";
import { isAnyText, asPropertyValueRef } from "@utils/property-refs";
import { ensureMany, justOne, OneOrMany, uniqBy } from "@utils/array";
import { usePushTo } from "@utils/navigation";

import { useCurrentPage } from "@ui/app-page";
import { Centered, Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { PlusIcon, Slash } from "@ui/icon";
import { PropertyValueTile } from "@ui/property-value-tile";
import { Sheet } from "@ui/sheet-layout";
import { Button } from "@ui/button";
import { EditableHeading } from "@ui/editable-heading";
import { TemplateBanner } from "@ui/template-banner";
import { PackageTag } from "@ui/package-label";
import { LocationBreadcrumb } from "@ui/location-button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { EmptyState } from "@ui/empty-state";
import { Text, TextMedium } from "@ui/text";
import { Card } from "@ui/card";
import { ScopedPropertySelect } from "@ui/select/property";
import { PropertyValuesList } from "@ui/property-values-list";
import { EntityPreview } from "@ui/entity-preview";
import { TemplateSelect } from "@ui/select";
import { WorkflowActions } from "@ui/workflow-action-button";
import { EntityList } from "@ui/entity-list";
import { PaneHeader } from "@ui/pane-header";
import { Section } from "@ui/section";

import { PaneOpts } from "../types";

import { ScheduleConfiguration } from "./editor";

import styles from "./styles.module.css";

export const SchedulePane = ({ id, item }: PaneOpts<Schedule>) => {
  const mutate = useUpdateEntity(id);
  const pushTo = usePushTo();
  const template = useLazyEntity(item.useTemplate?.id);
  const overrides = item?.overrides;
  const itemSource = useNestedSource(item, item.entity);
  const updateOverride = useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      mutate(
        asMutation(
          { field: "overrides", type: "json" },
          safeAs<JsonArray>(
            maybeMap(overrides || [], (c) =>
              c.field === justOne(change)?.field ? justOne(change) : c
            )
          )
        )
      );
    },
    [overrides, mutate]
  );

  const addOverride = useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      mutate(
        asMutation(
          { field: "overrides", type: "json" },
          safeAs<JsonArray>(
            uniqBy(
              [...(overrides || []), ...ensureMany(change)],
              (c) => c.field,
              "last"
            )
          )
        )
      );
    },
    [overrides, mutate]
  );

  const values = useMemo(
    () => map(overrides, (c) => asPropertyValueRef(c, c.value)),
    [overrides]
  );

  return (
    <Sheet size="primary-thicc">
      {!!item.template && <TemplateBanner />}

      <Header item={item} />

      <Centered stack="vertical" gap={30}>
        <CollapsibleSection>
          <ScheduleConfiguration
            schedule={item}
            source={item.source}
            templateId={template?.id}
            showTemplate={false}
            showNext={false}
          />
        </CollapsibleSection>

        <Section
          title={
            item?.useTemplate ? (
              <TextMedium>Create from template</TextMedium>
            ) : item.entity ? (
              <TextMedium>
                Create{" "}
                <PackageTag
                  disabled={true}
                  type={item.entity}
                  scope={item.source.scope}
                />{" "}
                with field values
              </TextMedium>
            ) : undefined
          }
          actions={
            item?.useTemplate ? (
              <TemplateSelect
                scope={item?.source.scope}
                type={item.entity}
                allowed={when(item?.entity, ensureMany)}
                value={undefined}
                portal
                onChange={(r) =>
                  mutate(
                    asMutation({ field: "useTemplate", type: "relation" }, r)
                  )
                }
              >
                <Button size="small" subtle>
                  <Text subtle>Pick template</Text>
                </Button>
              </TemplateSelect>
            ) : undefined
          }
        >
          {template && (
            <>
              <Card onClick={() => pushTo(template)}>
                <EntityPreview entity={template} editable={false} />
              </Card>

              <Text bold>With additional fields:</Text>
            </>
          )}

          <PropertyValuesList
            values={values}
            source={itemSource}
            onMutate={updateOverride}
          >
            <SpaceBetween>
              <ScopedPropertySelect
                source={itemSource}
                portal={true}
                blacklist={(p) =>
                  isAnyText(p) || p.readonly === true || p.type === "checklist"
                }
                onChanged={(p) => p && addOverride(asMutation(p, undefined))}
              >
                <Button inset subtle icon={PlusIcon}>
                  <Text subtle>Add value</Text>
                </Button>
              </ScopedPropertySelect>

              <Button
                subtle
                onClick={() =>
                  mutate(asMutation({ field: "overrides", type: "json" }, []))
                }
              >
                <Text subtle>Clear</Text>
              </Button>
            </SpaceBetween>
          </PropertyValuesList>
        </Section>
      </Centered>
    </Sheet>
  );
};

export const ScheduleInstancesPane = ({ id, item }: PaneOpts<Schedule>) => {
  const { create, loading: creating } = useCreateNextForSchedule(id);
  const instances = useLazyEntities(item.instances || []);

  return (
    <Sheet size="primary" height="content" transparency="mid">
      <PaneHeader
        title="Created by this schedule"
        actions={
          <Button
            subtle
            onClick={() => create?.()}
            size="small"
            loading={creating}
            disabled={!create}
          >
            <Text subtle>Force create next</Text>
          </Button>
        }
      />
      <Centered stack="vertical" gap={30} size="half" padding="horizontal">
        <EntityList items={instances || []} limit={30} />

        {!instances?.length && <EmptyState text={"Nothing created yet..."} />}
      </Centered>
    </Sheet>
  );
};

interface HeaderProps {
  item: Schedule;
}

const Header = ({ item }: HeaderProps) => {
  const pageId = useCurrentPage();
  const setAppCommands = useSetRecoilState(AppCommandsAtom);
  const mutate = useUpdateEntity(item.id, pageId);

  const openCmdK = useOpenAppCommands(item);

  if (!item) {
    return <h1>Not found.</h1>;
  }

  return (
    <>
      <Centered className={styles.container}>
        <VStack gap={20}>
          <Container gap={20} padding="none" inset="bottom" stack="vertical">
            <Container
              padding="none"
              inset="left"
              stack="horizontal"
              fit="content"
            >
              <SpaceBetween>
                <LocationBreadcrumb location={item.location} />

                <HStack gap={2}>
                  <PackageTag type="schedule" scope={item.source.scope} />
                  <Button size="small" icon={Slash} subtle onClick={openCmdK}>
                    Modify
                  </Button>
                </HStack>
              </SpaceBetween>
            </Container>

            <VStack gap={0}>
              <Container padding="none" inset="left">
                <PropertyValueTile
                  entity={item}
                  label={false}
                  field={"status"}
                  onChange={mutate}
                />
              </Container>
              <SpaceBetween>
                <VStack gap={0}>
                  <TextMedium subtle={!!item.name} bold>
                    {toSentence(item)}
                  </TextMedium>
                  <EditableHeading
                    key={`${item.id}-heading`}
                    size="h3"
                    text={item.name || ""}
                    placeholder="Untitled schedule"
                    autoFocus={false}
                    onChange={(text) => {
                      when(text, (i) => {
                        mutate(asMutation({ field: "name", type: "text" }, i));
                      });
                    }}
                  />
                </VStack>

                <WorkflowActions entity={item} />
              </SpaceBetween>
            </VStack>
          </Container>
        </VStack>
      </Centered>
    </>
  );
};
