import { filter, isString, some, uniqBy } from "lodash";
import { Ref, RelationRef } from "@api";
import { Maybe } from "./maybe";
import { maybeLookupById } from "./array";

export const uniqRefs = (refs: Maybe<(RelationRef | Ref)[]>) =>
  uniqBy(refs || [], "id");

export const containsRef = (
  refs: Maybe<(RelationRef | Ref)[]>,
  ref: Ref | RelationRef | string
) => some(refs || [], { id: isString(ref) ? ref : ref?.id });
export const includesRef = containsRef;

export const withoutRef = (
  refs: Maybe<(RelationRef | Ref)[]>,
  ref: Maybe<Ref | RelationRef | string>
) => filter(refs, (r) => r.id !== (isString(ref) ? ref : ref?.id));

export const withoutRefs = (
  refs: Maybe<(RelationRef | Ref)[]>,
  exclude: Maybe<(RelationRef | Ref)[]>
) => {
  const ids = maybeLookupById(exclude || []);
  return filter(refs, (r) => !ids(r.id));
};
