import { RelationRef, RichText } from "@api";

import * as md from "./markdown";
import { isDefined, Maybe, when } from "./maybe";
import { maybeValues } from "./object";

const trimHtml = (html: Maybe<string>) =>
  html?.trim()?.replace("<p></p>", "")?.replace("<p> </p>", "") || "";

const trimMarkdown = (md: Maybe<string>) => md?.trim() || "";

export const hasValue = (rt: Maybe<RichText>) =>
  !!rt &&
  !!(
    trimHtml(rt.html) ||
    trimMarkdown(rt.markdown) ||
    rt.text?.trim() ||
    rt.state
  );

export const isEqual = (a: Maybe<RichText>, b: Maybe<RichText>) => {
  if (isDefined(a?.html) || isDefined(b?.html)) {
    return trimHtml(a?.html) === trimHtml(b?.html);
  }

  if (isDefined(a?.markdown) || isDefined(b?.markdown)) {
    return trimMarkdown(a?.markdown) === trimMarkdown(b?.markdown);
  }

  return a?.text === b?.text;
};

export const isMeaningfulChange = (a: Maybe<RichText>, b: Maybe<RichText>) =>
  !isEqual(a, b) && (hasValue(a) || hasValue(b));

export const isEmpty = (rt: Maybe<RichText>) => !hasValue(rt);

export const toTextLike = (text: Maybe<RichText>) =>
  text?.markdown || text?.html || "";

export const toMarkdown = (text: Maybe<RichText>) =>
  text?.markdown || when(text?.html, md.toMarkdown) || "";

export const toHtml = (text: Maybe<RichText>) =>
  text?.html || when(text?.markdown, md.toHtml) || "";

export const extractMentions = (rt: Maybe<RichText>) => {
  const text = toHtml(rt);
  // it returns an empty array if there are no links
  const links = {} as Record<string, Maybe<RelationRef>>;

  // Regex to extract mention ids inside data-mention-id attributes and the text inside the a tag
  const idRegex = /<a[^>]*data-mention-id="([^"]+)"[^>]*>([^<]+)<\/a>/g;

  let match;

  // Extract markdown links
  match = idRegex.exec(text);
  while (match != null) {
    const id = match[1];
    links[id] = {
      id,
      name: match[2],
    };
    match = idRegex.exec(text);
  }

  return maybeValues(links);
};

const AT_END = /@\w+(\.\w+)?\s*$/;
const AT_START = /^\s*@\w+(\.\w+)?/;
export const trimTrailingMentions = (input: string) =>
  input.replace(AT_END, "").replace(AT_START, "").trim();
