import { atomFamily, selectorFamily } from "recoil";

import { ID, Agenda } from "@api";

import {
  getItem,
  localStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type AgendaStoreState = StoreState<Agenda>;

export const WorkspaceAgendaStoreAtom = atomFamily<AgendaStoreState, ID>({
  key: "WorkspaceAgendaStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [localStorageForStore(wid, "agenda")],
});

export const AgendaStoreAtom = WorkspaceWrappedAtom(
  "AgendaStoreAtom",
  WorkspaceAgendaStoreAtom
);

export const AgendaAtom = selectorFamily({
  key: "AgendaAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(AgendaStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(AgendaStoreAtom, mergeItem<Agenda>(newValue));
      }
    },
});
