import { useCallback } from "react";
import { upload } from "@vercel/blob/client";

import { useActiveWorkspaceId, useSession } from "@state/workspace";

import { fromScope } from "@utils/scope";
import { toPath } from "@utils/url";
import { switchEnum } from "@utils/logic";
import { formatShort, formatTime } from "@utils/date";
import { now } from "@utils/now";

const toFileSafe = (name: string) => encodeURIComponent(name);

export function useUpload(scope: string) {
  const workspaceId = useActiveWorkspaceId();
  const session = useSession();

  return useCallback(
    async (file: File) => {
      const name = switchEnum(file.name, {
        "image.png": () =>
          `From clipboard – ${formatShort(now())} at ${formatTime(now())}`,
        else: () => file.name,
      });

      const newBlob = await upload(
        [workspaceId, ...fromScope(scope), toFileSafe(name)]?.join("/"),
        file,
        {
          access: "public",
          handleUploadUrl: "/api/upload",
          multipart: true,
          clientPayload: `Bearer ${session.token}`,
        }
      );

      return {
        name: name,
        path: toPath(newBlob.url),
        url: newBlob.url,
        mimeType: newBlob.contentType,
      };
    },
    [scope]
  );
}
