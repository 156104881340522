import { first, map, omit, uniq, without } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  DatabaseID,
  Entity,
  EntityType,
  hasIcon,
  HasLocation,
  Ref,
  toTitleOrName,
} from "@api";

import { useRegisterPage, useSyncPageSelection } from "@state/app";
import { useInstalledEntities } from "@state/packages";
import {
  useCreateFromObject,
  useCreateFromTemplate,
  useLazyEntities,
} from "@state/generic";
import { useLazyAllTeams } from "@state/teams";
import { useLazyTemplates, useTaskTemplates } from "@state/templates";
import { useActiveWorkspaceId, useCurrentUser } from "@state/workspace";
import { useEntityLabel, useEntityLabels } from "@state/settings";

import { OneOrMany, ensureArray, ensureMany } from "@utils/array";
import { Fn } from "@utils/fn";
import { useGoTo } from "@utils/navigation";
import { Maybe, when } from "@utils/maybe";
import {
  PageSelectionContext,
  SelectionState,
  SetSelectionState,
  useKeyboardSelectable,
  useMouseSelectable,
  useSelectableState,
} from "@utils/selectable";
import { fromLocation, fromScope, toBaseScope } from "@utils/scope";
import { plural } from "@utils/string";

import { Container } from "@ui/container";
import { Props as DialogProps, DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { render, useEngine } from "@ui/engine";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowDownRight,
  ArrowUpLeft,
  ArrowUpRight,
  EyeSlash,
  Icon,
  iconFromString,
  PaintTool,
  PlusAlt,
  PlusIcon,
  ProjectIcon,
  TimesIcon,
} from "@ui/icon";
import { ListCard } from "@ui/list-card";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { CheckMenuItem, EmptyMenuItem, MenuItem } from "@ui/menu-item";
import { PeopleStack } from "@ui/people-stack";
import { Text, TextXLarge } from "@ui/text";
import { showError } from "@ui/notifications";
import { useCurrentPage } from "@ui/app-page";
import { MouseSelection } from "@ui/mouse-selection";
import { Button, Props as ButtonProps } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { LocationSelect } from "@ui/select";
import { EntityList, Props as EntityListProps } from "./entity-list";
import { useSelectionShortcuts } from "./selection-shortcuts";
import { ActionGroup, ActionItem, ActionMenu } from "@ui/action-menu";

import styles from "./add-work-dialog.module.css";

interface Props {
  defaults:
    | { source?: DatabaseID<EntityType> }
    | Partial<HasLocation>
    | Partial<Entity>;
  allowed?: EntityType[];
  onSaved?: Fn<OneOrMany<Ref>, void>;
  onCancel?: Fn<void, void>;
  onModeChanged?: Fn<DialogProps["mode"], void>;
}

export const AddWorkDialog = ({
  onCancel,
  allowed,
  onSaved,
  defaults,
}: Props) => {
  const [page] = useRegisterPage("task-create", undefined);
  const goTo = useGoTo();
  const [location, setLocation] = useState<Maybe<string>>(
    (defaults as HasLocation)?.location
  );
  const [dialogMode, setDialogMode] = useState<DialogProps["mode"]>("blocking");
  const [mode, setMode] = useState<"team-select" | "creating">(
    !!location ? "creating" : "team-select"
  );
  const templates = useTaskTemplates();

  const [selection, setSelection] = useSelectableState();
  useKeyboardSelectable(selection, setSelection);
  const drag = useMouseSelectable(selection, setSelection);
  useSelectionShortcuts(page.id, { selection, setSelection, onOpen: goTo });
  useSyncPageSelection(page.id, selection);

  const selectionState = useMemo(
    () => [selection, setSelection] as [SelectionState, SetSelectionState],
    [selection, setSelection]
  );

  const menuControl = (
    <Menu>
      <Text className={styles.spaceBottom} subtle>
        Quickly create any work for your team.
      </Text>

      <MenuGroup label="Submit a form">
        {map(templates, (t) => (
          <MenuItem key={t.id} icon={ProjectIcon} text={t.name} />
        ))}

        {!templates?.length && <EmptyMenuItem>No forms</EmptyMenuItem>}
      </MenuGroup>

      <MenuGroup label="Start workflow">
        {map(templates, (t) => (
          <MenuItem key={t.id} icon={ProjectIcon} text={t.name} />
        ))}
        {!templates?.length && <EmptyMenuItem>No workflows</EmptyMenuItem>}
      </MenuGroup>
    </Menu>
  );

  return (
    <DialogSplit
      mode={dialogMode}
      title={
        <SpaceBetween>
          <TextXLarge bold={true}>Add work</TextXLarge>
          {location && (
            <LocationSelect
              location={location}
              onChange={setLocation}
              source={undefined}
              showOpen={false}
              variant="compact"
              showCaret={true}
            />
          )}
        </SpaceBetween>
      }
      onDismiss={onCancel}
      className={{
        modal: styles.modal,
        left: mode === "creating" ? styles.leftThicc : styles.left,
        right: styles.right,
      }}
      side={
        <SpaceBetween direction="vertical">
          {mode === "creating" ? (
            menuControl
          ) : (
            <Text subtle>Quickly add tasks to any team or project.</Text>
          )}
          <EducationTip tip="You can quickly assign tasks by @mentioning the person in the task title." />
        </SpaceBetween>
      }
      actions={<></>}
    >
      <PageSelectionContext.Provider value={selectionState}>
        <MouseSelection {...drag} />
        <FillSpace>
          {mode === "team-select" && (
            <LocationPicker
              onLocation={(l) => {
                setLocation(l);
                setMode("creating");
              }}
            />
          )}
          {mode === "creating" && (
            <CreatingWork
              location={location || ""}
              allowed={allowed}
              defaults={defaults}
              onSaved={onSaved}
              onCancel={onCancel}
              mode={dialogMode}
              onModeChanged={setDialogMode}
            />
          )}
        </FillSpace>
      </PageSelectionContext.Provider>
    </DialogSplit>
  );
};

const CreatingWork = ({
  location,
  defaults,
  mode,
  allowed,
  onModeChanged,
  onCancel,
}: Props & { location: string; mode?: DialogProps["mode"] }) => {
  const goTo = useGoTo();
  const workspaceId = useActiveWorkspaceId();
  const baseScopeId = useMemo(() => first(fromScope(location)), [location]);
  const scope = useMemo(() => fromLocation(location, workspaceId), [location]);
  const installed = useInstalledEntities(baseScopeId || "never");
  const types = useMemo(() => allowed || installed, [allowed, installed]);
  const [adding, setAdding] = useState<EntityType[]>(
    when(types[0], ensureArray) || ["task"]
  );
  const buttons = useMemo(() => without(types, ...adding), [types, adding]);
  const toEntityLabel = useEntityLabels(location);

  useEffect(() => {
    setAdding(types[0] ? [types[0]] : []);
  }, [location]);

  return (
    <VStack>
      <SpaceBetween>
        <HStack>
          {map(buttons, (type) => (
            <Button
              icon={PlusIcon}
              subtle
              size="small"
              onClick={() => setAdding((a) => uniq([...a, type]))}
            >
              {toEntityLabel(type)}
            </Button>
          ))}
        </HStack>
        <HStack gap={0}>
          {mode === "blocking" && (
            <Button
              icon={ArrowDownRight}
              subtle
              onClick={() => onModeChanged?.("passive")}
            />
          )}
          {mode === "passive" && (
            <Button
              icon={ArrowUpLeft}
              subtle
              onClick={() => onModeChanged?.("blocking")}
            />
          )}
          <Button icon={TimesIcon} subtle onClick={() => onCancel?.()} />
        </HStack>
      </SpaceBetween>

      {map(adding, (type) => (
        <CollapsibleSection title={plural(toEntityLabel(type))}>
          <AddItemsList
            scope={scope}
            type={type}
            location={location}
            defaults={defaults}
            onOpen={(e) => {
              goTo(e);
              onModeChanged?.("passive");
            }}
          />
        </CollapsibleSection>
      ))}
    </VStack>
  );
};

const AddItemsList = ({
  type,
  scope,
  location,
  defaults,
  onOpen,
}: {
  type: EntityType;
  scope: string;
  location: string;
  defaults: Props["defaults"];
  onOpen?: Fn<Ref, void>;
}) => {
  const [created, setCreated] = useState<Ref[]>([]);
  const items = useLazyEntities(created);
  const source = useMemo(() => ({ scope, type }), [scope, type]);
  const onAdded = useCallback(
    (cs: OneOrMany<Ref>) => setCreated((c) => [...c, ...ensureMany(cs)]),
    [setCreated]
  );

  const addButtonOpts = useMemo(
    () =>
      ({
        defaults: { ...defaults, location: location },
        onAdded: onAdded,
        canLink: false,
        source,
      } as EntityListProps["addButton"]),
    [defaults, location, onAdded]
  );

  return (
    <EntityList
      showCode={false}
      variant="icon-only"
      onOpen={onOpen}
      items={items || []}
      addButton={addButtonOpts}
    />
  );
};

const LocationPicker = ({
  onLocation,
}: {
  onLocation: Fn<Maybe<string>, void>;
}) => {
  const [saveAsDefault, setSaveAsDefault] = useState(false);
  const teams = useLazyAllTeams();
  const engine = useEngine("team");
  const me = useCurrentUser();

  return (
    <VStack fit="container" gap={10}>
      <SpaceBetween fit="container">
        <Text>Where do you want to create this work?</Text>
        <span>
          <CheckMenuItem checked={saveAsDefault} onChecked={setSaveAsDefault}>
            Save as default location
          </CheckMenuItem>
        </span>
      </SpaceBetween>

      <ListCard
        padding="none"
        selectable={false}
        onClick={() => onLocation?.(me.id)}
      >
        <Container fit="container" stack="vertical" gap={10}>
          <HStack>
            <Icon icon={EyeSlash} />

            <TextXLarge bold>Private</TextXLarge>
          </HStack>

          <Text subtle>Nobody else will see this work.</Text>

          <PeopleStack people={[me]} />
        </Container>
      </ListCard>

      {map(teams, (t) =>
        render(engine.asListCard, {
          key: t.id,
          item: t,
          onOpen: (t) => onLocation(t.id),
        })
      )}
    </VStack>
  );
};

export const SmartDefaultsAddWorkDialog = ({
  entityId,
  defaults,
  ...props
}: { entityId: Maybe<string> } & Props) => {
  // const entity = useRecoilValue(GenericItem(entityId || ""));

  // TODO: Re-write using properties
  // const defaults = useMemo(
  //   () =>
  //     fallback(
  //       (): Maybe<Props["defaults"]> =>
  //         switchEnum(entity?.source?.type || "", {
  //           project: () => ({
  //             projects: when(entity, composel(toRef, ensureArray)),
  //           }),
  //           task: () => ({
  //             projects: when(
  //               (entity as Task)?.refs?.projects?.[0],
  //               composel(toRef, ensureArray)
  //             ),
  //           }),
  //           else: () => undefined,
  //         }),
  //       () =>
  //         when(
  //           isTemplateViewId(entityId || "")
  //             ? fromTemplateViewId(entityId || "")
  //             : undefined,
  //           ({ params }) => ({
  //             parent: when(params?.parent, (id) => ({ id })),
  //             team: when(params?.team, (id) => ({ id })),
  //             projects: when(params?.project, (p) => [{ id: p }]),
  //           })
  //         ),
  //       () => _defaults
  //     ),
  //   [entity?.source]
  // );

  return <AddWorkDialog {...props} defaults={defaults} />;
};

export const AddWorkButton = (props: ButtonProps & Props) => {
  const {
    defaults,
    onSaved,
    onCancel,
    allowed,
    onModeChanged,
    ...buttonProps
  } = props;
  const dialogProps = { defaults, onSaved, onCancel, allowed, onModeChanged };
  const [showing, setShowing] = useState(false);
  const type = props.defaults?.source?.type;
  const engine = useEngine(type);

  return (
    <>
      {showing && !engine?.asCreateDialog && (
        <AddWorkDialog {...dialogProps} onCancel={() => setShowing(false)} />
      )}

      {showing &&
        !!engine?.asCreateDialog &&
        render(engine?.asCreateDialog, {
          ...dialogProps,
          onSaved: (r) => {
            onSaved?.(r);
            setShowing(false);
          },
          onCancel: () => setShowing(false),
        })}

      <Button
        size="small"
        icon={PlusIcon}
        {...buttonProps}
        onClick={() => setShowing(true)}
      />
    </>
  );
};

type ActionMenuProps = {
  defaults:
    | { source?: DatabaseID<EntityType> }
    | Partial<HasLocation>
    | Partial<Entity>;
  onSaved?: Fn<OneOrMany<Ref>, void>;
} & Omit<ButtonProps, "onClick">;

export const AddWorkActionMenu = ({
  defaults,
  onSaved,
  children,
  ...rest
}: ActionMenuProps) => {
  const pageId = useCurrentPage();
  const wId = useActiveWorkspaceId();
  const source = useMemo(
    () => defaults.source || ({ type: "task", scope: wId } as DatabaseID),
    [defaults.source]
  );
  const engine = useEngine(source.type);
  const [showCreate, setShowCreate] = useState(false);
  const create = useCreateFromObject(source.type, source.scope, pageId);
  const { create: createTemplate, loading } = useCreateFromTemplate(
    source,
    onSaved
  );
  const goTo = useGoTo();
  const label = useEntityLabel(source.type, source.scope, {
    case: "lower",
  });
  const templates = useLazyTemplates(source);

  const handleCreateBlank = useCallback(() => {
    if (engine.asCreateDialog) {
      setShowCreate(true);
      return;
    }

    if (!create) {
      return showError("Not ready.");
    }

    const [neww] = create([
      { source: source, ...defaults, name: "" } as Partial<Entity>,
    ]);
    onSaved?.(neww);
  }, [create, engine]);

  const handleCreateFromTemplate = useCallback(
    (template: Ref) => {
      if (!createTemplate) {
        return showError("Not ready.");
      }

      createTemplate(template, {
        overrides: { ...omit(defaults, "source"), name: "" },
      });
    },
    [createTemplate]
  );

  return (
    <>
      <ActionMenu
        actions={
          <>
            <ActionItem icon={PlusAlt} onClick={handleCreateBlank}>
              From empty
            </ActionItem>

            <ActionGroup label="From template">
              {map(templates, (template: Entity) => (
                <ActionItem
                  key={template.id}
                  icon={
                    (template &&
                      hasIcon(template) &&
                      iconFromString(template.icon)) ||
                    PaintTool
                  }
                  onClick={() => handleCreateFromTemplate(template)}
                >
                  {toTitleOrName(template)}
                </ActionItem>
              ))}
            </ActionGroup>

            <ActionItem
              onClick={() =>
                goTo([toBaseScope(source.scope), "/settings/templates"])
              }
              iconRight={ArrowUpRight}
            >
              <Text subtle>Manage Templates</Text>
            </ActionItem>
          </>
        }
      >
        {children || (
          <Button icon={PlusIcon} loading={loading} {...rest}>
            <Text subtle>New {label}</Text>
          </Button>
        )}
      </ActionMenu>

      {showCreate &&
        !!engine?.asCreateDialog &&
        render(engine?.asCreateDialog, {
          defaults,
          onSaved: (r) => {
            onSaved?.(r);
            setShowCreate(false);
          },
          onCancel: () => setShowCreate(false),
        })}
    </>
  );
};
